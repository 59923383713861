import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CREATING_SUBFOLDER, SUBTITLES_FOR_DIALOGS } from 'common/constants/documents';
import { CustomTextField } from 'components';
import { FormDialog } from 'components/Dialogs';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocuments } from 'services/hooks';
import { useFormValidation } from 'services/hooks/useFormValidation';
import CustomAutoSelect from './CustomAutoSelect/CustomAutoSelect';

const useStyles = makeStyles({
  foldername: {
    marginTop: '1rem',
  },
  label: {
    marginBottom: '0.5rem',
  },
});

const constraints = {
  foldername: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
};

const AddFolderDialog = ({ open, onClose, title, currentFolders, getNewFolder }) => {
  const classes = useStyles();
  const { company_id, setCompanyDocuments, currentDocuments, setCurrentDocuments } = useDocumentsContext();
  const { getDocuments, createSubFolder } = useDocuments();
  const [formState, setFormState] = useState({
    values: {
      parentfolder: null,
      foldername: '',
    },
    errors: {},
  });
  const [formValues, setFormValues] = useState(formState.values);
  const isCreatingFolder = useRef(false);
  const loadingText = useRef(null);

  const { formValidated, validateForm } = useFormValidation(constraints);
  const updatedCurrentFolders = [{ id: 0, name: 'No Option' }].concat(currentFolders);

  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  useEffect(() => {
    setFormState(formValidated);
  }, [formValidated]);

  const handleChange = event => {
    event.persist();
    setFormValues(currentFormValues => ({
      ...currentFormValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleParentFolderChange = id => {
    const parentFolderId = id === 0 ? null : id;
    setFormValues(currentFormValues => ({
      ...currentFormValues,
      parentfolder: parentFolderId,
    }));
  };

  const refreshDocuments = useCallback(async () => {
    const updatedCompanyDocuments = await getDocuments(company_id);
    setCompanyDocuments(updatedCompanyDocuments);
    /* necessary to update the folders available
    after closing the dialog from a page that's not Documents */
    const updatedCurrentDocuments = updatedCompanyDocuments.find(documents => documents.id === currentDocuments.id);
    setCurrentDocuments(updatedCurrentDocuments);
  }, [setCompanyDocuments, getDocuments, company_id, currentDocuments, setCurrentDocuments]);

  const parentFolderId = formValues.parentfolder === '0' ? null : formValues.parentfolder;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const folderData = {
    md_documents_id: currentDocuments.id,
    parent_folder_id: parentFolderId,
    name: formValues.foldername || null,
  };

  const handleSave = useCallback(async () => {
    loadingText.current = CREATING_SUBFOLDER;
    isCreatingFolder.current = true;
    const response = await createSubFolder(currentDocuments.id, folderData);
    if (getNewFolder) {
      getNewFolder(response);
    }
    isCreatingFolder.current = false;
    loadingText.current = null;
    await refreshDocuments();
  }, [createSubFolder, getNewFolder, currentDocuments, folderData, refreshDocuments]);

  return (
    <FormDialog
      open={open}
      title={title}
      customButtonLabel="Done"
      onSave={handleSave}
      onClose={onClose}
      isValid={formState.isValid && !isCreatingFolder.current}
      isLoading={isCreatingFolder.current}
      loadingText={loadingText.current}>
      <>
        <FormControl fullWidth>
          {currentFolders && updatedCurrentFolders && (
            <>
              <Typography className={classes.label} variant="h3">
                Select an existing folder
              </Typography>
              <CustomAutoSelect
                inputProps={{
                  id: 'parent-folder',
                  name: 'parent-folder',
                  label: 'Select a folder',
                }}
                data={updatedCurrentFolders}
                onChange={handleParentFolderChange}
              />
            </>
          )}
          <CustomTextField
            id="folder-name"
            className={classes.foldername}
            name="foldername"
            type="text"
            label="Name"
            required
            fullWidth
            onChange={handleChange}
            value={formValues.foldername}
          />
        </FormControl>
        <Typography variant="caption" style={{ margin: '0.5rem 0', color: 'darkgray' }}>
          {SUBTITLES_FOR_DIALOGS('folder')}
        </Typography>
      </>
    </FormDialog>
  );
};

AddFolderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  currentFolders: PropTypes.array,
  getNewFolder: PropTypes.func,
};

export default AddFolderDialog;
