import { useCallback, useEffect, useState } from 'react';
import { globalAction } from 'common/actions';
import { useStore } from 'common/store';

const useDataSheetDialogActions = (isLedger = false) => {
  const [clearSelected, setClearSelected] = useState(false);
  const [{ dialogFlag }, dispatch] = useStore();

  const { openDialogFlag, dialogs } = dialogFlag;

  useEffect(() => {
    if (openDialogFlag && !isLedger) {
      setClearSelected(true);
    }
  }, [openDialogFlag, isLedger]);

  const setDialogFlag = useCallback(
    ({ open, dialogId, cellId, disableWhenLedgerOpen, alias }) => {
      const newDialogs = open
        ? [...dialogs, { dialogId, cellId, disableWhenLedgerOpen, alias }]
        : dialogs.filter(({ modalId: id }) => dialogId !== id);

      const newOpenDialogFlag = newDialogs && newDialogs.length > 0;

      dispatch(
        globalAction.setDialogFlag({
          openDialogFlag: newOpenDialogFlag,
          dialogs: newDialogs,
        })
      );
    },
    [dispatch, dialogs]
  );

  return {
    openDialogFlag,
    dialogs,
    setDialogFlag,
    clearSelectedFlag: clearSelected,
    setClearSelected,
  };
};

export default useDataSheetDialogActions;
