import React, { useCallback, useEffect, useState } from 'react';
import { DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Dialog } from 'components/Dialogs';
import { RESULTS_DISPLAY } from 'pages/DocumentGenerationJobs/constants';
import { useResponse } from 'services/hooks';
import ResultsDisplayModalProps from './ResultsDisplayModal.props';
import { TableData } from './types';

const ResultsDisplayModal = ({ resultsJsonString, children, ...rest }: ResultsDisplayModalProps) => {
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const { errorNotification } = useResponse();

  const generateFormattedResults = useCallback(() => {
    try {
      const resultsObject = JSON.parse(resultsJsonString);
      const { securityData } = resultsObject.data;

      // Extract unique column names
      const columns = new Set<string>();
      securityData.forEach((item: TableData) => {
        Object.keys(item).forEach(key => columns.add(key));
      });

      setColumnNames(Array.from(columns));
      setTableData(securityData);
    } catch (e) {
      errorNotification(RESULTS_DISPLAY.ERROR_PARSING_RESULTS);
    }
  }, [resultsJsonString, errorNotification]);

  useEffect(() => {
    if (resultsJsonString) {
      generateFormattedResults();
    }
  }, [resultsJsonString, generateFormattedResults]);

  return (
    <Dialog open={rest.open} onClose={() => rest.onClose && rest.onClose({}, 'backdropClick')}>
      <DialogTitle>{RESULTS_DISPLAY.TITLE}</DialogTitle>
      <Table>
        <TableHead>
          <TableRow>
            {columnNames.map(colName => (
              <TableCell key={colName}>{colName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, rowIndex) => (
            <TableRow key={`${row.securityName}_${JSON.stringify(row)}`}>
              {columnNames.map(colName => (
                <TableCell key={colName}>{row[colName] ?? '-'}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default ResultsDisplayModal;
