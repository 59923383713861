import { Cell, WorkbookCell } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import {
  FIVE_YEAR_VOLATILITY,
  ONE_YEAR_VOLATILITY,
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  TWO_YEAR_VOLATILITY,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit';
import { FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { VALUATIONS_PUBLIC_COMPANIES_APPROACH } from 'pages/ValuationsAllocation/common/constants/valuations';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utillities';
import { Change, CustomAfterCellChangedParams } from './types';

const getMExpression = (v1: string, v2: string, y1: number, y2: number) => `(${v2} - ${v1}) / (${y2} - ${y1})`;
const getVolatilityExpr = (v1: string, v2: string, y1: number, y2: number, maturityYearCellKey = 'B2') => {
  const m = getMExpression(v1, v2, y1, y2);
  return `=${m} * ${maturityYearCellKey} + ${v2} - ${m} * ${y2}`;
};

const calculateVolatilityValue = (comparison: number, gpcApproach: ValuationsApproach) => {
  const tableName = getApproachTableName({
    approach: gpcApproach,
    tableSuffix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  });

  let volatilityValue;
  switch (true) {
    case comparison <= 1:
      volatilityValue = `=${tableName}.${ONE_YEAR_VOLATILITY}`;
      break;
    case comparison > 1 && comparison < 2:
      volatilityValue = getVolatilityExpr(
        `${tableName}.${ONE_YEAR_VOLATILITY}`,
        `${tableName}.${TWO_YEAR_VOLATILITY}`,
        1,
        2,
        'A4'
      );
      break;
    case comparison > 2 && comparison < 5:
      volatilityValue = getVolatilityExpr(
        `${tableName}.${TWO_YEAR_VOLATILITY}`,
        `${tableName}.${FIVE_YEAR_VOLATILITY}`,
        2,
        5,
        'A4'
      );
      break;
    case comparison === 2:
      volatilityValue = `=${tableName}.${TWO_YEAR_VOLATILITY}`;
      break;
    default:
      volatilityValue = `=${tableName}.${FIVE_YEAR_VOLATILITY}`;
      break;
  }
  return volatilityValue;
};

const getCell = ({ allCells, sheetName, key }: any) => {
  const sheet = allCells[sheetName];
  return sheet[key];
};

const addCellToChangeList = (changeList: any, cell: Cell, value?: string | number | null) => {
  const cellInChangeList = changeList.find((change: any) => change.cell === cell);
  if (!cellInChangeList) {
    changeList.push({ cell, value });
  }
};

const customAfterCellChanged = (params: CustomAfterCellChangedParams) => {
  const { cells, initialChanges, approach, approaches, allCells } = getObjectValue(params);

  const {
    FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
    FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
    FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE,
  } = SHEET_ALIASES_CONSTANTS;

  const changes = [...initialChanges];
  const { cell, value } = changes[0];

  const maturityCell = cells.A4;
  const volatilitySourceCell = cells.A7;
  const volatilityCell = cells.A8;

  if (cell.alias === FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD) {
    // use FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_WATERFALL_CSE and
    // FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_OPM combined
    // with the getApproachTableName() function and the tableData.approach object
    // to get the names of the relevant sheets in the allCells object.
    const relatedSheetNames = [
      getApproachTableName({
        approach,
        tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_WATERFALL_CSE,
      }),
      getApproachTableName({
        approach,
        tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_OPM,
      }),
    ];
    // get the allocation methods cells, add them to the changeList
    relatedSheetNames
      .map(sheetName =>
        getCell({
          allCells,
          sheetName,
          key: 'A1',
        })
      )
      .forEach((changeCell: any) => addCellToChangeList(changes, changeCell, value));
    changes.forEach(change => {
      // eslint-disable-next-line no-param-reassign
      change.cell.value = change.value;
    });
    const relatedAllocationScenarioCells = Object.values(allCells.equityAllocation).filter(
      c =>
        (c as WorkbookCell).alias === EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD
        && (c as WorkbookCell).value?.toString()?.split('__')?.[1] === approach?.panelId
    );
    relatedAllocationScenarioCells.forEach(c => {
      const changeCell = c as WorkbookCell;
      const scenarioChanges = changeCell.sheet.afterCellChanged(
        [{ cell: changeCell, value: changeCell.expr }],
        changeCell.sheet.cells,
        changeCell.sheet.rowConfig,
        changeCell.sheet.tableData,
        allCells
      );
      scenarioChanges.forEach(scenarioChange => {
        changes.push(scenarioChange as Change);
      });
    });
  }

  const isVolatilityCell = cell.alias === FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE;
  const isMaturityYearsCell = cell.alias === FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS;

  if (isVolatilityCell || isMaturityYearsCell) {
    const gpcApproaches = approaches?.filter(
      ({ approach_type }: any) => approach_type === VALUATIONS_PUBLIC_COMPANIES_APPROACH
    );
    const valueId = isVolatilityCell ? value : Number(volatilitySourceCell.value);
    const isSpecified = volatilitySourceCell.value === null;
    let volatilityValue: string;
    const gpcApproach = gpcApproaches?.find(
      ({ valuations_approach_gpc, panelId }: any) => valuations_approach_gpc.id === valueId || panelId === valueId
    );
    if (gpcApproach) {
      const comparison = Number(isVolatilityCell ? maturityCell.value : value);
      volatilityValue = calculateVolatilityValue(comparison, gpcApproach);
    } else {
      volatilityValue = volatilityCell.value as string;
    }
    volatilityCell.readOnly = !isSpecified;
    changes.push({ cell: volatilityCell, value: volatilityValue });
  }

  return changes;
};

export default customAfterCellChanged;
