import {
  APPROACH_SELECTION,
  EBITDA_APPROACH_SELECTION,
  EBITDA_GPC_MULTIPLE,
  EBITDA_GPC_MULTIPLE_SELECTION,
  EBITDA_GPC_TITLE,
  EBITDA_TOTAL,
  GPC_MULTIPLE,
  GPC_MULTIPLE_SELECTION,
  METRIC_TOTAL,
  REVENUE_APPROACH_SELECTION,
  REVENUE_GPC_MULTIPLE,
  REVENUE_GPC_MULTIPLE_SELECTION,
  REVENUE_TOTAL,
} from 'pages/Valuations/util/constants';
import getColumns from '../../util/getColumns';

const getCombinedColumns = ({ valuationApproachDCF, dCFFinancialPeriod }) => {
  const [column] = getColumns({ valuationApproachDCF, dCFFinancialPeriod });
  const revColumn = {
    ...column,
    [APPROACH_SELECTION]: column[REVENUE_APPROACH_SELECTION],
    [GPC_MULTIPLE_SELECTION]: column[REVENUE_GPC_MULTIPLE_SELECTION],
    [GPC_MULTIPLE]: column[REVENUE_GPC_MULTIPLE],
    [EBITDA_GPC_TITLE]: 'Revenue',
    [METRIC_TOTAL]: column[REVENUE_TOTAL],
  };
  const ebitdaColumn = {
    ...column,
    [APPROACH_SELECTION]: column[EBITDA_APPROACH_SELECTION],
    [GPC_MULTIPLE_SELECTION]: column[EBITDA_GPC_MULTIPLE_SELECTION],
    [GPC_MULTIPLE]: column[EBITDA_GPC_MULTIPLE],
    [EBITDA_GPC_TITLE]: 'EBITDA',
    [METRIC_TOTAL]: column[EBITDA_TOTAL],
  };
  return [revColumn, ebitdaColumn];
};

export default getCombinedColumns;
