import { AllocationCapTableModel } from 'api';
import { NewAllocationScenario } from 'common/types/allocation';
import { Cells } from 'common/types/scalarSpreadsheet';
import { handleScenarioWeightingProbability } from 'pages/ValuationsAllocation/approaches/ValuationSummary/config/customReverseParser/utils';
import {
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_CSE_KEY,
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHOD_OPM_KEY,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY,
  ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import {
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
  EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases';
import { WeightingProbability } from 'pages/ValuationsAllocation/types';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue, getStringValue, parseFloatToFixed } from 'utillities';
import { CustomReverseParserParams, NameToIdMap } from './types';
import { checkAndResetScenario, handleScenarioType } from './utils';
import { EquityAllocationCellCustomData } from '../types';

const {
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  EQUITY_ALLOCATION_SPREADSHEET_TOTAL,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const getShareValues = (
  cells: Cells<EquityAllocationCellCustomData>,
  parent: string,
  columnLegend: string,
  securityNameToIdMap: NameToIdMap
) =>
  Array.from(new Set(Object.values(cells).filter(c => c.parent === parent && c.columnLegend === columnLegend)))
    .map(c => ({
      security: {
        id: securityNameToIdMap[c?.data?.security?.name as string],
        name: c?.data?.security?.name,
      },
      value: Number(c.value),
    }))
    .filter(c => c.security.id);

const customReverseParser = (params: CustomReverseParserParams) => {
  const { allocationScenarios, approaches, capTableVersions, cells, fieldAttributes, updateWeightingProbabilities }
    = params;

  Object.values(cells).forEach(cell => {
    const { alias, data, value } = getObjectValue(cell);
    const { scenarioId, scenarioRef } = getObjectValue(data);

    // Scenario
    const scenario = getObjectValue(
      (allocationScenarios as NewAllocationScenario[])?.find(allocationScenario => {
        const { id: currentScenarioId, scenario_ref: currentScenarioRef } = getObjectValue(allocationScenario);

        const matchScenarioIds = currentScenarioId && scenarioId && currentScenarioId === scenarioId;
        const matchScenarioRefs = currentScenarioRef && scenarioRef && currentScenarioRef === scenarioRef;

        return matchScenarioIds || matchScenarioRefs;
      })
    );

    // Scenario Weighting/Probability
    const scenarioWeightingProbability = getNumberValue(parseCellValue({ cell }));

    const weightingProbability = {
      allocation_scenario_id: scenarioId,
      weight: scenarioWeightingProbability,
    } as WeightingProbability;

    // Fields Decimal Places
    const maturityFieldDecimalPlaces = getNumberValue(
      fieldAttributes?.allocationScenarioAttrs?.maturity?.decimal_places
    );

    const volatilityFieldDecimalPlaces = getNumberValue(
      fieldAttributes?.allocationScenarioAttrs?.volatility?.decimal_places
    );

    const exitEquityValueFieldDecimalPlaces = getNumberValue(
      fieldAttributes?.allocationScenarioAttrs?.exit_equity_value?.decimal_places
    );

    const equityValueFieldDecimalPlaces = getNumberValue(
      fieldAttributes?.allocationScenarioAttrs?.equity_value?.decimal_places
    );

    const totalAggregateValueFieldDecimalPlaces = getNumberValue(
      fieldAttributes?.allocationScenarioAttrs?.total_aggregate_value?.decimal_places
    );
    const securityNameIdMap = scenario.cap_table?.securities?.reduce((acc, security) => {
      acc[security.name] = security.id as number;
      return acc;
    }, {} as NameToIdMap);

    switch (alias) {
      // Handle Allocation Method
      case EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD:
        switch (value) {
          case ALLOCATION_SCENARIO_METHOD_OPM_KEY:
            checkAndResetScenario({
              scenario,
              scenarioApproachId: null,
              scenarioMethod: ALLOCATION_SCENARIO_METHOD_OPM,
              scenarioType: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
            });

            scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_OPM;
            scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE;
            break;

          case ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY:
            checkAndResetScenario({
              scenario,
              scenarioApproachId: null,
              scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
              scenarioType: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
            });

            scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_WATERFALL;
            scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE;
            break;

          case ALLOCATION_SCENARIO_METHOD_CSE_KEY:
            checkAndResetScenario({
              scenario,
              scenarioApproachId: null,
              scenarioMethod: ALLOCATION_SCENARIO_METHOD_CSE,
              scenarioType: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
            });

            scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_CSE;
            scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE;
            break;

          // Add Approach
          case ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY:
          case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY:
          case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY:
            break;

          // Handle Scenario Type
          default:
            handleScenarioType({
              approaches,
              cell,
              fieldAttributes,
              scenario,
              value,
            });
            break;
        }
        break;

      // Handle Cap Table Selection
      case EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION:
        scenario.cap_table_id = getNumberValue(value as number);
        scenario.cap_table = getObjectValue(
          capTableVersions?.find(
            capTable => capTable?.id === getNumberValue(value as number)
          ) as AllocationCapTableModel
        );
        break;

      // Handle Maturity
      case EQUITY_ALLOCATION_SPREADSHEET_MATURITY:
        scenario.maturity = getStringValue(parseCellValue({ cell, fieldDecimalPlaces: maturityFieldDecimalPlaces }));
        break;

      // Handle Risk Free Rate
      case EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE:
        break;

      // Handle Volatility
      case EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY:
        scenario.volatility = parseFloatToFixed(
          getStringValue(getNumberValue(value as number)?.toString()),
          volatilityFieldDecimalPlaces // Fraction digits
        );
        break;

      // Handle Future Equity Value
      case EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE:
        scenario.exit_equity_value = getStringValue(
          parseCellValue({
            cell,
            fieldDecimalPlaces: exitEquityValueFieldDecimalPlaces,
          })
        );
        break;

      // Handle Present Equity Value
      case EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE:
        scenario.equity_value = getStringValue(
          parseCellValue({
            cell,
            fieldDecimalPlaces: equityValueFieldDecimalPlaces,
          })
        );
        break;

      // Handle Scenario Weighting/Probability
      case EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY:
        handleScenarioWeightingProbability({
          scenario,
          scenarioWeightingProbability: getStringValue(scenarioWeightingProbability?.toString()),
          updateWeightingProbabilities,
          weightingProbability,
        });
        break;

      // Handle Total
      case EQUITY_ALLOCATION_SPREADSHEET_TOTAL:
        scenario.total_aggregate_value = getStringValue(
          parseCellValue({
            cell,
            fieldDecimalPlaces: totalAggregateValueFieldDecimalPlaces,
          })
        );
        break;

      case EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE:
        if (cell.columnLegend && scenario && securityNameIdMap) {
          scenario.scenario_values = {
            aggregate_values: getShareValues(
              cells,
              EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
              cell.columnLegend ?? '',
              securityNameIdMap
            ),
            present_values: getShareValues(
              cells,
              EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
              cell.columnLegend ?? '',
              securityNameIdMap
            ),
          };
        }
        break;

      default:
        break;
    }
  });
};

export default customReverseParser;
