/* eslint-disable no-param-reassign */

import { IReverseParserCalibrationParams } from '../../guidelinePublicCompanies/PerformanceMetrics/config/reverseParser/types';

const reverseParserCalibrationOutputs = (params: IReverseParserCalibrationParams) => {
  const { cells, tableData } = params;
  tableData.calibration.ltm_revenue_multiple = String(
    Number.isNaN(Number(cells.A2.value)) ? 0 : Number(cells.A2.value)
  );
  tableData.calibration.ntm_revenue_multiple = String(
    Number.isNaN(Number(cells.A3.value)) ? 0 : Number(cells.A3.value)
  );
  tableData.calibration.ltm_revenue_multiple_percentile = String(
    Number.isNaN(Number(cells.A4.value)) ? 0 : Number(cells.A4.value)
  );
  tableData.calibration.ntm_revenue_multiple_percentile = String(
    Number.isNaN(Number(cells.A5.value)) ? 0 : Number(cells.A5.value)
  );
  tableData.calibration.ltm_revenue_multiple_premium = String(
    Number.isNaN(Number(cells.A6.value)) ? 0 : Number(cells.A6.value)
  );
  tableData.calibration.ntm_revenue_multiple_premium = String(
    Number.isNaN(Number(cells.A7.value)) ? 0 : Number(cells.A7.value)
  );
  tableData.calibration.ltm_ebitda_multiple = String(Number.isNaN(Number(cells.A9.value)) ? 0 : Number(cells.A9.value));
  tableData.calibration.ntm_ebitda_multiple = String(
    Number.isNaN(Number(cells.A10.value)) ? 0 : Number(cells.A10.value)
  );
  tableData.calibration.ltm_ebitda_multiple_percentile = String(
    Number.isNaN(Number(cells.A11.value)) ? 0 : Number(cells.A11.value)
  );
  tableData.calibration.ntm_ebitda_multiple_percentile = String(
    Number.isNaN(Number(cells.A12.value)) ? 0 : Number(cells.A12.value)
  );
  tableData.calibration.ltm_ebitda_multiple_premium = String(
    Number.isNaN(Number(cells.A13.value)) ? 0 : Number(cells.A13.value)
  );
};

export default reverseParserCalibrationOutputs;
