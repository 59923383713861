import { AfterCellChanged, Cells, InitialChanges, ScalarSpreadsheetConfigs } from 'common/types/scalarSpreadsheet';
import { EQUITY_ALLOCATION_KEY } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases';
import { VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY } from 'pages/ValuationsAllocation/common/constants/valuationSummary/sheetAliases';

const customAfterCellsChanged: AfterCellChanged = (
  initialChanges: InitialChanges[],
  cells: Cells,
  rowConfig: ScalarSpreadsheetConfigs['rowConfig'],
  tableData: ScalarSpreadsheetConfigs['tableData'],
  spreadsheets: Cells
) => {
  const changes = [...initialChanges];
  initialChanges.forEach(change => {
    const { cell, value } = change;
    const { alias, columnLegend } = cell;
    if (alias === VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY) {
      const relatedAllocationCell = Object.values(spreadsheets[EQUITY_ALLOCATION_KEY]).find(
        allocationCell =>
          allocationCell.alias === EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY
          && allocationCell.columnLegend === columnLegend
      );
      if (relatedAllocationCell) {
        changes.push({ cell: relatedAllocationCell, value });
      }
    }
  });
  return changes;
};

export default customAfterCellsChanged;
