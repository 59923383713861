import React, { useCallback } from 'react';
import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { CurrentRequestedDocumentTableRowProps } from './types';
import type { DocumentReferencesView } from '../../../../api';
import { ExtraActionsMenu } from '../../../../components';
import theme from '../../../../theme';
import { useDragRow } from '../hooks/useDragAndDropRow';
import useTableActionStyles from '../styles/useTableActionStyles';
import getFormattedDate from '../util/getFormattedDate';

const CurrentRequestedDocumentTableRow = (props: CurrentRequestedDocumentTableRowProps) => {
  const { row, fileRequestMap, downloadDocument, setDraggedRow, setSelectedFile, showRow } = props;
  const classes = useTableActionStyles();
  const { handleDragStart, handleDragEnd } = useDragRow(setDraggedRow);
  const fileOptionsForExtraActionsMenu = useCallback(
    (rowParam: DocumentReferencesView) => [
      {
        label: 'Download',
        callback: () => {
          if (downloadDocument) {
            downloadDocument(rowParam);
          }
        },
        isActive: true,
      },
      {
        label: 'Edit Document',
        callback: () => {
          setSelectedFile(rowParam);
        },
        isActive: true,
      },
    ],
    [setSelectedFile, downloadDocument]
  );

  return (
    <TableRow
      draggable
      onDragStart={event => handleDragStart(event, row)}
      onDragEnd={handleDragEnd}
      hover
      className={!showRow ? classes.hiddenRow : ''}
      key={row.file.id}>
      <TableCell>
        {row.file.file_type in defaultStyles && (
          <div
            style={{
              width: '1.5rem',
              height: '2rem',
              display: 'flex',
              alignItems: 'center',
            }}>
            <FileIcon extension={row.file.file_type} {...defaultStyles[row.file.file_type as DefaultExtensionType]} />
          </div>
        )}
      </TableCell>
      <TableCell>{row.file.filename}</TableCell>
      <TableCell>{row.file.uploaded_by?.full_name}</TableCell>
      <TableCell>{getFormattedDate(row.file.uploaded_date ?? null)}</TableCell>
      <TableCell>
        <ul>
          {row.file.id
            && fileRequestMap[row.file.id]?.map(request => (
              <li key={request.id}>
                <Typography variant="body2">{request.name}</Typography>
              </li>
            ))}
        </ul>
      </TableCell>
      <TableCell>
        <div className={classes.actionMenu}>
          <IconButton size="small" onClick={() => downloadDocument(row)}>
            <CloudDownloadOutlinedIcon className={classes.icon} />
          </IconButton>
          {fileOptionsForExtraActionsMenu && (
            <ExtraActionsMenu options={fileOptionsForExtraActionsMenu(row)} dotsColor={theme.palette.gray[500]} />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CurrentRequestedDocumentTableRow;
