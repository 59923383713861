/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setCurrency } from 'common/actions/format';
import { currencyFormat } from 'common/formats/formats';
import { Cell } from 'common/types/scalarSpreadsheet';
import { InfoCard } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS } from 'pages/Valuations/approaches/discountCashFlow/dcfNOLCarryover/config/constants';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { formatNumbers } from 'utillities';
import useStyles from './styles';
import { NOLCarryoverLedgerProps } from './types';
import DCFApproachContext from '../../context/DCFApproachContext';

const NOLCarryoverLedger = (props: NOLCarryoverLedgerProps) => {
  const { closeDialog, dcfNol } = props;
  const classes = useStyles();
  const { format, formatDispatch } = useContext(DCFApproachContext);
  const [totalTaxSavings, setTotalTaxSavings] = useState(0);
  const { onChange: onWorkbookChange, workbook } = useContext(ValuationContext);

  useEffect(() => {
    if (formatDispatch) formatDispatch(setCurrency());
  }, [formatDispatch]);

  const onChange = (cell: Cell, expr: string) => {
    onWorkbookChange?.(cell, expr, () => {});

    if (!workbook || !dcfNol?.name || !workbook[dcfNol.name]) {
      // If workbook or dcfNol.name is null/undefined, or if the sheet does not exist in workbook
      return;
    }

    const totalTaxSavingCalc = Object.values(workbook[dcfNol.name].cells).reduce((total: number, cell: Cell) => {
      if (cell.alias === PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS) {
        total += Number(cell.value);
      }
      return total;
    }, 0);

    setTotalTaxSavings(totalTaxSavingCalc);
  };

  const InfoCards = () => {
    const formatted = (value: number) =>
      formatNumbers({
        format: currencyFormat({ fractionDigits: 0 }),
        currency: format?.currency,
        value,
        prefix: null,
        suffix: null,
        allowNegativeValue: true,
        currencyCode: null,
        blankIfNull: false,
        staticCurrency: null,
      });

    return (
      <div className={classes.infoCards}>
        <InfoCard
          title="NOL SUMMARY"
          body={`Total Present Value of NOL Carryforward Tax Savings ${formatted(totalTaxSavings)}`}
        />
      </div>
    );
  };

  return (
    <LedgerDialog
      id="nol-carryover-ledger"
      title="NET Operating Loss Carryover Ledger"
      onClose={closeDialog}
      dialogHeader={<InfoCards />}
      actionButtonText={{ cancel: 'Close' }}>
      <ScalarSpreadsheet
        {...dcfNol}
        key={dcfNol.name}
        sheet={dcfNol}
        onChange={onChange}
        workbook={workbook}
        className={classes.spreadsheet}
        format={format}
        formatDispatch={formatDispatch}
      />
    </LedgerDialog>
  );
};

NOLCarryoverLedger.propTypes = {
  closeDialog: PropTypes.func,
  dcfNol: PropTypes.object,
};

export default NOLCarryoverLedger;
