/**
 * @name API Config
 * @memberof module:common/config
 * @type {Config}
 * @return {Object} Configurations of API
 */

// #region common

import API from '../config';

const base = `${API.BASE_DEV_HOST}/api/firms`;

export const all = `${base}`;
export const userManagementFirmList = (userId, isFromUserPage = true) =>
  `${base}?user_id=${userId}&is_from_user_page=${isFromUserPage}`;
export const selectedUserPermissions = userId => `${base}?user_id=${userId}`;
export const create = `${base}/new`;
// Get a firm by its id
export const firmById = firmId => `${base}/${firmId}`;
// Get the list of companies that belong to a firm
export const getCompanyListByFirmId = firmId => `${base}/${firmId}/companies`;
// Get the summary firm table
export const getFundByFirmId = firmId => `${base}/${firmId}/summary`;
// Get the list of funds that will be shown in the navigation
export const getFundListByFirmId = firmId => `${base}/${firmId}/funds`;
// Create a new company for a specific firm
export const createFirmCompany = `${base}/companies/new`;
// Create a new company for a specific firm
export const getCompaniesFromPreviousMD = (firmId, date) => `${base}/${firmId}/companies-list/${date}`;
// Add user to firm
export const addUserToFirm = firmId => `${base}/${firmId}/users`;
// Remove user from firm
export const removeUserFromFirm = (firmId, userId) => `${base}/${firmId}/users/${userId}`;
// Get the company extended info (data from the vw_firm_companies SQL view)
export const getFirmCompanyExtendedInfo = (firmId, companyId) => `${base}/${firmId}/company/${companyId}`;
// Create or Update Comp Group
export const createOrUpdateCompGroups = firmId => `${API.BASE_DEV_HOST}/api/valuation/comp-group/firm/${firmId}`;
// See pending decisions for this firm
export const getPendingDecisions = firmId => `${API.BASE_DEV_HOST}/api/pending-decisions/${firmId}`;
// Get tasks progress for a firm
export const getTasksProgress = firmId => `${base}/${firmId}/requests-progress`;
// Get base set of questions and documents for a firm
export const getFirmSetOfQuestionsAndDocuments = firmId => `${base}/${firmId}/questions-and-requested-documents`;
// Create required tasks for selected companies of a firm
export const createRequestedTasks = `${base}/create-requests`;
// Get progress detail for a task
export const getTaskProgressDetail = taskId => `${API.BASE_DEV_HOST}/api/tasks/${taskId}/progress-detail`;
// Change the user firm role
export const changeUserFirmRole = (firmId, userId) => `${base}/${firmId}/users/${userId}/roles`;
// Create a new requested question for a firm
export const createRequestedQuestion = `${base}/create-firm-question`;
// Delete a requested question for a firm
export const deleteRequestedQuestion = questionId => `${base}/question/${questionId}`;
// Update a requested question for a firm
export const updateRequestedQuestion = questionId => `${base}/question/${questionId}`;
// Create a new requested document for a firm
export const createFirmRequestedDocument = `${base}/create-firm-requested-document`;
// Delete a requested document for a firm
export const deleteFirmRequestedDocument = requestedDocumentId =>
  `${base}/firm-requested-document/${requestedDocumentId}`;
// Update a requested document for a firm
export const updateFirmRequestedDocument = requestedDocumentId =>
  `${base}/firm-requested-document/${requestedDocumentId}`;
export const hasFirmSummary = (firmId, fundsByMeasurementDate) => `${base}/${firmId}/has-summary`;
