/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */

import { GRAY_CELL } from 'common/constants/general';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { Changes, GetNextCellsChangesParams } from './types';

const afterCellChanged = (changes: Changes[], cells: Cells) => {
  const changeList: Changes[] = [];
  const getNextCellsChanges = ({ row, key }: GetNextCellsChangesParams) => {
    const currentIndex = Object.keys(cells).findIndex(item => item === key);

    Object.entries(cells).forEach(([, cell], index) => {
      const isSameRow = cell.rowNumber === row;
      const isNextRow = index > currentIndex;

      if (isSameRow && isNextRow) {
        const expr = cell.isCellCalculated ? cell.exprPair : '';
        changes.push({ cell, value: expr });
      }
    });
  };

  const getCellStatusUpdated = (cell: Cell, isCalculated: boolean): Cell => ({
    ...cell,
    isCellCalculated: isCalculated,
    defaultTooltip: isCalculated ? 'This cell has been calculated automatically' : '',
    className: isCalculated ? GRAY_CELL : '',
  });

  changes.forEach(change => {
    const { cell, value } = change;
    const { key, rowNumberPair, columnLegend, rowNumber: row } = cell;

    // If is a cell with pair cell and it's not the first column
    if (rowNumberPair && columnLegend !== 'A') {
      const pairCellKey = `${columnLegend}${rowNumberPair}`;
      const pairCell = cells[pairCellKey];
      const { exprPair } = pairCell;
      cell.pairCell = pairCell;
      pairCell.pairCell = cell;

      getCellStatusUpdated(cell, false);
      changeList.push({ cell, value });

      // Update current cell changed pair status
      getCellStatusUpdated(pairCell, true);
      changeList.push({ cell: pairCell, value: exprPair });

      getNextCellsChanges({ row, key });
    } else {
      changeList.push({ cell, value });
    }
  });

  return changeList;
};

export default afterCellChanged;
