import React, { FC } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import { useFilteredAllocationMethods } from 'pages/ValuationsAllocation/hooks';

const AllocationMethodSelector: FC = props => {
  const filteredAllocationMethodsOptions = useFilteredAllocationMethods();

  const selectProps = {
    menuPosition: 'fixed',
    objectOptions: { displayKey: 'name', valueKey: 'id' },
    options: filteredAllocationMethodsOptions,
  };

  return <GridSelect {...props} {...selectProps} />;
};

export default AllocationMethodSelector;
