import { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { captableAction, companiesAction, valuationsAction } from 'common/actions';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { EffectiveTaxRate, ValuationByAllocation } from 'common/types/valuation';
import { useGetFinancials } from 'pages/ValuationsAllocation/hooks/useGetFinancials';
import { useCheckMeasurementDateByCapTable, useGetPrimaryCapTableByCMD } from 'services/hooks/capTables';
import { useGetCapTableVersionsByCompanyAndMD, useGetCompanyMeasurementDateByMD } from 'services/hooks/companies';
import {
  useGetFinancialFiscalYearPeriodsByCMD,
  useGetFinancialStatementsByMD,
  useGetFinancialVersionsByMD,
} from 'services/hooks/financials';
import { useGetCompGroupsByFirm, useGetValuationByAllocation } from 'services/hooks/valuations';
import { useGetEffectiveTaxRateByCMD } from 'services/hooks/valuations/useGetEffectiveTaxRateByCMD';
import { useValuationsStore } from 'store';
import { InitValuationParams } from './types';

const useLoadValuation = (params: InitValuationParams) => {
  const { currentAllocation, currentMeasurementDate } = params;

  const [cashTaxRate, setCashTaxRate] = useState<EffectiveTaxRate | null>(null);
  const [valuation, setValuation] = useState<ValuationByAllocation | null>(null);

  const [storeValue, dispatch] = useStore() as unknown as UseStoreValues;
  const { companyInfo: currentCompany, firmInfo: currentFirm } = storeValue;

  // Valuations Store
  const setCurrentCapTable = useValuationsStore(state => state.setCurrentCapTable);
  const setCurrentCapTableVersions = useValuationsStore(state => state.setCurrentCapTableVersions);
  const setCurrentValuation = useValuationsStore(state => state.setCurrentValuation);

  // Valuation Info
  const {
    data: valuationInfo,
    refetch: getValuationInfo,
    isLoading: isLoadingValuationInfo,
  } = useGetValuationByAllocation({
    allocation: currentAllocation,
    shouldQueryAutomatically: Boolean(currentAllocation),
  });

  // Comps Groups
  const {
    data: compGroups,
    refetch: getCompGroups,
    isLoading: isLoadingCompGroups,
  } = useGetCompGroupsByFirm({
    firm: currentFirm,
    shouldQueryAutomatically: Boolean(currentFirm),
  });

  // Financial Statements
  const {
    data: financialStatementsList,
    refetch: getFinancialStatementsList,
    isLoading: isLoadingFinancialStatementsList,
  } = useGetFinancialStatementsByMD({
    measurementDate: currentMeasurementDate,
    shouldQueryAutomatically: Boolean(currentMeasurementDate),
  });

  // Financial Versions
  const {
    data: financialVersions,
    refetch: getFinancialVersions,
    isLoading: isLoadingFinancialVersions,
  } = useGetFinancialVersionsByMD({
    measurementDate: currentMeasurementDate,
    shouldQueryAutomatically: Boolean(currentMeasurementDate),
  });

  // Company Measurement Date
  const {
    data: companyMeasurementDate,
    refetch: getCompanyMeasurementDate,
    isLoading: isLoadingCompanyMeasurementDate,
  } = useGetCompanyMeasurementDateByMD({
    measurementDate: currentMeasurementDate,
    shouldQueryAutomatically: Boolean(currentMeasurementDate),
  });

  // Primary Cap Table
  const {
    data: initialPrimaryCapTable,
    refetch: getPrimaryCapTable,
    isLoading: isLoadingPrimaryCapTable,
  } = useGetPrimaryCapTableByCMD({
    companyMeasurementDate,
    shouldQueryAutomatically: Boolean(companyMeasurementDate),
  });

  const primaryCapTable = useMemo(() => {
    if (!initialPrimaryCapTable?.securities) {
      return null;
    }

    return {
      ...initialPrimaryCapTable,
      securities: initialPrimaryCapTable.securities.map(security => ({
        ...security,
        shares: security.shares_outstanding,
      })),
    };
  }, [initialPrimaryCapTable]);

  // Check Measurement Date
  const {
    data: hasMeasurementDate,
    refetch: getHasMeasurementDate,
    isLoading: isLoadingHasMeasurementDate,
  } = useCheckMeasurementDateByCapTable({
    capTable: primaryCapTable,
    shouldQueryAutomatically: Boolean(primaryCapTable),
  });

  // Financial Fiscal Year Periods
  const {
    data: financialFiscalYearPeriods,
    refetch: getFinancialFiscalYearPeriods,
    isLoading: isLoadingFinancialFiscalYearPeriods,
  } = useGetFinancialFiscalYearPeriodsByCMD({
    companyMeasurementDate,
    shouldQueryAutomatically: Boolean(companyMeasurementDate),
  });

  // Effective Tax Rate
  const {
    data: effectiveTaxRate,
    refetch: getEffectiveTaxRate,
    isLoading: isLoadingEffectiveTaxRate,
  } = useGetEffectiveTaxRateByCMD({
    companyMeasurementDate,
    shouldQueryAutomatically: false,
  });

  // Cap Table Versions
  const {
    data: capTableVersions,
    refetch: getCapTableVersions,
    isLoading: isLoadingCapTableVersions,
  } = useGetCapTableVersionsByCompanyAndMD({
    company: currentCompany,
    measurementDate: currentMeasurementDate,
    shouldQueryAutomatically:
      Boolean(currentCompany)
      && Boolean(currentMeasurementDate)
      && currentCompany?.id === currentMeasurementDate?.company_id,
  });

  // Financials
  const { financialPeriods, financialPeriodsPerMD, financials, otherFinancialStatements, primaryFinancialStatement }
    = useGetFinancials(financialStatementsList);

  // Set Valuation if Valuation Info is not empty
  useEffect(() => {
    if (!isEmpty(valuationInfo)) {
      setValuation(valuationInfo);
    }
  }, [valuationInfo]);

  // Get Effective Tax Rate if CMD is not empty and Current Allocation is not empty
  useEffect(() => {
    if (!isEmpty(companyMeasurementDate) && !isEmpty(currentAllocation)) getEffectiveTaxRate();
  }, [companyMeasurementDate, currentAllocation, getEffectiveTaxRate]);

  // Set Cash Tax Rate if Effective Tax Rate
  useEffect(() => {
    const effectiveTaxRateIsValid = effectiveTaxRate?.company_measurement_date?.id === companyMeasurementDate?.id;

    if (!isEmpty(effectiveTaxRate) && !isEmpty(companyMeasurementDate))
      setCashTaxRate(effectiveTaxRateIsValid ? effectiveTaxRate : null);
  }, [currentAllocation, effectiveTaxRate, companyMeasurementDate, setCashTaxRate]);

  // Set Cap Table to Store
  useEffect(() => {
    if (primaryCapTable && !isEmpty(primaryCapTable)) {
      setCurrentCapTable?.(primaryCapTable);
      dispatch?.(captableAction.setCaptableInfo(primaryCapTable));
    }

    return () => {
      setCurrentCapTable?.(null);
      dispatch?.(captableAction.setCaptableInfo(null));
    };
  }, [dispatch, primaryCapTable, setCurrentCapTable]);

  // Set Cap Table List to Store
  useEffect(() => {
    if (capTableVersions && !isEmpty(capTableVersions)) {
      setCurrentCapTableVersions?.(capTableVersions);
      dispatch?.(companiesAction.setCapTableList(capTableVersions));
    }

    return () => {
      setCurrentCapTableVersions?.(null);
      dispatch?.(companiesAction.setCapTableList(null));
    };
  }, [capTableVersions, dispatch, setCurrentCapTableVersions]);

  // Set Valuation Info to Store
  useEffect(() => {
    if (valuation && !isEmpty(valuation)) {
      setCurrentValuation?.(valuation);
      dispatch?.(valuationsAction?.setValuationInfo(valuation));
    }

    return () => {
      setCurrentValuation?.(null);
      dispatch?.(valuationsAction?.setValuationInfo(null));
    };
  }, [dispatch, setCurrentValuation, valuation]);

  return {
    // Valuation
    compGroups,
    getCompGroups,
    getValuationInfo,
    isLoadingCompGroups,
    isLoadingValuationInfo,
    setValuation,
    valuation,
    valuationInfo,

    // Financials
    financialFiscalYearPeriods,
    financialPeriods,
    financialPeriodsPerMD,
    financials,
    financialStatementsList,
    financialVersions,
    getFinancialFiscalYearPeriods,
    getFinancialStatementsList,
    getFinancialVersions,
    isLoadingFinancialFiscalYearPeriods,
    isLoadingFinancialStatementsList,
    isLoadingFinancialVersions,
    otherFinancialStatements,
    primaryFinancialStatement,

    // Other
    capTableVersions,
    cashTaxRate,
    companyMeasurementDate,
    effectiveTaxRate,
    getCapTableVersions,
    getCompanyMeasurementDate,
    getEffectiveTaxRate,
    getHasMeasurementDate,
    getPrimaryCapTable,
    hasMeasurementDate,
    isLoadingCapTableVersions,
    isLoadingCompanyMeasurementDate,
    isLoadingEffectiveTaxRate,
    isLoadingHasMeasurementDate,
    isLoadingPrimaryCapTable,
    primaryCapTable,
    setCashTaxRate,
  };
};

export default useLoadValuation;
