import { Cell, ScalarSpreadsheetConfigs, SpreadsheetsCells } from 'common/types/scalarSpreadsheet';
import { FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';

const conditions = (params: { cell: Cell; cells: SpreadsheetsCells; sheet: ScalarSpreadsheetConfigs }) => {
  const { cell, cells, sheet } = params;
  if (cell.alias === 'FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD') {
    const isOPM = cell.value === 'OPM';
    const greaterThan = isOPM ? 0 : undefined;
    const opmTableName = getApproachTableName({
      approach: sheet.initialObj,
      tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_OPM,
    });
    const opmSheetCells = cells[opmTableName];
    if (opmSheetCells && opmSheetCells.MATURITY) {
      opmSheetCells.MATURITY.isRequired = isOPM;
      opmSheetCells.MATURITY.greaterThan = greaterThan;
      opmSheetCells.RISK_FREE_RATE.isRequired = isOPM;
      opmSheetCells.RISK_FREE_RATE.greaterThan = greaterThan;
      opmSheetCells.VOLATILITY.isRequired = isOPM;
      opmSheetCells.VOLATILITY.greaterThan = greaterThan;
    }
    return true;
  }
};

export default conditions;
