/* eslint-disable no-param-reassign */
import { REVERT_APPROACH_SELECTION } from 'pages/Valuations/util/constants';

const updateApproachesSelectionsInTableData = ({ tableData, valueFromSelector, updatedAlias }) => {
  // 1. set reference if the selected approach isn't persisted yet (subtle difference for the backend)
  // 2. pass panel id and not PT or PC id if the selected approach isn't persisted yet
  // 3. make sure we're not setting two approaches for the same tvt (e.g. both PT AND PC for revenue)
  const { approaches } = tableData;
  const matchingPersistedApproach = approaches.find(({ panelId }) => panelId === valueFromSelector);
  const isApproachPersisted = Boolean(matchingPersistedApproach?.id);
  const aliasToUpdate = isApproachPersisted
    ? updatedAlias
    : `${updatedAlias.split('_').slice(0, -1).join('_')}_reference`;
  const valueToSet = isApproachPersisted
    ? matchingPersistedApproach?.valuations_approach_gpc?.id ?? matchingPersistedApproach?.valuations_approach_gpt?.id
    : valueFromSelector;
  tableData.approach.valuations_approach_dcf[aliasToUpdate] = valueToSet;
  const revertUpdatedAlias = REVERT_APPROACH_SELECTION[updatedAlias];
  tableData.approach.valuations_approach_dcf[revertUpdatedAlias] = null;
  const revertUpdateReference = `${revertUpdatedAlias.split('_').slice(0, -1).join('_')}_reference`;
  tableData.approach.valuations_approach_dcf[revertUpdateReference] = isApproachPersisted ? undefined : null;
};

export default updateApproachesSelectionsInTableData;
