import parser from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/getParser';
import {
  DCF_TVT_COMBINED_AVERAGE_EXIT_VALUE_ROW_NUMBER,
  DCF_TVT_COMBINED_DISCOUNT_PERIODS_ROW_NUMBER,
  DCF_TVT_COMBINED_MULTIPLE_ROW_NUMBER,
  DCF_TVT_COMBINED_PV_OF_TERMINAL_VALUE_ROW_NUMBER,
  DCF_TVT_COMBINED_SELECTED_APPROACH_ROW_NUMBER,
  DCF_TVT_COMBINED_TERMINAL_VALUE_ROW_NUMBER,
  DCF_TVT_COMBINED_TERMINAL_YEAR_ROW_NUMBER,
  DCF_TVT_COMBINED_WACC_ROW_NUMBER,
} from 'pages/Valuations/util/constants';
import getCombinedColumns from './getCombinedColumns';
import rowConfig from './rowConfig';

const parseMixin = (cell, cfTVTRMSheetName, dcfTVTEMSheetName) => {
  switch (cell.rowNumber) {
    case DCF_TVT_COMBINED_TERMINAL_YEAR_ROW_NUMBER: {
      if (cell.columnLegend === 'A') {
        return {
          ...cell,
          expr: `=${cfTVTRMSheetName}.A2`,
        };
      }
      if (cell.columnLegend === 'B') {
        return {
          ...cell,
          expr: `=${dcfTVTEMSheetName}.A2`,
        };
      }
      return cell;
    }
    case DCF_TVT_COMBINED_SELECTED_APPROACH_ROW_NUMBER: {
      if (cell.columnLegend === 'A') {
        return {
          ...cell,
          expr: `=${cfTVTRMSheetName}.A3`,
          isExpr: true,
        };
      }
      if (cell.columnLegend === 'B') {
        return {
          ...cell,
          expr: `=${dcfTVTEMSheetName}.A3`,
          isExpr: true,
        };
      }
      return cell;
    }
    case DCF_TVT_COMBINED_MULTIPLE_ROW_NUMBER: {
      return { ...cell, readOnly: cell.readOnly };
    }
    case DCF_TVT_COMBINED_TERMINAL_VALUE_ROW_NUMBER:
    case DCF_TVT_COMBINED_AVERAGE_EXIT_VALUE_ROW_NUMBER:
    case DCF_TVT_COMBINED_WACC_ROW_NUMBER:
    case DCF_TVT_COMBINED_DISCOUNT_PERIODS_ROW_NUMBER:
    case DCF_TVT_COMBINED_PV_OF_TERMINAL_VALUE_ROW_NUMBER:
      if (cell.columnLegend === 'B') {
        return {
          ignoreCell: true,
        };
      }
      return {
        ...cell,
        colSpan: 2,
      };
    default:
      return cell;
  }
};

const getConfig = ({ columnsProps }) => ({
  className: 'revenue-ebitda-multiple-terminal-table',
  rowConfig: rowConfig(columnsProps),
  parser: parser(parseMixin, columnsProps.dcfTVTRMSheetName, columnsProps.dcfTVTEMSheetName),
  columns: getCombinedColumns(columnsProps),
});

export default getConfig;
