import { sortBy } from 'lodash';
import { ValuationApproachWeightModel } from 'api';
import { ConfigurationSpreadsheets } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import {
  SHEET_CONFIG_CONSTANTS,
  VALUATION_SUMMARY_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { Configuration } from 'pages/ValuationsAllocation/hooks';
import { getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { createColumns } from './createColumns';
import customAfterCellsChanged from './customAfterCellsChanged/customAfterCellsChanged';
import { customParser, ValuationSummaryParserParams } from './customParser';
import { customReverseParser, ReverseParserParams } from './customReverseParser';
import { customRowConfig } from './customRowConfig';
import { customValidations } from './customValidations';
import { CreateValuationSummaryConfigurationParams } from './types';
import createConstantSheet from '../ConstantSheetConfig/createConstantSheet';

const { VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const DEFAULT_VALUE = 0;

const createValuationSummaryConfiguration = (params: CreateValuationSummaryConfigurationParams) => {
  const {
    allocationVersion,
    approaches,
    company,
    companyExchangeRate,
    configurations,
    deletedApproachesIds,
    fieldAttributes,
    financials,
    otherFinancialStatements,
    firm,
    isDisabled,
    isUniformCurrency,
    measurementDate,
    updateWeightingProbabilities,
    valuation,
  } = params;

  const { allocation } = getObjectValue(valuation);
  const { allocation_scenarios: allocationScenarios = [] } = getObjectValue(allocation);

  // Reset the Weights for each Approach
  approaches.forEach(approach => {
    const currentApproach = getObjectValue(approach);

    const getScenarioApproachWeights = allocationScenarios.reduce((accumulator, current) => {
      const { allocations_scenarios_weights: allocationsScenariosWeights } = current;

      const approachWeight = getArrayValue(
        allocationsScenariosWeights?.filter(
          weight =>
            weight?.valuation_approach_id === currentApproach?.id || weight.approach_uuid === currentApproach?.panel_id
        )
      );

      return [...accumulator, ...approachWeight];
    }, [] as ValuationApproachWeightModel[]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: allocations_scenarios_weights is not readonly
    currentApproach.valuations_approaches_weights = getScenarioApproachWeights;
  });

  const sortedAllocationScenarios = sortBy(allocationScenarios, 'order');

  const { financials_currency: financialsCurrency } = getObjectValue(company);
  const { total_cash_equivalents: totalCashEquivalents, total_debt: totalDebt } = getObjectValue(financials);

  // If is not uniform currency, multiply the values by the Company exchange rate
  const plusDebtTotal = isUniformCurrency ? totalDebt : totalDebt * getNumberValue(companyExchangeRate ?? 1);
  const lessCashTotal = isUniformCurrency
    ? totalCashEquivalents
    : totalCashEquivalents * getNumberValue(companyExchangeRate ?? 1);

  const getColumns = () =>
    createColumns({
      allocationScenarios: sortedAllocationScenarios,
      configurations,
      deletedApproachesIds,
      isUniformCurrency,
    });
  const columns = getColumns();

  const allocationVersionSlug = getStringValue(allocationVersion?.slug);
  const companySlug = getStringValue(company?.slug);
  const firmSlug = getStringValue(firm?.slug);
  const measurementDateSlug = getStringValue(measurementDate?.slug);

  const queryParams = `date=${measurementDateSlug}&version=${allocationVersionSlug}`;

  const rowConfig = customRowConfig({
    companySlug,
    configurations,
    firmSlug,
    isDisabled,
    queryParams,
  });

  const parser = (parserParams: ValuationSummaryParserParams) =>
    customParser({
      ...parserParams,
      allocationScenarios: sortedAllocationScenarios,
      approaches,
      companyExchangeRate: getNumberValue(companyExchangeRate ?? 1),
      financialsCurrency: getObjectValue(financialsCurrency),
      isDisabled,
      isUniformCurrency,
      lessCashTotal,
      plusDebtTotal,
    });

  const reverseParser = (reverseParserParams: ReverseParserParams) =>
    customReverseParser({
      ...reverseParserParams,
      allocationScenarios: sortedAllocationScenarios,
      approaches,
      updateWeightingProbabilities,
    });

  const sheet = new SpreadsheetConfig({
    allowConfirmAndDeleteColumn: false,
    allowCopyColumn: false,
    allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
    alwaysDisplayLegend: false,
    columns,
    currencyFormatter: true,
    customValidations: customValidations as unknown as SpreadsheetConfig['customValidations'],
    fieldAttributes,
    format: undefined,
    name: VALUATION_SUMMARY_KEY,
    afterCellChanged: customAfterCellsChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    page: VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
    parser,
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    rowConfig,
    showPreviousColsDivider: false,
    showTitlesColumn: true,
    showToolbar: true,
    showTotalColumn: false,
    getColumns,
    tableData: {},
    tableTerms: VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
    totalParser: undefined,
    unitsFormatter: true,
  }) as unknown as ConfigurationSpreadsheets;

  const constantSheet = createConstantSheet({
    otherFinancialStatements,
    isUniformCurrency,
    companyExchangeRate,
  }) as unknown as ConfigurationSpreadsheets;

  return {
    name: VALUATION_SUMMARY_KEY,
    spreadsheets: {
      [VALUATION_SUMMARY_KEY]: sheet,
      constants: constantSheet,
    },
    value: DEFAULT_VALUE, // Enterprise Value
  } as Configuration;
};

export default createValuationSummaryConfiguration;
