import React, { FC } from 'react';
import { SelectValueViewer } from 'components';
import { useFilteredAllocationMethods } from 'pages/ValuationsAllocation/hooks';

const ApproachScenarioSelectValueViewer: FC = props => {
  const filteredAllocationMethodsOptions = useFilteredAllocationMethods(); // Use the custom hook

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: SelectValueViewer is not typed
    <SelectValueViewer {...props} options={filteredAllocationMethodsOptions} />
  );
};

export default ApproachScenarioSelectValueViewer;
