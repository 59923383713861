import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { notFoundPath } from 'common/config/urls';
import { CAPTABLE_VERSION_FILTER, CAPTABLE_VERSION_FILTER_TITLE } from 'common/constants/pageFilters';
import { useStore } from 'common/store';
import LayoutContext from 'context/LayoutContext';
import UnsavedChanges from 'context/UnsavedChanges';
import { useCreateCapTableProforma, useGetCaptableInfo } from 'services/hooks/captable';
import { useGetVersionsByCompanyIdByDateId } from 'services/hooks/company';

const useCapTableVersionFilter = ({ selectedMeasurementDate }) => {
  const history = useHistory();
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);

  const [{ fundList, firmId, companyInfo, captableInfo }] = useStore();
  const companyId = companyInfo?.id;
  const selectedMeasurementDateId = selectedMeasurementDate?.id;

  const { pageFilters, setPageFilters } = useContext(LayoutContext);

  const { action: hasChanges, showDialog } = useContext(UnsavedChanges);

  const getCapTableVersions = useGetVersionsByCompanyIdByDateId();
  const [capTableVersions, setCapTableVersions] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [newProforma, setProformaInfo, createProforma] = useCreateCapTableProforma();

  const [, getCapTableInfo] = useGetCaptableInfo();

  const fetchCapTableVersions = useCallback(async () => {
    const fetchedCapTableVersions = await getCapTableVersions(companyId, selectedMeasurementDateId);

    const sortedCapTableVersions = fetchedCapTableVersions.sort((a, b) => a.id - b.id);
    setCapTableVersions(sortedCapTableVersions);
  }, [companyId, selectedMeasurementDateId, getCapTableVersions]);

  const setQueryString = useCallback(
    (paramName, newQueryString, replace = true) => {
      const currentPath = history.location.pathname;
      parsedQuery[paramName] = newQueryString;

      const newLocation = { pathname: currentPath, search: queryString.stringify(parsedQuery) };

      return replace ? history.replace(newLocation) : history.push(newLocation);
    },
    [history, parsedQuery]
  );

  const handleVersionQueryString = async (versionArg, capTableVersionsArg = capTableVersions) => {
    const { version: versionQueryString } = parsedQuery;
    const version = versionArg || versionQueryString;

    const selectPrimaryCapTable = async () => {
      try {
        setQueryString('version', companyInfo.cap_table_slug);
        await handleVersionQueryString(companyInfo.cap_table_slug, capTableVersionsArg);
      } catch (error) {
        throw new Error(error);
      }
    };

    const matchVersion = version =>
      capTableVersionsArg.find(item => item.slug === version || item.id.toString() === version.toString());
    try {
      if (version) {
        const matchedVersion = matchVersion(version);

        if (matchedVersion) {
          if (versionQueryString !== matchedVersion.slug) {
            setQueryString('version', matchedVersion.slug);
          }
          if (selectedVersion?.toString() !== matchedVersion.id.toString()) {
            setSelectedVersion(matchedVersion.id);
          }

          await getCapTableInfo(matchedVersion.id);
        } else {
          history.push(notFoundPath);
        }
      } else if (companyInfo.firm_id === firmId && companyInfo.cap_table_slug) {
        await selectPrimaryCapTable();
      }
      setProformaInfo({});
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (!isEmpty(capTableVersions)) {
      const primaryCaptable = capTableVersions?.find(ct => ct.is_primary);

      const currentVersion = selectedVersion || newProforma?.id || parsedQuery?.version || null;

      handleVersionQueryString(currentVersion || primaryCaptable.slug || null, capTableVersions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capTableVersions]);

  const saveAsNewProforma = useCallback(
    payload => {
      createProforma(payload);
    },
    [createProforma]
  );

  // get cap table versions when the selected measurement date changes
  useEffect(() => {
    if (selectedMeasurementDate) {
      fetchCapTableVersions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurementDate]);

  const updateCapTableVersions = useCallback(
    async proformaId => {
      if (proformaId && companyInfo && selectedMeasurementDate) {
        // Update Cap Table versions
        await fetchCapTableVersions();

        setSelectedVersion(proformaId);
        setQueryString('version', newProforma.slug, false);
      }
    },
    [setQueryString, companyInfo, selectedMeasurementDate, newProforma.slug, fetchCapTableVersions]
  );

  useEffect(() => {
    if (!isEmpty(newProforma)) {
      updateCapTableVersions(newProforma.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProforma]);

  const versionName = useMemo(() => {
    if (isEmpty(capTableVersions) || !selectedVersion) {
      return '';
    }
    const result = capTableVersions?.find(option => option.id.toString() === selectedVersion.toString());

    return isEmpty(result) ? '' : result.name;
  }, [capTableVersions, selectedVersion]);

  const onVersionChange = event => {
    const callback = async () => {
      try {
        await handleVersionQueryString(event.target.value);
      } catch (error) {
        throw new Error(error);
      }
    };

    if (hasChanges) {
      showDialog(callback);
    } else {
      callback();
    }
  };

  const onEditCaptable = async () => {
    if (!isEmpty(captableInfo)) {
      const versions = await getCapTableVersions(companyInfo.id, selectedMeasurementDate.id);
      setCapTableVersions(versions);
    }
  };

  const setFilters = () => {
    const pageFilterMap = pageFilters
      ? pageFilters.reduce((sofar, pageFilter) => {
        // eslint-disable-next-line no-param-reassign
        sofar[pageFilter.id] = pageFilter;
        return sofar;
      }, {})
      : {};
    if (
      pageFilterMap[CAPTABLE_VERSION_FILTER]?.selectedValue !== selectedVersion
      || !isEqual(capTableVersions, pageFilterMap[CAPTABLE_VERSION_FILTER]?.options)
    ) {
      pageFilterMap[CAPTABLE_VERSION_FILTER] = {
        id: CAPTABLE_VERSION_FILTER,
        options: capTableVersions,
        title: CAPTABLE_VERSION_FILTER_TITLE,
        selectedValue: selectedVersion,
        handler: onVersionChange,
      };

      setPageFilters(Object.values(pageFilterMap));
    }
  };

  // Set filters
  useEffect(() => {
    if (!isEmpty(fundList) && !isEmpty(capTableVersions) && selectedVersion && selectedVersion !== '' && pageFilters) {
      setFilters();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFilters, selectedMeasurementDate, selectedVersion, fundList, capTableVersions]);

  return {
    saveAsNewProforma,
    versionName,
    selectedVersion,
    onEditCaptable,
    updateCapTableVersions,
  };
};

export default useCapTableVersionFilter;
