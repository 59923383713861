/**
 * @file services/firms.js
 * @since v1.0
 * @author Noel Flores <noel.flores@struck.com>
 */

// #region dependencies
// #endregion
// #region common
import BaseServiceRetryWrapper from './baseRetry';
import { FIRMS, MEASUREMENT_DATES } from '../common/endpoints';
// #endregion

class FirmsService extends BaseServiceRetryWrapper {
  /**
   * @function
   * @name allData
   * @description load firm by name selected.
   */
  allData = () => this.get(FIRMS.all);

  /**
   * @function
   * @name getUserManagementFirmList
   * @description get firms filtered for user management view
   */
  getUserManagementFirmList = userId => this.get(FIRMS.userManagementFirmList(userId));

  /**
   * @function
   * @name getSelectedUserPermissions
   * @description get firm permissions for the selected user
   */
  getSelectedUserPermissions = userId => this.get(FIRMS.selectedUserPermissions(userId));

  /**
   * @function
   * @name getFirmById
   * @description Get firm by its id
   * @param {integer} firmId the firm id
   * @return {object}
   */
  getFirmById = firmId => this.get(FIRMS.firmById(firmId));

  /**
   * @function
   * @name getCompanyListByFirmId
   * @description Get the list of companies that belongs to a firm
   * @param {integer} firmId
   * @return {array}
   */
  getCompanyListByFirmId = firmId => this.get(FIRMS.getCompanyListByFirmId(firmId));

  /**
   * @function
   * @name getFundByFirmId
   * @description Get the list of funds with its companies (summary) by firm id
   * @param {integer} firmId
   * @return {array}
   */
  getFundByFirmId = firmId => this.get(FIRMS.getFundByFirmId(firmId));

  /**
   * @function
   * @name getFundListByFirmId
   * @description Get the list of funds by firm id that will be shown in navigation
   * @param {integer} firmId
   * @return {array}
   */
  getFundListByFirmId = firmId => this.get(FIRMS.getFundListByFirmId(firmId));

  /**
   * @function
   * @name createFirm
   * @description Create new firm
   * @param {integer} Firm data
   */
  createFirm = data => this.post(FIRMS.create, data);

  updateFirmById = (firmId, data) => this.put(FIRMS.firmById(firmId), data);

  getMeasurementDatesByFirmId = id => this.get(MEASUREMENT_DATES.measurementDatesByFirmId(id));

  createFirmCompany = data => this.post(FIRMS.createFirmCompany, data);

  getCompaniesFromPreviousMD = (firmId, date) => {
    const endpoint = FIRMS.getCompaniesFromPreviousMD(firmId, date);
    return this.get(endpoint);
  };

  addUserToFirm = (firmId, userData) => this.put(FIRMS.addUserToFirm(firmId), userData);

  removeUserFromFirm = (firmId, userId) => this.delete(FIRMS.removeUserFromFirm(firmId, userId));

  getFirmCompanyExtendedInfo = (firmId, companyId) => this.get(FIRMS.getFirmCompanyExtendedInfo(firmId, companyId));

  createOrUpdateCompGroups = (firmId, data) => this.post(FIRMS.createOrUpdateCompGroups(firmId), data);

  getPendingDecisionsByFirm = firmId => this.get(FIRMS.getPendingDecisions(firmId));

  updatePendingDecisionsByFirm = (firmId, decisionData) => this.post(FIRMS.getPendingDecisions(firmId), decisionData);

  getTasksProgress = firmId => this.get(FIRMS.getTasksProgress(firmId));

  getFirmSetOfQuestionsAndDocuments = firmId => this.get(FIRMS.getFirmSetOfQuestionsAndDocuments(firmId));

  createRequestedTasks = data => this.post(FIRMS.createRequestedTasks, data);

  getTaskProgressDetail = taskId => this.get(FIRMS.getTaskProgressDetail(taskId));

  changeUserFirmRole = (firmId, userId, roleId) =>
    this.put(FIRMS.changeUserFirmRole(firmId, userId), { role_id: roleId });

  getAllMeasurementDatesByFirmId = id => this.get(MEASUREMENT_DATES.allMeasurementDatesByFirmId(id));

  getCompaniesAndfundsByFirmAndDate = (firmId, date) =>
    this.get(MEASUREMENT_DATES.companiesAndfundsByFirmAndDate(firmId, date));

  deleteCompaniesAndFundByMd = data => this.post(MEASUREMENT_DATES.deleteCompaniesAndFundsByMd, data);

  createRequestedQuestion = data => this.post(FIRMS.createRequestedQuestion, data);

  deleteRequestedQuestion = questionId => this.delete(FIRMS.deleteRequestedQuestion(questionId));

  updateRequestedQuestion = (questionId, data) => this.put(FIRMS.updateRequestedQuestion(questionId), data);

  createFirmRequestedDocument = data => this.post(FIRMS.createFirmRequestedDocument, data);

  deleteFirmRequestedDocument = requestedDocumentId =>
    this.delete(FIRMS.deleteFirmRequestedDocument(requestedDocumentId));

  updateFirmRequestedDocument = (requestedDocumentId, data) =>
    this.put(FIRMS.updateFirmRequestedDocument(requestedDocumentId), data);

  hasFirmSummary = (firmId, fundsByMeasurementDate) => this.post(FIRMS.hasFirmSummary(firmId), fundsByMeasurementDate);
}

export default FirmsService;
