import { isEmpty, uniqBy } from 'lodash';
import { AllocationWeightedShareValue, ShareValueSecurity } from 'common/types/valuation';
import {
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/weightedShareValues';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { generateColumnKey, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { CustomReverseParserParams, GetSecurityValuesParams } from './types';

const {
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS,
  ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SHARES_TITLE,
} = SHEET_TITLES_CONSTANTS;

const { WEIGHTED_VALUE_PER_SHARE, MOIC, NUMBER_OF_SHARES, VALUE }
  = ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS;

const getSecurityValues = (params: GetSecurityValuesParams) => {
  const { cells, fieldAttributes, securityAlias } = params;

  const securityValues = Object.values(cells)
    .filter(cell => cell?.alias === securityAlias)
    .reduce((accumulator, current) => {
      const { y: columnNumber } = getObjectValue(current);
      const adjustedColumnNumber = getNumberValue(columnNumber) + 1;

      const fieldDecimalPlaces = getNumberValue(
        fieldAttributes?.equityAllocationAttrs?.enterprise_value?.decimal_places
      );

      const updatedValue = getNumberValue(
        parseCellValue({
          cell: current,
          fieldDecimalPlaces,
        })
      );

      const security = {} as ShareValueSecurity;

      switch (adjustedColumnNumber) {
        case WEIGHTED_VALUE_PER_SHARE.columnNumber:
          security.price = updatedValue;
          break;

        case NUMBER_OF_SHARES.columnNumber:
          security.shares = updatedValue;
          break;

        case VALUE.columnNumber:
          security.unrealized_value = updatedValue;
          break;

        case MOIC.columnNumber:
          security.xfactor = updatedValue;
          break;

        default:
          break;
      }

      return {
        ...accumulator,
        ...security,
      };
    }, {} as ShareValueSecurity);

  return securityValues;
};

const customReverseParser = (params: CustomReverseParserParams) => {
  const { cells, fieldAttributes, fund, securities, weightedShareValues = [], allocation } = params;

  const { id: fundId = 0, name: fundName } = getObjectValue(fund);

  const shareValues = Object.values(cells).reduce((accumulator, current) => {
    const { data } = getObjectValue(current);
    const { security: securityName, isCustomSecurity } = getObjectValue(data);

    // Exclude Non-Security rows
    if (isEmpty(securityName)) {
      return accumulator;
    }

    const securityAlias = generateColumnKey({
      id: fundId,
      name: getStringValue(securityName),
      prefix: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    const {
      price,
      shares,
      unrealized_value: unrealizedValue,
      xfactor,
    } = getSecurityValues({
      cells,
      fieldAttributes,
      securityAlias,
    });

    const capTableSecurity = securities?.find(security => security?.name === getStringValue(securityName));

    const shareValue = {
      is_custom_security: Boolean(isCustomSecurity),
      price,
      security: getStringValue(securityName),
      securityId: capTableSecurity?.id ?? undefined,
      shares,
      unrealized_value: unrealizedValue,
      xfactor,
    } as ShareValueSecurity;

    return [...accumulator, shareValue];
  }, [] as ShareValueSecurity[]);

  const fundShareValues = uniqBy(shareValues, 'security');

  const fundWeightedShareValues = {
    fund: {
      id: fundId,
      name: getStringValue(fundName),
    },
    name: `${fundName} ${ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_SHARES_TITLE}`,
    share_values: fundShareValues,
  } as AllocationWeightedShareValue;

  const fundWeightedShareValuesIndex = getNumberValue(
    weightedShareValues?.findIndex(weightedShareValue => weightedShareValue?.fund?.id === fundId) ?? -1
  );

  // Add new Fund - Weighted Share Values
  if (fundWeightedShareValuesIndex === -1) {
    weightedShareValues.push(fundWeightedShareValues);
  }
  // Update existing Fund - Weighted Share Values
  else {
    weightedShareValues?.splice(fundWeightedShareValuesIndex, 1, fundWeightedShareValues);
  }
  allocation.weighted_share_values = weightedShareValues;
};

export default customReverseParser;
