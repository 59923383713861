/* eslint-disable no-param-reassign */
import { sortBy } from 'lodash';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { DCF_FINANCIAL_PERIOD_TABLE_NAME } from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import {
  APPROACH_SELECTIONS_ALIASES,
  EBITDA_GPC_MULTIPLE,
  MULTIPLE_ALIASES,
  REVENUE_GPC_MULTIPLE,
  TERMINAL_VALUE_OPTIONS,
} from 'pages/Valuations/util/constants';
import { getSelectionCellOptions, publicCompsOrTransactionsExists } from 'pages/ValuationsAllocation/util';
import { getValueByPropOrFallback, parseDatabaseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import swapMap from 'utillities/swapMap';
import getCurrentSelectedApproach from './getCurrentSelectedApproach';
import { getSelectedApproachAliases } from './getSelectedApproachAliases';
import getSelectionFromTVT from './getSelectionFromTVT';
import obtainMapForSelectMultipleAlias from './obtainMapForSelectMultipleAlias';
import updateApproachesSelectionsInTableData from './updateApproachesSelectionsInTable';

const reverseParser = ({
  cells,
  columns,
  allowEmptyValues,
  rowConfig,
  tableData,
  sheet,
  fieldAttributes,
  approachOptions,
}) => {
  const sortedColumns = sortBy(columns, ['order']);
  const { approaches } = tableData;
  const alphabet = alphabetGenerator([], sortedColumns.length);
  const { dcfApproachAttrs } = fieldAttributes;
  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      const key = `${columnLegend}${cellIndex + 1}`;
      const defaultValue = getValueByPropOrFallback(cell, 'defaultValue', null);
      const type = getValueByPropOrFallback(cell, 'dbType', null);
      const format = getValueByPropOrFallback(cell, 'format', null);
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;
      const decimalPlaces = dcfApproachAttrs[cell.alias]?.decimal_places || cell?.dbDecimalPlaces || 2;

      const calcValue = parseDatabaseValue({
        type,
        value,
        defaultValue,
        format,
        allowEmptyValues,
        gridType,
        decimalPlaces,
      });

      const selectedApproach = getCurrentSelectedApproach(approaches, tableData.approach);
      const specificApproach = selectedApproach?.valuations_approach_gpc ?? selectedApproach?.valuations_approach_gpt;

      const multipleOptions = getSelectionCellOptions({
        isDCF: true,
        specificApproach,
      });

      // need to send multiple selection as label, not number
      const selection = getSelectionFromTVT(cell, value, multipleOptions);

      if (cell.origin !== DCF_FINANCIAL_PERIOD_TABLE_NAME) {
        // this is to temporarily save the 'Other' multiple numeric value
        if (MULTIPLE_ALIASES.includes(cell.alias)) {
          const reversedMap = swapMap(obtainMapForSelectMultipleAlias());
          const selectMultipleAlias = reversedMap.get(cell.alias);
          const selectionStored = column[selectMultipleAlias];
          tableData.approach.valuations_approach_dcf[cell.alias] = calcValue;
          if (selectionStored === VALUATIONS_OTHER_LABEL || !publicCompsOrTransactionsExists(approaches)) {
            const terminalValue = tableData.approach.valuations_approach_dcf.terminal_value;
            const isRevenue = terminalValue === TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE;
            const otherMultipleValueKey = isRevenue ? REVENUE_GPC_MULTIPLE : EBITDA_GPC_MULTIPLE;
            tableData.approach.valuations_approach_dcf[otherMultipleValueKey] = calcValue;
            // make sure the selection is 'Other'
            tableData.approach.valuations_approach_dcf[`${otherMultipleValueKey}_selection`] = VALUATIONS_OTHER_LABEL;
          }
        } else if (APPROACH_SELECTIONS_ALIASES.includes(cell.alias)) {
          const selectedApproach = approachOptions.find(({ value: approachValue }) => approachValue === value);
          const updatedAlias = getSelectedApproachAliases(cell.alias, selectedApproach?.type);
          const selectedApproachValue
            = selectedApproach?.value === 0 || selectedApproach?.value === undefined ? null : selectedApproach?.value;
          if (selectedApproachValue) {
            updateApproachesSelectionsInTableData({
              tableData,
              valueFromSelector: selectedApproachValue,
              updatedAlias,
            });
          }
        } else {
          tableData.approach.valuations_approach_dcf[cell.alias] = selection || calcValue;
        }
      }

      columns.forEach(column => {
        column[cell.alias] = selection || calcValue;
      });

      sheet.columns = columns;
    });
  });
};
export default reverseParser;
