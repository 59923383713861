import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CustomTextField } from 'components';
import { useFormValidation } from 'services/hooks/useFormValidation';

const useStyles = makeStyles({
  formHeader: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  margin: {
    marginBottom: 20,
  },
  textField: {
    width: '100%',
  },
});

const constraints = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 3, message: 'must be at least 3 characters' },
  },
};

const CapTableForm = ({ defaultFormState: { values, errors, dbErrors }, onFormChange }) => {
  const classes = useStyles();
  const { formValidated, validateForm } = useFormValidation(constraints);
  const [formValues, setFormValues] = useState(values);

  useEffect(() => {
    validateForm(formValues);
  }, [validateForm, formValues]);

  useEffect(() => {
    onFormChange(formValidated);
  }, [onFormChange, formValidated]);

  const handleChange = event => {
    event.persist();
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Grid container>
      <CustomTextField
        required
        fullWidth
        id="name"
        name="name"
        type="text"
        label="Name"
        className={classes.margin}
        value={formValues.name}
        onChange={handleChange}
        formErrors={errors}
        dbErrors={dbErrors}
      />
    </Grid>
  );
};

CapTableForm.propTypes = {
  defaultFormState: PropTypes.shape({
    values: PropTypes.shape({
      name: PropTypes.string,
    }),
    errors: PropTypes.shape({
      name: PropTypes.arrayOf(PropTypes.string),
    }),
    dbErrors: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default CapTableForm;
