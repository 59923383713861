import { dcfPercentFormatWithNegativeValues } from 'common/formats/formats';
import { RowConfigParams } from './types';

export const WACC_COST_OF_EQUITY_TITLE = 'Cost Of Equity';
export const WACC_COST_OF_DEBT_TITLE = 'Cost Of Debt';
export const WACC_INVESTED_CAPITAL_EQUITY_TITLE = 'Equity / Invested Capital';
export const WACC_INVESTED_CAPITAL_DEBT_TITLE = 'Debt / Invested Capital';
export const WACC_TITLE = 'Weighted Average Cost Of Capital';

export const WACC_COST_OF_EQUITY_ALIAS = 'wacc_cost_of_equity';
export const WACC_COST_OF_DEBT_ALIAS = 'wacc_cost_of_debt';
export const WACC_INVESTED_CAPITAL_EQUITY_ALIAS = 'wacc_invested_capital_equity';
export const WACC_INVESTED_CAPITAL_DEBT_ALIAS = 'wacc_invested_capital_debt';
export const WACC_ALIAS = 'wacc';

export const WACC_COST_OF_EQUITY_ROW_NUMBER = 2;
export const WACC_COST_OF_DEBT_ROW_NUMBER = 3;
export const WACC_INVESTED_CAPITAL_EQUITY_ROW_NUMBER = 4;
export const WACC_INVESTED_CAPITAL_DEBT_ROW_NUMBER = 5;
export const WACC_ROW_NUMBER = 6;

const getRowConfig = ({ cashTaxRate, isDisabled }: RowConfigParams) => [
  {
    className: 'bigger-row-label',
    value: 'Weighted Average Cost Of Capital (WACC) Analysis',
    alias: 'title',
    rowNumber: 1,
    hidden: true,
    readOnly: true,
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    checkPrevCol: true,
    defaultValue: '0',
    isTitleOrHeader: true,
  },
  {
    value: WACC_COST_OF_EQUITY_TITLE,
    alias: WACC_COST_OF_EQUITY_ALIAS,
    rowNumber: WACC_COST_OF_EQUITY_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    dbDecimalPlaces: 3,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    checkPrevCol: true,
    defaultValue: '0',
  },
  {
    value: WACC_COST_OF_DEBT_TITLE,
    alias: WACC_COST_OF_DEBT_ALIAS,
    rowNumber: WACC_COST_OF_DEBT_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    dbDecimalPlaces: 3,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    checkPrevCol: true,
    defaultValue: '0',
  },
  {
    value: WACC_INVESTED_CAPITAL_EQUITY_TITLE,
    alias: WACC_INVESTED_CAPITAL_EQUITY_ALIAS,
    rowNumber: WACC_INVESTED_CAPITAL_EQUITY_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: dcfPercentFormatWithNegativeValues,
    dbDecimalPlaces: 3,
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '= 1-@5',
    checkPrevCol: true,
    defaultValue: '0',
  },
  {
    value: WACC_INVESTED_CAPITAL_DEBT_TITLE,
    alias: WACC_INVESTED_CAPITAL_DEBT_ALIAS,
    rowNumber: WACC_INVESTED_CAPITAL_DEBT_ROW_NUMBER,
    hidden: false,
    readOnly: isDisabled,
    format: dcfPercentFormatWithNegativeValues,
    dbDecimalPlaces: 3,
    gridType: 'percentage',
    dbType: 'string',
    allowNegativeValue: true,
    expr: '',
    checkPrevCol: true,
    defaultValue: '0',
  },
  {
    value: WACC_TITLE,
    alias: WACC_ALIAS,
    rowNumber: WACC_ROW_NUMBER,
    hidden: false,
    readOnly: true,
    format: {
      ...dcfPercentFormatWithNegativeValues,
      isDecimal: true,
    },
    gridType: 'number',
    dbType: 'string',
    allowNegativeValue: true,
    expr: `= @2*@4+@3*(1-${cashTaxRate.effective_tax_rate})*@5`,
    checkPrevCol: true,
    defaultValue: '0',
  },
];

export default getRowConfig;
