import { makeStyles } from '@material-ui/styles';
import { ITheme } from '../../../../theme/types';

const useTableActionStyles = makeStyles((theme: ITheme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  actionMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  hiddenRow: {
    display: 'none',
  },
}));

export default useTableActionStyles;
