import { OPM } from 'common/constants/allocations';
import { underScoreSpaces } from 'pages/Allocation/allocations/utilities/util';
import { BACKSOLVE_TABLE_NAMES } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { OPM_METHOD } from 'pages/ValuationsAllocation/approaches/FutureExit/utils/constants';
import { FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import {
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import {
  handleBacksolveReturn,
  handleBacksolveScenarioApproachParams,
  handleBacksolveType,
  handleFutureExitReturn,
  handleFutureExitType,
  handleSSVScenarioApproachParams,
} from './props';
import {
  enableCells,
  handleBacksolveScenario,
  handleSpecifiedShareValuesScenario,
} from '../../../customAfterCellChanged/utils';

export const handleApproach: handleFutureExitType = (approach, spreadsheets) => {
  const returnObj: handleFutureExitReturn = { isApproachWithOPM: false };
  if (approach.approach_type === VALUATIONS_FUTURE_EXIT_APPROACH) {
    returnObj.opmInputTableName = getApproachTableName({
      approach,
      tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_OPM,
    });
    returnObj.futureExitValueTableName = getApproachTableName({
      approach,
      tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.FUTURE_EQUITY_VALUE,
    });
    returnObj.isApproachWithOPM
      = Object.values(spreadsheets[returnObj?.opmInputTableName])?.find(
        c => c.alias === FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD
      )?.value === OPM_METHOD;
  }
  return returnObj;
};

export const handleBacksolve: handleBacksolveType = (approach, spreadsheets, defaultTableName, defaultHasOpm) => {
  const returnObj: handleBacksolveReturn = { isApproachWithOPM: defaultHasOpm, opmInputTableName: defaultTableName };
  if (approach.approach_type === VALUATIONS_BACKSOLVE_APPROACH) {
    returnObj.opmInputTableName = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.OPM_INPUTS });
    const backsolveTableName = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE });
    const allocationMethods = Array.from(
      new Set(
        Object.values(spreadsheets[backsolveTableName])
          .filter(c => c.alias === 'allocation_method' && !c.isTotal)
          .map(c => c.value)
      )
    );
    returnObj.isApproachWithOPM = allocationMethods?.includes(OPM.toString());
  }
  return returnObj;
};

export const handleBacksolveScenarioApproach = (backsolveParams: handleBacksolveScenarioApproachParams) => {
  const {
    approach,
    capTableSelectionCell,
    cells,
    changes,
    primaryCapTable,
    isApproachWithOPM,
    volatilityCell,
    maturityCell,
    riskFreeRateCell,
    columnLegend,
    params,
  } = backsolveParams;
  if (approach?.approach_type === VALUATIONS_BACKSOLVE_APPROACH) {
    const backsolveSheetName = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE });
    const securityNameTotalKeyMap = Array.from(
      new Set(Object.values(params.spreadsheets[backsolveSheetName]).filter(c => c.isTotal && c.securityName))
    ).map(c => [c.securityName, c.alias]);
    securityNameTotalKeyMap.forEach(([securityName, key]) => {
      changes.push({
        cell: cells[`${underScoreSpaces(securityName)}_${columnLegend}`],
        value: `=${backsolveSheetName}.TOTALS_${key}`,
      });
    });

    handleBacksolveScenario({
      capTableSelectionCell,
      changes,
      enableCells,
      isApproachWithOPM,
      maturityCell,
      primaryCapTable,
      riskFreeRateCell,
      volatilityCell,
      relatedApproach: approach,
    });
  }
};

export function handleSSVScenarioApproach(futureExitParams: handleSSVScenarioApproachParams) {
  const { approach, spreadsheets, cells, columnLegend, changes, capTableSelectionCell, ssvTableName }
    = futureExitParams;
  if (approach?.approach_type === VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH) {
    const ssvSheetName = getApproachTableName({ approach });
    Object.values(spreadsheets[ssvSheetName])
      .filter(c => c.alias === 'security')
      .map(c => [
        c.value,
        Object.values(spreadsheets[ssvSheetName]).find(
          shareCell => shareCell.x === c.x && shareCell.alias === 'share_price'
        ),
      ])
      .forEach(([securityName, shareCell]) => {
        const updateCell = cells[`${underScoreSpaces(securityName)}_${columnLegend}`];
        changes.push({
          cell: updateCell,
          value: `=${ssvSheetName}.${shareCell.key}`,
        });
      });
    Object.values(spreadsheets[ssvSheetName])
      .filter(c => c.alias === 'security')
      .map(c => [
        c.value,
        Object.values(spreadsheets[ssvSheetName]).find(shareCell => shareCell.x === c.x && shareCell.alias === 'value'),
      ])
      .forEach(([securityName, shareCell]) => {
        const updateCell = cells[`${underScoreSpaces(securityName)}_${columnLegend}_aggregate`];
        changes.push({
          cell: updateCell,
          value: `=${ssvSheetName}.${shareCell.key}`,
        });
      });
    handleSpecifiedShareValuesScenario({
      capTableSelectionCell,
      changes,
      ssvTableName,
    });
  }
}
