import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { MessageBox } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import createColumns from 'pages/Valuations/approaches/discountCashFlow/dcf/config/createColumns';
import getNolColumns from 'pages/Valuations/approaches/discountCashFlow/dcfNOLCarryover/config/createColumns';
import { getThresholdDate } from 'pages/Valuations/util/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import template from './dcf/config/template';

const DCFTable = ({ format, formatDispatch, spreadsheets, onChange, workbook }) => {
  const dcfSpreadsheet = spreadsheets.dcf;
  const { approach, isDisabled } = dcfSpreadsheet.tableData;
  const { valuations_approach_dcf } = approach;
  const { dcf_financial_period } = valuations_approach_dcf;

  const { measurementDate, financialStatementPeriods, resetConfiguration, companyMeasurementDate }
    = useContext(ValuationContext);

  const thresholdDate = getThresholdDate(companyMeasurementDate);

  const addYear = useCallback(async () => {
    const lastPeriod = dcfSpreadsheet.columns[dcfSpreadsheet.columns.length - 1];
    const lastPeriodDate = lastPeriod ? lastPeriod.date : moment(measurementDate.date).subtract(1, 'year');
    const newPeriodDate = moment(lastPeriodDate).add(1, 'year');
    const formatedDate = newPeriodDate.format('YYYY-MM-DD');

    const updatedColumns = createColumns({
      financialPeriods: dcfSpreadsheet?.columns,
      thresholdDate,
      isAddingNewYear: true,
    });

    const columnId = uuid();

    const newColumn = {
      ...template,
      columnId,
      date: formatedDate,
      year: newPeriodDate.year(),
      valuation_approach_dcf: valuations_approach_dcf.id,
      isDeleteableColumn: true,
    };
    const relatedStatement = financialStatementPeriods?.find(fsp => fsp.statement_date === formatedDate);
    if (relatedStatement) {
      newColumn.financial_statement_period = relatedStatement;
      newColumn.financial_statement_period_id = relatedStatement.id;
    } else {
      newColumn.fsp_year = newColumn.year;
    }

    updatedColumns.push(newColumn);

    dcfSpreadsheet.tableData.approach.valuations_approach_dcf.dcf_financial_period = updatedColumns;

    await dcfSpreadsheet.reset({ columns: updatedColumns });

    await spreadsheets.dcfNol.reset({ columns: getNolColumns({ approach, thresholdDate }) });

    resetConfiguration();
  }, [
    approach,
    dcfSpreadsheet,
    financialStatementPeriods,
    measurementDate.date,
    resetConfiguration,
    spreadsheets.dcfNol,
    thresholdDate,
    valuations_approach_dcf.id,
  ]);

  const deleteColumn = useCallback(
    async (columnIndex, __columnId, __wb, cb) => {
      if (dcfSpreadsheet.columns.length === 2) {
        return;
      }

      const column = dcfSpreadsheet.columns[columnIndex];
      const filteredPeriods = dcf_financial_period.filter(({ columnId }) => columnId !== column.columnId);

      const columns = createColumns({ financialPeriods: filteredPeriods, thresholdDate });

      const deletedId = column.id;
      let { deleted_dcf_financial_periods } = valuations_approach_dcf;

      if (deletedId) {
        deleted_dcf_financial_periods = deleted_dcf_financial_periods
          ? [...deleted_dcf_financial_periods, deletedId]
          : [deletedId];
      }

      dcfSpreadsheet.tableData.approach.valuations_approach_dcf = {
        ...valuations_approach_dcf,
        deleted_dcf_financial_periods,
        dcf_financial_period: filteredPeriods,
      };

      await dcfSpreadsheet.reset({ columns });

      await spreadsheets.dcfNol.reset({ columns: getNolColumns(approach, thresholdDate) });

      if (cb) cb();

      resetConfiguration();
    },
    [
      approach,
      dcfSpreadsheet,
      dcf_financial_period,
      resetConfiguration,
      spreadsheets.dcfNol,
      thresholdDate,
      valuations_approach_dcf,
    ]
  );

  return (
    <div style={{ width: '100%' }}>
      {!dcfSpreadsheet.columns?.length && (
        <MessageBox title="No columns have been added yet" tagline="Please add a year to continue" fullWidth={false} />
      )}
      <ScalarSpreadsheet
        {...dcfSpreadsheet}
        key={dcfSpreadsheet.name}
        onChange={onChange}
        sheet={dcfSpreadsheet}
        workbook={workbook}
        format={format}
        formatDispatch={formatDispatch}
        className="valuations-dcf"
        deleteColumn={deleteColumn}
      />
      <ExtendedFabButton
        id="financials-dcf-table-add-year-btn"
        label="Add Year"
        notFixed
        onClick={addYear}
        btnStyle={{
          marginTop: '1.5em',
          float: 'right',
        }}
        disabled={isDisabled}
      />
    </div>
  );
};

DCFTable.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
};

export default DCFTable;
