import React from 'react';
import { EQUITY_VALUE_ALIAS } from 'common/constants/allocations';
import { largeCurrencyFormat, largeDecimalFormat, smallCurrencyFormat } from 'common/formats/formats';
import GridRowLabel from 'components/Grid/GridRowLabel';
import { underScoreSpaces } from 'pages/Allocation/allocations/utilities/util';
import {
  FIRST_ROW_WITH_DATA,
  SECURITY_ALIAS,
  SECURITY_TITLE,
  SHARE_PRICE_ALIAS,
  SHARE_PRICE_TITLE,
  SHARES_ALIAS,
  SHARES_TITLE,
  TABLE_HEADER_CONFIG,
  TOTAL_VALUE_ALIAS,
  TOTAL_VALUE_TITLE,
} from 'pages/Valuations/approaches/SpecifiedShareValues/constants';
import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';
import {
  SHEET_ALIASES_CONSTANTS,
  VALUATION_SUMMARY_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';

const { VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY, VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY }
  = SHEET_ALIASES_CONSTANTS;

export default async ({ rowConfig, tableData, initialObject }) => {
  const { isDisabled } = tableData;
  const customClasses = `${TABLE_HEADER_CONFIG.className} align-right`;
  const shareValueMap = new Map();
  initialObject.valuations_approach_ssv.share_values?.forEach(shareValue => {
    shareValueMap.set(shareValue.security, shareValue);
  });
  const cells = {
    A1: {
      ...TABLE_HEADER_CONFIG,
      value: SECURITY_TITLE,
      key: 'A1',
      valueViewer: props => <GridRowLabel {...props} unitsFormatter={false} currencyFormatter formatterCell />,
    },
    B1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: SHARE_PRICE_TITLE,
      key: 'B1',
    },
    C1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: SHARES_TITLE,
      key: 'C1',
    },
    D1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: TOTAL_VALUE_TITLE,
      key: 'D1',
    },
  };

  const { securities } = tableData;

  const getSumExprCells = colLegend => securities.map((_, i) => `${colLegend}${i + FIRST_ROW_WITH_DATA}`).join(',');

  const EQUITY_VALUE_LABEL_FORMAT = {
    readOnly: true,
    value: 'Equity Value',
    key: `A${rowConfig.length - 1}`,
  };

  const ENTERPRISE_VALUE_LABEL_FORMAT = {
    readOnly: true,
    value: 'Enterprise Value',
    key: `A${rowConfig.length}`,
  };

  const TOTAL_EQUITY_VALUE_CELL = {
    readOnly: true,
    value: '',
    gridType: 'number',
    key: `D${rowConfig.length - 1}`,
    expr: `=SUM(${getSumExprCells('D')})`,
    format: largeCurrencyFormat,
    alias: EQUITY_VALUE_ALIAS,
  };

  const TOTAL_ENTERPRISE_VALUE_CELL = {
    readOnly: true,
    value: '',
    gridType: 'number',
    key: `D${rowConfig.length}`,
    expr: `=SUM(${getSumExprCells(
      'D'
    )}) + ${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY} - ${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`,
    format: largeCurrencyFormat,
    customKey: VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
  };

  cells[EQUITY_VALUE_LABEL_FORMAT.key] = EQUITY_VALUE_LABEL_FORMAT;
  cells[ENTERPRISE_VALUE_LABEL_FORMAT.key] = ENTERPRISE_VALUE_LABEL_FORMAT;
  cells[TOTAL_EQUITY_VALUE_CELL.key] = TOTAL_EQUITY_VALUE_CELL;
  cells[TOTAL_ENTERPRISE_VALUE_CELL.key] = TOTAL_ENTERPRISE_VALUE_CELL;

  rowConfig.forEach((security, rowIdx) => {
    if (!security.skipParse) {
      const rowNumber = rowIdx + 1;
      const security_name_cell = {
        alias: SECURITY_ALIAS,
        key: `A${rowNumber}`,
        columnLegend: 'A',
        expr: security.name,
        readOnly: true,
        ignoreRowCopy: true,
        isRequired: true,
        id: security.security,
      };
      const share_price_value_cell = {
        key: `B${rowNumber}`,
        value: shareValueMap.get(security.id)?.value,
        gridType: 'number',
        format: smallCurrencyFormat,
        customKey: underScoreSpaces(security.name),
        minValue: 0,
        alias: SHARE_PRICE_ALIAS,
        readOnly: isDisabled,
      };
      const shares_cell = {
        key: `C${rowNumber}`,
        gridType: 'number',
        readOnly: true,
        isRequired: true,
        alias: SHARES_ALIAS,
        value: security.shares,
        format: largeDecimalFormat,
      };
      const total_value_cell = {
        key: `D${rowNumber}`,
        expr: `=B${rowNumber} * C${rowNumber}`,
        customKey: `${underScoreSpaces(security.name)}_aggregate`,
        gridType: 'number',
        format: TOTAL_EQUITY_VALUE_CELL.format,
        alias: TOTAL_VALUE_ALIAS,
      };
      cells[security_name_cell.key] = security_name_cell;
      cells[shares_cell.key] = shares_cell;
      cells[share_price_value_cell.key] = share_price_value_cell;
      cells[total_value_cell.key] = total_value_cell;
    }
  });
  return cells;
};
