import React from 'react';
import { InformationContextValue } from './types';

const InformationDashboard409AContext = React.createContext<InformationContextValue>({
  handleGetCurrentInformationRequests: (): Promise<void> => new Promise(() => {}),
  currentDocuments: undefined,
  addFilesToFolder: async () => new Promise(() => {}),
  refreshDocuments: () => null,
  downloadDocument: () => null,
  updateSubFolder: async () => new Promise(() => {}),
});

export default InformationDashboard409AContext;
