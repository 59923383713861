import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { EditFileDialogProps } from './types';

const EditFileDialog: React.FC<EditFileDialogProps> = ({
  open,
  onClose,
  file,
  fileRequests,
  fileRequestMap,
  onSave,
}) => {
  const [fileName, setFileName] = useState(file.file.filename);
  const initiallySelectedFileRequests = useMemo(() => {
    if (file.file.id) {
      return fileRequestMap[file.file.id]?.map(request => request.id) ?? [];
    }
    return [];
  }, [fileRequestMap, file.file.id]);

  const [selectedFileRequestIds, setSelectedFileRequestIds] = useState<number[]>(initiallySelectedFileRequests);

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  const handleFileRequestChange = (id: number) => {
    setSelectedFileRequestIds(prevState =>
      prevState.includes(id) ? prevState.filter(requestId => requestId !== id) : [...prevState, id]
    );
  };

  const handleSubmit = useCallback(() => {
    const deselectedFileRequestIds = initiallySelectedFileRequests.filter(id => !selectedFileRequestIds.includes(id));
    const newlySelectedFileRequestIds = selectedFileRequestIds.filter(
      id => !initiallySelectedFileRequests.includes(id)
    );
    onSave({ ...file.file, filename: fileName }, newlySelectedFileRequestIds, deselectedFileRequestIds);
  }, [file, fileName, initiallySelectedFileRequests, onSave, selectedFileRequestIds]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit File</DialogTitle>
      <DialogContent>
        <TextField label="File Name" value={fileName} onChange={handleFileNameChange} fullWidth />
        <FormGroup>
          {fileRequests.map(request => (
            <FormControlLabel
              key={request.id}
              control={
                <Checkbox
                  checked={selectedFileRequestIds.includes(request.id)}
                  onChange={() => handleFileRequestChange(request.id)}
                />
              }
              label={request.name}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFileDialog;
