import { getObjectValue } from '../../../../../utillities';
import { Approach } from '../../guidelinePublicCompanies/types';

const customValidations = async ({ parsedColumns: parsedCells, tableData }: any) => {
  const { approaches } = tableData;

  const parsedCell = getObjectValue(parsedCells.public_comps_approach_calibration);
  if (parsedCell.value === 0) {
    if (!parsedCell.tooltipMessage) {
      parsedCell.tooltipMessages = ['Select Approach'];
    } else {
      parsedCell.tooltipMessages.push('Select Approach');
    }
    parsedCell.isValid = false;
  }

  approaches.forEach((approach: Approach) => {
    if (approach?.id === undefined) {
      if (!parsedCell.tooltipMessage) {
        parsedCell.tooltipMessages = ['Unsaved GPCs'];
      } else {
        parsedCell.tooltipMessages.push('Unsaved GPCs');
      }
    }
  });
};

export default customValidations;
