import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import reverseParser from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/reverseParser';
import { BACKSOLVE_TABLE_NAMES } from 'pages/Valuations/approaches/backsolveApproach/constants';
import parser from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentTable/util/parser';
import rowConfig from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentTable/util/rowConfig';
import tableValidations from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentTable/util/tableValidations';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getStringValue, shortDate } from 'utillities';
import afterCellsChanged from './util/afterCellsChanged';

const createMarketAdjustmentData = ({ approach, approaches, backsolveSummarySheet, attrs, isDisabled }) => {
  const name = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.MARKET_ADJUSTMENT });

  const asOfDate = getStringValue(shortDate(approach?.valuations_approach_backsolve?.as_of_date));

  const column = approach.valuations_approach_backsolve;
  column.valuation_approach_gpc = column.gpc_approach;

  column.as_of_date = asOfDate;
  const savedPublicCompStatusIds
    = approach.valuations_approach_backsolve?.public_companies_status?.map(({ id }) => id) || [];

  const getColumns = () => [{}, column];

  return new SpreadsheetConfig({
    name,
    parser,
    reverseParser,
    rowConfig: rowConfig(approach, approaches, backsolveSummarySheet.name, isDisabled),
    tableData: {
      approach,
      approaches,
      isDisabled,
      savedPublicCompStatusIds,
      backsolveSummarySheetName: backsolveSummarySheet.name,
    },
    afterCellChanged: afterCellsChanged,
    columns: getColumns(),
    showTitlesColumn: false,
    showToolbar: true,
    fieldAttributes: attrs,
    customValidations: tableValidations,
    displayRowIndicator: false,
    displayColumnIndicator: false,
    getColumns,
  });
};

export default createMarketAdjustmentData;
