import { TERMINAL_VALUE_OPTIONS } from 'pages/Valuations/util/constants';
import { getObjectValue } from 'utillities';
import getSelectedApproach from './getSelectedApproach';

const getCurrentSelectedApproach = (approaches, approach, isSecondColumn = false) => {
  const approachDcf = approach.valuations_approach_dcf;
  const {
    terminal_value,
    ebitda_gpc_approach_selection,
    ebitda_gpt_approach_selection,
    revenue_gpc_approach_selection,
    revenue_gpt_approach_selection,
  } = getObjectValue(approachDcf);

  const revenueApproachSelection = revenue_gpc_approach_selection ?? revenue_gpt_approach_selection;
  const ebitdaApproachSelection = ebitda_gpc_approach_selection ?? ebitda_gpt_approach_selection;
  const isCombinedTVT = terminal_value === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;
  if (isCombinedTVT) {
    if (isSecondColumn) {
      return getSelectedApproach(approaches, ebitdaApproachSelection);
    }
    return getSelectedApproach(approaches, revenueApproachSelection);
  }
  const isRevenue = terminal_value === TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE;
  const approachSelectedValue = isRevenue ? revenueApproachSelection : ebitdaApproachSelection;
  const selectedApproach = getSelectedApproach(approaches, approachSelectedValue);
  return selectedApproach;
};

export default getCurrentSelectedApproach;
