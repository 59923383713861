import React from 'react';
import { smallCurrencyFormat } from 'common/formats/formats';
import { getEquityValueFormula } from 'common/formulas/math.js/ENTERPRISE_AND_EQUITY_VALUE';
import { SelectValueViewer } from 'components';
import { GridDate, GridDateEditor, GridDateValueViewer, GridSelect } from 'components/FeaturedSpreadsheet/components';
import { METRICS } from 'pages/Valuations/approaches/backsolveApproach/constants';
import MarketAdjustmentLedger from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/MarketAdjustmentLedger';
import { AS_OF_DATE_ALIAS } from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/constants';
import { formulaProps } from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/getRowConfig';
import {
  ADJUSTED_ENTERPRISE_ALIAS,
  ADJUSTED_ENTERPRISE_TITLE,
  ADJUSTED_EQUITY_ALIAS,
  ADJUSTED_EQUITY_TITLE,
  ADJUSTMENT_OPTIONS,
  ADJUSTMENT_SELECTION_TITLE,
  ADJUSTMENT_TYPE_ALIAS,
  MARKET_ADJUSTMENT_ALIAS,
  MARKET_ADJUSTMENT_CUSTOM_KEY,
  MARKET_ADJUSTMENT_TITLE,
} from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentTable/util/constants';
import { extractGPCApproaches } from 'pages/Valuations/util/util';
import {
  VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
  VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import {
  SHEET_ALIASES_CONSTANTS,
  VALUATION_SUMMARY_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';

const { VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY, VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY }
  = SHEET_ALIASES_CONSTANTS;

export default (approach, approaches, backsolveSummaryName, isDisabled) => {
  const publicCompsList = extractGPCApproaches(approaches);
  return [
    { ignoreRowCopy: true, largeHeader: true },
    {
      value: 'As of Date',
      alias: AS_OF_DATE_ALIAS,
      rowNumber: 2,
      readOnly: isDisabled,
      dbType: 'string',
      defaultValue: '',
      gridType: 'gridDate',
      customKey: 'AsOfDate',
      ignoreRowCopy: true,
      gridDateComponent: GridDate,
      dataEditor: props => <GridDateEditor {...props} />,
      valueViewer: props => <GridDateValueViewer {...props} />,
    },
    {
      value: 'Public Comps Approach',
      alias: 'valuation_approach_gpc',
      rowNumber: 3,
      readOnly: isDisabled,
      dbType: 'string',
      expr: '',
      ignoreRowCopy: true,
      dataEditor: props => <GridSelect menuPosition="fixed" options={publicCompsList} {...props} />,
      valueViewer: props => <SelectValueViewer options={publicCompsList} {...props} />,
    },
    {
      value: 'Metric',
      alias: 'metric',
      customKey: 'Metric',
      rowNumber: 4,
      readOnly: true,
      dbType: 'string',
      defaultValue: METRICS[0],
      expr: '',
      ignoreRowCopy: true,
    },
    {
      ...formulaProps,
      value: MARKET_ADJUSTMENT_TITLE,
      alias: MARKET_ADJUSTMENT_ALIAS,
      customKey: MARKET_ADJUSTMENT_CUSTOM_KEY,
      rowNumber: 5,
      readOnly: true,
      dbType: 'string',
      expr: '',
      ignoreRowCopy: true,
      dialog: { content: <MarketAdjustmentLedger /> },
      disableWhenLedgerOpen: true,
    },
    {
      value: ADJUSTMENT_SELECTION_TITLE,
      alias: ADJUSTMENT_TYPE_ALIAS,
      rowNumber: 6,
      dbType: 'string',
      readOnly: isDisabled,
      ignoreRowCopy: true,
      valueViewer: props => <SelectValueViewer options={ADJUSTMENT_OPTIONS} useLabel {...props} />,
      dataEditor: props => <GridSelect menuPosition="fixed" options={ADJUSTMENT_OPTIONS} enumerated {...props} />,
    },
    {
      value: ADJUSTED_ENTERPRISE_TITLE,
      alias: ADJUSTED_ENTERPRISE_ALIAS,
      customKey: VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
      rowNumber: 7,
      readOnly: true,
      dbType: 'string',
      gridType: 'number',
      expr: `=${backsolveSummaryName}.B3 + ${backsolveSummaryName}.B3 * B5`,
      ignoreRowCopy: true,
      format: smallCurrencyFormat,
    },
    {
      value: ADJUSTED_EQUITY_TITLE,
      alias: ADJUSTED_EQUITY_ALIAS,
      customKey: VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY,
      rowNumber: 8,
      readOnly: true,
      dbType: 'string',
      gridType: 'number',
      expr: `=${getEquityValueFormula(
        'B7',
        `${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`,
        `${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY}`
      )}`,
      ignoreRowCopy: true,
      format: smallCurrencyFormat,
    },
  ];
};
