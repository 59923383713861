import {
  EBITDA_APPROACH_SELECTION,
  EBITDA_GPC_APPROACH_SELECTION,
  EBITDA_GPT_APPROACH_SELECTION,
  REVENUE_APPROACH_SELECTION,
  REVENUE_GPC_APPROACH_SELECTION,
  REVENUE_GPT_APPROACH_SELECTION,
} from 'pages/Valuations/util/constants';
import { VALUATIONS_PUBLIC_COMPANIES_APPROACH } from 'pages/ValuationsAllocation/common/constants/valuations';

type ApproachType = typeof EBITDA_APPROACH_SELECTION | typeof REVENUE_APPROACH_SELECTION;

export const getSelectedApproachAliases = (currentAlias: ApproachType, type: string) => {
  const selectedGPCApproachAliases = {
    [EBITDA_APPROACH_SELECTION]: EBITDA_GPC_APPROACH_SELECTION,
    [REVENUE_APPROACH_SELECTION]: REVENUE_GPC_APPROACH_SELECTION,
  };
  const selectedGPTApproachAliases = {
    [EBITDA_APPROACH_SELECTION]: EBITDA_GPT_APPROACH_SELECTION,
    [REVENUE_APPROACH_SELECTION]: REVENUE_GPT_APPROACH_SELECTION,
  };

  if (type === VALUATIONS_PUBLIC_COMPANIES_APPROACH) {
    return selectedGPCApproachAliases[currentAlias];
  }

  return selectedGPTApproachAliases[currentAlias];
};
