import { ValuationsApproach } from 'common/types/valuation';

const getGpcAndGptForDropdown = (approaches: ValuationsApproach[]) => {
  if (!approaches.length) return [{ value: 0, label: 'N/A', type: '' }];

  return approaches.map(({ panelId, name, approach_type }) => ({
    value: panelId,
    label: name,
    type: approach_type,
  }));
};

export default getGpcAndGptForDropdown;
