import React, { useContext } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import { getObjectValue } from 'utillities';
import getOptionsAccordingApproach from './getOptionsAccordingApproach';
import DCFTVTContext from '../../context/DCFTVTContext';

const MultipleTypeApproachSelect = props => {
  const { cell, dcfWacc } = getObjectValue(props);
  const { revenueMultipleOptions, ebitdaMultipleOptions } = useContext(DCFTVTContext);
  const options = getOptionsAccordingApproach({ dcfWacc, cell, revenueMultipleOptions, ebitdaMultipleOptions });

  return <GridSelect useHandleChange={false} usePortal options={options} {...props} />;
};

export default MultipleTypeApproachSelect;
