/* eslint-disable no-param-reassign */
import getSelectionFromTVT from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/getSelectionFromTVT';
import { DCF_FINANCIAL_PERIOD_TABLE_NAME } from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import {
  APPROACH_SELECTION,
  EBITDA_APPROACH_SELECTION,
  EBITDA_GPC_MULTIPLE,
  EBITDA_GPC_MULTIPLE_SELECTION,
  GPC_MULTIPLE,
  GPC_MULTIPLE_SELECTION,
  REM_TVT_SECOND_COLUMN,
  REVENUE_APPROACH_SELECTION,
  REVENUE_GPC_MULTIPLE,
  REVENUE_GPC_MULTIPLE_SELECTION,
  TERMINAL_VALUE_OPTIONS,
} from 'pages/Valuations/util/constants';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util';
import { parseDatabaseValue } from 'utillities';
import getCurrentSelectedApproach from '../../util/getCurrentSelectedApproach';
import { getSelectedApproachAliases } from '../../util/getSelectedApproachAliases';
import updateApproachesSelectionsInTableData from '../../util/updateApproachesSelectionsInTable';

const reverseParser = ({ cells, tableData, rowConfig, approachOptions }) => {
  Object.values(cells).forEach(cell => {
    if (cell.alias) {
      const { value } = cell;
      const { approaches } = tableData;
      const defaultValue = null;
      const decimalPlaces = cell.dbDecimalPlaces;

      const dbValue = parseDatabaseValue({
        type: cell?.dbType,
        value,
        defaultValue,
        format: cell?.format,
        allowEmptyValues: true,
        dontMultiply: true,
        decimalPlaces,
      });

      const modifiedPropToWriteTo = [GPC_MULTIPLE, GPC_MULTIPLE_SELECTION, APPROACH_SELECTION].includes(cell.alias);

      const isSecondColumn = cell.columnLegend === REM_TVT_SECOND_COLUMN;
      if (cell.origin !== DCF_FINANCIAL_PERIOD_TABLE_NAME && !modifiedPropToWriteTo) {
        tableData.approach.valuations_approach_dcf[cell.alias] = dbValue;
      } else if (cell.alias === GPC_MULTIPLE_SELECTION) {
        const selectedApproach = getCurrentSelectedApproach(approaches, tableData.approach, isSecondColumn);
        const specificApproach = selectedApproach?.valuations_approach_gpc ?? selectedApproach?.valuations_approach_gpt;
        const multipleOptions = getSelectionCellOptions({
          isDCF: true,
          specificApproach,
        });
        const selection = getSelectionFromTVT(cell, value, multipleOptions);
        const propToWriteTo = isSecondColumn ? EBITDA_GPC_MULTIPLE_SELECTION : REVENUE_GPC_MULTIPLE_SELECTION;
        tableData.approach.valuations_approach_dcf[propToWriteTo] = selection;
      } else if (cell.alias === GPC_MULTIPLE) {
        const propToWriteTo = isSecondColumn ? EBITDA_GPC_MULTIPLE : REVENUE_GPC_MULTIPLE;
        tableData.approach.valuations_approach_dcf[propToWriteTo] = dbValue;
      } else if (
        cell.alias === APPROACH_SELECTION
        && tableData.approach.valuations_approach_dcf.terminal_value === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE
      ) {
        const propToWriteTo = isSecondColumn ? EBITDA_APPROACH_SELECTION : REVENUE_APPROACH_SELECTION;
        const selectedApproach = approachOptions.find(({ value: approachValue }) => approachValue === value);
        const approachType = selectedApproach?.type;
        const updatedAlias = getSelectedApproachAliases(propToWriteTo, approachType);
        const selectedApproachValue
          = selectedApproach?.value === 0 || selectedApproach?.value === undefined ? null : selectedApproach?.value;
        if (selectedApproachValue) {
          updateApproachesSelectionsInTableData({
            tableData,
            valueFromSelector: selectedApproachValue,
            updatedAlias,
          });
        }
      }
    }
  });
};
export default reverseParser;
