/* eslint-disable no-param-reassign */

import { parseCellValue } from '../../../../ValuationsAllocation/util';
import { IReverseParserCalibrationParams } from '../../guidelinePublicCompanies/PerformanceMetrics/config/reverseParser/types';

const reverseParserCalibration = (params: IReverseParserCalibrationParams) => {
  const { cells, tableData } = params;
  const calibrationDate = cells.A2.value?.toString() || '';
  const transactionAllocationMethod = cells.A3.value?.toString() || '';
  const publicCompsApproachCalibration = Number(cells.A9?.value || '0');
  const selectedFinancialVersion = Number(cells.A10?.value ?? '0');
  const ltmRevenue = cells.A11.value || 0;
  const ntmRevenue = cells.A12.value || 0;
  const ltmEbitda = cells.A13.value || 0;
  const ntmEbitda = cells.A14.value || 0;
  const plusCash = cells.A5.value || 0;
  const lessDebt = cells.A6.value || 0;

  const updatedEquityCellValue = parseCellValue({ cell: cells.A7, fieldDecimalPlaces: 0 });
  const updatedEnterpriseCellValue = parseCellValue({ cell: cells.A4, fieldDecimalPlaces: 0 });

  tableData.calibration.calibration_date = calibrationDate;
  tableData.calibration.transaction_allocation_method = transactionAllocationMethod;
  tableData.calibration.enterprise_value_inputs = updatedEnterpriseCellValue;
  tableData.calibration.equity_value_inputs = updatedEquityCellValue;
  tableData.calibration.public_comps_approach_calibration = publicCompsApproachCalibration;
  tableData.calibration.financials_version = selectedFinancialVersion;

  if (tableData.calibration.data_updated === true) {
    tableData.calibration.ltm_revenue_inputs = ltmRevenue.toString();
    tableData.calibration.ntm_revenue_inputs = ntmRevenue.toString();
    tableData.calibration.ltm_ebitda_inputs = ltmEbitda.toString();
    tableData.calibration.ntm_ebitda_inputs = ntmEbitda.toString();
  }
  tableData.calibration.data_updated = true;

  tableData.calibration.plus_cash_inputs = plusCash.toString();
  tableData.calibration.less_debt_inputs = lessDebt.toString();
};

export default reverseParserCalibration;
