import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { ExtraActionsMenu } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { InformationDashboard409AContext } from 'context/InformationDashboard409AContext';
import {
  ERROR_DISMISSING_REQUESTED_DOCUMENT,
  SUCCESS_DISMISSING_REQUESTED_DOCUMENT,
  SUCCESS_MODIFYING_INFORMATION_REQUEST,
} from 'dashboard409a/common/constants';
import { modifyExistingRequest } from 'dashboard409a/services/dashboard';
import useStyles from 'pages/Documents/components/DocumentsTable/useStyles';
import theme from 'theme';
import CurrentRequestedDocumentTableFolderRow from './CurrentRequestedDocumentTableFolderRow';
import CurrentRequestedDocumentTableRow from './CurrentRequestedDocumentTableRow';
import EditFileDialog from './EditFileDialog';
import EditFolderDialog from './EditFolderDialog';
import {
  CurrentRequestedDocumentTableProps,
  DocumentProgress,
  FileRequestMap,
  FolderFileMap,
  FolderOpenMap,
} from './types';
import { ApiService, DocumentReferencesView, FolderView } from '../../../../api';
import { ADD_NEW_DOCUMENT, ADD_SUBFOLDER_LABEL } from '../../../../common/constants/documents';
import DocumentDialog from '../../../../components/Documents/DocumentDialog';
import { DocumentsContext } from '../../../../context/DocumentsContext';
import AddFolderDialog from '../../../../pages/Documents/components/AddFolderDialog/AddFolderDialog';
import { ITheme } from '../../../../theme/types';
import { useDropRow } from '../hooks/useDragAndDropRow';
import getFormattedDate from '../util/getFormattedDate';

const useLocalStyles = makeStyles((localTheme: ITheme) => ({
  footer: {
    backgroundColor: localTheme?.palette?.grey?.[200],
    position: 'fixed',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerText: {
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  folder: {
    backgroundColor: localTheme?.palette?.grey?.[200],
    color: localTheme?.palette?.primary?.main,
    fontWeight: 'bold',
  },
  actionMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const CurrentRequestedDocumentTable: FC<CurrentRequestedDocumentTableProps> = ({ documents }) => {
  const classes = useLocalStyles();
  const responsibleUsers = documents?.responsible_users || [];
  const lastTaskId = documents?.id || null;
  const [isOpenDismissDocumentRequestModal, setIsOpenDismissDocumentRequestModal] = useState(false);
  const [documentToRemove, setDocumentToRemove] = useState<DocumentProgress | null>(null);
  const {
    handleGetCurrentInformationRequests,
    currentDocuments,
    currentMeasurementDateId,
    refreshDocuments,
    downloadDocument,
    addFilesToFolder,
    updateSubFolder,
  } = useContext(InformationDashboard409AContext);
  const tableClasses = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [openFolderDialog, setOpenFolderDialog] = useState(false);
  const [openNewDocumentDialog, setOpenNewDocumentDialog] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const [draggedRow, setDraggedRow] = useState<DocumentReferencesView | null>(null);
  const [selectedFile, setSelectedFile] = useState<DocumentReferencesView>();
  const [selectedFolder, setSelectedFolder] = useState<FolderView>();

  const isEditFolderDialogOpen = useMemo(() => selectedFolder !== undefined, [selectedFolder]);
  const isEditFileDialogOpen = useMemo(() => selectedFile !== undefined, [selectedFile]);

  const closeEditFolderDialog = useCallback(() => setSelectedFolder(undefined), [setSelectedFolder]);
  const closeEditFileDialog = useCallback(() => setSelectedFile(undefined), [setSelectedFile]);

  const updateFolder = useCallback(
    async (name: string, parentFolder: FolderView | undefined) => {
      if (selectedFolder?.id && selectedFolder.md_documents) {
        const data = {
          ...selectedFolder,
          parent_folder: parentFolder?.id ?? null,
          name,
        };
        const updatedFolder = await updateSubFolder(
          selectedFolder.md_documents.toString(),
          selectedFolder.id.toString(),
          data
        );
        if (updatedFolder) {
          refreshDocuments();
          closeEditFolderDialog();
        }
      }
    },
    [closeEditFolderDialog, refreshDocuments, selectedFolder, updateSubFolder]
  );

  const updateFile = useCallback(
    async (fileUpdate, selectedFileRequestIds, deselectedFileRequestIds) => {
      await ApiService.apiDocumentsUpdateRequestedFileUpdate(fileUpdate.id, {
        id: fileUpdate.id,
        filename: fileUpdate.filename,
        requested_file_ids_to_add: selectedFileRequestIds,
        requested_file_ids_to_remove: deselectedFileRequestIds,
      });
      refreshDocuments();
      await handleGetCurrentInformationRequests();
      closeEditFileDialog();
    },
    [closeEditFileDialog, handleGetCurrentInformationRequests, refreshDocuments]
  );

  const { handleDragOver, handleDrop, handleDragLeave } = useDropRow({
    draggedRow,
    addFilesToFolder,
    refreshDocuments,
  });

  const handleClose = useCallback(() => {
    if (refreshDocuments) {
      refreshDocuments();
    }
    setOpenNewDocumentDialog(false);
    setOpenFolderDialog(false);
  }, [refreshDocuments]);

  const tableHeadRows = [
    { id: 'format', label: 'Format' },
    { id: 'name', label: 'Name' },
    { id: 'source', label: 'Source' },
    { id: 'sentUploadDate', label: 'Sent Date' },
    { id: 'references', label: 'References' },
    { id: 'actions', label: 'Actions' },
  ];

  const unmetRequests = useMemo(() => documents?.progress.filter(request => request.files.length === 0), [documents]);

  const fileRequestMap = useMemo(
    () =>
      documents.progress.reduce((acc, request) => {
        request.files.forEach(file => {
          const fileId = file.id ?? 0;
          if (!acc[fileId]) {
            acc[fileId] = [];
          }
          acc[fileId].push(request);
        });
        return acc;
      }, {} as FileRequestMap),
    [documents]
  );

  // we need to create an object that maps files in currentDocuments to corresponding folders in currentDocuments
  const folderFileMap = useMemo(() => {
    const map = currentDocuments?.files?.reduce((acc, file) => {
      const index = file.folder ?? 0;
      if (!acc[index]) {
        acc[index] = [];
      }
      acc[file.folder ?? 0].push(file);
      return acc;
    }, {} as FolderFileMap);
    return map;
  }, [currentDocuments]);

  const getInitialFolderStatusMap = useCallback(
    () =>
      currentDocuments?.folders?.reduce((acc, folder) => {
        if (folder.id) {
          acc[folder.id] = {
            open: true,
            parent: folder.parent_folder?.id,
          };
        }
        return acc;
      }, {} as FolderOpenMap) || {},
    [currentDocuments]
  );

  const [folderStatusMap, setFolderStatusMap] = useState<FolderOpenMap>(getInitialFolderStatusMap());

  const updateFolderStatusMap = useCallback(
    (folderId: number) => {
      setFolderStatusMap(prevState => {
        if (!prevState[folderId]) {
          return getInitialFolderStatusMap();
        }
        return {
          ...prevState,
          [folderId]: {
            ...prevState[folderId],
            open: !prevState[folderId].open,
          },
        };
      });
    },
    [getInitialFolderStatusMap]
  );

  const tableData = useMemo(() => {
    const topFolders = currentDocuments?.folders?.filter(folder => folder.parent_folder === null);
    const folders = topFolders?.map(folder => {
      //   find all the files related to this folder
      const childFolders = currentDocuments?.folders
        ?.filter(subFolder => subFolder.parent_folder?.id === folder.id)
        ?.map(subFolder => {
          const files = folderFileMap?.[subFolder.id ?? 0] || [];
          return {
            ...subFolder,
            files,
          };
        });
      return {
        ...folder,
        files: folderFileMap?.[folder?.id ?? 0] || [],
        childFolders: childFolders || [],
      };
    });
    const filesWithoutFolder = currentDocuments?.files?.filter(file => file.folder === null);
    return {
      folders,
      files: filesWithoutFolder,
    };
  }, [currentDocuments, folderFileMap]);

  const openDismissDocumentRequestModal = (document: DocumentProgress) => {
    setDocumentToRemove(document);
    setIsOpenDismissDocumentRequestModal(true);
  };

  const removeDocument = async (document: DocumentProgress) => {
    try {
      if (!lastTaskId || !document.id) {
        enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
        return false;
      }
      const data = {
        task: lastTaskId,
        requested_documents_to_delete: [document.id],
        requested_documents: [],
      };
      const response = await modifyExistingRequest(data);

      if (response?.message === SUCCESS_MODIFYING_INFORMATION_REQUEST) {
        enqueueSnackbar(SUCCESS_DISMISSING_REQUESTED_DOCUMENT, { variant: 'success' });
        return true;
      }
      enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
      return false;
    } catch (error) {
      enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
      return false;
    } finally {
      setDocumentToRemove(null);
      setIsOpenDismissDocumentRequestModal(false);
      handleGetCurrentInformationRequests();
    }
  };

  const optionsForExtraActionsMenu = (documentParam: DocumentProgress | null) => [
    {
      label: 'UPLOAD THIS DOCUMENT',
      callback: () => '',
      isActive: false,
    },
    {
      label: 'DISMISS DOCUMENT REQUEST',
      callback: () => {
        if (documentParam !== null) {
          openDismissDocumentRequestModal(documentParam);
        }
      },
      isActive: true,
    },
  ];

  const actions = [
    {
      label: 'CANCEL',
      variant: 'outlined',
      disabled: false,
      callback: () => setIsOpenDismissDocumentRequestModal(false),
    },
    {
      label: 'YES, CONFIRM',
      variant: 'contained',
      disabled: false,
      callback: () => {
        if (documentToRemove !== null) {
          removeDocument(documentToRemove);
        }
      },
    },
  ];

  const getSentAndUploadDate = (document: DocumentProgress) => {
    const { created_at: sentDate } = document || {};
    const formattedCreatedDate = getFormattedDate(sentDate);
    return formattedCreatedDate;
  };

  const documentsContextValue = useMemo(() => ({ currentDocuments }), [currentDocuments]);

  return (
    <DocumentsContext.Provider value={documentsContextValue}>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }} size="small" aria-label="documents table">
          <EnhancedTableHead
            classes={tableClasses}
            onRequestSort={() => {}}
            order="asc"
            orderBy="name"
            columns={tableHeadRows}
          />
          <TableBody onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
            {unmetRequests?.map(document => (
              <TableRow hover key={document.id}>
                <TableCell />
                <TableCell>{document.name}</TableCell>
                <TableCell>{responsibleUsers.map(user => user.full_name).join(', ')}</TableCell>
                <TableCell>{getSentAndUploadDate(document)}</TableCell>
                <TableCell />
                <TableCell>
                  <div className={classes.actionMenu}>
                    <ExtraActionsMenu
                      options={optionsForExtraActionsMenu(document)}
                      dotsColor={theme.palette.gray[500]}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {tableData?.files?.map(row => (
              <CurrentRequestedDocumentTableRow
                key={row.file.id}
                row={row}
                setSelectedFile={setSelectedFile}
                fileRequestMap={fileRequestMap}
                setDraggedRow={setDraggedRow}
                downloadDocument={downloadDocument}
                showRow
              />
            ))}
          </TableBody>
          {tableData?.folders?.map(folderRow => (
            <TableBody key={folderRow.id}>
              <CurrentRequestedDocumentTableFolderRow
                folder={folderRow}
                refreshDocuments={refreshDocuments}
                addFilesToFolder={addFilesToFolder}
                toggleFolder={updateFolderStatusMap}
                isFolderOpen={folderStatusMap[folderRow.id as number]?.open}
                draggedRow={draggedRow}
                setSelectedFolder={setSelectedFolder}
                showFolder
              />
              {folderRow.files.map(subRow => (
                <CurrentRequestedDocumentTableRow
                  key={subRow.file.id}
                  row={subRow}
                  setSelectedFile={setSelectedFile}
                  fileRequestMap={fileRequestMap}
                  setDraggedRow={setDraggedRow}
                  downloadDocument={downloadDocument}
                  showRow={folderStatusMap[folderRow.id as number]?.open}
                />
              ))}
              {folderRow.childFolders.map(childFolder => (
                <>
                  <CurrentRequestedDocumentTableFolderRow
                    folder={childFolder}
                    refreshDocuments={refreshDocuments}
                    addFilesToFolder={addFilesToFolder}
                    draggedRow={draggedRow}
                    toggleFolder={updateFolderStatusMap}
                    showFolder={folderStatusMap[folderRow.id as number]?.open}
                    isFolderOpen={folderStatusMap[childFolder.id as number]?.open}
                    setSelectedFolder={setSelectedFolder}
                  />
                  {childFolder.files.map(subRow => (
                    <CurrentRequestedDocumentTableRow
                      row={subRow}
                      key={subRow.file.id}
                      fileRequestMap={fileRequestMap}
                      setSelectedFile={setSelectedFile}
                      setDraggedRow={setDraggedRow}
                      downloadDocument={downloadDocument}
                      showRow={
                        folderStatusMap[childFolder.id as number]?.open && folderStatusMap[folderRow.id as number]?.open
                      }
                    />
                  ))}
                </>
              ))}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={isOpenDismissDocumentRequestModal}
        title="Do you confirm that you want to dismiss this document request?"
        onClose={() => setIsOpenDismissDocumentRequestModal(false)}
        actions={actions}>
        <span>
          This modification will affect the original request to the company user, eliminating it from its portal.
        </span>
      </ConfirmationDialog>
      <Box component="footer" className={classes.footer}>
        <Container maxWidth="lg" className={classes.buttonContainer}>
          <Button startIcon={<CreateNewFolderIcon />} color="primary" onClick={() => setOpenFolderDialog(true)}>
            Add Subfolder
          </Button>
          <Button startIcon={<CloudUploadIcon />} color="primary" onClick={() => setOpenNewDocumentDialog(true)}>
            Upload Document
          </Button>
          <Typography variant="body2" color="textSecondary" className={classes.footerText}>
            {currentDocuments?.files?.length} Document(s) | {currentDocuments?.folders?.length} Subfolder(s)
          </Typography>
        </Container>
      </Box>
      <DocumentDialog
        open={openNewDocumentDialog}
        onClose={handleClose}
        title={ADD_NEW_DOCUMENT}
        addSelectComponent={false}
        droppedFiles={droppedFiles}
        setDroppedFiles={setDroppedFiles}
        selectedMeasurementDateId={currentMeasurementDateId}
        customButtonLabel="Done"
        fromReferenceWidget={false}
      />

      <AddFolderDialog
        open={openFolderDialog}
        onClose={handleClose}
        title={ADD_SUBFOLDER_LABEL}
        currentFolders={currentDocuments?.folders?.filter(folder => folder.parent_folder === null)}
      />
      {selectedFile && (
        <EditFileDialog
          open={isEditFileDialogOpen}
          onClose={closeEditFileDialog}
          file={selectedFile}
          fileRequests={documents.progress}
          fileRequestMap={fileRequestMap}
          onSave={updateFile}
        />
      )}
      {selectedFolder && (
        <EditFolderDialog
          open={isEditFolderDialogOpen}
          onClose={closeEditFolderDialog}
          folder={selectedFolder}
          onSave={updateFolder}
          folders={currentDocuments?.folders}
        />
      )}
    </DocumentsContext.Provider>
  );
};

export default CurrentRequestedDocumentTable;
