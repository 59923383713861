import React from 'react';
import { SHEET_TITLES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import ApproachScenarioSelectValueViewer from 'pages/ValuationsAllocation/components/ApproachScenarioSelectValueViewer/ApproachScenarioSelectValueViewer';
import { getStringValue } from 'utillities';
import { HandleAllocationMethodColumnParams } from './types';

const { FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER, getValuationSummaryHeader } = SHEET_TITLES_CONSTANTS;

const handleAllocationMethodColumn = (params: HandleAllocationMethodColumnParams) => {
  const { adjustedColumnNumber, cell, colNumber, financialsCurrency, isUniformCurrency } = params;

  const isScenarioColumn = FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER <= adjustedColumnNumber;

  if (isScenarioColumn) {
    cell.forceComponent = true;
    cell.gridType = 'string';
    cell.isRequired = true;
    cell.readOnly = true;
    cell.valueViewer = props => <ApproachScenarioSelectValueViewer {...props} />;
  } else {
    cell.value = getStringValue(
      getValuationSummaryHeader({
        colNumber,
        financialsCurrency,
        isUniformCurrency,
      })?.title
    );
  }
};

export default handleAllocationMethodColumn;
