import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import getRowConfig from './data/getRowConfig';
import parser from './utilities/parser';
import reverseParser from './utilities/reverseParser';
import { SSV_TABLE_NAMES } from '../../constants';

const createCapTableSelectionConfig = ({ approach, ssvAttributes, capTableList, isDisabled }) => {
  const getColumns = () => [[], approach.valuations_approach_ssv];
  const contentColumns = getColumns();

  const name = getApproachTableName({ approach, tableSuffix: SSV_TABLE_NAMES.CAP_TABLE_SELECTION });

  return new SpreadsheetConfig({
    columns: contentColumns,
    rowConfig: getRowConfig({ capTableList, isDisabled }),
    tableData: { isDisabled },
    parser,
    name,
    initialObj: approach,
    reverseParser,
    allowConfirmAndDeleteColumn: false,
    allowDeleteColumn: false,
    handleDeleteColumn: false,
    fieldAttributes: ssvAttributes,
    showTitlesColumn: false,
    displayRowIndicator: false,
    displayColumnIndicator: false,
    getColumns,
  });
};

export default createCapTableSelectionConfig;
