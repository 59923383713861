import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { AllocationScenarioValuationModel, ApiService, AppliedMethodology } from 'api';
import { BACKSOLVE, SSV } from 'common/constants/allocations';
import { useStore } from 'common/store';
import { NewAllocationScenario } from 'common/types/allocation';
import { FinancialStatementsBasic } from 'common/types/financials';
import { SpreadsheetsCells } from 'common/types/scalarSpreadsheet';
import { LayoutContextValues, UseStoreValues } from 'common/types/store';
import { ValuationsApproach } from 'common/types/valuation';
import { LayoutContext } from 'context';
import ebitdaRowConfig from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/config/MultipleTerminal/ebitdaRowConfig';
import revenueRowConfig from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/config/MultipleTerminal/revenueRowConfig';
import revenueAndEbitdaRowConfig from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/config/RevEbitdaMultiple/rowConfig';
import createCalibrationConfig from 'pages/Valuations/approaches/guidelineCalibration/createCalibrationConfig';
import { useCustomClasses as usePerformanceMetricsCustomClasses } from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/config';
import { Approach } from 'pages/Valuations/approaches/guidelinePublicCompanies/types';
import { AllocationScenarioMethods } from 'pages/ValuationsAllocation/allocation/EquityAllocation/config';
import createEquityAllocationConfiguration from 'pages/ValuationsAllocation/allocation/EquityAllocation/config/createEquityAllocationConfiguration';
import { ScenarioData } from 'pages/ValuationsAllocation/allocation/EquityAllocation/config/customAfterCellChanged/types';
import { createWeightedShareValuesConfiguration } from 'pages/ValuationsAllocation/allocation/WeightedShareValues/config';
import { createValuationSummaryConfiguration } from 'pages/ValuationsAllocation/approaches/ValuationSummary/config';
import {
  ADD_ALLOCATION_SCENARIO_TYPES,
  ALLOCATION_ALLOCATION_METHOD_PREFIX,
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_METHODS,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { EQUITY_ALLOCATION_MATURITY_MAX_VALUE } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases';
import {
  CALIBRATION_MODEL_NAME,
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH,
  VALUATIONS_EXTERNAL_VALUATION_APPROACH,
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
  VALUATIONS_SPREADSHEET_GPC_KEY,
  VALUATIONS_SPREADSHEET_OTHER_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { useAssociateFinancialStatementToValuation } from 'pages/ValuationsAllocation/hooks/useAssociateFinancialStatementToValuation';
import { useMapApproachToConfig } from 'pages/ValuationsAllocation/hooks/useMapApproachToConfig';
import { useReadValuationAllocationAttributes } from 'pages/ValuationsAllocation/hooks/useReadValuationAllocationAttributes';
import { WeightingProbabilities, WeightingProbability } from 'pages/ValuationsAllocation/types';
import {
  DCFRowConfigs,
  getAllocationBacksolve,
  getAllocationFutureExit,
  getAllocationSpecifiedShareValues,
  getApproachTableName,
  getBacksolveScenarioMethods,
  getCapTableSecurities,
  getFutureExitScenarioMethod,
  updateDCFOptions,
} from 'pages/ValuationsAllocation/util';
import {
  BackSolveValuesWithIdentifier,
  OPMInputWithReference,
  PresentValue,
  ScenarioWithIdentifier,
  useGetBacksolvesEquityValues,
  useGetRiskFreeRates,
} from 'services/hooks/allocations';
import { generateColumnKey, getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import {
  Configuration,
  ConfiguredSpreadsheets,
  EnterpriseValues,
  UpdateScenariosParams,
  UseCreateValuationParams,
} from './types';
import { getBacksolveBasket } from './util';
import { getScenarioWithIdentifier } from './util/getScenarioWithIdentifier';
import { AllocationScenarioDetailSharesSecurityModel } from '../../util/getCapTableSecurities/types';

// Approaches Implemented on Valuations
const IMPLEMENTED_APPROACHES = Object.freeze([
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH,
  VALUATIONS_EXTERNAL_VALUATION_APPROACH,
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
]);

const DCF_ROW_CONFIGURATIONS = Object.freeze({
  ebitdaRowConfig,
  revenueAndEbitdaRowConfig,
  revenueRowConfig,
} as DCFRowConfigs);

const useCreateValuation = (params: UseCreateValuationParams) => {
  const {
    financialStatementsList,
    allocationVersion,
    approaches,
    capTable,
    capTableVersions,
    compGroups,
    deletedApproachesIds,
    discountedCashFlowProperties,
    financialPeriods,
    financials,
    isValuationDisabled,
    otherFinancialStatements,
    valuation,
  } = params;

  const { cashTaxRate, measurementDate } = discountedCashFlowProperties;

  const { allocation, calibrations } = getObjectValue(valuation);
  const { allocation_scenarios: allocationScenarios = [] } = getObjectValue(allocation);

  const [allocationOPMInputs, setAllocationOPMInputs] = useState<OPMInputWithReference[]>([]);
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [enterpriseValues, setEnterpriseValues] = useState<EnterpriseValues>({} as EnterpriseValues);
  const [securities, setSecurities] = useState<AllocationScenarioDetailSharesSecurityModel[]>([]);
  const [weightedShareValuesNames, setWeightedShareValuesNames] = useState<string[]>([]);
  const [backsolveValues, setBacksolveValues] = useState<BackSolveValuesWithIdentifier[]>([]);
  const [weightingProbabilities, setWeightingProbabilities] = useState<WeightingProbabilities>(
    {} as WeightingProbabilities
  );

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { companyInfo, firmInfo, format, fundList } = storeValue;
  const { captable_currency: captableCurrency, financials_currency: financialsCurrency } = getObjectValue(companyInfo);

  const { companyExchangeRate } = useContext(LayoutContext) as unknown as LayoutContextValues;

  // Performance Metrics Custom Classes
  const { customClasses: performanceMetricsCustomClasses } = usePerformanceMetricsCustomClasses();

  // Financial Statement by Valuation
  const approachFinancialMap = useAssociateFinancialStatementToValuation({
    approaches,
    financials,
    otherFinancialStatements,
  });

  // Get Valuation Approaches and Allocation Attributes
  const {
    allocationAttributes,
    backsolveAttributes,
    discountedCashFlowAttributes,
    externalValuationAttributes,
    futureExitAttributes,
    guidelinePublicCompaniesAttributes,
    guidelinePublicTransactionsAttributes,
    publicCompaniesAttributes,
    specifiedShareValuesAttributes,
    valuationApproachWeightAttributes,
  } = useReadValuationAllocationAttributes();

  const mapApproachToConfiguration = useMapApproachToConfig({
    approaches,
    approachFinancialMap,
    backsolveAttributes,
    capTable,
    capTableVersions,
    companyExchangeRate,
    companyInfo,
    compGroups,
    discountedCashFlowAttributes,
    discountedCashFlowProperties,
    externalValuationAttributes,
    financialPeriods,
    format,
    futureExitAttributes,
    guidelinePublicCompaniesAttributes,
    guidelinePublicTransactionsAttributes,
    isValuationDisabled,
    measurementDate,
    performanceMetricsCustomClasses,
    specifiedShareValuesAttributes,
    financials,
    valuation,
  });

  // Risk Free Rates
  const {
    data: riskFreeRates,
    isLoading: isLoadingRiskFreeRates,
    refetch: refetchRiskFreeRates,
  } = useGetRiskFreeRates({
    measurementDate,
    OPMInputs: allocationOPMInputs,
    shouldQueryAutomatically: !isEmpty(allocationOPMInputs),
  });

  // Backsolves Equity Values
  const { data: backsolvesEquityValues, isLoading: isLoadingBacksolvesEquityValues } = useGetBacksolvesEquityValues({
    backsolveValues,
    shouldQueryAutomatically: !isEmpty(backsolveValues),
  });

  const [isLoadingAllocationScenariosValues, setIsLoadingAllocationScenariosValues] = useState(false);

  // Approaches Configurations
  const approachesConfigurations = useMemo(
    () =>
      approaches
        .filter(approach => IMPLEMENTED_APPROACHES.includes(getStringValue(approach?.approach_type)))
        .map(approach => mapApproachToConfiguration(approach)) as unknown as Configuration[],
    [approaches, mapApproachToConfiguration]
  );

  const calibrationsConfigurations = useMemo(() => {
    const hasValidCalibrations = calibrations.length > 0;

    if (!hasValidCalibrations) {
      return []; // Return an empty array if there are no valid calibrations
    }
    const approach = { approach_type: '', name: '' };
    return calibrations.map(calibration =>
      createCalibrationConfig({
        company: companyInfo,
        companyExchangeRate,
        calibration,
        isDisabled: isValuationDisabled,
        name: CALIBRATION_MODEL_NAME,
        customClasses: performanceMetricsCustomClasses,
        financials,
        approach,
        approaches: approaches as Approach[],
        financialStatementsList: financialStatementsList as FinancialStatementsBasic[],
      })
    ) as unknown as Configuration[];
  }, [
    approaches,
    calibrations,
    companyExchangeRate,
    companyInfo,
    financialStatementsList,
    financials,
    isValuationDisabled,
    performanceMetricsCustomClasses,
  ]);

  // Valuations Spreadsheets
  const ValuationsSpreadsheets = useMemo(
    () =>
      getArrayValue(
        configurations?.flatMap(configuration => Object.values(getObjectValue(configuration?.spreadsheets)))
      ),
    [configurations]
  );

  // Scenario Methods Approaches
  const scenarioMethodsApproaches = useMemo(
    () =>
      getArrayValue(
        approaches?.filter(approach =>
          [
            VALUATIONS_BACKSOLVE_APPROACH,
            VALUATIONS_FUTURE_EXIT_APPROACH,
            VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
          ].includes(getStringValue(approach?.approach_type))
        )
      ),
    [approaches]
  );

  const [approachesScenarioMethods, setApproachesScenarioMethods] = useState<AllocationScenarioMethods[]>([]);
  const filteredApproaches = useMemo(
    () =>
      scenarioMethodsApproaches?.filter(approach =>
        approach.approach_type === VALUATIONS_BACKSOLVE_APPROACH
          ? !approach.valuations_approach_backsolve?.adjust_for_market
          : true
      ),
    [scenarioMethodsApproaches]
  );
  const updateApproachesScenarioMethods = useCallback(
    () =>
      setApproachesScenarioMethods(
        filteredApproaches.map(approach => {
          const {
            approach_type: approachType,
            equity_value: approachEquityValue,
            name: approachName = '',
            panel_id: approachPanelId,
            valuations_approach_backsolve: valuationsApproachBacksolve,
            valuations_approach_future_exit: valuationsApproachFutureExit,
            valuations_approach_ssv: valuationsApproachSpecifiedShareValues,
          } = getObjectValue(approach);

          // Backsolve
          const { id: backsolveId } = getObjectValue(valuationsApproachBacksolve);
          const { valuations_approach_backsolve: backsolveValuationApproach } = getObjectValue(approach);
          const { backsolveScenarioMethodsNames, isBacksolveWithOPM }
            = getBacksolveScenarioMethods(backsolveValuationApproach);

          // Future Exit
          const {
            allocation_method: futureExitAllocationMethod,
            financials_metric_value: futureExitFinancialsMetricValue,
            id: futureExitId,
            less_debt: futureExitLessDebt,
            plus_cash: futureExitPlusCash,
            referenced_approach_multiple_value: futureExitReferencedApproachMultipleValue,
          } = getObjectValue(valuationsApproachFutureExit);

          const futureExitEnterpriseValue
            = getNumberValue(futureExitFinancialsMetricValue) * getNumberValue(futureExitReferencedApproachMultipleValue);

          const futureExitFutureEquityValue
            = futureExitEnterpriseValue + getNumberValue(futureExitPlusCash) - getNumberValue(futureExitLessDebt);

          const { futureExitScenarioMethod, isFutureExitWithOPM }
            = getFutureExitScenarioMethod(valuationsApproachFutureExit);

          const scenarioMethodName = `${backsolveScenarioMethodsNames || futureExitScenarioMethod} ${approachName}`;

          // Specified Share Values
          const { id: specifiedShareValuesId } = getObjectValue(valuationsApproachSpecifiedShareValues);

          switch (getStringValue(approachType)) {
            case VALUATIONS_BACKSOLVE_APPROACH:
              return {
                approachEquityValue: getNumberValue(approachEquityValue),
                approachPanelId,
                approachValuation: valuationsApproachBacksolve,
                approachValuationId: backsolveId,
                id: generateColumnKey({
                  id: approachPanelId,
                  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
                }),
                isApproachWithOPM: isBacksolveWithOPM,
                name: scenarioMethodName,
                scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
                scenarioType: ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
              } as AllocationScenarioMethods;

            case VALUATIONS_FUTURE_EXIT_APPROACH:
              return {
                approachEquityValue: getNumberValue(approachEquityValue),
                approachFutureEquityValue: futureExitFutureEquityValue,
                approachPanelId,
                approachValuation: valuationsApproachFutureExit,
                approachValuationId: futureExitId,
                id: generateColumnKey({
                  id: approachPanelId,
                  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
                }),
                isApproachWithOPM: isFutureExitWithOPM,
                name: scenarioMethodName,
                scenarioMethod: getNumberValue(futureExitAllocationMethod),
                scenarioType: ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
              } as AllocationScenarioMethods;

            case VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH:
              return {
                approachEquityValue: getNumberValue(approachEquityValue),
                approachPanelId,
                approachValuation: valuationsApproachSpecifiedShareValues,
                approachValuationId: specifiedShareValuesId,
                id: generateColumnKey({
                  id: approachPanelId,
                  prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
                }),
                name: approachName,
                scenarioMethod: ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
                scenarioType: ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
              } as AllocationScenarioMethods;

            default:
              return {} as AllocationScenarioMethods;
          }
        })
      ),
    [filteredApproaches]
  );

  useEffect(() => {
    updateApproachesScenarioMethods();
  }, [updateApproachesScenarioMethods]);

  // Allocation Methods Options
  const allocationMethodsOptions = useMemo(
    () =>
      [
        ...ALLOCATION_SCENARIO_METHODS,
        ...approachesScenarioMethods,
        ...ADD_ALLOCATION_SCENARIO_TYPES,
      ] as AllocationScenarioMethods[],
    [approachesScenarioMethods]
  );

  // Handling and adjusting cells if the Company has different currencies
  const isUniformCurrency = useMemo(
    () => captableCurrency === financialsCurrency,
    [captableCurrency, financialsCurrency]
  );

  // Reset Configurations
  const resetConfigurations = useCallback(
    (benchmarkApproach: ValuationsApproach | null = null) => {
      if (configurations) {
        const updatedConfiguration = configurations?.map(config => {
          let updatedConfig: Configuration = { ...config };

          const { approach } = getObjectValue(config);
          const { approach_type: approachType } = getObjectValue(approach);

          const updatedName = approach ? getApproachTableName({ approach }) : '';

          if (
            [VALUATIONS_PUBLIC_COMPANIES_APPROACH, VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH].includes(
              getStringValue(approachType)
            )
          ) {
            const keyToUse
              = approachType === VALUATIONS_PUBLIC_COMPANIES_APPROACH
                ? VALUATIONS_SPREADSHEET_GPC_KEY
                : VALUATIONS_SPREADSHEET_OTHER_KEY;

            const { spreadsheets: spreadsheetsConfig } = getObjectValue(updatedConfig);

            if (spreadsheetsConfig) {
              updatedConfig = {
                ...updatedConfig,
                enterpriseValueReference: updatedName,
                name: updatedName,
                spreadsheets: {
                  ...spreadsheetsConfig,
                  [keyToUse]: {
                    ...spreadsheetsConfig[keyToUse],
                    name: updatedName,
                  },
                },
              };
            }
          }

          if (approachType === VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH && benchmarkApproach) {
            updateDCFOptions({
              DCFSpreadsheets: updatedConfig.spreadsheets,
              benchmarkApproach,
              DCFRowConfigs: DCF_ROW_CONFIGURATIONS,
              approaches,
            });
          }

          return config;
        });

        setConfigurations([...updatedConfiguration]);
      }
    },
    [approaches, configurations]
  );

  // Update Weighting Probabilities
  const updateWeightingProbabilities = (id: number, weightingProbability: WeightingProbability) =>
    setWeightingProbabilities(previousState => ({
      ...previousState,
      [id]: weightingProbability,
    }));

  // Update Securities
  const updateSecurities = useCallback((updatedAllocationScenarios: AllocationScenarioValuationModel[]) => {
    const updatedSecurities = getCapTableSecurities(updatedAllocationScenarios);

    setSecurities(updatedSecurities);

    return updatedSecurities;
  }, []);

  // Update OPM Inputs
  const updateOPMInputs = useCallback(
    async (OPMInputsParams: UpdateScenariosParams) => {
      const { allocationScenarios: updatedAllocationScenarios, approaches: updatedApproaches } = OPMInputsParams;

      const updatedOPMInputs = updatedAllocationScenarios
        .map(scenario => {
          const {
            id: scenarioId,
            maturity: scenarioMaturity,
            scenario_method: scenarioMethod,
            scenario_ref: scenarioRef,
          } = getObjectValue(scenario as NewAllocationScenario);

          // Scenario Id or Ref
          const scenarioIdOrRef = getStringValue(scenarioId ? scenarioId?.toString() : scenarioRef);

          // Backsolve
          const { backsolveValuationApproach, isBacksolveWithOPM } = getAllocationBacksolve({
            approaches: updatedApproaches,
            scenario,
          });

          const { opm_backsolve_date: backsolveDate, maturity: backsolveMaturity }
            = getObjectValue(backsolveValuationApproach);

          // Future Exit
          const { futureExitValuationApproach, isFutureExitWithOPM } = getAllocationFutureExit({
            approaches: updatedApproaches,
            scenario,
          });

          const { maturity: futureExitMaturity } = getObjectValue(futureExitValuationApproach);
          const maturity = getNumberValue(backsolveMaturity ?? futureExitMaturity ?? scenarioMaturity);

          const isApproachWithOPM = isBacksolveWithOPM || isFutureExitWithOPM;

          // If the scenario method is not OPM and is not Backsolve or Future Exit with OPM
          if (![ALLOCATION_SCENARIO_METHOD_OPM].includes(getNumberValue(scenarioMethod)) && !isApproachWithOPM) {
            return null;
          }

          // Do not calculate Risk Free Rate if the Maturity is less than or equal to 0
          if (maturity <= 0 || maturity > EQUITY_ALLOCATION_MATURITY_MAX_VALUE) {
            return null;
          } // Maturity max value is 999.99 (max 5 digits)

          return {
            backsolveDate,
            maturity,
            scenarioIdOrRef: getStringValue(scenarioIdOrRef),
          } as OPMInputWithReference;
        })
        .filter(Boolean) as OPMInputWithReference[];

      setAllocationOPMInputs(updatedOPMInputs);

      await refetchRiskFreeRates();
    },
    [refetchRiskFreeRates]
  );

  const updateScenarioValues = useCallback(
    async (
      scenario: AllocationScenarioValuationModel,
      scenarioIdOrRef,
      scenarioType: number,
      scenarioWithIdentifier: ScenarioWithIdentifier | null,
      cells?: SpreadsheetsCells,
      onChange?: any
    ) => {
      if (scenarioWithIdentifier && scenarioIdOrRef && scenarioWithIdentifier.captableId) {
        const scenarioValueData = {
          ...scenarioWithIdentifier,
          scenarioMethod: scenario.scenario_method as number,
          captableId: scenarioWithIdentifier.captableId,
          discountRate: scenarioWithIdentifier.discountRate ?? undefined,
          scenarioType,
          presentValues: scenarioWithIdentifier.presentValues?.map(pv => pv.toString()) ?? [],
        };
        return ApiService.apiAllocationScenarioAllocationResultsCreate(scenarioValueData).then(response => {
          if (cells && onChange) {
            const cell = Object.values(cells.equityAllocation).find(cell => {
              const cellData: ScenarioData = cell?.data as ScenarioData;
              const cellDataIdOrRef
                = cellData?.scenarioId !== 0 ? cellData?.scenarioId?.toString() : cellData?.scenarioRef;
              return (
                cell?.alias === EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE
                && scenarioIdOrRef === cellDataIdOrRef
              );
            });
            if (scenario.scenario_type === BACKSOLVE) {
              response.present_values = [];
            }
            onChange(cell, response);
          }
        });
      }
      return Promise.resolve(scenarioWithIdentifier);
    },
    []
  );

  // Update Allocation Scenarios Values
  const updateAllocationScenariosValues = useCallback(
    async (scenarioValuesParams: UpdateScenariosParams, cells?: SpreadsheetsCells, onChange?: any) => {
      const { allocationScenarios: updatedAllocationScenarios, approaches: updatedApproaches } = scenarioValuesParams;

      // Backsolve Values
      const updatedBacksolveValues = getArrayValue(
        updatedAllocationScenarios
          ?.filter(scenario => getNumberValue(scenario?.scenario_type) === ALLOCATION_SCENARIO_TYPE_BACKSOLVE)
          ?.map(scenario => {
            const { allocation: allocationId } = getObjectValue(scenario);

            const { backsolveValuationApproach } = getAllocationBacksolve({ approaches: updatedApproaches, scenario });
            const {
              applied_methodologies: backsolveAppliedMethodologies,
              id: backsolveValuationApproachId,
              securities_basket: backsolveScuritiesBasket,
              opm_backsolve_date: backsolveOPMBacksolveDate,
            } = getObjectValue(backsolveValuationApproach);

            const appliedMethodologies = backsolveAppliedMethodologies
              ?.map(appliedMethodology => {
                const {
                  allocation_method: methodologyAllocationMethod,
                  cap_table: methodologyCapTable,
                  maturity: methodologyMaturity,
                  volatility: methodologyVolatility,
                  weight: methodologyWeight,
                } = getObjectValue(appliedMethodology);

                const isOPM = [ALLOCATION_SCENARIO_METHOD_OPM].includes(getNumberValue(methodologyAllocationMethod));

                const backsolveDate = getStringValue(backsolveOPMBacksolveDate);

                // Valid CapTable is required
                if (!methodologyCapTable) {
                  return null;
                }

                // Backsolve Date is required in OPM
                if (isOPM && isEmpty(backsolveDate)) {
                  return null;
                }

                const includeOPMBacksolveDate = {
                  opm_backsolve_date: backsolveDate,
                };

                return {
                  allocation_method: getNumberValue(methodologyAllocationMethod),
                  cap_table: getNumberValue(methodologyCapTable),
                  maturity: isOPM ? getNumberValue(methodologyMaturity) : 0,
                  volatility: isOPM ? getNumberValue(methodologyVolatility) : 0,
                  weight: getNumberValue(methodologyWeight),
                  ...(isOPM ? includeOPMBacksolveDate : {}),
                } as unknown as AppliedMethodology;
              })
              .filter(Boolean) as AppliedMethodology[];

            const basket = getBacksolveBasket({ backsolveScuritiesBasket, securities });

            return {
              allocation: getNumberValue(allocationId),
              applied_methodologies: getArrayValue(appliedMethodologies),
              basket: getArrayValue(basket),
              target_value: getNumberValue(backsolveScuritiesBasket?.target_value),
              valuationApproachId: getNumberValue(backsolveValuationApproachId),
            } as BackSolveValuesWithIdentifier;
          })
      );

      // Update Backsolve Values
      if (!isEmpty(securities)) {
        setBacksolveValues(updatedBacksolveValues);
      }

      const updatePromises = updatedAllocationScenarios
        .filter(scenario => scenario.scenario_type !== SSV)
        .map(scenario => {
          const {
            cap_table_id: scenarioCapTable,
            discount_rate: scenarioDiscountRate,
            equity_value: scenarioEquityValue,
            exit_date: scenarioExitDate,
            id: scenarioId,
            maturity: scenarioMaturity,
            scenario_method: scenarioMethod,
            scenario_ref: scenarioRef,
            scenario_type: scenarioType,
            volatility: scenarioVolatility,
          } = getObjectValue(scenario as NewAllocationScenario);

          // Scenario Id or Ref
          const scenarioIdOrRef = getStringValue(scenarioId ? scenarioId?.toString() : scenarioRef);

          // Backsolve
          const { backsolveAllocationMethods, backsolveValuationApproach, isBacksolveWithOPM } = getAllocationBacksolve(
            {
              approaches: updatedApproaches,
              scenario,
            }
          );

          const {
            applied_methodologies: backsolveAppliedMethodologies,
            id: backsolveValuationApproachId,
            maturity: backsolveMaturity,
            opm_backsolve_date: backsolveDate,
            volatility: backsolveVolatility,
          } = getObjectValue(backsolveValuationApproach);

          const isBacksolve = [ALLOCATION_SCENARIO_TYPE_BACKSOLVE].includes(getNumberValue(scenarioType));

          const backsolveCapTable = backsolveAppliedMethodologies?.[0]?.cap_table;

          // Future Exit
          const { futureExitValuationApproach, isFutureExitWithOPM } = getAllocationFutureExit({
            approaches: updatedApproaches,
            scenario,
          });

          const {
            cap_table: futureExitCapTable,
            discount_rate: futureExitDiscountRate,
            exit_date: futureExitExitDate,
            maturity: futureExitMaturity,
            volatility: futureExitVolatility,
          } = getObjectValue(futureExitValuationApproach);

          const isFutureExit = [ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT].includes(getNumberValue(scenarioType));

          const discountRate = getStringValue(futureExitDiscountRate ?? scenarioDiscountRate);
          const exitDate = getStringValue(futureExitExitDate ?? scenarioExitDate);

          // Specified Share Values
          const { specifiedShareValuesApproach } = getAllocationSpecifiedShareValues({
            approaches: updatedApproaches,
            scenario,
          });

          const { cap_table: specifiedShareValuesCapTable, share_values: specifiedShareValuesShareValues }
            = getObjectValue(specifiedShareValuesApproach);

          const isSpecifiedShareValues
            = [ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES].includes(getNumberValue(scenarioType))
            || [ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES].includes(getNumberValue(scenarioMethod));

          const specifiedShareValuesPresentValues = getArrayValue(
            specifiedShareValuesShareValues?.map(
              shareValue =>
                ({
                  price: getNumberValue(shareValue?.share_price),
                  security: {
                    id: getNumberValue(shareValue?.security),
                    name: getStringValue(shareValue?.name),
                  },
                  value: getNumberValue(shareValue?.share_price),
                } as PresentValue)
            )
          );

          const maturity = backsolveMaturity ?? futureExitMaturity ?? scenarioMaturity;
          const volatility = backsolveVolatility ?? futureExitVolatility ?? scenarioVolatility;

          const isOPM
            = [ALLOCATION_SCENARIO_METHOD_OPM].includes(getNumberValue(scenarioMethod))
            || isBacksolveWithOPM
            || isFutureExitWithOPM;

          const scenarioWithIdentifier = getScenarioWithIdentifier({
            backsolveAllocationMethods,
            backsolveCapTable,
            backsolveDate,
            backsolvesEquityValues,
            backsolveValuationApproach,
            backsolveValuationApproachId,
            discountRate,
            exitDate,
            futureExitCapTable,
            futureExitValuationApproach,
            isBacksolve,
            isFutureExit,
            isOPM,
            isSpecifiedShareValues,
            maturity,
            scenarioCapTable,
            scenarioEquityValue,
            scenarioIdOrRef,
            scenarioMethod,
            scenarioType,
            specifiedShareValuesApproach,
            specifiedShareValuesCapTable,
            specifiedShareValuesPresentValues,
            volatility,
          });
          return updateScenarioValues(scenario, scenarioIdOrRef, scenarioType, scenarioWithIdentifier, cells, onChange);
        });
      setIsLoadingAllocationScenariosValues(true);
      Promise.all(updatePromises).then(() => setIsLoadingAllocationScenariosValues(false));
    },
    [backsolvesEquityValues, securities, updateScenarioValues]
  );

  // Init Securities
  useEffect(() => {
    updateSecurities(allocationScenarios);
  }, [allocationScenarios, updateSecurities]);

  // Init OPM Inputs
  useEffect(() => {
    updateOPMInputs({ allocationScenarios, approaches });
  }, [allocationScenarios, approaches, updateOPMInputs]);

  // Init Configurations
  useEffect(() => {
    // This is to prevent configurations from being updated before all Approaches have been loaded
    const approachesAreSynchronized = valuation?.valuations_approaches?.length === approaches?.length;

    // Set Configurations, Summary Config if Approaches are synchronized
    if (approaches && financials && cashTaxRate && approachesAreSynchronized) {
      const valuationSummaryConfiguration = createValuationSummaryConfiguration({
        allocationVersion,
        approaches,
        company: companyInfo,
        companyExchangeRate,
        configurations: approachesConfigurations,
        deletedApproachesIds,
        fieldAttributes: valuationApproachWeightAttributes,
        financials,
        otherFinancialStatements,
        firm: firmInfo,
        isDisabled: isValuationDisabled,
        isUniformCurrency,
        measurementDate,
        updateWeightingProbabilities,
        valuation,
      });

      const equityAllocationConfiguration = createEquityAllocationConfiguration({
        approaches,
        capTableVersions,
        fieldAttributes: allocationAttributes,
        isDisabled: isValuationDisabled,
        isUniformCurrency,
        riskFreeRates,
        securities,
        updateWeightingProbabilities,
        valuation,
      });

      const weightedShareValuesConfigurations = createWeightedShareValuesConfiguration({
        capTable,
        fieldAttributes: allocationAttributes,
        funds: fundList,
        isUniformCurrency,
        securities,
        valuation,
      });

      setWeightedShareValuesNames(
        getArrayValue(
          weightedShareValuesConfigurations?.map(weightedShareValues => getStringValue(weightedShareValues?.name))
        )
      );
      approachesConfigurations
        .flatMap(conf => Object.values(conf.spreadsheets as ConfiguredSpreadsheets))
        .forEach(sheet => sheet.refreshColumns());

      const updatedConfigurations = [
        valuationSummaryConfiguration,
        ...calibrationsConfigurations,
        equityAllocationConfiguration,
        ...approachesConfigurations,
        ...weightedShareValuesConfigurations,
      ];

      setConfigurations(updatedConfigurations);
    }
  }, [
    allocationAttributes,
    allocationVersion,
    approaches,
    approachesConfigurations,
    capTable,
    capTableVersions,
    cashTaxRate,
    companyExchangeRate,
    companyInfo,
    deletedApproachesIds,
    financials,
    firmInfo,
    fundList,
    isUniformCurrency,
    isValuationDisabled,
    measurementDate,
    riskFreeRates,
    securities,
    valuation,
    valuationApproachWeightAttributes,
    performanceMetricsCustomClasses,
    financialStatementsList,
    calibrationsConfigurations,
    otherFinancialStatements,
  ]);

  return {
    backsolveAttributes,
    configurations,
    enterpriseValues,
    guidelinePublicCompaniesAttributes,
    isLoadingAllocationScenariosValues,
    isLoadingBacksolvesEquityValues,
    isLoadingRiskFreeRates,
    isUniformCurrency,
    publicCompaniesAttributes,
    resetConfigurations,
    riskFreeRates,
    securities,
    setEnterpriseValues,
    spreadsheets: ValuationsSpreadsheets,
    updateAllocationScenariosValues,
    updateOPMInputs,
    updateSecurities,
    weightedShareValuesNames,
    weightingProbabilities,
    updateApproachesScenarioMethods,
    allocationMethodsOptions,
  };
};

export default useCreateValuation;
