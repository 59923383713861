import { BACKSOLVE_TABLE_NAMES } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { CAP_TABLE_SELECTION } from 'pages/Valuations/util/constants';
import {
  MATURITY_YEARS_CUSTOM_KEY,
  RISK_FREE_RATE_CUSTOM_KEY,
  VOLATILITY_CUSTOM_KEY,
} from 'pages/ValuationsAllocation/approaches/FutureExit/AllocationMethodTable/utils/constants';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { HandleBacksolveScenarioParams } from './types';

const handleBacksolveScenario = (params: HandleBacksolveScenarioParams) => {
  const {
    capTableSelectionCell,
    changes,
    enableCells,
    isApproachWithOPM,
    maturityCell,
    riskFreeRateCell,
    volatilityCell,
    relatedApproach,
  } = params;

  const backsolveOpmTableName = getApproachTableName({
    approach: relatedApproach,
    tableSuffix: BACKSOLVE_TABLE_NAMES.OPM_INPUTS,
  });
  const backsolveCapTableSelectionName = getApproachTableName({
    approach: relatedApproach,
    tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE,
  });

  if (capTableSelectionCell) {
    capTableSelectionCell.readOnly = true;
    changes?.push({ cell: capTableSelectionCell, value: `=${backsolveCapTableSelectionName}.${CAP_TABLE_SELECTION}` });
  }

  if (isApproachWithOPM) {
    // Read-only OPM Inputs
    enableCells?.({ cells: [maturityCell, riskFreeRateCell, volatilityCell], isReadOnly: true });

    if (maturityCell) {
      const change = changes?.find(change => change.cell === maturityCell);
      if (change) {
        change.value = `=${backsolveOpmTableName}.${MATURITY_YEARS_CUSTOM_KEY}`;
      } else {
        changes?.push({ cell: maturityCell, value: `=${backsolveOpmTableName}.${MATURITY_YEARS_CUSTOM_KEY}` });
      }
    }

    if (riskFreeRateCell) {
      changes?.push({ cell: riskFreeRateCell, value: `=${backsolveOpmTableName}.${RISK_FREE_RATE_CUSTOM_KEY}` });
    }

    if (volatilityCell) {
      changes?.push({ cell: volatilityCell, value: `=${backsolveOpmTableName}.${VOLATILITY_CUSTOM_KEY}` });
    }
  }
};

export default handleBacksolveScenario;
