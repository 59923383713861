import { CAP_TABLE_SELECTION } from 'pages/Valuations/util/constants';
import { HandleSpecifiedShareValuesScenarioParams } from './types';

const handleSpecifiedShareValuesScenario = (params: HandleSpecifiedShareValuesScenarioParams) => {
  const { capTableSelectionCell, changes, ssvTableName } = params;

  if (capTableSelectionCell) {
    capTableSelectionCell.readOnly = true;
    changes?.push({
      cell: capTableSelectionCell,
      value: `=${ssvTableName}.${CAP_TABLE_SELECTION}`,
    });
  }
};

export default handleSpecifiedShareValuesScenario;
