import { ValuationsApproach } from 'common/types/valuation';
import { ExtendedValuationApproachFutureExit } from '../customReverseParser/types';

export const getApproachFromColumn = (
  col: ExtendedValuationApproachFutureExit,
  approaches: Array<ValuationsApproach>
) => {
  if (col.valuation_approach_gpc) {
    return approaches.find(a => a.valuations_approach_gpc?.id === col.valuation_approach_gpc);
  }
  if (col.gpc_reference) {
    return approaches.find(a => {
      const id = a?.id?.toString() ?? a.panelId;
      return id === col.gpc_reference;
    });
  }
  if (col.valuation_approach_gpt) {
    return (
      approaches.find(a => a.valuations_approach_gpt?.id === col.valuation_approach_gpt)
      || approaches.find(a => {
        const id = a?.id?.toString() ?? a.panelId;
        return id === col.gpt_reference;
      })
    );
  }
  if (col.gpt_reference) {
    return approaches.find(a => {
      const id = a?.id?.toString() ?? a.panelId;
      return id === col.gpt_reference;
    });
  }
  return null;
};
