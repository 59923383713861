/* eslint-disable spaced-comment,no-multi-spaces */
import { largeCurrencyFormat } from 'common/formats/formats';
import {
  DISCOUNT_PERIODS_CONFIG,
  ESTIMATED_EXIT_VALUE_CONFIG,
  MULTIPLE_CONFIG,
  PV_OF_TERMINAL_VALUE_CONFIG_2,
  SELECT_APPROACH_CONFIG,
  SELECT_MULTIPLE_CONFIG,
  TERMINAL_VALUE_CONFIG,
  WACC_CONFIG,
} from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/rowConfigConstants';
import {
  APPROACH_SELECTION,
  DCF_FINANCIAL_PERIOD_TABLE_NAME,
  DCF_TVT_COMBINED_AVERAGE_EXIT_VALUE_ROW_NUMBER,
  DCF_TVT_COMBINED_DISCOUNT_PERIODS_ROW_NUMBER,
  DCF_TVT_COMBINED_MULTIPLE_ROW_NUMBER,
  DCF_TVT_COMBINED_TERMINAL_YEAR_ROW_NUMBER,
  DCF_TVT_COMBINED_WACC_ROW_NUMBER,
  EBITDA_GPC_TITLE,
  GPC_MULTIPLE,
  GPC_MULTIPLE_SELECTION,
  MAX_VALUATION_NUMBER_DIGITS,
  METRIC_TOTAL,
  VALUES_OPTIONS,
} from 'pages/Valuations/util/constants';

const TITLE_CONFIG = {
  readOnly: true,
  value: '',
  alias: EBITDA_GPC_TITLE,
  gridType: 'string',
};

const TERMINAL_YEAR_METRIC_CONFIG = {
  readOnly: true,
  value: 'Terminal Year Metric',
  gridType: 'number',
  format: largeCurrencyFormat,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: METRIC_TOTAL,
  origin: DCF_FINANCIAL_PERIOD_TABLE_NAME,
};

const AVERAGE_EXIT_VALUE_CONFIG = {
  readOnly: true,
  value: 'Average Exit Value',
  gridType: 'number',
  format: largeCurrencyFormat,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: 'estimated_exit_value',
  expr: '=MEAN([A7, B7])',
};

export default ({
  mainTableName: mainTableReference,
  dcfWaccSheet: dcfWacc,
  percentileSelections,
  benchmarkType,
  options = VALUES_OPTIONS,
  approachOptions,
  isDisabled,
}) => [
  { ...TERMINAL_VALUE_CONFIG(isDisabled), ignoreRowCopy: true }, //1
  TITLE_CONFIG, //2
  TERMINAL_YEAR_METRIC_CONFIG, //3
  SELECT_APPROACH_CONFIG(APPROACH_SELECTION, approachOptions, isDisabled), //4
  SELECT_MULTIPLE_CONFIG(GPC_MULTIPLE_SELECTION, dcfWacc, percentileSelections, isDisabled), //5
  MULTIPLE_CONFIG(GPC_MULTIPLE, options, benchmarkType, isDisabled), //6
  ESTIMATED_EXIT_VALUE_CONFIG(DCF_TVT_COMBINED_TERMINAL_YEAR_ROW_NUMBER, DCF_TVT_COMBINED_MULTIPLE_ROW_NUMBER), //7
  { ...AVERAGE_EXIT_VALUE_CONFIG, ignoreRowCopy: true, className: 'division-top-only' }, //8
  { ...WACC_CONFIG(dcfWacc), ignoreRowCopy: true }, //9
  {
    ...DISCOUNT_PERIODS_CONFIG(mainTableReference),
    ignoreRowCopy: true,
  }, //10
  {
    ...PV_OF_TERMINAL_VALUE_CONFIG_2(
      DCF_TVT_COMBINED_AVERAGE_EXIT_VALUE_ROW_NUMBER,
      DCF_TVT_COMBINED_WACC_ROW_NUMBER,
      DCF_TVT_COMBINED_DISCOUNT_PERIODS_ROW_NUMBER
    ),
    ignoreRowCopy: true,
  }, //11
];
