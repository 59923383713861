import { isEmpty } from 'lodash';
import rowTransformerCalibrationInputs from './createColumnsCalibrationInputs';
import customCalibrationAfterCellsChanged from './customCalibrationAfterCellsChanged';
import customValidations from './customValidations';
import parser from './parser';
import reverseParserCalibration from './reverseParserCalibration';
import rowConfigCalibrationInputs from './rowConfigCalibrationInputs';
import { Cells } from '../../../../../common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from '../../../../../components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { Change } from '../../../../ValuationsAllocation/approaches/ExternalValuation/config/customAfterCellsChanged/types';
import { ExternalValuationCellCustomData } from '../../../../ValuationsAllocation/approaches/ExternalValuation/config/customReverseParser/types';
import { CreateInputsCalibrationDataParams } from '../performance_metrics/types';

const createCalibrationInputsData = (params: CreateInputsCalibrationDataParams) => {
  const {
    calibration,
    financials,
    company,
    name,
    approaches,
    financialStatementsList,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    version,
    approach,
    comparisons,
    isDisabled,
  } = params;

  let readOnlyData = true;

  if (version === 0) {
    readOnlyData = false;
  }

  const rowConfig = rowConfigCalibrationInputs({
    isDisabled,
    financialStatementsList,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    readOnlyData,
  });

  const columns = rowTransformerCalibrationInputs(financials);

  const afterCellsChanged = (initialChanges: Array<Change>, cells: Cells<ExternalValuationCellCustomData>) =>
    customCalibrationAfterCellsChanged({
      initialChanges,
      cells,
    });

  const spreadsheet = !isEmpty(columns)
    ? new SpreadsheetConfig({
      columns,
      name,
      parser,
      rowConfig,
      tableData: {
        calibration,
        approaches,
        isDisabled,
        financials,
        columnsPerformanceCalibration,
        rowsPerformanceCalibration,
        companyName: company?.name,
        approach,
        gpcComparisonByDate: comparisons,
        financialStatementsList,
      },
      totalParser: undefined,
      unitsFormatter: true,
      currencyFormatter: true,
      format: undefined,
      afterCellChanged: afterCellsChanged as unknown as SpreadsheetConfig['afterCellChanged'],
      reverseParser: reverseParserCalibration as unknown as SpreadsheetConfig['reverseParser'],
      customValidations: customValidations as unknown as SpreadsheetConfig['customValidations'],
      showToolbar: true,
      allowConfirmAndDeleteColumn: false,
      allowCopyColumn: false,
      allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
      allowCopyRows: !isDisabled,
      allowAddSingleRow: !isDisabled,
      allowDeleteRow: !isDisabled,
      allowDeleteColumn: false,
    })
    : undefined;

  return { spreadsheet };
};

export default createCalibrationInputsData;
