import { EQUITY_VALUE_ALIAS } from 'common/constants/allocations';
import { SECURITY_ALIAS, SHARE_PRICE_ALIAS } from 'pages/Valuations/approaches/SpecifiedShareValues/constants';
import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';
import { parseDatabaseValue } from 'utillities';
import normalizeShareValues from './normalizeShareValues';

const reverseParse = (cell, cells, fieldAttributes, allowEmptyValues) => {
  const { key, alias, id } = cell;

  // Early return to handle the security cell (no further processing is needed)
  if (alias === SECURITY_ALIAS) {
    return id;
  }

  const defaultValue = cell.defaultValue || null;
  const type = cell.dbType || null;
  const format = cell.format || null;
  const value = cells[key] ? cells[key].value : defaultValue;
  const { gridType } = cell;
  const decimalPlaces = fieldAttributes[cell.alias]?.decimal_places || cell?.dbDecimalPlaces;

  const calcValue = parseDatabaseValue({
    type,
    value,
    defaultValue,
    format,
    allowEmptyValues,
    gridType,
    decimalPlaces,
  });

  if (alias === SHARE_PRICE_ALIAS) {
    return Number(calcValue);
  }

  return calcValue;
};

const reverseParser = ({ cells, tableData, initialObj, fieldAttributes, allowEmptyValues }) => {
  const alphabet = ['A', 'B', 'C', 'D'];
  const tmpApproach = initialObj.valuations_approach_ssv;
  tableData.securities.forEach((security, index) => {
    alphabet.forEach(columnLegend => {
      const cell = cells[`${columnLegend}${index + 2}`];
      // eslint-disable-next-line no-param-reassign
      security[cell.alias] = reverseParse(cell, cells, fieldAttributes, allowEmptyValues);
    });
  });

  const cellsArray = Object.values(cells);
  tmpApproach.share_values = normalizeShareValues({
    share_values: tableData.securities,
  });
  tmpApproach.cap_table = tableData.capTable.id;
  tmpApproach.enterprise_value = cellsArray.find(
    c => c.customKey === VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY
  ).value;
  tmpApproach.equity_value = cellsArray.find(c => c.alias === EQUITY_VALUE_ALIAS).value;

  return {
    ...initialObj,
    enterprise_value: tmpApproach.enterprise_value,
    equity_value: tmpApproach.equity_value,
    valuations_approach_ssv: tmpApproach,
  };
};

export default reverseParser;
