import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { isEmpty, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { ALLOCATIONS_DRAFT, STATUSES_LIST } from 'common/constants/allocations';
import { FIX_BEFORE_CONTINUE, NEW_MEASUREMENT_DATE } from 'common/constants/messages/validations';
import { useStore } from 'common/store';
import { Dialog } from 'components/Dialogs';
import { MDContext } from 'context/MDContext';
import { useGetCompaniesFromPreviousMD, useGetCompanyListByFirmId } from 'services/hooks/firm';
import { useRolloverCompanies } from 'services/hooks/useRolloverCompanies/useRolloverCompanies';
import { dbShortDate, objToArray } from 'utillities';
import CompaniesSelector from './components/CompaniesSelector';
import CompaniesTable from './components/CompaniesTable';
import RolloverConfirmation from './components/RolloverConfirmation';
import RolloverStatus from './components/RolloverStatus/RolloverStatus';
import { companiesAction } from '../../common/actions';

const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  dangerBtn: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  paperSm: {
    maxWidth: '700px !important',
  },
}));

const STEPS = [0, 1, 2, 3];
const [FIRST, SECOND, THIRD, FOURTH] = STEPS;

const MeasurementDateDialog = props => {
  const { open, onClose, activeFund, activeCompany, getMeasurementDates } = props;
  const classes = useStyles();
  const [measurementDate, setMeasurementDate] = useState();
  const [items, setItems] = useState([]);
  const [selectedFunds, setSelectedFunds] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [activeStep, setActiveStep] = useState(FIRST);
  const [rollover, setRollover] = useState(false);
  const [shouldResetFinancials, setShouldResetFinancials] = useState(false);
  const [shouldExcludeDocuments, setShouldExcludeDocuments] = useState(false);
  const [errors, setErrors] = useState({});
  const [{ firmInfo, fundList }, dispatch] = useStore();
  const subItems = useMemo(() => items.flatMap(item => item.subItems), [items]);

  const { companies, getCompanies, isLoading: loadingCompanies } = useGetCompaniesFromPreviousMD();

  const { rolloverCompanies, isLoading: processingRollover, statusList, response } = useRolloverCompanies(subItems);

  const { getCompanies: updateCompanies } = useGetCompanyListByFirmId();

  useEffect(() => {
    if (activeFund) {
      setSelectedFunds({ [activeFund.id]: true });
    }
  }, [activeFund]);

  useEffect(() => {
    if (activeCompany) {
      setSelectedCompanies({ [activeCompany.id]: true });
    }
  }, [activeCompany]);

  // funds are only sent to the back end if they have all their companies selected or
  // they don't have any company selected but the fund itself is selected
  const getSelectedFunds = useCallback(
    () =>
      items.reduce((funds, fund) => {
        let isSelected;
        if (fund.id) {
          if (fund.subItems.length === 0 || fund.subItems.every(company => !selectedCompanies?.[company.id])) {
            // selectedFunds doesn't exist until a fund has been clicked on
            isSelected = selectedFunds?.[fund.id];
          } else {
            isSelected = fund.subItems.every(
              company => selectedCompanies?.[company.id] && fund.id && !funds.includes(fund.id)
            );
          }
          if (isSelected) {
            funds.push(fund.id);
          }
        }
        return funds;
      }, []),
    [items, selectedCompanies, selectedFunds]
  );

  const submit = useMemo(
    () =>
      selectedCompanies
      && companies
      && objToArray(selectedCompanies)
        .filter(([, selected]) => selected)
        .map(([companyId]) => companies.find(company => company.company_id.toString() === companyId))
        .every(company => isNull(company.al_id)),
    [selectedCompanies, companies]
  );

  const disableActionButtons = useMemo(
    () => isUndefined(companies) || (isEmpty(selectedFunds) && isEmpty(selectedCompanies)) || processingRollover,
    [companies, selectedFunds, selectedCompanies, processingRollover]
  );

  const isStep = useCallback(step => activeStep === step, [activeStep]);

  const handleBack = useCallback(() => {
    setActiveStep(lastStep => lastStep - 1);
  }, []);

  const handleCreate = useCallback(() => {
    setActiveStep(FOURTH);
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep(lastStep => lastStep + 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    const allocationsToRollover = [];
    const companiesToRollover = [];
    const postData = {
      new_measurement_date: dbShortDate(measurementDate),
      fund_ids: getSelectedFunds(),
      firm_id: firmInfo.id,
      reset_financials: shouldResetFinancials,
      exclude_documents: shouldExcludeDocuments,
    };

    const getStatus = status => {
      const statuses = STATUSES_LIST.map(s => s.toUpperCase());
      return Number(status) === ALLOCATIONS_DRAFT ? 'DRAFT' : statuses[status];
    };

    objToArray(selectedCompanies).forEach(([companyId, company]) => {
      if (company.allocationId && !isUndefined(company.allocationStatus)) {
        allocationsToRollover.push({
          allocation_status: getStatus(company.allocationStatus),
          allocation_id: company.allocationId,
          primary_ct_only: rollover,
        });
      } else if (company) {
        companiesToRollover.push(companyId);
      }
    });

    postData.allocation_rollovers = allocationsToRollover;

    postData.primary_ct_only_company_ids = companiesToRollover;
    rolloverCompanies(postData);
  }, [
    shouldExcludeDocuments,
    shouldResetFinancials,
    firmInfo.id,
    getSelectedFunds,
    measurementDate,
    rollover,
    rolloverCompanies,
    selectedCompanies,
  ]);

  const onCloseLedgerUpdate = useCallback(async () => {
    // on close first so that updateCompanies doesn't cause page to flicker
    onClose();
    const updatedCompanies = await updateCompanies(firmInfo.id);
    if (activeCompany) {
      const updatedCompany = updatedCompanies.find(company => company.company_id === activeCompany.id);
      if (updatedCompany) {
        dispatch(companiesAction.setCompanyInfo({ ...updatedCompany }));
      }
    }
    if (getMeasurementDates) {
      // A success response return the measurement date
      await getMeasurementDates(response);
    }
  }, [firmInfo.id, getMeasurementDates, onClose, updateCompanies, activeCompany, dispatch, response]);

  useEffect(() => {
    if (isStep(FOURTH)) {
      handleSubmit();
    }
  }, [isStep, handleSubmit]);

  const getContent = () => {
    const steps = [
      <CompaniesSelector key={FIRST} activeCompany={activeCompany} />,
      <CompaniesTable key={SECOND} />,
      <RolloverConfirmation key={THIRD} />,
      <RolloverStatus key={FOURTH} />,
    ];

    return steps[activeStep];
  };

  useEffect(() => {
    if (firmInfo?.id && measurementDate) {
      getCompanies(firmInfo.id, dbShortDate(measurementDate));
    }
  }, [firmInfo, getCompanies, measurementDate]);

  useEffect(() => {
    if (companies) {
      let tmpItems = [];

      const companiesWithoutFund = { id: null, name: 'Companies without Fund' };

      if (!isEmpty(companies) && !isEmpty(fundList)) {
        const funds = [companiesWithoutFund, ...fundList];

        tmpItems = funds.reduce((filteredFunds, fund) => {
          const subItems = companies.reduce((filteredCompanies, item) => {
            if (item) {
              if (fund.id === item.fund_id) {
                filteredCompanies.push({
                  id: item.company_id,
                  allocationId: item.al_id,
                  name: item.company_name,
                  isPublished: item.is_published,
                  isFinal: item.is_final,
                });
              }
            }

            return filteredCompanies;
          }, []);

          filteredFunds.push({
            id: fund.id,
            name: fund.name,
            subItems,
          });

          return filteredFunds;
        }, []);
      }

      if (isEmpty(companies) && !isEmpty(activeCompany)) {
        // If the list is empty but the dialog was open from a company

        tmpItems = [
          ...tmpItems,
          {
            ...companiesWithoutFund,
            subItems: [
              {
                id: activeCompany.id,
                allocationId: null,
                name: activeCompany.name,
              },
            ],
          },
        ];
      }

      setItems(tmpItems);
    }
  }, [companies, fundList, activeFund, activeCompany]);

  const requestStatus = useMemo(
    () => [...statusList.funds, ...statusList.allocations, ...statusList.capTablesOnly].map(({ status }) => status),
    [statusList]
  );

  const progress = useMemo(() => {
    const completedCount = requestStatus.filter(status => status === 'success' || status === 'error').length;
    const totalCount = requestStatus.length;
    if (totalCount === 0) {
      return 0;
    }
    return Math.round((completedCount / totalCount) * 100);
  }, [requestStatus]);

  const mdContextValue = useMemo(
    () => ({
      errors,
      items,
      subItems,
      measurementDate,
      selectedFunds,
      selectedCompanies,
      rollover,
      setMeasurementDate,
      setSelectedFunds,
      setSelectedCompanies,
      setRollover,
      setShouldResetFinancials,
      shouldResetFinancials,
      shouldExcludeDocuments,
      setShouldExcludeDocuments,
      statusList,
      isLoading: loadingCompanies || processingRollover,
    }),
    [
      subItems,
      statusList,
      errors,
      items,
      loadingCompanies,
      measurementDate,
      processingRollover,
      rollover,
      selectedCompanies,
      selectedFunds,
      setShouldResetFinancials,
      shouldExcludeDocuments,
      setShouldExcludeDocuments,
      shouldResetFinancials,
    ]
  );

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      title={NEW_MEASUREMENT_DATE}
      onClose={onClose}
      loadingIncrement={progress}
      classes={{
        paperWidthSm: classes.paperSm,
      }}
      isLoading={loadingCompanies || processingRollover}
      fullWidth
      dialogProps={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        disableTitleClose: false,
      }}>
      <MDContext.Provider value={mdContextValue}>
        {!isEmpty(errors) && (
          <Collapse in={!isEmpty(errors)}>
            <Alert
              variant="outlined"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrors({});
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {FIX_BEFORE_CONTINUE}
            </Alert>
          </Collapse>
        )}
        {getContent()}
        <Grid container justifyContent="flex-end" spacing={2} classes={{ root: classes.actionButtons }}>
          {isStep(FIRST) && (
            <Grid item>
              <Button
                id={submit ? 'create-md-btn' : 'next-md-btn'}
                variant="contained"
                color="secondary"
                onClick={() => (submit ? handleCreate() : handleNext())}
                disabled={disableActionButtons}>
                {submit ? 'Create' : 'Next'}
              </Button>
            </Grid>
          )}
          {(isStep(SECOND) || isStep(THIRD)) && (
            <Grid item>
              <Button color="primary" onClick={handleBack}>
                Back
              </Button>
            </Grid>
          )}
          {isStep(SECOND) && (
            <Grid item>
              <Button id="next-md-btn" variant="contained" color="secondary" onClick={handleNext}>
                Next
              </Button>
            </Grid>
          )}
          {isStep(THIRD) && (
            <Grid item>
              <Button
                id="rollover-ok-btn"
                color="secondary"
                onClick={handleNext}
                variant="contained"
                disabled={processingRollover || isUndefined(rollover)}>
                Create
              </Button>
            </Grid>
          )}
          {isStep(FOURTH) && (
            <Grid item>
              <Button
                id="rollover-ok-btn"
                color="secondary"
                onClick={onCloseLedgerUpdate}
                disabled={processingRollover}
                variant="contained">
                Ok
              </Button>
            </Grid>
          )}
        </Grid>
      </MDContext.Provider>
    </Dialog>
  );
};

MeasurementDateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activeFund: PropTypes.object,
  activeCompany: PropTypes.object,
  getMeasurementDates: PropTypes.func,
};

export default MeasurementDateDialog;
