/* eslint-disable no-param-reassign */
import React from 'react';
import { AllocationScenarioValuationModel } from 'api';
import { BACKSOLVE, FUTURE_EXIT, OPM, SSV } from 'common/constants/allocations';
import { largeCurrencyFormat, smallCurrencyFormat, weightingPercentFormat } from 'common/formats/formats';
import { Cell, Cells, Row } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import { underScoreSpaces } from 'pages/Allocation/allocations/utilities/util';
import { BACKSOLVE_TABLE_NAMES } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { SSV_TABLE_NAMES } from 'pages/Valuations/approaches/SpecifiedShareValues/constants';
import { BACKSOLVE_APPROACH, SPECIFIED_SHARE_VALUES } from 'pages/Valuations/util/constants';
import {
  EquityAllocationCellCustomData,
  EquityAllocationColumn,
} from 'pages/ValuationsAllocation/allocation/EquityAllocation/config';
import {
  getCollapsableColumns,
  handleHeaderTitleColumn,
} from 'pages/ValuationsAllocation/approaches/ValuationSummary/config/customParser/utils';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import {
  VALUATION_SUMMARY_KEY,
  SHEET_ALIASES_CONSTANTS as VALUATION_SUMMARY_SHEET_ALIASES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { AllocationMethodSelector } from 'pages/ValuationsAllocation/components';
import ApproachScenarioSelectValueViewer from 'pages/ValuationsAllocation/components/ApproachScenarioSelectValueViewer/ApproachScenarioSelectValueViewer';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { generateColumnKey, generateTotalExpression, getExpression, getNumberValue, getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { CellParserParams, EquityAllocationParserParams } from './types';
import {
  handleCapTableSelection,
  handleFutureEquityValue,
  handleMaturity,
  handleRiskFreeRate,
  handleVolatility,
} from './utils';
import { SecurityNameSharesMap } from '../types';

const {
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  EQUITY_ALLOCATION_SPREADSHEET_TOTAL,
  EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const { getAdjustedColumnNumber, EQUITY_ALLOCATION_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;
const { ENTERPRISE_VALUE, EQUITY_VALUE } = EQUITY_ALLOCATION_SPREADSHEET_HEADERS;

const VALUE_ALLOCATED_TO_SECURITY_CLASS_ROW_NUMBER = 11;

function handleApproach(
  usedApproach: ValuationsApproach | undefined,
  scenarioType: number | undefined,
  scenarioMethod: AllocationScenarioValuationModel.scenario_method | null | undefined
) {
  let relatedApproachOPMTableName = '';
  let relatedApproachCaptableSelectionName = '';

  if (usedApproach) {
    if (scenarioType === BACKSOLVE) {
      relatedApproachOPMTableName = getApproachTableName({
        approach: usedApproach,
        tableSuffix: BACKSOLVE_TABLE_NAMES.OPM_INPUTS,
      });
      relatedApproachCaptableSelectionName = getApproachTableName({
        approach: usedApproach,
        tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE,
      });
    }
    if (scenarioType === FUTURE_EXIT) {
      if (scenarioMethod === OPM) {
        relatedApproachOPMTableName = getApproachTableName({
          approach: usedApproach,
          tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_OPM,
        });
        relatedApproachCaptableSelectionName = relatedApproachOPMTableName;
      } else {
        relatedApproachCaptableSelectionName = getApproachTableName({
          approach: usedApproach,
          tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.ALLOCATION_METHOD_VALUE_WATERFALL_CSE,
        });
      }
    }
    if (scenarioType === SSV) {
      relatedApproachCaptableSelectionName = getApproachTableName({
        approach: usedApproach,
        tableSuffix: SSV_TABLE_NAMES.CAP_TABLE_SELECTION,
      });
    }
  }
  return { relatedApproachOPMTableName, relatedApproachCaptableSelectionName };
}

function handleShareValue(
  isPresentValuePerShare: boolean | undefined,
  isValueAllocatedToSecurityClass: boolean | undefined,
  cell: Row<EquityAllocationCellCustomData>,
  column: EquityAllocationColumn,
  row: Row<EquityAllocationCellCustomData>,
  columnLegend: string,
  usedApproach: ValuationsApproach | undefined,
  securityNameSharesMap: SecurityNameSharesMap
) {
  if (isPresentValuePerShare || isValueAllocatedToSecurityClass) {
    cell.format = largeCurrencyFormat;
    cell.gridType = 'number';
    cell.value = getNumberValue(cell.value as number);
    cell.data = {
      ...cell.data,
      security: {
        id: column[row.alias]?.securityId,
        name: column[row.alias]?.securityName,
      },
    };

    if (isPresentValuePerShare) {
      cell.customKey = `${underScoreSpaces(row.value)}_${columnLegend}`;
      cell.format = smallCurrencyFormat;
      if (usedApproach && usedApproach.approach_type === BACKSOLVE_APPROACH && column[row.alias]) {
        const tableName = getApproachTableName({
          approach: usedApproach,
          tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE,
        });
        const expr = `=${tableName}.TOTALS_${column[row.alias].securityId}`;
        cell.expr = expr;
      } else if (usedApproach && usedApproach.approach_type === SPECIFIED_SHARE_VALUES && column[row.alias]) {
        const tableName = getApproachTableName({
          approach: usedApproach,
        });
        const expr = `=${tableName}.${underScoreSpaces(column[row.alias].securityName)}`;
        cell.expr = expr;
      }
    } else {
      cell.expr = `=${underScoreSpaces(row.value)}_${columnLegend} * ${securityNameSharesMap[row.value as string]}`;
      cell.customKey = `${underScoreSpaces(row.value)}_${columnLegend}_aggregate`;
    }
  }
}

const cellParser = (params: CellParserParams) => {
  const {
    allocationScenariosQuantity,
    allSecuritiesExpression,
    alphabet,
    securityNameSharesMap,
    colIndex,
    column,
    isUniformCurrency,
    row,
    rowIndex,
  } = params;

  const colNumber = colIndex + 1;
  const rowNumber = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + rowNumber;

  const {
    backsolveDate,
    isApproachWithOPM,
    scenarioId,
    scenarioMethod,
    scenarioRef,
    scenarioType,
    value: columnValue,
  } = getObjectValue(column[row.alias]);
  const usedApproach = params.approaches.find(
    a => a.panelId === column.EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD?.value?.toString()?.split('__')?.[1]
  );

  const { isPresentValuePerShare, isValueAllocatedToSecurityClass } = getObjectValue(row.data);

  const { relatedApproachOPMTableName, relatedApproachCaptableSelectionName } = handleApproach(
    usedApproach,
    scenarioType,
    scenarioMethod
  );

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  const adjustedColumnNumber = getAdjustedColumnNumber({ colNumber, isUniformCurrency });

  const scenarioEquityValueCustomKey = generateColumnKey({
    id: scenarioId,
    name: VALUATION_SUMMARY_SHEET_ALIASES_CONSTANTS.VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
    prefix: columnLegend,
  });

  // Total Value Allocated to Security Class
  const totalValueAllocatedToSecurityClassExpression = getExpression({
    columnLegend,
    expr: `SUM(${allSecuritiesExpression})`,
  });

  // Parse Cell based on Row alias
  switch (row.alias) {
    case EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE:
      handleHeaderTitleColumn({ allocationScenariosQuantity, cell, colNumber, isUniformCurrency });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD:
      cell.dataEditor = props => <AllocationMethodSelector {...props} />;
      cell.forceComponent = true;
      cell.gridType = 'string';
      cell.isRequired = true;
      cell.valueViewer = props => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: SelectValueViewer is not typed
        <ApproachScenarioSelectValueViewer {...props} />
      );
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION:
      handleCapTableSelection({ cell, scenarioType, relatedApproachCaptableSelectionName });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS:
    case EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS:
    case EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE:
      cell.hidden = true;
      cell.isRequired = false;
      cell.value = '';
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_MATURITY:
      handleMaturity({ cell, isApproachWithOPM, scenarioMethod, scenarioType, relatedApproachOPMTableName });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE:
      handleRiskFreeRate({ cell, isApproachWithOPM, scenarioMethod, scenarioType, relatedApproachOPMTableName });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY:
      handleVolatility({ cell, isApproachWithOPM, scenarioMethod, scenarioType, relatedApproachOPMTableName });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE:
      handleFutureEquityValue({ cell, scenarioType });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE:
      cell.expr = `=${VALUATION_SUMMARY_KEY}.${scenarioEquityValueCustomKey}`;
      cell.format = largeCurrencyFormat;
      cell.gridType = 'number';
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY:
      cell.dbDecimalPlaces = 1;
      cell.dbType = 'number';
      cell.defaultZero = true;
      cell.format = weightingPercentFormat;
      cell.gridType = 'percentage';
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_TOTAL:
      cell.expr = `=${totalValueAllocatedToSecurityClassExpression}`;
      cell.format = largeCurrencyFormat;
      cell.gridType = 'number';
      break;

    // Rest of the Rows
    default:
      handleShareValue(
        isPresentValuePerShare,
        isValueAllocatedToSecurityClass,
        cell,
        column,
        row,
        columnLegend,
        usedApproach,
        securityNameSharesMap
      );
      break;
  }

  // Custom Data for Equity Allocation
  cell.data = {
    ...getObjectValue(cell.data),
    backsolveDate,
    isApproachWithOPM,
    scenarioId,
    scenarioRef,
  } as EquityAllocationCellCustomData;

  // Handle Approaches Cells
  if ([ENTERPRISE_VALUE.columnNumber, EQUITY_VALUE.columnNumber].includes(adjustedColumnNumber)) {
    cell.hidden = true;
    cell.isRequired = false;
    cell.readOnly = true;
    cell.value = '';
  }

  // Handle Collapsable Columns
  const { columnId, isParent, parentColumn } = getCollapsableColumns({ colNumber, isUniformCurrency });

  // Set default Cell values
  const {
    allowNegativeValue = true,
    className = '',
    colSpan = 1,
    component = null,
    customKey = null,
    data = {},
    dbDecimalPlaces = 0,
    dbType = 'string',
    defaultZero = false,
    expr = '',
    forceComponent = false,
    format = null,
    gridType = 'string',
    hidden = false,
    readOnly = false,
    value = '',
  } = getObjectValue(cell);

  return {
    [cell.key]: {
      ...cell,
      allowNegativeValue,
      className,
      colSpan,
      columnId,
      columnLegend,
      component,
      customKey,
      data,
      dbDecimalPlaces,
      dbType,
      defaultZero,
      expr: getExpression({ expr, columnLegend }),
      forceComponent,
      format,
      gridType,
      hidden,
      isParent,
      parentColumn,
      readOnly,
      value,
    } as Cell,
  };
};

const customParser = (params: EquityAllocationParserParams) => {
  const { allocationScenarios, columns, isUniformCurrency, rowConfig, securities, tableData } = params;

  const { approaches } = tableData;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];
  const columnsLength = columns.length;

  const allocationScenariosQuantity = allocationScenarios.length;

  const allSecuritiesExpression = generateTotalExpression({
    items: securities,
    skippedIndexes: VALUE_ALLOCATED_TO_SECURITY_CLASS_ROW_NUMBER + 1,
  });

  const securityNameSharesMap = securities.reduce((acc, security) => {
    if (security.name) {
      acc[security.name] = Number(security.shares ?? 0);
    }
    return acc;
  }, {} as SecurityNameSharesMap);

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allocationScenariosQuantity,
          allSecuritiesExpression,
          securityNameSharesMap,
          alphabet,
          colIndex,
          approaches,
          column,
          columnsLength,
          isUniformCurrency,
          row,
          rowIndex,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
