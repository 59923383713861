/* eslint-disable import/no-unresolved */
import { sortBy } from 'lodash';
import { DCFFinancialPeriod } from 'api';
import { DCFPeriodAttributes } from 'pages/ValuationsAllocation/types';
import { parseDatabaseValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { ReverseParserParams } from './types';
// period columns are reverse parsed here
const CURRENCY_DECIMAL_PLACES = 2;

const reverseParser = (params: ReverseParserParams) => {
  const { cells, columns, rowConfig, allowEmptyValues = true, tableData, sheet, fieldAttributes } = params;

  const tableColumns: DCFFinancialPeriod[] = [];
  const { dcfPeriodAttrs } = fieldAttributes;
  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // copy original values
    let tmpColumn = { ...column };

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      const key = columnLegend + (cellIndex + 1);
      const defaultValue = cell.defaultValue ?? null;
      const decimalPlaces = dcfPeriodAttrs
        ? dcfPeriodAttrs[cell.alias as keyof DCFPeriodAttributes]?.decimal_places ?? CURRENCY_DECIMAL_PLACES
        : CURRENCY_DECIMAL_PLACES;
      const value = parseDatabaseValue({
        type: cell?.dbType,
        value: cells[key] ? cells[key].value : defaultValue,
        format: cell?.format,
        allowEmptyValues,
        dontMultiply: true,
        gridType: cell.gridType,
        decimalPlaces: decimalPlaces as number,
      });

      if (cell.alias && cells[key]) {
        tmpColumn = {
          ...tmpColumn,
          [cell.alias]: value,
        };
      }
      if (cell.alias && cell.rowNumberPair) {
        const tempCell = cells[key];
        const { alias, isCellCalculated } = tempCell;
        const cellEnabledAlias = `${alias}_enabled`;

        tmpColumn = {
          ...tmpColumn,
          [cellEnabledAlias]: !isCellCalculated,
        };
      }
    });

    tableColumns.push(tmpColumn);
  });
  // eslint-disable-next-line no-param-reassign
  sheet.columns = tableColumns;
  if (tableData?.approach?.valuations_approach_dcf?.dcf_financial_period) {
    // eslint-disable-next-line no-param-reassign
    tableData.approach.valuations_approach_dcf.dcf_financial_period = tableColumns;
  }
};

export default reverseParser;
