import { VALUATIONS_PERCENTILE_PATTERN as patterns } from 'common/constants/valuations';
import { PUBLIC_COMPANIES, PUBLIC_TRANSACTIONS } from 'pages/Valuations/util/constants';
import { matchPattern } from 'pages/ValuationsAllocation/util';

export const getPrimaryGPCIndex = approaches =>
  approaches.findIndex(({ approach_type }) => approach_type === PUBLIC_COMPANIES);

export const getWaccDataApproach = (approach, approachName) => {
  if (!(approachName in approach)) return null;

  const { wacc_cost_of_equity, wacc_cost_of_debt, wacc_invested_capital_debt, wacc_invested_capital_equity, wacc }
    = approach[approachName];

  return {
    wacc_cost_of_equity,
    wacc_cost_of_debt,
    wacc_invested_capital_debt,
    wacc_invested_capital_equity,
    wacc,
  };
};

export const getNolDataApproach = (approach, approachName) => {
  if (!(approachName in approach)) return null;

  const { nol_ebit_pre_tax_cut, nol_ebit_post_tax_cut, nol_present_value_savings } = approach[approachName];

  return {
    nol_ebit_pre_tax_cut,
    nol_ebit_post_tax_cut,
    nol_present_value_savings,
  };
};

export const getThresholdDate = companyMeasurementDate => {
  if (companyMeasurementDate?.company) {
    const [mdYear, mdMonth, mdDay] = companyMeasurementDate.measurement_date.date.split('-');
    const { fiscal_year_end } = companyMeasurementDate.company;
    const auxYear = mdYear - 1;

    const auxDate = `${auxYear}-${mdMonth}-${mdDay}`;
    return fiscal_year_end.end_month === Number(mdMonth) && fiscal_year_end.end_day === Number(mdDay)
      ? companyMeasurementDate.measurement_date.date
      : auxDate;
  }
};

export const trimUpdateFields = ({ comparison, fieldAttributes, fieldList }) =>
  fieldList.reduce((updated, field) => {
    const decimalPlaces = fieldAttributes[field]?.decimal_places || 2;
    // eslint-disable-next-line no-param-reassign
    updated[field] = parseFloat(parseFloat(comparison[field]).toFixed(decimalPlaces));
    return updated;
  }, {});

export const getFixedValue = (value, decimalPlaces) => {
  try {
    const valueType = typeof value;
    return valueType === 'string' ? Number(value).toFixed(decimalPlaces) : value.toFixed(decimalPlaces);
  } catch (e) {
    return value;
  }
};
// expressions generators for row configs
const emptyRowRange = '[]';

export const getMedianExpression = (rowRange, startWithEqual = true) =>
  startWithEqual ? `=MEDIAN(FILTER_ENABLED(${rowRange}))` : `MEDIAN(FILTER_ENABLED(${rowRange}))`;
export const getMeanExpression = (rowRange, startWithEqual = true) =>
  startWithEqual ? `=MEAN(FILTER_ENABLED(${rowRange}))` : `MEAN(FILTER_ENABLED(${rowRange}))`;

export const get75thPercentileExpression = (rowRange, startWithEqual = true) =>
  startWithEqual ? `=PERCENTILE(FILTER_ENABLED(${rowRange}), 75)` : `PERCENTILE(FILTER_ENABLED(${rowRange}), 75)`;
export const get25thPercentileExpression = (rowRange, startWithEqual = true) =>
  startWithEqual ? `=PERCENTILE(FILTER_ENABLED(${rowRange}), 25)` : `PERCENTILE(FILTER_ENABLED(${rowRange}), 25)`;

export const getPercentileExpression = (rowRange, percentile, startWithEqual = true) =>
  startWithEqual
    ? `=PERCENTILE(FILTER_ENABLED(${rowRange}), ${percentile})`
    : `PERCENTILE(FILTER_ENABLED(${rowRange}), ${percentile})`;
export const getAppliedMultipleExpression = ({ offset, comparisonsLength, variableExpressions }) =>
  `=CHOOSE(@${offset + comparisonsLength}, ${variableExpressions})`;

export const getCustomPercentileExpression = (rowRange, percentile, startWithEqual = true) => {
  if (!percentile) return '';
  const percentileValue = percentile.replace(/\D/g, '');
  return startWithEqual
    ? `=PERCENTILE(FILTER_ENABLED(${rowRange}), ${percentileValue})`
    : `PERCENTILE(FILTER_ENABLED(${rowRange}), ${percentileValue})`;
};

export const getVariableExpressions = ({
  companiesRange,
  currentSelectionOptions,
  numberOfCompanies,
  replaceSymbol = '@',
  forDCF = false,
  benchmarkType = '',
}) => {
  if (companiesRange === emptyRowRange) {
    return [0, 0, 0, 0];
  }
  const optionsLength = currentSelectionOptions.length;
  // when obtaining expressions for DCF we need to consider an extra option
  const endOfSlice = forDCF ? optionsLength - 2 : optionsLength - 1;
  const percentileOptions = currentSelectionOptions.slice(2, endOfSlice);
  const medianExpr = getMedianExpression(companiesRange, false);
  const meanExpr = getMeanExpression(companiesRange, false);
  const percentilesExpressions = percentileOptions.map(option => {
    const matchedPattern = matchPattern({
      label: option,
      patterns,
    });
    const percentile = option.split(matchedPattern)[0];
    return getPercentileExpression(companiesRange, percentile, false);
  });
  const specifiedCellOffset = forDCF && benchmarkType === PUBLIC_TRANSACTIONS ? 10 : 9;
  const specifiedCellExpression = `${replaceSymbol}${numberOfCompanies + specifiedCellOffset}`;
  return [medianExpr, meanExpr, ...percentilesExpressions, specifiedCellExpression];
};

export const extractGPCApproaches = approaches =>
  approaches
    .filter(({ approach_type }) => approach_type === PUBLIC_COMPANIES)
    .map(({ panelId, valuations_approach_gpc, name }) => ({
      value: valuations_approach_gpc.id || panelId,
      label: name,
    }));
