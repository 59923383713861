import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { CompanyDescription } from 'pages/Valuations/components/CompanyDescription';
import { getTransactionInfoTableRows } from 'pages/ValuationsAllocation/util/getTransactionInfoTableRows';
import { ITheme } from 'theme/types';
import { useStyles } from './styles';
import { TransactionInfoDialogProps } from './types';

const TransactionInfoDialog: FC<TransactionInfoDialogProps> = props => {
  const classes = useStyles();
  const theme: ITheme = useTheme();
  const { openDialog, setOpenDialog, data: transactionData, financialsCurrency } = props;
  const [hideDealInfo, setHideDealInfo] = useState(true);

  const toggleDealInfo = () => {
    setHideDealInfo(!hideDealInfo);
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const { targetName, acquirerInfo, dealResolution, dealComments, bottomRows, businessDescription }
    = getTransactionInfoTableRows({
      transactionData,
      financialsCurrency,
    });

  return (
    <Dialog
      aria-labelledby="transaction-info"
      fullWidth
      maxWidth="sm"
      data-testid="companyNameDialog"
      open={openDialog}
      onClose={onClose}>
      <DialogTitle id="transaction-info-title">
        <Typography variant="h2">{targetName}</Typography>
        <Typography variant="body2" style={{ color: theme.palette.gray[400] }}>
          Acquirer Name: <strong>{acquirerInfo}</strong>
        </Typography>
      </DialogTitle>
      <DialogContent data-testid="company-info-content" dividers>
        <div>
          <Typography variant="h4">BUSINESS DESCRIPTION</Typography>
          <CompanyDescription
            description={businessDescription}
            truncate={hideDealInfo}
            toggleDescription={toggleDealInfo}
            maxCharsDisplay={300}
            isCollapsible
          />
        </div>
        {!hideDealInfo && (
          <>
            <div>
              <Typography variant="h4">DEAL RESOLUTION</Typography>
              <CompanyDescription
                description={dealResolution}
                truncate={hideDealInfo}
                toggleDescription={toggleDealInfo}
                maxCharsDisplay={300}
              />
            </div>
            <div className={classes.dealCommentsSection}>
              <Typography variant="h4">DEAL COMMENTS</Typography>
              <CompanyDescription
                description={dealComments}
                truncate={hideDealInfo}
                toggleDescription={toggleDealInfo}
                maxCharsDisplay={300}
              />
            </div>
          </>
        )}
        <Table aria-label="Guideline Transaction Company Table" size="small">
          <TableBody>
            {bottomRows.map(({ label, value }) => (
              <TableRow key={`${label}-${value}`}>
                <TableCell align="left" className={classes.tableCellValue}>
                  <strong data-testid={`transaction-data-row-value-${label}`}>{value}</strong>
                </TableCell>
                <TableCell align="left" colSpan={9}>
                  {label}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions id="transaction-info-actions">
        <Button
          id={`${transactionData.target_name}-cancel-btn`}
          variant="contained"
          onClick={onClose}
          className="cancel-btn">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionInfoDialog;
