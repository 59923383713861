/* eslint-disable no-param-reassign */
import moment from 'moment';
import { CustomDCFFinancialPeriod } from './types';

const updateYear = (dcfFinancialPeriod: CustomDCFFinancialPeriod) => {
  if (!dcfFinancialPeriod.year) {
    const { financial_statement_period } = dcfFinancialPeriod;
    let date;
    if (financial_statement_period) {
      date = moment(financial_statement_period.statement_date);
    } else {
      date = moment(dcfFinancialPeriod.date);
    }
    dcfFinancialPeriod.year = date.year();
    dcfFinancialPeriod.date = date.format('YYYY-MM-DD');
  }
};

export default updateYear;
