import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProgressProps } from './types';

const TaskCompletionProgress = (props: ProgressProps) => (
  <Box display="flex" alignItems="center">
    <Box position="relative" display="inline-flex">
      <CircularProgress color="secondary" variant="determinate" value={props.value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary">
          {props.display}
        </Typography>
      </Box>
    </Box>
    <Typography style={{ marginLeft: '8px' }}>
      Requests: {props.total} sent, {props.completed} uploaded, {props.pending} pending
    </Typography>
  </Box>
);

export default TaskCompletionProgress;
