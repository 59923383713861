import { isNil } from 'lodash';
import { Scenario } from 'api';
import { underScoreSpaces } from 'pages/Allocation/allocations/utilities/util';
import { SSV_TABLE_NAMES } from 'pages/Valuations/approaches/SpecifiedShareValues/constants';
import {
  ALLOCATION_SCENARIO_METHOD_CSE_KEY,
  ALLOCATION_SCENARIO_METHOD_OPM_KEY,
  ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import {
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases';
import {
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { VALUATION_SUMMARY_KEY } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY } from 'pages/ValuationsAllocation/common/constants/valuationSummary/sheetAliases';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utillities';
import { CustomAfterCellChangedParams } from './types';
import { enableCells, handleFutureExitScenario, resetCells } from './utils';
import {
  handleApproach,
  handleBacksolve,
  handleBacksolveScenarioApproach,
  handleSSVScenarioApproach,
} from '../customParser/utils/handleApproach/handleApproach';

const {
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const customAfterCellChanged = (params: CustomAfterCellChangedParams) => {
  const { cells, initialChanges, primaryCapTable, spreadsheets, tableData } = params;
  const { approaches } = tableData;

  const changes = [...initialChanges];

  initialChanges.forEach(change => {
    const { cell, value } = getObjectValue(change);
    const { alias, columnLegend } = getObjectValue(cell);

    // Column Cells
    const columnCells = Object.values(cells).filter(currentCell => currentCell?.columnLegend === columnLegend);

    const capTableSelectionCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION
    );

    const maturityCell = columnCells.find(currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_MATURITY);

    const riskFreeRateCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE
    );

    const volatilityCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY
    );

    const futureEquityValueCell = columnCells.find(
      currentCell => currentCell?.alias === EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE
    );

    // Update Cell Data
    const updatedCell = cell;
    // Update Original Cell
    changes[0] = { cell: updatedCell, value };

    // Handle Allocation Method
    if (alias === EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD) {
      // Reset CapTable Selection
      if (capTableSelectionCell) {
        capTableSelectionCell.readOnly = false;
      }

      // Reset Cells
      resetCells({ cells: [futureEquityValueCell, maturityCell, riskFreeRateCell, volatilityCell], changes });
      const approach = approaches?.find(approach => approach.panelId === value?.toString()?.split('__')[1]);
      if (approach) {
        let ssvTableName;
        const futureExitReturn = handleApproach(approach, spreadsheets);
        let { opmInputTableName, isApproachWithOPM } = futureExitReturn;
        const { futureExitValueTableName } = futureExitReturn;
        const backsolveReturn = handleBacksolve(approach, spreadsheets, opmInputTableName, isApproachWithOPM);
        opmInputTableName = backsolveReturn.opmInputTableName;
        isApproachWithOPM = backsolveReturn.isApproachWithOPM;
        if (approach.approach_type === VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH) {
          ssvTableName = getApproachTableName({ approach, tableSuffix: SSV_TABLE_NAMES.CAP_TABLE_SELECTION });
        }

        switch (value) {
          case ALLOCATION_SCENARIO_METHOD_OPM_KEY:
            // Enable OPM Inputs
            enableCells({ cells: [maturityCell, riskFreeRateCell, volatilityCell] });
            break;

          case ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY:
          case ALLOCATION_SCENARIO_METHOD_CSE_KEY:
            // Clear and Hide OPM Inputs
            break;

          // Add Approch
          case ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY:
          case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY:
          case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY:
            break;

          // Handle Scenario Type
          default:
            if (!isNil(approach)) {
              handleBacksolveScenarioApproach({
                approach,
                params,
                changes,
                cells,
                columnLegend,
                capTableSelectionCell,
                isApproachWithOPM,
                maturityCell,
                primaryCapTable,
                riskFreeRateCell,
                volatilityCell,
              });
              handleSSVScenarioApproach({
                approach,
                spreadsheets,
                cells,
                columnLegend,
                changes,
                capTableSelectionCell,
                ssvTableName,
              });
              if (approach?.approach_type === VALUATIONS_FUTURE_EXIT_APPROACH) {
                handleFutureExitScenario({
                  capTableSelectionCell,
                  changes,
                  enableCells,
                  futureEquityValueCell,
                  opmInputTableName,
                  futureExitValueTableName,
                  isApproachWithOPM,
                  maturityCell,
                  primaryCapTable,
                  riskFreeRateCell,
                  volatilityCell,
                });
              }
              Object.values(spreadsheets.summary)
                .filter(summaryCell => summaryCell.columnLegend === cell.columnLegend && summaryCell.data.isApproach)
                .forEach(summaryCell => {
                  const splitValue = change?.value?.toString()?.split('__') ?? [];
                  if (splitValue[1] === summaryCell.alias) {
                    // eslint-disable-next-line no-param-reassign
                    summaryCell.hidden = false;
                    changes.push({ cell: summaryCell, value: 1 });
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    summaryCell.hidden = true;
                    changes.push({ cell: summaryCell, value: 0 });
                  }
                });
            }
            break;
        }
      }
    }
    if (change.cell.alias === EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY) {
      const summaryProbabilityCell = Object.values(spreadsheets[VALUATION_SUMMARY_KEY]).find(
        summaryCell =>
          summaryCell.alias === VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY
          && summaryCell.columnLegend === cell.columnLegend
      );
      changes.push({ cell: summaryProbabilityCell, value });
    }
    if (change.cell.alias === EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE) {
      const cellChanges = change.value as unknown as Scenario;
      const cellLegend = change.cell.columnLegend;
      cellChanges.present_values.forEach(presentValue => {
        const relatedPresentShareValueCell = cells[`${underScoreSpaces(presentValue.security.name)}_${cellLegend}`];
        if (relatedPresentShareValueCell) {
          changes.push({ cell: relatedPresentShareValueCell, value: presentValue.value });
        }
      });
      cellChanges.aggregate_values.forEach(aggregateValue => {
        const relatedAggregateValueCell
          = cells[`${underScoreSpaces(aggregateValue.security.name)}_${cellLegend}_aggregate`];
        if (relatedAggregateValueCell) {
          changes.push({ cell: relatedAggregateValueCell, value: aggregateValue.value });
        }
      });
    }
  });

  return changes;
};

export default customAfterCellChanged;
