export const FUTURE_EXIT_TABLE_NAMES = {
  FUTURE_EQUITY_VALUE: 'futureEquityValue',
  MODIFIED_PRESENT_EQUITY_VALUE: 'modifiedPresentEquityValue',
  ALLOCATION_METHOD_VALUE: 'allocationMethodValue',
};

export const CSE_METHOD = 'CSE';
export const OPM_METHOD = 'OPM';
export const CSE = 1;
export const OPM = 2;

export const ALLOCATION_METHODS_VALUE = {
  [CSE_METHOD]: CSE,
  [OPM_METHOD]: OPM,
};
