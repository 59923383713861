import { isEmpty } from 'lodash';
import { VALUATIONS_OTHER_LABEL, VALUATIONS_PERCENTILE_PATTERN } from 'common/constants/valuations';
import {
  LIMITED_VALUES_OPTIONS,
  SPECIFIED_LABEL,
  VALUES_OPTIONS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { getPercentileLabel } from 'pages/ValuationsAllocation/util/getPercentileLabel';
import { matchPattern } from 'pages/ValuationsAllocation/util/matchPattern';
import { getObjectValue } from 'utillities';
import { BenchmarkApproach, GetSelectionCellOptionsParams } from './types';

const getSelectionCellOptions = (params: GetSelectionCellOptionsParams) => {
  const { specificApproach, isDCF = false, shouldIgnorePercentiles = false } = params;

  const options = shouldIgnorePercentiles ? [...LIMITED_VALUES_OPTIONS] : [...VALUES_OPTIONS];

  if (isEmpty(specificApproach)) {
    return isDCF ? ['Other'] : options;
  }

  const { percentile_selection_a: percentileSelectionA, percentile_selection_b: percentileSelectionB } = getObjectValue(
    specificApproach as BenchmarkApproach
  );

  if (percentileSelectionA && percentileSelectionA !== 75) {
    options.push(getPercentileLabel(percentileSelectionA - 1));
  }

  if (percentileSelectionB && percentileSelectionB !== 25) {
    options.push(getPercentileLabel(percentileSelectionB - 1));
  }

  if (options.length > 5) {
    const [firstOption, secondOption, ...restOptions] = options;

    const percentileOptions = restOptions.filter(option => option !== SPECIFIED_LABEL);

    percentileOptions.sort((a, b) => {
      const aPercentileSelectionPattern = matchPattern({
        label: a,
        patterns: VALUATIONS_PERCENTILE_PATTERN,
      });

      const bPercentileSelectionPattern = matchPattern({
        label: b,
        patterns: VALUATIONS_PERCENTILE_PATTERN,
      });

      const aPercentileSelectionArray = a.split(aPercentileSelectionPattern);
      const bPercentileSelectionArray = b.split(bPercentileSelectionPattern);

      return aPercentileSelectionArray?.[0]?.localeCompare(bPercentileSelectionArray?.[0]);
    });

    const uniqueOptions = new Set([firstOption, secondOption, ...percentileOptions, SPECIFIED_LABEL]);

    if (isDCF) return [...uniqueOptions, VALUATIONS_OTHER_LABEL];

    return [...uniqueOptions];
  }

  if (isDCF) options.push(VALUATIONS_OTHER_LABEL);

  return options;
};

export default getSelectionCellOptions;
