import { isEmpty, isNumber } from 'lodash';

const FILTER_NON_POSITIVE = ({ _data: data }: { _data: (number | null | undefined)[] }) => {
  if (!Array.isArray(data)) {
    return [];
  }

  const result = data.filter(value => isNumber(value) && value > 0);

  return isEmpty(result) ? [] : result;
};

export default FILTER_NON_POSITIVE;
