import { CAP_TABLE_SELECTION } from 'pages/Valuations/util/constants';
import {
  MATURITY_YEARS_CUSTOM_KEY,
  RISK_FREE_RATE_CUSTOM_KEY,
  VOLATILITY_CUSTOM_KEY,
} from 'pages/ValuationsAllocation/approaches/FutureExit/AllocationMethodTable/utils/constants';
import { FUTURE_VALUE_TO_CURRENT_SHAREHOLDERS_CUSTOM_KEY } from 'pages/ValuationsAllocation/common/constants/futureExit';
import { HandleFutureExitScenarioParams } from './types';

const handleFutureExitScenario = (params: HandleFutureExitScenarioParams) => {
  const {
    capTableSelectionCell,
    changes,
    enableCells,
    futureEquityValueCell,
    isApproachWithOPM,
    maturityCell,
    riskFreeRateCell,
    volatilityCell,
    opmInputTableName,
    futureExitValueTableName,
  } = params;

  if (capTableSelectionCell) {
    capTableSelectionCell.readOnly = true;
    changes?.push({ cell: capTableSelectionCell, value: `=${opmInputTableName}.${CAP_TABLE_SELECTION}` });
  }

  if (isApproachWithOPM) {
    // Read-only OPM Inputs
    enableCells?.({ cells: [maturityCell, riskFreeRateCell, volatilityCell], isReadOnly: true });

    if (maturityCell) {
      changes?.push({ cell: maturityCell, value: `=${opmInputTableName}.${MATURITY_YEARS_CUSTOM_KEY}` });
    }

    if (riskFreeRateCell) {
      changes?.push({ cell: riskFreeRateCell, value: `=${opmInputTableName}.${RISK_FREE_RATE_CUSTOM_KEY}` });
    }

    if (volatilityCell) {
      changes?.push({ cell: volatilityCell, value: `=${opmInputTableName}.${VOLATILITY_CUSTOM_KEY}` });
    }
  }

  if (futureEquityValueCell) {
    enableCells?.({ cells: [futureEquityValueCell], isReadOnly: true });
    changes?.push({
      cell: futureEquityValueCell,
      value: `=${futureExitValueTableName}.${FUTURE_VALUE_TO_CURRENT_SHAREHOLDERS_CUSTOM_KEY}`,
    });
  }
};

export default handleFutureExitScenario;
