import { isEmpty } from 'lodash';
import { DCFFinancialPeriod } from 'api';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getThresholdDate } from 'pages/Valuations/util/util';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utillities';
import { isValidDate } from 'utillities/datesFormats';
import afterCellChanged from './config/afterCellChanged';
import { percentagesAlias } from './config/constants';
import createColumns from './config/createColumns';
import getDCFRowConfig from './config/getRowConfig';
import parser from './config/parser';
import reverseParser from './config/reverseParser';
import {
  CreateDCFDataProps,
  CustomDCFFinancialPeriod,
  GetDcfConfigProps,
  ProcessFinancialsPeriodsProps,
} from './config/types';

const processFinancialsPeriods = (props: ProcessFinancialsPeriodsProps) => {
  const { approach } = getObjectValue(props);
  const { valuations_approach_dcf } = getObjectValue(approach);
  const { dcf_financial_period } = getObjectValue(valuations_approach_dcf);

  if (!dcf_financial_period || isEmpty(dcf_financial_period)) {
    return;
  }

  const financialPeriods = dcf_financial_period.map(period =>
    Object.keys(period).reduce((flattenedObj: { [key: string]: string | number }, key) => {
      const value = period[key as keyof DCFFinancialPeriod] as string;
      const isPercentageKey = percentagesAlias.includes(key);
      const toFixedArgument = isPercentageKey ? 4 : 2;

      // eslint-disable-next-line no-param-reassign
      flattenedObj[key]
        = !Number.isNaN(parseFloat(value)) && !Number.isInteger(parseFloat(value)) && !isValidDate(value)
          ? parseFloat(value).toFixed(toFixedArgument)
          : value;

      return flattenedObj;
    }, {})
  );

  if (approach?.valuations_approach_dcf?.dcf_financial_period) {
    // eslint-disable-next-line no-param-reassign
    approach.valuations_approach_dcf.dcf_financial_period = financialPeriods;
  }
};

export const getDcfConfig = (props: GetDcfConfigProps) => {
  const { approach, measurementDate, companyMeasurementDate, cashTaxRate, dcfWaccSheet, isDisabled } = props;
  processFinancialsPeriods({ approach });

  const thresholdDate = getThresholdDate(companyMeasurementDate);

  const getColumnsFn = () =>
    createColumns({
      financialPeriods: approach?.valuations_approach_dcf?.dcf_financial_period as CustomDCFFinancialPeriod[],
      thresholdDate,
    });
  const columns = getColumnsFn();

  const rowConfig = getDCFRowConfig({
    cashTaxRate,
    dcfWaccSheet,
    isDisabled,
  });

  return {
    columns,
    getColumns: getColumnsFn,
    rowConfig,
    tableData: {
      approach,
      measurementDate,
      isDisabled,
    },
  };
};

const createDCFData = (props: CreateDCFDataProps) => {
  const { approach, dcfProps, dcfWaccSheet, dcfAttributes, isDisabled } = props;
  const name = getApproachTableName({ approach });

  const dcfConfig = getDcfConfig({
    approach,
    ...dcfProps,
    dcfWaccSheet,
    isDisabled,
  });

  return new SpreadsheetConfig({
    name,
    parser,
    showToolbar: true,
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    afterCellChanged: afterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    tableTerms: {
      tableSlug: 'valuation-dcf',
      columnName: 'year',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    allowDeleteOnlySpecificColumn: true,
    format: undefined,
    totalParser: undefined,
    ...dcfConfig,
  });
};

export default createDCFData;
