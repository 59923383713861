/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import { CAP_TABLE_ALIAS, FUTURE_EQUITY_VALUE_ALIAS } from 'common/constants/allocations';
import { CAP_TABLE_REFERENCE_TYPE } from 'common/constants/documents';
import { largeCurrencyFormat } from 'common/formats/formats';
import { useStore } from 'common/store';
import ScalarDatasheet from 'components/ScalarDatasheet';
import { ValueEditor, ValueViewer } from 'components/Spreadsheet/components';
import ExcelExportContext from 'context/ExcelExportContext';
import { useTableValidation } from 'services/hooks';
import rowConfig from './data/rowConfig';

const Waterfall = ({ setSelectedCaptable, setEquityValue, currencyCode }) => {
  const [{ capTableList, companyInfo }] = useStore();
  const { validateCells } = useTableValidation();
  const { setCurrentSelectedValues } = useContext(ExcelExportContext);

  const initialFormValues = {
    [FUTURE_EQUITY_VALUE_ALIAS]: null,
    [CAP_TABLE_ALIAS]: null,
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [validatedEquityValueCell, setValidatedEquityValueCell] = useState();

  const rowTitleClass = 'no-uppercase row-label align-left';

  useEffect(() => {
    if (companyInfo && capTableList) {
      const primaryCapTable = capTableList.find(capTable => capTable.is_primary);
      setFormValues({
        [FUTURE_EQUITY_VALUE_ALIAS]: null,
        [CAP_TABLE_ALIAS]: primaryCapTable?.id,
      });
    }
  }, [capTableList]);

  useEffect(() => {
    if (formValues[CAP_TABLE_ALIAS]) {
      setSelectedCaptable(formValues[CAP_TABLE_ALIAS]);
    }
    setCurrentSelectedValues({
      cap_table: formValues.CAP_TABLE_ALIAS,
      fo_cap_table: formValues.CAP_TABLE_ALIAS,
      breakpoint_cap_table: formValues.CAP_TABLE_ALIAS,
      waterfall_cap_table: formValues.CAP_TABLE_ALIAS,
    });

    return () => setSelectedCaptable(undefined);
  }, [formValues[CAP_TABLE_ALIAS]]);

  const data = useMemo(
    () => [
      [
        {
          value: rowConfig[0].value,
          readOnly: true,
          className: rowTitleClass,
        },
        {
          gridType: 'number',
          key: 'ev',
          alias: FUTURE_EQUITY_VALUE_ALIAS,
          greaterThan: 0,
          format: largeCurrencyFormat,
          // Spread the cell error values if a validation problem is found. The ternary check
          // prevents this from trigerring on the first render when the equity value is null.
          ...(isNull(formValues[FUTURE_EQUITY_VALUE_ALIAS]) ? null : validatedEquityValueCell),
          value: formValues[FUTURE_EQUITY_VALUE_ALIAS],
          // Display the currency symbol when not in editing mode
          currencyCode,
        },
      ],
      [
        {
          value: rowConfig[1].value,
          readOnly: true,
          className: rowTitleClass,
        },
        {
          ...rowConfig[1],
          key: CAP_TABLE_REFERENCE_TYPE,
          alias: CAP_TABLE_ALIAS,
          value: formValues[CAP_TABLE_ALIAS],
        },
      ],
    ],
    [formValues, validatedEquityValueCell]
  );

  useEffect(() => {
    if (!isNull(formValues[FUTURE_EQUITY_VALUE_ALIAS])) {
      const { validatedCells, areCellsValid } = validateCells({ cellsToValidate: data.flat() });
      if (!areCellsValid) {
        setValidatedEquityValueCell(validatedCells[1]);
        setEquityValue(null);
      } else {
        setValidatedEquityValueCell(undefined);
        setEquityValue(formValues[FUTURE_EQUITY_VALUE_ALIAS]);
      }
    }

    return () => setEquityValue(undefined);
  }, [formValues[FUTURE_EQUITY_VALUE_ALIAS]]);

  const onChange = args => {
    const newValue = args[0].value;
    const { alias } = args[0].cell;
    setFormValues({ ...formValues, [alias]: newValue });
  };

  return (
    <div id="waterfall-inputs">
      {capTableList && (
        <ScalarDatasheet
          data={data}
          className="waterfall-data-sheet"
          onCellsChanged={onChange}
          dataEditor={ValueEditor}
          valueViewer={ValueViewer}
        />
      )}
    </div>
  );
};

Waterfall.propTypes = {
  setSelectedCaptable: PropTypes.func,
  setEquityValue: PropTypes.func,
  currencyCode: PropTypes.string,
};

export default Waterfall;
