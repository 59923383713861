export const TERMINAL_VALUE_OPTIONS = {
  PERPETUITY_GROWTH: 'Perpetuity Growth',
  H_MODEL: 'H-Model',
  REVENUE_MULTIPLE: 'Revenue Multiple',
  EBITDA_MULTIPLE: 'EBITDA Multiple',
  REVENUE_AND_EBITDA_MULTIPLE: 'Rev & EBITDA Multiple',
};

export const MULTIPLE_OPTIONS = {
  MEAN: 'Mean',
  MEDIAN: 'Median',
  TWENTY_FIFTH: '25th',
  SEVENTY_FIFTH: '75th',
  OTHER: 'Other',
};

export const WACC_LABEL = 'Weighted Average Cost of Capital (WACC)';

export const VALUATION_APPROACH_DCF_TABLE_NAME = 'valuations_approach_dcf';
export const DCF_FINANCIAL_PERIOD_TABLE_NAME = 'dcf_financial_period';

export const DISCOUNT_PERIODS = 'terminal_discount_periods';

export const TVT_REM_TABLE_NAME = 'tvt_rem';
export const TVT_EM_TABLE_NAME = 'tvt_em';
export const TVT_RM_TABLE_NAME = 'tvt_rm';
export const TVT_HM_TABLE_NAME = 'tvt_hm';
export const TVT_PG_TABLE_NAME = 'tvt_pg';

export const TERMINAL_VALUE_CUSTOM_KEY = 'terminalValueMetric';

export const PV_OF_TERMINAL_VALUE_CUSTOM_KEY = 'pv_terminal_value';

export const TERMINAL_VALUE_EXPR_CUSTOM_KEY = 'terminalValueExpr';

export const DCF_SUMMARY_TABLE_NAME = 'summary';
