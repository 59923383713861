export const ACTIVE_ACCOUNT_TYPE = 'ACTIVE_ACCOUNT_TYPE';
export const AUTH_TYPE = 'AUTH_TYPE';
export const COMPANY_TYPE = 'COMPANY_TYPE';
export const FIRM_TYPE = 'FIRM_TYPE';
export const FUND_TYPE = 'FUND_TYPE';
export const NOT_FOUND_TYPE = 'NOT_FOUND_TYPE';
export const USER_TYPE = 'USER_TYPE';

// Pages where the Excel export is allowed
export const VALID_PAGE_TYPES_TO_EXPORT = [COMPANY_TYPE, FIRM_TYPE, FUND_TYPE];
