import React, { useContext, useMemo } from 'react';
import { toString } from 'lodash';
import PropTypes from 'prop-types';
import createColumnsCalibrationPerformance from 'pages/Valuations/approaches/guidelineCalibration/performance_metrics/config/createColumns/createColumnsCalibrationPerformance';
import rowConfigCalibrationPerformance from 'pages/Valuations/approaches/guidelineCalibration/performance_metrics/config/rowConfig/rowConfigCalibrationPerformance';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { getObjectValue } from 'utillities';
import rowTransformerCalibrationInputs from './createColumnsCalibrationInputs';
import getRowConfigCalibrationInputs from './rowConfigCalibrationInputs';
import { emptyFinancials } from '../../../../../common/constants/calibration';
import { CustomSelect, withTooltip } from '../../../../../components';
import FeaturedSpreadsheetContext from '../../../../../components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import CalibrationContext from '../../../../../context/CalibrationContext';
import { transposeFinancials } from '../../../../ValuationsAllocation/util';
import rowConfigCalibrationOutputs from '../Outputs/rowConfigCalibrationOutputs';
import rowTransformerCalibrationOutputs from '../Outputs/rowTransformerCalibrationOutputs';
import { mapPerformancesToGPCWithCapIQ } from '../utilities';

const FinancialVersionsList = ({ cell, options, ...restProps }) => {
  const { onCommit, onRevert } = restProps;

  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);
  const {
    approaches,
    calibrationPerformanceSheet,
    calibrationOutputSheet,
    calibrationInputSheet,
    company,
    customClasses,
    financialStatementsList,
    calibration,
  } = useContext(CalibrationContext);
  const { resetConfiguration } = useContext(ValuationContext);

  const handleChange = value => {
    const newValue = toString(value);

    let financials = emptyFinancials;

    let isReadOnlyData = true;
    if (Number(newValue) === 0) {
      isReadOnlyData = false;
    } else {
      const selectedFinancialVersion = financialStatementsList.filter(tab => tab.id === Number(newValue));

      financials = transposeFinancials(getObjectValue(selectedFinancialVersion[0]));
    }

    const approach = approaches.find(
      item => item.panelId === cell.sheet.tableData.calibration.public_comps_approach_calibration
    );
    const { valuations_approach_gpc: valuationsApproachGpc } = getObjectValue(approach);

    const rowConfigInputs = getRowConfigCalibrationInputs({
      financialStatementsList,
      readOnlyData: isReadOnlyData,
    });

    const columnsInputs = rowTransformerCalibrationInputs(financials);

    /* eslint-disable no-param-reassign */
    calibration.ltm_revenue_inputs = columnsInputs[0].ltm_revenue_inputs.value;
    calibration.ntm_revenue_inputs = columnsInputs[0].ntm_revenue_inputs.value;
    calibration.ltm_ebitda_inputs = columnsInputs[0].ltm_ebitda_inputs.value;
    calibration.ntm_ebitda_inputs = columnsInputs[0].ntm_ebitda_inputs.value;
    calibration.data_updated = false;
    cell.sheet.tableData.financials = financials;

    calibrationInputSheet.reset({
      rowConfig: rowConfigInputs,
      columns: columnsInputs,
    });

    const { gpc_comparison: gpcComparison } = calibration.approach?.valuations_approach_gpc ?? {};
    const gpcModels = mapPerformancesToGPCWithCapIQ(calibration.performances || [], gpcComparison || []);

    const columns = {
      valuationsApproachGpc,
      financials,
      gpcComparisonByDate: gpcModels,
    };
    const rowConfig = rowConfigCalibrationPerformance({
      company,
      customClasses,
      valuationsApproachGpc,
      gpcComparisonByDate: gpcModels,
    });
    const columnsPerformance = createColumnsCalibrationPerformance(columns);
    columnsPerformance.splice(0, 1);
    calibrationPerformanceSheet.reset({
      rowConfig,
      columns: columnsPerformance,
    });

    const rowConfigOutputs = rowConfigCalibrationOutputs({
      calibration,
      columnsPerformance,
    });

    const columnsOutputs = rowTransformerCalibrationOutputs([]);

    calibrationOutputSheet.reset({
      rowConfig: rowConfigOutputs,
      columns: columnsOutputs,
    });

    onCellsChanged([{ cell, value: newValue }]);

    resetConfiguration();
  };

  const getOptions = useMemo(() => {
    const tmpOptions = [];
    if (Array.isArray(options)) {
      options.forEach(option => {
        tmpOptions.push({
          value: option.id,
          label: option.name,
        });
      });
      tmpOptions.push({
        value: 0,
        label: 'Specify Financials Metrics',
      });
    }

    return tmpOptions;
  }, [options]);

  return (
    <CustomSelect
      id={`financial-versions-list-${cell.key}`}
      value={cell.value}
      options={getOptions}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      onEmptyValue="SELECT-OPTION"
      disabled={cell.readOnly}
      disableUnderline
    />
  );
};

FinancialVersionsList.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

FinancialVersionsList.propTypes = {
  cell: PropTypes.object,
  options: PropTypes.object,
};

export default withTooltip(FinancialVersionsList);
