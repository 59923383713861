export const ALLOCATION_METHOD_OPTIONS = {
  OPM: 'OPM',
  CSE: 'CSE',
};
export const CSE = 1;
export const OPM = 2;
export const FUTURE_EXIT = 0;
export const BACKSOLVE = 2;
export const FUTURE_VALUES_PER_SHARE_TABLE_NAME = 'future_values_per_share';
export const PRESENT_VALUES_PER_SHARE_TABLE_NAME = 'present_values_per_share';
export const OTHER_LABEL = 'Other';
export const ALLOCATION_METHOD_VALUE = 'Allocation Method';
export const ALLOCATION_METHOD_ALIAS = 'allocation_method';
export const CAP_TABLE_VALUE = 'Cap Table Selection';
export const CAP_TABLE_ALIAS = 'cap_table';

// OPM Constants for Row Config
export const OPM_INPUTS_VALUE = 'OPM Inputs';
export const OPM_INPUTS_ALIAS = 'opm_inputs';

export const MATURITY_YEARS_VALUE = 'Maturity (Years)';
export const MATURITY_YEARS_ALIAS = 'maturity';
export const MATURITY_YEARS_CUSTOM_KEY = 'MATURITY';

export const MATURITY_DATE_VALUE = 'Maturity Date';
export const MATURITY_DATE_ALIAS = 'maturity_date';

export const RISK_FREE_RATE_VALUE = 'Risk Free Rate';
export const RISK_FREE_RATE_ALIAS = 'risk_free_rate';
export const RISK_FREE_RATE_CUSTOM_KEY = 'RISK_FREE_RATE';

export const VOLATILITY_SOURCE_VALUE = 'Volatility Source';
export const VOLATILITY_SOURCE_ALIAS = 'volatility_source';

export const VOLATILITY_VALUE = 'Volatility';
export const VOLATILITY_ALIAS = 'volatility';
export const VOLATILITY_CUSTOM_KEY = 'VOLATILITY';

export const ALLOCATION_METHOD_TABLE_NAME = 'Allocation Method';
export const ALLOCATION_METHOD_TABLE_SLUG = 'allocation-method-table';

export const SPECIFIED_VOLATILITY = 'Specified';
