/* eslint-disable no-param-reassign */
import { useCallback, useContext } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { MEAN, MEDIAN, PERCENTILE, Q25, Q75 } from 'common/formulas/math.js';
import { METRICS_KEYS_MAP } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { parseGpcComparison } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/utillities';
import { useCombinedGPCComparisonPerfMetricAttrs } from 'pages/Valuations/approaches/guidelinePublicCompanies/utils/useCombinedGPCComparisonPerfMetricAttrs';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { MOMENT_DEFAULT_DATE_FORMAT } from 'utillities';

const PERCENTILE_SELECTION_A = 'Selection A';
const PERCENTILE_SELECTION_B = 'Selection B';
const SPECIFIED = 'Specified';

const ADJUSTMENTS_MAP = {
  Median: MEDIAN,
  Mean: MEAN,
  '75th Percentile': Q75,
  '25th Percentile': Q25,
  'Selection A': PERCENTILE,
  'Selection B': PERCENTILE,
};

export const useRefreshHelpers = ({ measurementDate, backsolveAttributes, gpcAttributes }) => {
  const combinedGPCComparisonPerfMetricAttrs = useCombinedGPCComparisonPerfMetricAttrs(gpcAttributes);

  const getFlattenedComparisons = useCallback(
    comparisons =>
      comparisons.map(gpc =>
        parseGpcComparison({
          data: gpc,
          attributes: combinedGPCComparisonPerfMetricAttrs,
        })
      ),
    [combinedGPCComparisonPerfMetricAttrs]
  );

  const updateGPCApproach = useCallback(({ flattenedComparisons, approach }) => {
    const updatedComparisons = [];
    approach.valuations_approach_gpc.gpc_comparison.forEach(element => {
      const updatedCom = flattenedComparisons?.find(comp => comp.cap_iq_id === element.cap_iq_id);
      if (updatedCom) {
        updatedComparisons.push({ ...element, ...updatedCom });
      } else {
        updatedComparisons.push({ ...element });
      }
    });
    return {
      ...approach,
      valuations_approach_gpc: {
        ...approach.valuations_approach_gpc,
        last_refresh_date: new Date().toISOString(),
        gpc_comparison: updatedComparisons,
      },
    };
  }, []);

  const calculateMarketAdjustmentForBSApproach = useCallback((approachBS, approachGPC) => {
    const adjustmentSelected = approachBS.valuations_approach_backsolve.adjustment_selected;
    if ([null, undefined, ''].includes(adjustmentSelected)) {
      return null;
    }
    const metricKey = METRICS_KEYS_MAP[approachBS.valuations_approach_backsolve.metric];

    const percentileSelection
      = adjustmentSelected === PERCENTILE_SELECTION_A
        ? approachBS.valuations_approach_backsolve.percentile_selection_a
        : approachBS.valuations_approach_backsolve.percentile_selection_b;

    if (adjustmentSelected !== SPECIFIED) {
      const data = approachBS.valuations_approach_backsolve.public_companies_status
        .filter(pcs => pcs.enabled) // not sure if that is a good thing, we should probably do it for all of them
        .map(pcs => {
          const comparison = approachGPC.valuations_approach_gpc.gpc_comparison.find(
            comp => comp.cap_iq_id === pcs.cap_iq_id
          );
          const asOfDateMetric = parseFloat(pcs[metricKey]);
          let percentageChange = 0.0;
          if (!comparison) {
            return percentageChange;
          }
          percentageChange = (parseFloat(comparison[metricKey]) - asOfDateMetric) / asOfDateMetric;
          return percentageChange;
        });
      if ([PERCENTILE_SELECTION_A, PERCENTILE_SELECTION_B].includes(adjustmentSelected)) {
        return ADJUSTMENTS_MAP[adjustmentSelected](data, percentileSelection);
      }
      return ADJUSTMENTS_MAP[adjustmentSelected](data);
    }
    return parseFloat(approachBS.valuations_approach_backsolve.specified_adjustment || '0.00');
  }, []);

  const searchRelatedBSApproachesWithMarketAdjustment = useCallback((approachGPC, approaches) => {
    const relatedBacksolveApproaches = approaches.filter(
      approach =>
        approach?.valuations_approach_backsolve?.adjust_for_market
        && Number(approach?.valuations_approach_backsolve?.gpc_approach) === approachGPC.valuations_approach_gpc.id
    );
    return relatedBacksolveApproaches;
  }, []);

  const refreshBacksolveApproachWithMarketAdjustment = useCallback(
    (backsolveApproach, approachGPC, flattenedComparisonsByDate) => {
      const asOfDate = backsolveApproach.valuations_approach_backsolve.as_of_date;
      const asOfDateIso = moment(asOfDate, MOMENT_DEFAULT_DATE_FORMAT).toISOString().split('T')[0];
      const comparisons = flattenedComparisonsByDate[asOfDateIso];

      const refreshedPublicCompaniesStatus = [];

      approachGPC.valuations_approach_gpc.gpc_comparison.forEach(comparison => {
        const { cap_iq_id, id, ticker_symbol: comp_ticker_symbol } = comparison;
        const updatedComparison = comparisons.find(comparison => comparison.cap_iq_id === cap_iq_id);
        const toUpdate = backsolveApproach.valuations_approach_backsolve.public_companies_status.find(
          pcs => pcs.cap_iq_id === cap_iq_id
        );
        if (updatedComparison) {
          const { ltm_revenue, ltm_ebitda, ntm_revenue, ntm_ebitda, market_cap, enterprise_value, ticker_symbol }
            = updatedComparison;
          if (toUpdate) {
            // update the already existing public companies status
            const updatedPublicCompanyStatus = {
              ...toUpdate,
              ltm_revenue,
              ltm_ebitda,
              ntm_revenue,
              ntm_ebitda,
              market_cap,
              enterprise_value,
              ticker_symbol,
            };
            refreshedPublicCompaniesStatus.push(updatedPublicCompanyStatus);
          } else {
            // add the new comparison to public companies status
            const newPublicCompanyStatus = {
              ltm_revenue,
              ltm_ebitda,
              ntm_revenue,
              ntm_ebitda,
              market_cap,
              enterprise_value,
              ticker_symbol,
              cap_iq_id,
              valuation_approach_backsolve: backsolveApproach.valuations_approach_backsolve.id,
              public_company: id,
              enabled: true,
            };
            refreshedPublicCompaniesStatus.push(newPublicCompanyStatus);
          }
        } else {
          // add empty comparison to public companies status
          const newPublicCompanyStatus = {
            ltm_revenue: 0.0,
            ltm_ebitda: 0.0,
            ntm_revenue: 0.0,
            ntm_ebitda: 0.0,
            market_cap: 0.0,
            enterprise_value: 0.0,
            reference_for_backsolve: null,
            ticker_symbol: comp_ticker_symbol,
            cap_iq_id,
            valuation_approach_backsolve: backsolveApproach.valuations_approach_backsolve.id,
            public_company: id,
            enabled: true,
          };
          refreshedPublicCompaniesStatus.push(newPublicCompanyStatus);
        }
      });

      // remove public companies status related to removed gpc comparisons from the related gpc approach
      const existingPublicCompaniesStatus = approachGPC.valuations_approach_gpc.gpc_comparison.map(
        ({ cap_iq_id }) => cap_iq_id
      );
      const deletedPublicCompanyStatusIds = backsolveApproach.valuations_approach_backsolve.public_companies_status
        .filter(pcs => pcs.id && !existingPublicCompaniesStatus.includes(pcs.cap_iq_id))
        .map(({ id }) => id);

      const refreshedBackSolveApproach = {
        ...backsolveApproach,
        valuations_approach_backsolve: {
          ...backsolveApproach.valuations_approach_backsolve,
          deleted_public_company_status_ids: deletedPublicCompanyStatusIds,
          public_companies_status: refreshedPublicCompaniesStatus,
        },
      };

      let marketAdjustment = calculateMarketAdjustmentForBSApproach(refreshedBackSolveApproach, approachGPC);
      // ensure we have the correct number of decimal places
      marketAdjustment = Number(
        marketAdjustment.toFixed(backsolveAttributes?.backsolveApproachAttrs.market_adjustment.decimal_places)
      );
      refreshedBackSolveApproach.valuations_approach_backsolve.market_adjustment = marketAdjustment;
      return refreshedBackSolveApproach;
    },
    [calculateMarketAdjustmentForBSApproach, backsolveAttributes]
  );

  const getTickerListsByDate = useCallback(
    (outdatedApproaches, approaches, date) => {
      // Collect all tickers and group them by date so we can
      // distribute them into several, parallel Cap IQ API calls
      const measurementValue = date ?? measurementDate.date;
      const tickerListsByDate = outdatedApproaches.reduce((acc, approach) => {
        const approachIdentifiers = approach.valuations_approach_gpc.gpc_comparison.map(({ cap_iq_id }) => cap_iq_id);
        const relatedBacksolveApproaches = searchRelatedBSApproachesWithMarketAdjustment(approach, approaches);
        if (!acc[measurementValue]) {
          acc[measurementValue] = [];
        }
        acc[measurementValue].push(...approachIdentifiers);

        let asOfDateMPD = null;
        if (approach.valuations_approach_gpc.use_multiple_premium_discount) {
          const multiplePremiumDiscount = approach.valuations_approach_gpc.multiple_premium_discount.find(
            mpd => mpd.as_of_date !== measurementValue
          );

          if (multiplePremiumDiscount) {
            asOfDateMPD = multiplePremiumDiscount.as_of_date;
            if (!acc[asOfDateMPD]) {
              acc[asOfDateMPD] = [];
            }
            acc[asOfDateMPD].push(...approachIdentifiers);
          }
        }

        approach.valuations_approach_gpc.last_refresh_date = new Date().toISOString();

        relatedBacksolveApproaches.forEach(backsolveApproach => {
          const asOfDate = backsolveApproach.valuations_approach_backsolve.as_of_date;
          const asOfDateIso = moment(asOfDate, MOMENT_DEFAULT_DATE_FORMAT).toISOString().split('T')[0];
          if (!acc[asOfDateIso]) {
            acc[asOfDateIso] = [];
          }
          acc[asOfDateIso].push(...approachIdentifiers);
        });
        return acc;
      }, {});
      return tickerListsByDate;
    },
    [searchRelatedBSApproachesWithMarketAdjustment, measurementDate]
  );

  const refreshMultiplePremiumDiscount = useCallback(
    (approach, flattenedComparisonsByDate) => {
      const date = approach.valuation_date;

      if (approach.valuations_approach_gpc.use_multiple_premium_discount) {
        const multiplePremiumDiscount = approach.valuations_approach_gpc.multiple_premium_discount.find(
          mpd => mpd.as_of_date !== date
        );

        const asOfDateMPD = multiplePremiumDiscount ? multiplePremiumDiscount.as_of_date : date;
        const flattenedComparisonsMPD = flattenedComparisonsByDate[asOfDateMPD];
        approach.valuations_approach_gpc.gpc_comparison.forEach(comparison => {
          comparison.custom_date_performance_metrics.forEach(element => {
            if (element.as_of_date === asOfDateMPD) {
              const { cap_iq_id } = comparison;
              const updatedComparison
                = flattenedComparisonsMPD.find(comparison => comparison.cap_iq_id === cap_iq_id) || {};

              element.ltm_revenue = updatedComparison.ltm_revenue;
              element.ltm_revenue_growth = updatedComparison.ltm_revenue_growth;
              element.ntm_revenue = updatedComparison.ntm_revenue;
              element.ntm_revenue_growth = updatedComparison.ntm_revenue_growth;
              element.ltm_ebitda = updatedComparison.ltm_ebitda;
              element.ntm_ebitda = updatedComparison.ntm_ebitda;
              element.gross_margin = updatedComparison.gross_margin;
              element.ebitda_margin = updatedComparison.ebitda_margin;
            }
          });
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [measurementDate]
  );

  const getRefreshedApproaches = useCallback(
    (publicCompsDataByDate, outdatedApproaches, approaches, isValuationDateChange = false) => {
      if (!isEmpty(combinedGPCComparisonPerfMetricAttrs)) {
        const flattenedComparisonsByDate = {};
        Object.entries(publicCompsDataByDate).forEach(([date, comparisons]) => {
          flattenedComparisonsByDate[date] = getFlattenedComparisons(comparisons);
        });

        const refreshedApproaches = {};
        outdatedApproaches.forEach(approach => {
          const flattenedComparisons
            = flattenedComparisonsByDate[isValuationDateChange ? approach?.valuation_date : measurementDate?.date];
          const refreshedApproach = updateGPCApproach({ flattenedComparisons, approach });

          refreshMultiplePremiumDiscount(refreshedApproach, flattenedComparisonsByDate);

          const key = approach.id ? approach.id : approach.panelId;
          refreshedApproaches[key] = refreshedApproach;
          // searh related backsolve approaches with adjust for market
          // to refresh them and add to refreshed Approaches dict
          const relatedBacksolveApproaches = searchRelatedBSApproachesWithMarketAdjustment(approach, approaches);
          const refreshedBacksolveApproaches = relatedBacksolveApproaches.map(approachBS =>
            refreshBacksolveApproachWithMarketAdjustment(approachBS, refreshedApproach, flattenedComparisonsByDate)
          );
          refreshedBacksolveApproaches.forEach(refreshedBacksolveApproach => {
            const approachKey = refreshedBacksolveApproach.id
              ? refreshedBacksolveApproach.id
              : refreshedBacksolveApproach.panelId;
            refreshedApproaches[approachKey] = refreshedBacksolveApproach;
          });
        });

        const updatedApproaches = approaches.map(approach => {
          const refreshedApproach = approach.id
            ? refreshedApproaches[approach.id]
            : refreshedApproaches[approach.panelId];
          if (refreshedApproach) {
            return refreshedApproach;
          }
          return approach;
        });

        return updatedApproaches;
      }

      return approaches;
    },
    [
      getFlattenedComparisons,
      refreshBacksolveApproachWithMarketAdjustment,
      searchRelatedBSApproachesWithMarketAdjustment,
      updateGPCApproach,
      measurementDate,
      refreshMultiplePremiumDiscount,
      combinedGPCComparisonPerfMetricAttrs,
    ]
  );

  return {
    getTickerListsByDate,
    getRefreshedApproaches,
    getFlattenedComparisons,
  };
};
export const useRefreshHelpersWithContext = () => {
  const { measurementDate, backsolveAttributes, gpcAttributes } = useContext(ValuationContext);

  const refreshHelpers = useRefreshHelpers({
    measurementDate,
    backsolveAttributes,
    gpcAttributes,
  });

  return refreshHelpers;
};
