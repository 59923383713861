import { CAP_TABLE_SELECTION_ALIAS } from 'common/constants/valuations';
import { smallCurrencyFormat, weightingPercentFormat } from 'common/formats/formats';
import { ALLOCATION_METHOD_ALIAS } from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/constants';
import {
  BACKSOLVE_ROW_HEADER_CONFIG,
  BOLD_ROW_HEADER_CONFIG,
  PRESENT_SHARE_VALUES_ROW_NUMBER,
  TABLE_HEADER_CONFIG,
  TABLE_HEADER_ROW,
} from 'pages/Valuations/approaches/backsolveApproach/constants';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const totalParser = ({ cells, columns, rows }) => {
  let totalsRow = [];
  const alphabet = alphabetGenerator([], columns.length);

  const sumExpr = rowIndexAux =>
    Object.keys(columns)
      .filter(c => c > 0)
      .map(columnIndex => alphabet[columnIndex] + rowIndexAux)
      .filter(key => cells[key]);

  const presentShareValuesExpr = rowIndexAux =>
    Object.keys(columns)
      .filter(c => c > 0)
      .map(columnIndex => `(${alphabet[columnIndex]}${rowIndexAux} * ${alphabet[columnIndex]}4)`);

  rows.forEach(({ alias, hasTotal, format }, rowIndex) => {
    const columnLegend = alphabet[0];
    const rowIndexAux = rowIndex + 1;
    const keyActual = `${columnLegend + rowIndexAux}`;
    const key = `TOTAL_${columnLegend + rowIndexAux}`;

    const colFormat = format?.style;
    const gridFormat = colFormat && 'number';

    let cell = {
      ...rows[rowIndex],
      key: keyActual,
      isTotal: true,
      readOnly: true,
      alias,
      dataSourceKey: '',
      gridType: gridFormat,
      className: [rows[rowIndex].className].join(' '),
      format: weightingPercentFormat,
      dbDecimalPlaces: 10,
      useFormulaValue: true,
      linkedCellSymbols: undefined,
    };

    if (rowIndex === TABLE_HEADER_ROW) {
      cell = {
        ...cell,
        ...TABLE_HEADER_CONFIG,
        className: `${TABLE_HEADER_CONFIG.className} header-no-border-left`,
        style: { height: '62px' },
      };
    }

    if (rowIndexAux === PRESENT_SHARE_VALUES_ROW_NUMBER) {
      cell = {
        ...cell,
        ...BACKSOLVE_ROW_HEADER_CONFIG,
      };
    }

    if (cell.alias === ALLOCATION_METHOD_ALIAS) {
      cell = {
        ...cell,
        ...BOLD_ROW_HEADER_CONFIG,
        expr: 'Backsolve Total',
        valueViewer: null,
      };
    }

    // Prevent the cap table id from showing up in the total column
    if (cell.alias === CAP_TABLE_SELECTION_ALIAS) {
      cell = {
        ...cell,
        hidden: true,
        valueViewer: null,
      };
    }

    if (hasTotal && rowIndex !== TABLE_HEADER_ROW) {
      cell.expr = `=SUM(${sumExpr(rowIndexAux)})`;

      if (rowIndexAux > PRESENT_SHARE_VALUES_ROW_NUMBER) {
        cell.expr = `=SUM(${presentShareValuesExpr(rowIndexAux)})`;
        cell.format = smallCurrencyFormat;
        cell.gridType = 'number';
      }
    }

    totalsRow = {
      ...totalsRow,
      [key]: cell,
    };
  });
  return totalsRow;
};

export default totalParser;
