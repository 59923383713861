import { isNil } from 'lodash';
import { OPM } from 'common/constants/allocations';
import { GREATER_THAN, IS_REQUIRED_ERROR } from 'common/constants/messages/validations';

const customValidations = ({ cell: tmpCell, tableData, addFeedback, removeFeedback }) => {
  const backsolveApproachIncludesOPMMethodology = tableData.data.applied_methodologies.some(
    methodology => Number(methodology.allocation_method) === OPM
  );
  const { value, isRequiredIfOPM, greaterThanIfOPM } = tmpCell;
  if (
    backsolveApproachIncludesOPMMethodology
    && isRequiredIfOPM
    && (isNil(value) || value.toString().trim().length === 0)
  ) {
    addFeedback(IS_REQUIRED_ERROR);
  } else {
    removeFeedback(IS_REQUIRED_ERROR);
  }

  // Greater than zero, could be 0.1 or 0.01...
  if (
    backsolveApproachIncludesOPMMethodology
    && !isNil(greaterThanIfOPM)
    && Number(value) <= Number(greaterThanIfOPM)
  ) {
    addFeedback(GREATER_THAN(greaterThanIfOPM));
  } else {
    removeFeedback(GREATER_THAN(greaterThanIfOPM));
  }
};

export default customValidations;
