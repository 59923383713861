import React from 'react';
import { Cell } from 'common/types/scalarSpreadsheet';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import SelectValueViewer from 'components/SelectValueViewer/SelectValueViewer';
import { CAP_TABLE_SELECTION } from 'pages/Valuations/util/constants';
import {
  getCellValue,
  getCustomParser,
} from 'pages/ValuationsAllocation/approaches/FutureExit/AllocationMethodTable/utils/utils';
import {
  ALLOCATION_METHOD_OPTIONS,
  ALLOCATION_METHOD_OPTIONS_KEYS,
  AllocationMethodsMapKeys,
  SHEET_ALIASES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import { CapTableVersionSelector } from 'pages/ValuationsAllocation/components';
import { getObjectValue } from 'utillities';
import { CellParserParams, CustomParserParams } from './types';

const { FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD, FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE }
  = SHEET_ALIASES_CONSTANTS;

const cellParser = (params: CellParserParams) => {
  const { alphabet, colIndex, column, row, rowIndex } = params;

  const ROW_NUMBER = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + ROW_NUMBER;

  const { value: columnValue } = getObjectValue(column[row.alias]);

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  // Parse Cell based on Row alias
  switch (row.alias) {
    case FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD:
      cell.value
        = typeof columnValue === 'number'
          ? ALLOCATION_METHOD_OPTIONS_KEYS[columnValue as keyof AllocationMethodsMapKeys]
          : columnValue;
      cell.dbType = 'number';
      cell.gridType = 'string';
      cell.valueViewer = (props: any) => (
        <SelectValueViewer options={Object.values(ALLOCATION_METHOD_OPTIONS)} useLabel {...props} />
      );
      cell.dataEditor = (props: any) => (
        <GridSelect
          enumerated
          menuPosition="fixed"
          useHandleChange={false}
          options={Object.values(ALLOCATION_METHOD_OPTIONS)}
          {...props}
        />
      );
      cell.forceComponent = true;
      break;

    case FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE:
      cell.value = columnValue;
      cell.isRequired = true;
      cell.dbType = 'number';
      cell.gridType = 'number';
      cell.customKey = CAP_TABLE_SELECTION;
      cell.dataEditor = props => <CapTableVersionSelector {...props} />;
      cell.valueViewer = (props: any) => <SelectValueViewer {...props} />;
      cell.forceComponent = true;
      break;

    default:
      break;
  }

  const cellData = getCellValue(cell, columnLegend);

  return {
    [cell.key]: {
      ...cellData,
    } as Cell,
  };
};

const customParser = (params: CustomParserParams) => getCustomParser({ parserParams: params, cellParser });

export default customParser;
