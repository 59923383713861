/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import parser from 'pages/Valuations/approaches/discountCashFlow/dcfTerminalValue/util/getParser';
import {
  TVT_EM_TABLE_NAME,
  TVT_HM_TABLE_NAME,
  TVT_PG_TABLE_NAME,
  TVT_REM_TABLE_NAME,
  TVT_RM_TABLE_NAME,
} from 'pages/Valuations/approaches/discountCashFlow/utilities/constants';
import { TERMINAL_VALUE_OPTIONS } from 'pages/Valuations/util/constants';
import { getWaccDataApproach } from 'pages/Valuations/util/util';
import {
  extractSpecificApproachFromApproach,
  getApproachTableName,
  getCompaniesOrTransactions,
} from 'pages/ValuationsAllocation/util';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import getHModelConfig from './config/HModel/getConfig';
import getMultipleTerminalConfig from './config/MultipleTerminal/getConfig';
import getPerpetuityGrowthConfig from './config/PerpetuityGrowth/getConfig';
import getCombinedColumns from './config/RevEbitdaMultiple/getCombinedColumns';
import getRevEbitdaMultipleConfig from './config/RevEbitdaMultiple/getConfig';
import remReverseParser from './config/RevEbitdaMultiple/reverseParser';
import afterCellChanged from './util/afterCellChanged';
import getColumns from './util/getColumns';
import reverseParser from './util/reverseParser';

const terminalValueProps = {
  [TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH]: getPerpetuityGrowthConfig,
  [TERMINAL_VALUE_OPTIONS.H_MODEL]: getHModelConfig,
  [TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE]: getMultipleTerminalConfig,
  [TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE]: getMultipleTerminalConfig,
  [TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE]: getRevEbitdaMultipleConfig,
};

export const getTvtConfig = ({
  approach,
  approachOptions,
  mainTableName,
  tvtType,
  dcfWaccSheet,
  dcfTVTRMSheetName,
  dcfTVTEMSheetName,
  benchmarkApproach,
  isDisabled,
}) => {
  const { valuations_approach_dcf: valuationApproachDcf } = approach;

  const waccData = getWaccDataApproach(approach, 'valuations_approach_dcf');

  const dcfFinancialPeriod = valuationApproachDcf.dcf_financial_period?.length
    ? valuationApproachDcf.dcf_financial_period.reduce((sofar, next) => (next.year > sofar.year ? next : sofar))
    : undefined;

  const dcfFinancialPeriodWithDiscountPeriods = () => {
    if (dcfFinancialPeriod?.discount_periods) {
      const discountPeriodValue = Number(dcfFinancialPeriod.discount_periods) + 0.5;
      dcfFinancialPeriod.terminal_discount_periods = discountPeriodValue.toString();
    }
  };

  const isPeriodWithDiscountPeriods
    = tvtType === TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE
    || tvtType === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;

  // things from benchmark approach to read and select multiples
  const specificApproach = extractSpecificApproachFromApproach(benchmarkApproach);
  const selectionOptions = isEmpty(specificApproach)
    ? [VALUATIONS_OTHER_LABEL]
    : getSelectionCellOptions({
      isDCF: true,
      specificApproach,
    });
  const companiesRows = getCompaniesOrTransactions(specificApproach);
  const percentileSelections = {
    percentile_selection_a: specificApproach?.percentile_selection_a || 75,
    percentile_selection_b: specificApproach?.percentile_selection_b || 25,
  };
  const benchmarkType = benchmarkApproach.approach_type;

  const columnsProps = {
    valuationApproachDCF: valuationApproachDcf,
    dCFFinancialPeriod: isPeriodWithDiscountPeriods ? dcfFinancialPeriodWithDiscountPeriods() : dcfFinancialPeriod,
    waccData,
    mainTableName,
    dcfWaccSheet,
    dcfTVTRMSheetName,
    dcfTVTEMSheetName,
    options: selectionOptions,
    companiesRows,
    percentileSelections,
    approachOptions,
    benchmarkType,
    isDisabled,
  };

  const getColumnsFn = () =>
    tvtType === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE
      ? getCombinedColumns(columnsProps)
      : getColumns(columnsProps);
  const columns = getColumnsFn();

  const getConfig = terminalValueProps[tvtType || TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH];

  let spreadsheetProps = { columns, getColumns: getColumnsFn };

  if (getConfig) {
    spreadsheetProps = {
      ...spreadsheetProps,
      ...getConfig({
        columnsProps,
        ...(tvtType === TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE && { ebitda: true }),
      }),
      isDisabled,
    };
  }

  return spreadsheetProps;
};

export const createTVTPGData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_PG_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH;
  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customAfterCellChanged = (...params) => afterCellChanged(...params, approaches, approachOptions);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser,
    afterCellChanged: customAfterCellChanged,
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials',
    tableTerms: {
      tableSlug: 'dcf-terminal-value-pg-table',
    },
    fieldAttributes: dcfAttributes,
    ...spreadsheetProps,
    allowCopyColumn: false,
    allowReorderColumns: false,
  });
};

export const createTVTHMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_HM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.H_MODEL;

  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customAfterCellChanged = (...params) => afterCellChanged(...params, approaches, approachOptions);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser,
    afterCellChanged: customAfterCellChanged,
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials',
    tableTerms: {
      tableSlug: 'dcf-terminal-value-hm-table',
    },
    fieldAttributes: dcfAttributes,
    ...spreadsheetProps,
  });
};

export const createTVTRMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_RM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE;
  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customReverseParser = reverseParserParams =>
    reverseParser({
      ...reverseParserParams,
      approachOptions,
    });

  const customAfterCellChanged = (...params) => afterCellChanged(...params, approaches, approachOptions);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser: customReverseParser,
    afterCellChanged: customAfterCellChanged,
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials',
    tableTerms: {
      tableSlug: 'dcf-terminal-value-rm-table',
    },
    fieldAttributes: dcfAttributes,
    ...spreadsheetProps,
  });
};

export const createTVTEMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_EM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE;

  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customReverseParser = reverseParserParams =>
    reverseParser({
      ...reverseParserParams,
      approachOptions,
    });

  const customAfterCellChanged = (...params) => afterCellChanged(...params, approaches, approachOptions);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser: customReverseParser,
    afterCellChanged: customAfterCellChanged,
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials',
    tableTerms: {
      tableSlug: 'dcf-terminal-value-em-table',
    },
    fieldAttributes: dcfAttributes,
    ...spreadsheetProps,
  });
};

export const createTVTREMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  dcfTVTRMSheetName,
  dcfTVTEMSheetName,
  dcfAttributes,
  benchmarkApproach,
  name: tableName,
  isDisabled,
}) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_REM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;

  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    dcfTVTRMSheetName,
    dcfTVTEMSheetName,
    benchmarkApproach,
    isDisabled,
  });

  const customReverseParser = reverseParserParams =>
    remReverseParser({
      ...reverseParserParams,
      approachOptions,
    });

  const customAfterCellChanged = (...params) => afterCellChanged(...params, approaches, approachOptions);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser: customReverseParser,
    afterCellChanged: customAfterCellChanged,
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials',
    tableTerms: {
      tableSlug: 'dcf-terminal-value-rem-table',
    },
    fieldAttributes: dcfAttributes,
    ...spreadsheetProps,
  });
};
