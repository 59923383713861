import { ValuationsApproach } from 'common/types/valuation';

const publicCompsOrTransactionsExists = (approaches: ValuationsApproach[]) =>
  Boolean(
    approaches.filter(
      ({ valuations_approach_gpt, valuations_approach_gpc }) => valuations_approach_gpc || valuations_approach_gpt
    ).length
  );

export default publicCompsOrTransactionsExists;
