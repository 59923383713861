import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { MISSING_SECURITIES, MISSING_SECURITIES_MESSAGE } from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import LedgerContext from 'context/LedgerContext';
import { EmptyCapTableMessage } from 'pages/CapTable/cap-table/components';
import { getEditableSecurityValues } from 'pages/Valuations/approaches/backsolveApproach/BacksolveApproach';

const SecurityTable = ({
  spreadsheets,
  onChange,
  setPrevEditableSecurityValues,
  securities,
  securityList,
  deleteRowFn,
  isLedgerTable,
}) => {
  const [format] = useFormat({ page: CAP_TABLE_CURRENCY_PAGE, units: REGULAR_UNIT });

  const { securitySheet } = spreadsheets;
  const { isDisabled } = securitySheet.tableData;
  const onWorkbookChange = useCallback(
    (cell, value) => {
      const rowIndex = Number(cell.key[1]) - 2;
      const auxSecurities = [...securities];
      // to set previous securities values with latest change
      if (cell.alias === 'shares') {
        auxSecurities[rowIndex].shares = value;
      }
      setPrevEditableSecurityValues(getEditableSecurityValues(auxSecurities));
      onChange(cell, value);
    },
    [onChange, securities, setPrevEditableSecurityValues]
  );
  const onCellsChanged = useCallback(
    changes => {
      onWorkbookChange(changes[0].cell, changes[0].value);
    },
    [onWorkbookChange]
  );

  const LedgerContextData = useMemo(
    () => ({
      onCellsChange: onCellsChanged,
      cells: securitySheet.cells,
    }),
    [onCellsChanged, securitySheet]
  );

  if (securityList && securityList.length === 0) {
    return <EmptyCapTableMessage title={MISSING_SECURITIES} tagline={MISSING_SECURITIES_MESSAGE} />;
  }
  return (
    <LedgerContext.Provider value={LedgerContextData}>
      <ScalarSpreadsheet
        {...securitySheet}
        id="security-table"
        className={securitySheet.name}
        onChange={onWorkbookChange}
        sheet={securitySheet}
        deleteRowFn={deleteRowFn}
        displayDeleteRowBtn={!isDisabled}
        tableTerms={{ tableSlug: 'security-table' }}
        alwaysDisplayLegend={false}
        isLedgerTable={isLedgerTable}
        format={format}
      />
    </LedgerContext.Provider>
  );
};

export default SecurityTable;

SecurityTable.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  deleteRowFn: PropTypes.func,
  setPrevEditableSecurityValues: PropTypes.func,
  securities: PropTypes.array,
  securityList: PropTypes.array,
  isLedgerTable: PropTypes.bool,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
};
