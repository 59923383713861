import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import type { DocumentReferencesView } from 'api';
import { ITheme } from '../../../../theme/types';
import { DropProps } from '../CurrentRequestedDocumentsAndQuestions/types';

const useStyles = makeStyles((theme: ITheme) => ({
  dragOver: {
    outline: `1.5px dashed ${theme.palette.primary.main}`,
  },
}));

export const useDragRow = (setDraggedRow: (row: DocumentReferencesView | null) => void) => {
  const handleDragStart = useCallback(
    (event: React.DragEvent<HTMLTableRowElement>, row: DocumentReferencesView) => {
      setDraggedRow(row);
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.effectAllowed = 'move';
    },
    [setDraggedRow]
  );

  const handleDragEnd = useCallback(() => {
    setDraggedRow(null);
  }, [setDraggedRow]);

  return { handleDragStart, handleDragEnd };
};

export const useDropRow = (props: DropProps) => {
  const { draggedRow, addFilesToFolder, refreshDocuments } = props;
  const classes = useStyles();
  const handleDragOver = (event: React.DragEvent<HTMLTableRowElement | HTMLTableSectionElement>) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLTableRowElement | HTMLTableSectionElement>) => {
      event.currentTarget.classList.remove(classes.dragOver);
    },
    [classes]
  );

  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLTableRowElement | HTMLTableSectionElement>, folderId?: number) => {
      event.preventDefault();
      if (draggedRow?.document_reference_id) {
        const data = {
          folder_id: folderId,
          document_references_ids: [draggedRow?.document_reference_id],
        };
        handleDragLeave(event);
        await addFilesToFolder(draggedRow.md_documents, data, true);
        refreshDocuments();
      }
    },
    [draggedRow, addFilesToFolder, refreshDocuments, handleDragLeave]
  );

  return { handleDragOver, handleDragLeave, handleDrop };
};
