import React from 'react';
import { fundOwnershipUrl, fundSummaryUrl } from 'common/config/urls';
import { Row, Rows, RowWithCategory } from 'common/types/scalarSpreadsheet';
import { ValueWithLinkViewer } from 'components';
import {
  COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
  COMPANY_SUMMARY_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Portfolio/common/constants/companySummary';
import { createColumnKeyName } from 'pages/Portfolio/pages/CompanySummary/config/createColumns';
import { generateColumnKey, getArrayValue, getObjectValue, getStringValue } from 'utillities';
import { CreateSecuritiesRowsParams, RowConfigParams, RowData } from './types';
import {
  COMPANY_SUMMARY_SPREADSHEET_FUND_TITLE,
  COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL,
} from '../../../../common/constants/companySummary/sheetAliases';

const createSecuritiesRows = (params: CreateSecuritiesRowsParams): RowWithCategory[] => {
  const { companyUrl, fundSlug, investments: securities } = params;

  return getArrayValue(
    securities?.map(security => {
      const securityName = getStringValue(security?.security_name);

      return {
        alias: generateColumnKey({
          id: security.id,
          name: createColumnKeyName({ name: securityName, slug: fundSlug }),
          prefix: COMPANY_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
        }),
        gridType: 'string',
        readOnly: true,
        category: `security_${fundSlug}-${securityName}`,
        value: <ValueWithLinkViewer name={securityName} url={companyUrl} />,
      } as RowWithCategory;
    })
  );
};

const getTitlesRows = (params: Rows): Rows => {
  const titleParams = params.filter(fund => !fund.alias.includes('security_'));

  return titleParams;
};

// Function to create the list with parent-child relationship and add 'value' field
const createRowsFromInvestments = (params: RowWithCategory[]): Rows => {
  const dataParams = params.filter(fund => fund.alias.includes('security_'));

  const newRowsList: { [key: string]: Row } = {};

  const newParams = [...dataParams];

  dataParams.forEach((item, index) => {
    const { value, category } = item;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newValue = value?.props?.name ?? value;
    const newAlias = category ?? '';
    newRowsList[newAlias] = {
      alias: newAlias,
      value: newValue,
      gridType: item.gridType,
      hasSubRows: true,
      hidden: false,
      allowNegativeValue: true,
      readOnly: true,
      showSubRows: true,
    } as Row;
    newParams[index] = { ...item, parent: newAlias, style: { paddingLeft: '1rem' } };
  });

  // Combine both arrays
  return [...Object.values(newRowsList), ...newParams];
};

function organizeRows(parentSonRows: RowWithCategory[], titles: RowWithCategory[]): RowWithCategory[] {
  const organizedRows: RowWithCategory[] = [];

  // Group parentSonRows by their parent (for child rows) and alias (for parent rows)
  const parentGroups: { [parentAlias: string]: RowWithCategory[] } = {};
  const childGroups: { [parentAlias: string]: RowWithCategory[] } = {};

  parentSonRows.forEach(row => {
    if (row.hasSubRows) {
      const rowValue = row.value?.toString() ?? '';
      const alias = row?.alias?.toString().replace(rowValue, '') ?? '';
      // Parent rows
      if (!parentGroups[alias]) {
        parentGroups[alias] = [];
      }
      parentGroups[alias].push(row);
    } else {
      const category = row?.category ?? '';
      // Child rows (sons)
      if (!childGroups[category]) {
        childGroups[category] = [];
      }
      childGroups[category].push(row);
    }
  });

  const titleRows = titles.filter(row => row.alias === COMPANY_SUMMARY_SPREADSHEET_FUND_TITLE);
  const totalRows = titles.filter(row => row.alias === COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL);

  // Now iterate over each title and organize it with its parent and child rows
  titleRows.forEach(titleRow => {
    organizedRows.push(titleRow);

    // Find parents related to the title's category

    const relevantParents = parentSonRows.filter(row => {
      const rowValue = row.value?.toString() ?? '';
      const parentAlias = row.alias.replace(`-${rowValue}`, ''); // Remove the title alias part from the row alias to get the "parent"
      return parentAlias === titleRow.category && row.hasSubRows === true; // This checks if the row alias matches the title
    });

    relevantParents.forEach(parentRow => {
      organizedRows.push(parentRow);
      const rowAlias = parentRow.alias?.toString() ?? '';
      const children = childGroups[rowAlias] || [];
      children.forEach(childRow => organizedRows.push(childRow));
    });

    // Add total row if exists in parentSonRows under this title's category
    const totalRow = totalRows.find(row => row.category === titleRow.category);
    if (totalRow) organizedRows.push(totalRow);
  });

  return organizedRows;
}

function rearrangeItems(items: Rows): Rows {
  const parents: Rows = [];
  const children: Rows = [];

  // Separate parents and children
  items.forEach(item => {
    if (item.hasSubRows) {
      parents.push(item);
    } else {
      children.push(item);
    }
  });

  // Create a map to easily find parents by their alias
  const parentMap: { [key: string]: Row } = {};
  parents.forEach(parent => {
    parentMap[parent.alias] = parent;
  });

  // Create a new array to hold the rearranged items
  const rearrangedItems: Rows = [];

  // Add parents first, followed by their respective children
  parents.forEach(parent => {
    rearrangedItems.push(parent);
    // Find children for this parent
    children.forEach(child => {
      if (child.parent === parent.alias) {
        rearrangedItems.push(child);
      }
    });
  });

  return rearrangedItems;
}

const rowConfig = (params: RowConfigParams): RowWithCategory[] => {
  const { companySlug, firmSlug, funds, queryParams } = params;

  // Get Company Fund Ownership URL
  const companyUrl = `${fundOwnershipUrl(firmSlug, companySlug)}${queryParams}`;

  // Get Funds Securities
  const fundsSecurities = getArrayValue(
    funds?.reduce((accumulator, current) => {
      const { fund_name: fundName, fund_slug: currentFundSlug, investments } = getObjectValue(current);

      const fundSlug = getStringValue(currentFundSlug);
      const fundUrl = `${fundSummaryUrl(firmSlug, fundSlug)}${queryParams}`;

      const currentFund = [
        // Fund Title
        {
          alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FUND_TITLE,
          className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-fund-title-header subheader-division`,
          gridType: 'string',
          hidden: true,
          readOnly: true,
          category: `security_${fundSlug}`,
          value: <ValueWithLinkViewer name={getStringValue(fundName)} isDarkMode url={fundUrl} />,
        } as RowWithCategory,
        // Fund Securities
        ...createSecuritiesRows({ companyUrl, fundSlug, investments }),
        // Fund Total
        {
          alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL,
          className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-fund-total subtotal-division`,
          data: { fund: fundSlug },
          format: null,
          gridType: 'string',
          readOnly: true,
          category: `security_${fundSlug}`,
          value: SHEET_TITLES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL,
        } as RowWithCategory<RowData>,
      ];

      return [...accumulator, ...currentFund];
    }, [] as RowWithCategory[])
  );

  const titlesRows = getTitlesRows(fundsSecurities);
  const updatedFundsSecurities = createRowsFromInvestments(fundsSecurities);
  const securitiesOrdered = rearrangeItems(updatedFundsSecurities);
  const securitiesGroupedByFund = organizeRows(securitiesOrdered, titlesRows);

  return [
    // Header title
    {
      alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_HEADER_TITLE,
      className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-header division-bottom-only`,
      gridType: 'string',
      largeHeader: true,
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_HEADERS[0].value,
    } as Row,
    // Fund Securities
    ...securitiesGroupedByFund,
    // Firm Total
    {
      alias: SHEET_ALIASES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FIRM_TOTAL,
      className: `${COMPANY_SUMMARY_SPREADSHEET_TABLE}-firm-total division`,
      format: null,
      gridType: 'string',
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.COMPANY_SUMMARY_SPREADSHEET_FIRM_TOTAL,
    } as Row,
  ];
};

export default rowConfig;
