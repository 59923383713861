import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CropFree as CropFreeIcon } from '@material-ui/icons';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';
import { Dialog } from 'components/Dialogs';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { formatNumbers } from 'utillities';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  addDateButton: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  dialogWidget: {
    display: 'flex',
  },
  dialogIcon: {
    width: '16px !important',
    height: '20px !important',
    fill: `${theme.palette.cellIconColor} !important`,
  },
}));

const cellPropTypes = PropTypes.shape({
  alias: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.instanceOf(undefined)]),
  columnId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnLegend: PropTypes.string,
  columnOrder: PropTypes.number,
  columnRef: PropTypes.string,
  component: PropTypes.shape({}),
  dataSourceKey: PropTypes.string,
  dbType: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dependencies: PropTypes.instanceOf(Set),
  dialog: PropTypes.shape({ content: PropTypes.shape({}), maxWidth: PropTypes.string }),
  disableBackdropClick: PropTypes.bool,
  disabled: PropTypes.bool,
  disableEvents: PropTypes.bool,
  disableWhenLedgerOpen: PropTypes.bool,
  evaluated: PropTypes.bool,
  expr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  forceComponent: PropTypes.bool,
  format: PropTypes.shape({}),
  hasTotal: PropTypes.bool,
  hidden: PropTypes.bool,
  invalidExpr: PropTypes.bool,
  investmentDates: PropTypes.string,
  isExpr: PropTypes.bool,
  key: PropTypes.string,
  linkedCells: PropTypes.instanceOf(Set),
  linkedCellSymbols: PropTypes.instanceOf(Set),
  providers: PropTypes.instanceOf(Set),
  readOnly: PropTypes.bool,
  relatedSecurity: PropTypes.number,
  sheet: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueViewer: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
});

const CellContent = props => {
  const { cell, WrappedComponent } = props;
  const { format } = useContext(FeaturedSpreadsheetContext);

  const cellFormat = useMemo(() => {
    if (format && format !== cell.format) {
      return format;
    }
    return {};
  }, [format, cell.format]);

  if (cell.valueViewer) return <WrappedComponent {...props} />;

  return cell.component
    ? React.cloneElement(cell.component, { cell }, null)
    : formatNumbers({ ...cell, ...cellFormat });
};

const withDialog = WrappedComponent => {
  const WithDialog = props => {
    const classes = useStyles();
    const customDialogRef = useRef(uuid());
    const { setDialogFlag } = useDataSheetDialogActions();
    const [open, setOpen] = useState(false);
    const {
      cell,
      cell: { disableBackdropClick: disableclick, key },
    } = props;

    const disableBackdropClick = !isUndefined(disableclick) ? disableclick : true;

    useEffect(() => {
      const dialogId = customDialogRef.current;
      if (cell.key && cell.dialog) {
        setDialogFlag({
          open: true,
          dialogId,
          cellId: cell.key,
          disableWhenLedgerOpen: false,
          alias: cell.alias,
        });
      }
      return () => {
        if (setDialogFlag && cell.key && cell.dialog) {
          setDialogFlag({ open: false, dialogId, cellId: null });
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDialog = () => {
      setOpen(true);
    };

    const closeDialog = () => {
      setOpen(false);
    };

    const disableDialog = useMemo(() => cell.dbType === 'boolean' && !Number(cell.value), [cell.dbType, cell.value]);

    if (isEmpty(cell.dialog)) {
      return <WrappedComponent {...props} />;
    }

    return (
      <div className={`dialog ${classes.root}`}>
        <div className="dialog__value">
          <span className="value-viewer">
            <CellContent cell={cell} WrappedComponent={WrappedComponent} />
          </span>
        </div>
        <div className={classes.dialogWidget}>
          {!disableDialog && (
            <IconButton disabled={cell.disabled} onClick={openDialog} size="small" color="primary">
              <CropFreeIcon
                id={`${key}-cell_icon-dialog`}
                className={classes.dialogIcon}
                size="small"
                color="secondary"
              />
            </IconButton>
          )}

          {open && (
            <Dialog
              open={open}
              customDialogRef={customDialogRef}
              className="ledger-dialog"
              onClose={closeDialog}
              aria-labelledby="form-dialog-title"
              PaperProps={{
                square: true,
              }}
              hideTitle
              dialogProps={{
                disableBackdropClick,
                disableEscapeKeyDown: true,
                disableTitleClose: true,
                cellKey: cell.key,
                disableWhenLedgerOpen: cell.disableWhenLedgerOpen,
              }}
              maxWidth={cell?.dialog?.maxWidth || 'md'}>
              {cell.dialog.content
                && React.cloneElement(cell.dialog.content, { closeDialog, cell, className: 'read-only--white' }, null)}
            </Dialog>
          )}
        </div>
      </div>
    );
  };

  WithDialog.propTypes = {
    cell: cellPropTypes.isRequired,
  };

  return WithDialog;
};

CellContent.propTypes = {
  cell: cellPropTypes.isRequired,
  WrappedComponent: PropTypes.elementType.isRequired,
};

export default withDialog;
