import {
  EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS,
  EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_TOTAL_ALIAS,
  EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS,
  EBIT_CAPITAL_EXPENSES_TOTAL_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_TOTAL_ALIAS,
  REVENUE_GROWTH_ALIAS,
  REVENUE_TOTAL_ALIAS,
} from 'pages/Valuations/util/constants';

export const ROW_WITH_PAIRS = [
  REVENUE_TOTAL_ALIAS,
  REVENUE_GROWTH_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_TOTAL_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_TOTAL_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS,
  EBIT_CAPITAL_EXPENSES_TOTAL_ALIAS,
  EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS,
  EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ALIAS,
  EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS,
];

export const percentagesAlias = [
  EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS,
  EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS,
];

export const TVTREM_KEY = 'dcfTVTREM';
export const TVTRM_KEY = 'dcfTVTRM';
export const TVTEM_KEY = 'dcfTVTEM';
export const BENCHMARK_PERCENTILE_A = 'benchmark.percentile_selection_a';
export const BENCHMARK_PERCENTILE_B = 'benchmark.percentile_selection_b';
