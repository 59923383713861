import moment from 'moment';
import { getObjectValue, toString } from 'utillities';
import { ColumnsData, CreateColumnsParams, Period } from './types';

const FIRST_COLUMN_INDEX = 0;

const createColumns = (params: CreateColumnsParams): ColumnsData[] => {
  const { approach, thresholdDate } = params;
  const valuationsApproachDcf = getObjectValue(approach.valuations_approach_dcf);
  const periods = (valuationsApproachDcf.dcf_financial_period as Period[]) ?? [];
  const auxCols: ColumnsData[] = [];
  periods.forEach((period: Period, index: number) => {
    const { discount_factor, discount_periods, ebit_total, financial_statement_period } = period;
    const date = financial_statement_period ? moment(financial_statement_period.statement_date) : moment(period.date);
    const year = date.year();
    auxCols.push({
      discount_factor,
      discount_periods,
      ebit_total,
      year,
      date: toString(date),
      nol_ebit_pre_tax_cut: index === FIRST_COLUMN_INDEX ? valuationsApproachDcf.nol_ebit_pre_tax_cut : 0,
      nol_ebit_post_tax_cut: index === FIRST_COLUMN_INDEX ? valuationsApproachDcf.nol_ebit_post_tax_cut : 0,
    });
  });
  const filteredColumns = auxCols.filter(({ date }) => moment(date).isSameOrAfter(thresholdDate, 'day')) || [];
  return filteredColumns;
};

export default createColumns;
