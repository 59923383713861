import moment from 'moment';
import { SORT_DESC } from 'pages/Valuations/util/constants';
import { getNumberValue } from 'utillities/getNumberValue';
import { getStringValue } from 'utillities/getStringValue';

type SortOrder = 'asc' | 'desc';
type SortFunction<T> = (a: T, b: T, key: keyof T, sortOrder: SortOrder) => number;

export const sortByDate: SortFunction<Record<string, string>> = (a, b, key, sortOrder) => {
  try {
    const dateA = moment(a?.[key]).toDate();
    const dateB = moment(b?.[key]).toDate();
    return sortOrder === SORT_DESC ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
  } catch (error) {
    return 0;
  }
};

export const sortByString: SortFunction<Record<string, string>> = (a, b, key, sortOrder) => {
  try {
    const stringA = getStringValue(a?.[key]);
    const stringB = getStringValue(b?.[key]);
    return sortOrder === SORT_DESC ? stringB.localeCompare(stringA) : stringA.localeCompare(stringB);
  } catch (error) {
    return 0;
  }
};

export const sortByNumber: SortFunction<Record<string, number>> = (a, b, key, sortOrder) => {
  try {
    const numberA = getNumberValue(a?.[key]);
    const numberB = getNumberValue(b?.[key]);
    return sortOrder === SORT_DESC ? numberB - numberA : numberA - numberB;
  } catch (error) {
    return 0;
  }
};
