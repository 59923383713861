/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import ApproachesList from './ApproachesList';
import CalibrationGridDate from './CalibrationGridDate';
import FinancialVersionsList from './FinancialVersionsList';
import * as CALIBRATION from '../../../../../common/constants/calibration';
import { largeCurrencyFormat } from '../../../../../common/formats/formats';
import { SelectValueViewer } from '../../../../../components';
import {
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
} from '../../../../ValuationsAllocation/common/constants/valuationSummary/sheetAliases';
import { rowConfigInputsParams } from '../performance_metrics/config/rowConfig/types';

interface ApproachesListProps {
  cell: {
    value: any;
    key: any;
  };
  columnsPerformanceCalibration: any;
  rowsPerformanceCalibration: any;
  [key: string]: any;
}

const rowConfigCalibrationInputs = (params: rowConfigInputsParams): Rows => {
  const {
    financialStatementsList,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    readOnlyData,
    isDisabled,
  } = params;
  return [
    {
      alias: '',
      className: 'table-header full-width-label',
      readOnly: true,
      value: 'Inputs table',
      columnSpan: 2,
      gridType: 'string',
      rowSpan: 1,
      ignoreRowCopy: true,
      isTitleOrHeader: true,
    } as Row,
    {
      alias: 'calibration_date',
      readOnly: isDisabled,
      value: 'Calibration Date',
      dbType: 'string',
      gridType: 'gridDate',
      className: 'row-label-indented',
      isRequired: true,
      // @ts-ignore: gridDateComponent is not typed
      gridDateComponent: (props: unknown) => <CalibrationGridDate {...props} />,
      // @ts-ignore: dataEditor is not typed
      dataEditor: (props: unknown) => <GridDateEditor {...props} />,
      // @ts-ignore: valueViewer is not typed
      valueViewer: props => <GridDateValueViewer {...props} />,
    } as unknown as Row,
    {
      alias: 'transaction_allocation_method',
      readOnly: isDisabled,
      value: 'Transaction Allocation Methodology',
      gridType: 'string',
      ignoreRowCopy: true,
      className: 'row-label-indented',
    } as Row,
    {
      alias: 'enterprise_value_inputs',
      readOnly: isDisabled,
      value: 'Enterprise Value',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
      readOnly: readOnlyData,
      value: 'Plus Cash',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
    } as Row,
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
      readOnly: readOnlyData,
      value: '(Less Debt)',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
    } as Row,
    {
      alias: 'equity_value_inputs',
      readOnly: isDisabled,
      value: 'Equity Value',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
    } as Row,
    {
      alias: 'empty',
      readOnly: true,
      value: '',
      ignoreRowCopy: true,
      className: 'row-label-indented',
      style: { visibility: 'hidden', border: 'none' },
    } as Row,
    {
      value: 'Public Comps Approach',
      alias: CALIBRATION.PUBLIC_COMPS_APPROACH,
      readOnly: isDisabled,
      className: 'row-label-indented',
      isRequired: true,
      // @ts-ignore: dataEditor is not typed
      dataEditor: (props: ApproachesListProps) => (
        <ApproachesList
          {...props}
          columnsPerformanceCalibration={columnsPerformanceCalibration}
          rowsPerformanceCalibration={rowsPerformanceCalibration}
        />
      ),
      // @ts-ignore: valueViewer is not typed
      valueViewer: (props: unknown) => <SelectValueViewer {...props} />,
    } as unknown as Row,
    {
      alias: CALIBRATION.FINANCIALS_VERSION_CALIBRATION,
      value: 'Financials Version',
      readOnly: isDisabled,
      className: 'row-label-indented',
      // @ts-ignore: dataEditor is not typed
      dataEditor: props => <FinancialVersionsList {...props} options={financialStatementsList} />,
      // @ts-ignore: valueViewer is not typed
      valueViewer: props => <SelectValueViewer {...props} options={financialStatementsList} />,
    } as unknown as Row,
    {
      alias: 'ltm_revenue_inputs',
      readOnly: readOnlyData,
      value: 'LTM Revenue',
      ...CALIBRATION.commonInputFields,
    } as Row,
    {
      alias: 'ntm_revenue_inputs',
      readOnly: readOnlyData,
      value: 'NTM Revenue',
      ...CALIBRATION.commonInputFields,
    } as Row,
    {
      alias: 'ltm_ebitda_inputs',
      readOnly: readOnlyData,
      value: 'LTM EBITDA',
      ...CALIBRATION.commonInputFields,
    } as Row,
    {
      alias: 'ntm_ebitda_inputs',
      readOnly: readOnlyData,
      value: 'NTM EBITDA',
      ...CALIBRATION.commonInputFields,
    } as Row,
  ];
};

export default rowConfigCalibrationInputs;
