/* eslint-disable import/prefer-default-export */
// General
export const FINANCIAL_TITLE = 'Financials';
export const FINANCIAL_SLUG = 'financials';

export const FIRST_ROW_TITLE = 'Projections';
export const FIRST_ROW_ALIAS = 'title';
export const FIRST_ROW_ROW_NUMBER = 1;

export const FISCAL_YEAR_PREFIX = 'FY';
export const FORMAT_YEAR_TITLE = year => `${FISCAL_YEAR_PREFIX} ${year}`;

export const SECOND_ROW_TITLE = 'Quarter';
export const SECOND_ROW_ALIAS = 'subtitle';
export const SECOND_ROW_ROW_NUMBER = 2;

export const THIRD_ROW_TITLE = 'Date';
export const THIRD_ROW_ALIAS = 'date';
export const THIRD_ROW_ROW_NUMBER = 3;

export const FOURTH_ROW_ROW_NUMBER = 4;

export const PAGES_USE_FINANCIALS_CURRENCY = ['financials', 'valuations'];

export const INCOME_STATEMENT_SHEET_NAME = 'incomeStatement';
export const BALANCE_SHEET_NAME = 'balanceSheet';

export const INCOME_STATEMENT_PROP = 'income_statement';
export const BALANCE_SHEET_PROP = 'balance_sheet';

export const COLUMN_UPDATED = 'COLUMN UPDATED';
export const COLUMN_UPDATED_ACTION_TITLE
  = 'Previously projected financials need to be updated with reported financials.';

export const NO_FINANCIAL_STATEMENT = 'Unable to retrieve a financial statement';

export const HISTORICAL = 'Add Historical Year';
export const PROJECTED = 'Add Projection Year';

export const VALUE_MANUALLY_INPUTTED = 'Value was manually inputted. Click to calculate the sum of all quarters.';
export const SUM_QUARTERS_FOR_ROW_ACTION_MESSAGE
  = 'Value was manually input. Click to calculate the sum of all quarters for this row.';
export const SUM_QUARTERS_ACTION_MESSAGE = 'Values were manually input. Click to calculate the sum of all quarters.';

export const SHOW_ADDITIONAL_HISTORICAL_YEARS = 'Additional Historical Years:';
export const HIDE_ADDITIONAL_HISTORICAL_YEARS = 'Earliest historical years can be hidden:';
export const SHOW_BTN = 'SHOW ALL';
export const HIDE_BTN = 'HIDE';

export const PRIMARY_FINANCIAL_LABEL = 'Primary Financial Statement';
