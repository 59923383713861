import calculateTotalByAcquisitionRef from './calculateTotalByAcquisitionRef';
import calculateTotalFromCellByAlias from './calculateTotalFromCellByAlias';
import { AMOUNT_ALIAS, SOLD_PERCENT_ALIAS } from '../constants';

const calculateConvertibleNoteOutstandingFromCells = ({
  purchasedCells,
  soldCells,
  convertedCells,
  loanNote,
  loanValueRatio,
}) => {
  const purchasedValues = purchasedCells ? Object.values(purchasedCells) : [];
  const soldValues = soldCells ? Object.values(soldCells) : [];
  const convertedValues = convertedCells ? Object.values(convertedCells) : [];

  const totalAmountPurchased = calculateTotalFromCellByAlias(AMOUNT_ALIAS, purchasedValues);
  const totalAmountSold = calculateTotalFromCellByAlias(AMOUNT_ALIAS, soldValues);
  const totalAmountConverted = calculateTotalFromCellByAlias(AMOUNT_ALIAS, convertedValues);

  let balance = totalAmountPurchased - totalAmountSold - totalAmountConverted;
  const outstandingValues = {
    sharesOutstanding: 0,
    costBasisOutstanding: 0,
    amountOutstanding: balance,
    outstandingLoanValue: 0,
    totalInvested: totalAmountPurchased,
  };
  if (loanNote) {
    const accumulatedSoldPercent = calculateTotalByAcquisitionRef(SOLD_PERCENT_ALIAS, soldValues);
    balance = purchasedValues
      .filter(({ alias }) => alias === AMOUNT_ALIAS)
      .reduce(
        (acc, { value, acquisition_ref }) => acc + Number(value) * (1 - (accumulatedSoldPercent[acquisition_ref] ?? 0)),
        0
      );
    const totalLoan = balance >= loanNote.note_principle_amount ? loanNote.loan_value : balance * loanValueRatio;
    outstandingValues.outstandingLoanValue = totalLoan;
    outstandingValues.amountOutstanding = balance;
  }
  return outstandingValues;
};

export default calculateConvertibleNoteOutstandingFromCells;
