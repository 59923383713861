import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'common/store';
import { FormDialog } from 'components';
import { CapTableForm } from 'components/Forms';
import { useUpdateCaptableInfo } from 'services/hooks/captable';

const CapTableDialog = ({ open, onClose, onSave }) => {
  const [, updateCapTableData] = useUpdateCaptableInfo(null, null);
  const [{ captableInfo }] = useStore();
  const [formState, setFormState] = useState({
    values: {},
    errors: {},
  });

  useEffect(() => {
    if (captableInfo) {
      setFormState(prevFormState => ({
        ...prevFormState,
        values: { name: captableInfo.name },
      }));
    }
  }, [captableInfo]);

  const saveData = async () => {
    const temporalCaptableInfo = { ...captableInfo, name: formState.values.name };
    await updateCapTableData(captableInfo.id, temporalCaptableInfo);
    onSave();
  };

  return (
    <FormDialog
      open={open}
      title={captableInfo.name}
      onSave={saveData}
      onClose={onClose}
      isValid={formState.isValid}
      editMode>
      <CapTableForm defaultFormState={formState} onFormChange={prevFormState => setFormState(prevFormState)} />
    </FormDialog>
  );
};

CapTableDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onSave: PropTypes.func,
};

export default CapTableDialog;
