import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import parser from 'pages/Valuations/approaches/backsolveApproach/BacksolveSummaryTable/util/parser';
import rowConfig from 'pages/Valuations/approaches/backsolveApproach/BacksolveSummaryTable/util/rowConfig';
import reverseParser from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/reverseParser';
import { BACKSOLVE_TABLE_NAMES } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';

const createBacksolveSummaryData = ({ approach, attrs, isDisabled, financials }) => {
  const name = getApproachTableName({ approach, tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE_SUMMARY });
  const getColumns = () => [[], approach.valuations_approach_backsolve];

  return new SpreadsheetConfig({
    name,
    parser,
    reverseParser,
    rowConfig,
    tableData: { approach, isDisabled, primaryFinancials: financials },
    columns: getColumns(),
    showTitlesColumn: false,
    showToolbar: true,
    fieldAttributes: attrs,
    getColumns,
  });
};

export default createBacksolveSummaryData;
