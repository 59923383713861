import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { SELECT_MULTIPLE_ALIASES } from 'pages/Valuations/util/constants';
import { determineDatabaseOption } from 'pages/Valuations/util/percentileOptionsTools';

const getSelectionFromTVT = (cell, value, multipleOptions) => {
  const { alias, percentileSelections } = cell;
  if (SELECT_MULTIPLE_ALIASES.includes(alias)) {
    // if there is only one option it means there isn't a benchmark approach to read multiples from and the only choice is "Other".
    // if the value is the last option, it means it is "Other" (always the last option regardless of the number of multiples available)
    if (multipleOptions.length === 1 || Number(value) === multipleOptions.length - 1) return VALUATIONS_OTHER_LABEL;
    return determineDatabaseOption({ value, options: multipleOptions }, percentileSelections);
  }
  return '';
};

export default getSelectionFromTVT;
