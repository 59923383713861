import React, { useCallback, useMemo } from 'react';
import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';
import { CurrentRequestedDocumentTableFolderRowProps } from './types';
import { FolderView } from '../../../../api';
import ExtraActionsMenu from '../../../../components/ExtraActionsMenu';
import theme from '../../../../theme';
import { useDropRow } from '../hooks/useDragAndDropRow';
import useTableActionStyles from '../styles/useTableActionStyles';

const useStyles = makeStyles((localTheme: ITheme) => ({
  folder: {
    color: localTheme?.palette?.primary?.main,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  folderText: {
    color: localTheme?.palette?.primary?.main,
    fontWeight: 'bold',
  },
  icon: {
    marginRight: localTheme.spacing(1),
  },
  hiddenRow: {
    display: 'none',
  },
  clickableRow: {
    cursor: 'pointer',
  },
}));

const CurrentRequestedDocumentTableFolderRow = (props: CurrentRequestedDocumentTableFolderRowProps) => {
  const classes = useStyles();
  const actionClasses = useTableActionStyles();
  const {
    folder,
    refreshDocuments,
    addFilesToFolder,
    draggedRow,
    toggleFolder,
    isFolderOpen,
    showFolder,
    setSelectedFolder,
  } = props;

  const { handleDragOver, handleDrop, handleDragLeave } = useDropRow({
    draggedRow,
    addFilesToFolder,
    refreshDocuments,
  });

  const folderName = useMemo(() => {
    if (folder.parent_folder) {
      return `${folder.parent_folder.name} / ${folder.name}`.toUpperCase();
    }
    return folder.name.toUpperCase();
  }, [folder]);

  const fileOptionsForExtraActionsMenu = useCallback(
    (row: FolderView) => [
      {
        label: 'Edit Folder',
        callback: () => {
          setSelectedFolder(row);
        },
        isActive: true,
      },
    ],
    [setSelectedFolder]
  );

  return (
    <TableRow
      onDrop={event => handleDrop(event, folder?.id ?? 0)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      className={showFolder ? '' : classes.hiddenRow}>
      <TableCell className={`${classes.clickableRow}`} colSpan={5} onClick={() => toggleFolder(folder.id as number)}>
        <div className={classes.folder}>
          <FolderOutlinedIcon className={classes.icon} />
          <Typography className={classes.folderText} variant="subtitle1">
            {folderName}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <div className={actionClasses.actionMenu}>
          <IconButton size="small" onClick={() => toggleFolder(folder.id as number)}>
            {isFolderOpen ? (
              <KeyboardArrowUpIcon className={actionClasses.icon} />
            ) : (
              <KeyboardArrowDownIcon className={actionClasses.icon} />
            )}
          </IconButton>
          <ExtraActionsMenu options={fileOptionsForExtraActionsMenu(folder)} dotsColor={theme.palette.gray[500]} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CurrentRequestedDocumentTableFolderRow;
