import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import { useFormat } from 'common/hooks';
import { Widgets } from 'components';
import { USD_THOUSANDS } from 'components/FeaturedSpreadsheet/constants';
import CurrencyChipContext from 'components/FeaturedSpreadsheet/context/CurrencyChipContext';
import DCFTerminalValueTable from 'pages/Valuations/approaches/discountCashFlow//DCFTerminalValueTable';
import DCFSummaryTable from 'pages/Valuations/approaches/discountCashFlow/DCFSummaryTable';
import FinancialsDCFTable from 'pages/Valuations/approaches/discountCashFlow/DCFTable';
import { UPDATED_BENCHMARK_ROWS } from 'pages/Valuations/util/constants';
import { updateDCFOptions } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useNotes } from 'services/hooks/notes';
import DCFApproachContext from './context/DCFApproachContext';
import { BENCHMARK_PERCENTILE_A, BENCHMARK_PERCENTILE_B } from './dcf/config/constants';
import ebitdaRowConfig from './dcfTerminalValue/config/MultipleTerminal/ebitdaRowConfig';
import revenueRowConfig from './dcfTerminalValue/config/MultipleTerminal/revenueRowConfig';
import revenueAndEbitdaRowConfig from './dcfTerminalValue/config/RevEbitdaMultiple/rowConfig';

const DiscountCashFlow = ({ spreadsheets, onChange, workbook, name, approaches }) => {
  const dcfSpreadsheet = spreadsheets.dcf;
  const { approach, isDisabled } = dcfSpreadsheet.tableData;
  const approachPanelId = approach.panelId;
  const benchmarkSpreadsheet = useMemo(() => workbook?.benchmark, [workbook]);

  const [format, formatDispatch] = useFormat();
  const [globalCurrency, setGlobalCurrency] = useState(USD_THOUSANDS);
  const [currentTerminalValue, setCurrentTerminalValue] = useState(null);
  const { setNotesInApproach } = useContext(ValuationContext);
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();

  const updateCurrentTerminalValueTable = useCallback(
    tvt => {
      tvt.reverseShouldValidate();
      if (currentTerminalValue) {
        currentTerminalValue.reverseShouldValidate();
      }
      setCurrentTerminalValue(tvt);
    },
    [currentTerminalValue]
  );

  // eslint-disable-next-line
  const rowConfigsObject = {
    ebitdaRowConfig,
    revenueAndEbitdaRowConfig,
    revenueRowConfig,
  };

  const resetRowConfigs = useCallback(() => {
    if (benchmarkSpreadsheet) {
      updateDCFOptions({
        DCFSpreadsheets: spreadsheets,
        benchmarkApproach: benchmarkSpreadsheet.tableData.approach,
        DCFRowConfigs: rowConfigsObject,
        approaches,
      });
    }
  }, [approaches, benchmarkSpreadsheet, spreadsheets, rowConfigsObject]);

  useEffect(() => {
    document.addEventListener(BENCHMARK_PERCENTILE_A, resetRowConfigs);
    document.addEventListener(BENCHMARK_PERCENTILE_B, resetRowConfigs);
    document.addEventListener(UPDATED_BENCHMARK_ROWS, resetRowConfigs);

    return () => {
      document.removeEventListener(BENCHMARK_PERCENTILE_A, resetRowConfigs);
      document.removeEventListener(BENCHMARK_PERCENTILE_B, resetRowConfigs);
      document.addEventListener(UPDATED_BENCHMARK_ROWS, resetRowConfigs);
    };
  }, [resetRowConfigs]);

  useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesInApproach(prevState => {
        const tmpNotes = prevState.filter(note => note.panelId !== approachPanelId);
        return [...tmpNotes, { notes, panelId: approachPanelId, notesHasChanged }];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  const commonProps = {
    format,
    formatDispatch,
    spreadsheets,
    onChange,
    workbook,
    name,
    terminalValue: approach?.valuations_approach_dcf?.terminal_value,
    isDisabled,
    approach,
    approaches,
  };

  const dcfContextValue = useMemo(
    () => ({
      panelId: approach?.panelId,
      format,
      onChange,
      formatDispatch,
      currentTerminalValue,
      setCurrentTerminalValue: updateCurrentTerminalValueTable,
    }),
    [approach, format, formatDispatch, currentTerminalValue, updateCurrentTerminalValueTable, onChange]
  );

  const chipContextValue = useMemo(
    () => ({
      globalCurrency,
      setGlobalCurrency,
    }),
    [globalCurrency, setGlobalCurrency]
  );

  return (
    <DCFApproachContext.Provider value={dcfContextValue}>
      <CurrencyChipContext.Provider value={chipContextValue}>
        <Grid container spacing={4}>
          <Grid xs={12} item>
            <FinancialsDCFTable {...commonProps} />
          </Grid>
          <Grid xs={12} md={6} item>
            <DCFSummaryTable {...commonProps} />
          </Grid>
          <Grid xs={12} md={6} item>
            <DCFTerminalValueTable {...commonProps} />
          </Grid>
          <Grid xs={12} item>
            <Widgets
              notesProps={{
                pageType: VALUATIONS_PAGE_VALUE,
                pageTypeKey: VALUATIONS_PAGE_KEY,
                pageTypeId: approach.id,
                notes,
                isApproach: true,
                setNotes,
                onAddNote,
                onUpdateNotes,
                onDeleteNote,
                isDisabled,
              }}
            />
          </Grid>
        </Grid>
      </CurrencyChipContext.Provider>
    </DCFApproachContext.Provider>
  );
};

DiscountCashFlow.propTypes = {
  name: PropTypes.string,
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
};

export default DiscountCashFlow;
