import { ALLOCATION_METHOD_ALIAS } from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/util/constants';
import { CAP_TABLE_ALIAS } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases';

const afterCellsChanged = (initialChanges, cells, rowConfig, tableData, workbookCells) => {
  const changes = [...initialChanges];
  initialChanges.forEach(change => {
    const { cell, value } = change;
    if (cell.alias === ALLOCATION_METHOD_ALIAS) {
      const relatedAllocationScenarioCells = Object.values(workbookCells.equityAllocation).filter(
        c =>
          c.alias === EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD
          && c.value?.split('__')?.[1] === tableData.approach.panelId
      );
      relatedAllocationScenarioCells.forEach(c => {
        const scenarioChanges = c.sheet.afterCellChanged(
          [{ cell: c, value: c.expr }],
          c.sheet.cells,
          c.sheet.rowConfig,
          c.sheet.tableData,
          workbookCells
        );
        scenarioChanges.forEach(scenarioChange => {
          changes.push(scenarioChange);
        });
      });
    }
    if (cell.alias === CAP_TABLE_ALIAS) {
      const updatedCaptableCells = Object.values(cells)
        .filter(c => c.alias === CAP_TABLE_ALIAS && c.key !== cell.key && !cell.totalKey)
        .map(c => ({ cell: c, value }));
      // add in updatedCaptableCells to the changes array
      changes.push(...updatedCaptableCells);
    }
  });
  return changes;
};

export default afterCellsChanged;
