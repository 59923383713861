import { oneDecimalPercentFormatValidateFloatTrue, xStandardSuffixFormat } from 'common/formats/formats';
import { Rows, RowWithOrder } from '../../../../../common/types/scalarSpreadsheet';
import { rowConfigOutputsParams } from '../performance_metrics/config/rowConfig/types';
import { getCalibratedKeys, getPerformanceMetricsKeys } from '../utils';

const rowConfigCalibrationOutputs = (params: rowConfigOutputsParams): Rows => {
  const { calibration, columnsPerformance } = params;
  const { panelId } = calibration;

  const panelIdNew = panelId.slice(0, 5);

  const performanceMetricsKeys = getPerformanceMetricsKeys(columnsPerformance, 'performance_metrics');

  const calibratedPerformanceMetricsLtmKeys = getCalibratedKeys(
    performanceMetricsKeys,
    panelIdNew,
    'performance_metrics_ltm_revenue_multiple'
  );

  const calibratedPerformanceMetricsNtmKeys = getCalibratedKeys(
    performanceMetricsKeys,
    panelIdNew,
    'performance_metrics_ntm_revenue_multiple'
  );

  const calibratedPerformanceMetricsLtmEbitdaKeys = getCalibratedKeys(
    performanceMetricsKeys,
    panelIdNew,
    'performance_metrics_ltm_ebitda_multiple'
  );

  const calibratedPerformanceMetricsNtmEbitdaKeys = getCalibratedKeys(
    performanceMetricsKeys,
    panelIdNew,
    'performance_metrics_ntm_ebitda_multiple'
  );

  const rows = [
    {
      alias: 'outputs_header',
      className: 'table-header full-width-label',
      readOnly: true,
      value: 'Calibrated Outputs',
      colSpan: 2,
      gridType: 'string',
      rowSpan: 1,
      ignoreRowCopy: true,
      isTitleOrHeader: true,
      order: 1,
    } as RowWithOrder,
    {
      alias: 'ltm_revenue_multiple_outputs',
      readOnly: true,
      value: 'LTM Revenue Multiple',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ltm_revenue_inputs`,
      order: 2,
    } as RowWithOrder,
    {
      alias: 'ntm_revenue_multiple_outputs',
      readOnly: true,
      value: 'NTM Revenue Multiple',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ntm_revenue_inputs`,
      order: 3,
    } as RowWithOrder,
    {
      alias: 'ltm_revenue_multiple_percentile',
      readOnly: true,
      value: 'LTM Revenue Multiple Percentile',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `=PERCENTRANK([${calibratedPerformanceMetricsLtmKeys}], ltm_revenue_multiple_outputs )`,
      order: 4,
    } as RowWithOrder,
    {
      alias: 'ntm_revenue_multiple_percentile',
      readOnly: true,
      value: 'NTM Revenue Multiple Percentile',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `=PERCENTRANK([${calibratedPerformanceMetricsNtmKeys}], ntm_revenue_multiple_outputs)`,
      order: 5,
    } as RowWithOrder,
    {
      alias: 'empty',
      readOnly: false,
      value: '',
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      style: { visibility: 'hidden', border: 'none' },
      order: 8,
    } as RowWithOrder,
    {
      alias: 'ltm_ebitda_multiple_outputs',
      readOnly: false,
      value: 'LTM EBITDA Multiple',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ltm_ebitda_inputs`,
      order: 9,
    } as RowWithOrder,
    {
      alias: 'ntm_ebitda_multiple_outputs',
      readOnly: false,
      value: 'NTM EBITDA Multiple',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ntm_ebitda_inputs`,
      order: 10,
    } as RowWithOrder,
    {
      alias: 'ltm_ebitda_multiple_percentile',
      readOnly: false,
      value: 'LTM EBITDA Multiple Percentile',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `=PERCENTRANK([${calibratedPerformanceMetricsLtmEbitdaKeys}], ltm_ebitda_multiple_outputs)`,
      order: 11,
    } as RowWithOrder,
    {
      alias: 'ntm_ebitda_multiple_percentile',
      readOnly: false,
      value: 'NTM EBITDA Multiple Percentile',
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `=PERCENTRANK([${calibratedPerformanceMetricsNtmEbitdaKeys}], ntm_ebitda_multiple_outputs)`,
      order: 12,
    } as RowWithOrder,
  ];

  const calibratedPerformanceMetricsLtmKeysNoCurrentCompany = calibratedPerformanceMetricsLtmKeys;

  const calibratedPerformanceMetricsNtmKeysNoCurrentCompany = calibratedPerformanceMetricsNtmKeys;

  const calibratedPerformanceMetricsLtmEbitdaKeysNoCurrentCompany = calibratedPerformanceMetricsLtmEbitdaKeys;

  const calibratedPerformanceMetricsNtmEbitdaKeysNoCurrentCompany = calibratedPerformanceMetricsNtmEbitdaKeys;

  const rowsMedian = [
    {
      alias: 'ltm_revenue_multiple_premium_over_median',
      readOnly: false,
      value: 'LTM Revenue Multiple Premium Over Median',
      gridType: 'number',
      format: oneDecimalPercentFormatValidateFloatTrue,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      expr: `= ( ltm_revenue_multiple_outputs - MEDIAN([${calibratedPerformanceMetricsLtmKeysNoCurrentCompany}])) / MEDIAN([${calibratedPerformanceMetricsLtmKeysNoCurrentCompany}])`,
      order: 6,
    } as RowWithOrder,
    {
      alias: 'ntm_revenue_multiple_premium_over_median',
      readOnly: false,
      value: 'NTM Revenue Multiple Premium Over Median',
      gridType: 'number',
      format: oneDecimalPercentFormatValidateFloatTrue,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      order: 7,
      expr: `= ( ntm_revenue_multiple_outputs - MEDIAN([${calibratedPerformanceMetricsNtmKeysNoCurrentCompany}])) / MEDIAN([${calibratedPerformanceMetricsNtmKeysNoCurrentCompany}])`,
    } as RowWithOrder,
    {
      alias: 'ltm_ebitda_multiple_premium_over_median',
      readOnly: false,
      value: 'LTM EBITDA Multiple Premium Over Median',
      gridType: 'number',
      format: oneDecimalPercentFormatValidateFloatTrue,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      order: 13,
      expr: `= ( ltm_ebitda_multiple_outputs - MEDIAN([${calibratedPerformanceMetricsLtmEbitdaKeysNoCurrentCompany}])) / MEDIAN([${calibratedPerformanceMetricsLtmEbitdaKeysNoCurrentCompany}])`,
    } as RowWithOrder,
    {
      alias: 'ntm_ebitda_multiple_premium_over_median',
      readOnly: false,
      value: 'NTM EBITDA Multiple Premium Over Median',
      gridType: 'number',
      format: oneDecimalPercentFormatValidateFloatTrue,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      order: 14,
      expr: `= ( ntm_ebitda_multiple_outputs - MEDIAN([${calibratedPerformanceMetricsNtmEbitdaKeysNoCurrentCompany}])) / MEDIAN([${calibratedPerformanceMetricsNtmEbitdaKeysNoCurrentCompany}])`,
    } as RowWithOrder,
  ];

  const allRows = [...rows, ...rowsMedian];

  allRows.sort((a, b) => a.order - b.order);

  const originalRows: Rows = allRows.map(({ order, ...rest }) => rest);

  return originalRows;
};

export default rowConfigCalibrationOutputs;
