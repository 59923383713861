import { isNil, isNull, isUndefined } from 'lodash';
import { all, create } from 'mathjs';
import { mathGlobalScope } from 'common/constants';
import { GRID_DATE, GRID_NUMBER_CHECKBOX, LEDGER, STRING } from 'common/constants/gridType';
import * as customFormulas from 'common/formulas/math.js';
import { getGetSymbolSet } from 'components/ScalarSpreadsheet/utilities/mathUtil';
import { formatNumbers } from 'utillities';
import { shouldAddCurrencySymbol } from 'utillities/computeCellChanges';
import { toString } from 'utillities/typesConverter';
import getPercentileLabelCalibration from '../../../pages/ValuationsAllocation/util/getPercentileLabel/getPercentileLabelCalibration';

const math = create(all);
math.import(customFormulas);

export class Cell {
  constructor(expr) {
    this.expr = expr;
    this.dependencies = new Set();
    this.evaluated = false;
    this.providers = new Set();
    this.linkedCells = new Set();
  }

  setXY(x, y) {
    this.x = x;
    this.y = y;
  }

  setKey(key, sheet) {
    this.key = key;
    this.sheet = sheet;
  }

  setNotExpr() {
    this.value = this.expr;
    this.isExpr = false;
  }

  setIsExpr(code) {
    this.isExpr = true;
    this.evaluated = false;
    this.code = code;
  }

  setExpr(isExpr, code) {
    this.isExpr = isExpr;
    if (isExpr === false) {
      this.setNotExpr();
    } else {
      this.setIsExpr(code);
    }
  }

  setProps(props) {
    Object.entries(props).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  setValue(value) {
    if (!this.displayUndefined && (Number.isNaN(value) || isUndefined(value)) && isUndefined(this.totalKey)) {
      this.value = 0;
    } else {
      this.value = value;
    }
    this.evaluated = true;
  }

  getFullKey() {
    if (this.sheet.name && this.key) {
      let { key } = this;
      if (this.totalKey) {
        key = this.totalKey;
      }
      return `${this.sheet.name}.${key}`;
    }
  }

  initializeLinkedCells(sheet, scope) {
    const symbols = this.linkedCellSymbols || new Set();
    const arrayData = Array.from(symbols);
    const linkedCells = new Set();
    arrayData.forEach(symbol => {
      if (symbol.indexOf('.') >= 0) {
        const [sheetName, key] = symbol.split('.');
        linkedCells.add(scope[sheetName][key]);
      } else if (sheet?.[symbol]) {
        linkedCells.add(sheet[symbol]);
      } else if (isUndefined(scope[symbol])) {
        // undefined symbol:
        throw new Error(`Undefined symbol: ${symbol}`);
      }
    });
    this.linkedCells = linkedCells;
  }

  getKeys() {
    if (this.customKey) {
      return [this.customKey, this.key];
    }
    return [this.key];
  }

  getRelativeKeys(sheet) {
    if (this.sheet.name === sheet.name) {
      return this.getKeys();
    }
    return this.getKeys().map(key => `${this.sheet.name}.${key}`);
  }

  initializeDependencies(symbols, sheet, scope) {
    const arrayData = Array.from(symbols);
    const providers = new Set();
    arrayData.forEach(symbol => {
      if (symbol.indexOf('.') >= 0) {
        const [sheetName, key] = symbol.split('.');
        scope[sheetName][key].dependencies.add(this);
        providers.add(scope[sheetName][key]);
      } else if (sheet?.[symbol]) {
        sheet[symbol].dependencies.add(this);
        providers.add(sheet[symbol]);
      } else if (isUndefined(scope[symbol])) {
        // undefined symbol:
        throw new Error(`Undefined symbol: ${symbol}`);
      }
    });
    this.symbols = symbols;
    this.providers = providers;
  }

  getExprString() {
    if (!isNil(this.expr) && this.expr !== '') {
      return this.expr.toString();
    }
    return '';
  }

  getSymbols() {
    const strValue = this.getExprString();
    const node = math.parse(strValue.substring(1));
    const symbols = getGetSymbolSet(node);
    return symbols;
  }

  initializeCellValue(sheet, cellScope) {
    const strValue = this.getExprString();
    this.invalidExpr = false;
    const scope = {
      ...cellScope,
      ...mathGlobalScope,
    };
    this.initializeLinkedCells(sheet, scope);
    if (strValue[0] === '=') {
      this.isExpr = true;
      try {
        const node = math.parse(strValue.substring(1));
        const symbols = getGetSymbolSet(node);
        this.initializeDependencies(symbols, sheet, scope);
        this.setIsExpr(node.compile());
      } catch (e) {
        if (this.defaultZero) {
          this.expr = 0;
          this.setNotExpr();
        } else {
          this.invalidExpr = true;
          // eslint-disable-next-line no-console
          console.error(`Cannot initialize expression ${strValue} on cell ${this.sheet.name}.${this.key}`);
        }
      }
    } else {
      this.setNotExpr();
    }
  }

  getExprScope() {
    return Array.from(this.providers).reduce(
      (sofar, provider) => {
        if (provider.sheet === this.sheet) {
          // eslint-disable-next-line no-param-reassign
          sofar[provider.key] = provider.parseCellValue();
          if (provider.customKey) {
            // eslint-disable-next-line no-param-reassign
            sofar[provider.customKey] = sofar[provider.key];
          }
        } else {
          if (isUndefined(sofar[provider.sheet.name])) {
            // eslint-disable-next-line no-param-reassign
            sofar[provider.sheet.name] = {};
          }
          const cellValue = provider.parseCellValue();
          if (!provider.isTotal) {
            // eslint-disable-next-line no-param-reassign
            sofar[provider.sheet.name][provider.key] = cellValue;
            if (provider.customKey) {
              // eslint-disable-next-line no-param-reassign
              sofar[provider.sheet.name][provider.customKey] = cellValue;
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            sofar[provider.sheet.name][`TOTALS_${provider.key}`] = cellValue;
            // eslint-disable-next-line no-param-reassign
            sofar[provider.sheet.name][`TOTALS_${provider.alias}`] = cellValue;
          }
        }
        return sofar;
      },
      { ...mathGlobalScope }
    );
  }

  updateDependencies(startNode) {
    return Array.from(this.dependencies)
      .flatMap(dependency => dependency.calcValue(startNode))
      .filter(result => !!result);
  }

  getLinkedCells() {
    if (this.linkedCells) {
      return [this, ...Array.from(this.linkedCells).flatMap(linkedCell => linkedCell.getLinkedCells())];
    }
    return [];
  }

  setCellBackgroundColor(color) {
    this.backgroundColor = color;
  }

  setDisplayConfig(tableConfig = {}, cellConfig = {}) {
    Object.entries(tableConfig).forEach(([key, value]) => {
      this[key] = { ...tableConfig?.[key], ...cellConfig?.[key] } || value;
    });
  }

  parseCellValue() {
    if (this.isTotal && !this.useFormulaValue) {
      return shouldAddCurrencySymbol(this.value)
        ? formatNumbers({
          format: this.format,
          currencyCode: this.currency?.currencyCode || null,
          value: this.value,
        })
        : this.value;
    }
    if (this.gridType === GRID_DATE) return this.value;
    if (this.gridType === GRID_NUMBER_CHECKBOX) return this;
    if (this.gridType === STRING) return this.value;
    if (this.gridType === LEDGER) return this.value;
    // eslint-disable-next-line no-restricted-globals
    if (isUndefined(this) || isNaN(this.value)) return 0;
    if (this.gridType === 'percentage') return parseFloat(Number(this.value));
    return parseFloat(Number(this.value));
  }

  fitValue(value) {
    // make sure we know what type we are working with
    let tmpValue = value ? value.toString() : value;
    if (
      value
      && this.maxNumberDigits
      && this.isExpr
      && value.toString().includes('.')
      && value.toString().length > this.maxNumberDigits
    ) {
      tmpValue = Number(Number(value).toFixed(this.maxNumberDigits - value.toString().split('.')[0].length));
    }
    if (value && this.dbDecimalPlaces) {
      tmpValue = Number(Number(tmpValue).toFixed(this.dbDecimalPlaces));
    }
    if (isNull(value)) {
      return value;
    }
    if (tmpValue === Infinity || Number.isNaN(tmpValue)) {
      tmpValue = 0;
    }
    return tmpValue;
  }

  providersAreEmpty() {
    let providersList = Array.from(this.providers);
    if (this.symbols?.size === 0 && !isUndefined(this.defaultValue)) {
      return false;
    }
    providersList = providersList.filter(c => isUndefined(c.enabled) || c.enabled);
    if (providersList.length === 0) {
      return true;
    }
    return providersList.every(c => !toString(c.value).length);
  }

  calcValue(startNode) {
    let sNode = startNode;
    if (startNode === this) {
      return;
    }
    if (!startNode) {
      sNode = this;
    }

    const getDefaultValue = () => (!isUndefined(this.defaultValue) ? this.defaultValue : null);
    const providerCheckCallback = provider =>
      this.skipIfNullProviders ? provider.evaluated && provider.value !== null : provider.evaluated;

    if (this.isExpr && Array.from(this.providers).every(provider => providerCheckCallback(provider))) {
      try {
        const exprScope = this.getExprScope();

        // If all cells providers are empty, then is not necessary to evaluate the expression
        let val = null;
        try {
          val = this.providersAreEmpty() ? getDefaultValue() : this.code.evaluate(exprScope);
          val = getPercentileLabelCalibration(val, this.alias);
        } catch (e) {
          val = null;
        }
        const fittedValue = this.fitValue(val);
        this.setValue(fittedValue);

        const changes = this.updateDependencies(sNode);
        return [this, ...changes];
      } catch (e) {
        throw new Error(`Error updating dependencies on cell: ${this.sheet.name} ${this.key}: ${e.toString()}`);
      }
    }
    if (!this.isExpr && this.dependencies) {
      this.setValue(this.expr);
      const changes = this.updateDependencies(sNode);
      return [this, ...changes];
    }
    return undefined;
  }

  removeDependencies() {
    if (this.value?.[0] === '=') {
      Array.from(this.dependencies).forEach(node => {
        node.removeDependencies();
      });
      this.dependencies = new Set();
    }
  }

  unmarkDependencies(startNode) {
    let sNode = startNode;
    if (sNode === this) {
      return;
    }
    if (!sNode) {
      sNode = this;
    }
    this.evaluated = false;
    Array.from(this.dependencies).forEach(dependency => dependency.unmarkDependencies(sNode));
  }

  resetCell(updatedCell) {
    Array.from(this.dependencies).forEach(dependency => {
      dependency.providers.delete(this);
      dependency.providers.add(updatedCell);
    });
  }

  dispatchEvent() {
    if (this.sheet && this.key) {
      const event = new Event(this.getFullKey(), { bubbles: true, cancelable: true });
      document.dispatchEvent(event);
    }
  }

  removePairCell() {
    this.dependencies.delete(this.pairCell);
  }

  onChange(expr, scope) {
    const sheet = scope[this.sheet.name];
    this.expr = expr;
    this.removePairCell();
    this.removeDependencies();
    this.initializeCellValue(sheet, scope);
    this.unmarkDependencies();
    return this.calcValue();
  }
}
