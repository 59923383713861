/* eslint-disable react/display-name */
import React from 'react';
import {
  dcfPercentFormat,
  largeCurrencyFormat,
  smallDecimalFormat,
  xStandardSuffixFormat,
} from 'common/formats/formats';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import SelectValueViewer from 'components/SelectValueViewer/SelectValueViewer';
import WACCLedger from 'pages/Valuations/approaches/discountCashFlow/components/WACCLedger';
import {
  DCF_FINANCIAL_PERIOD_TABLE_NAME,
  DISCOUNT_FACTOR,
  DISCOUNT_PERIODS,
  EBITDA_TOTAL,
  HIGH_GROWTH_RATE,
  LONG_TERM_GROWTH_RATE,
  MAX_VALUATION_NUMBER_DIGITS,
  PV_OF_TERMINAL_VALUE,
  REVENUE_TOTAL,
  TERMINAL_VALUE,
  TERMINAL_VALUE_OPTIONS,
  TOTAL_CASH_FLOWS,
  VALUATION_APPROACH_DCF_TABLE_NAME,
  WACC,
  WACC_LABEL,
  YEARS_OF_HIGH_GROWTH,
} from 'pages/Valuations/util/constants';
import MultipleTypeApproachSelect from './MultipleTypeApproachSelect';
import MultipleTypeApproachValueViewer from './MultipleTypeApproachValueViewer';

export const TERMINAL_VALUE_CONFIG = isDisabled => ({
  className: 'table-header bigger-row-label',
  readOnly: isDisabled,
  // eslint-disable-next-line react/display-name
  dataEditor: props => (
    <GridSelect enumerated useHandleChange={false} options={Object.values(TERMINAL_VALUE_OPTIONS)} {...props} />
  ),
  // eslint-disable-next-line react/display-name
  valueViewer: props => <SelectValueViewer options={Object.values(TERMINAL_VALUE_OPTIONS)} useLabel {...props} />,
  forceComponent: true,
  value: 'Terminal Value',
  gridType: 'string',
  style: { textAlign: 'center' },
  alias: TERMINAL_VALUE,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
});
export const TOTAL_CASH_FLOWS_CONFIG = mainTableReference => ({
  readOnly: true,
  value: 'Terminal Year Cash Flows',
  gridType: 'number',
  format: largeCurrencyFormat,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: TOTAL_CASH_FLOWS,
  expr: `=${mainTableReference}.col_last_21`,
});
export const WACC_CONFIG = dcfWacc => ({
  readOnly: true,
  value: WACC_LABEL,
  dbDecimalPlaces: 3,
  gridType: 'percentage',
  format: dcfPercentFormat,
  expr: `=${dcfWacc?.name ?? ''}.wacc`,
  alias: WACC,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
  dialog: {
    content: <WACCLedger dcfWacc={dcfWacc} />,
  },
  disableEvents: true,
});
export const LONG_TERM_GROWTH_RATE_CONFIG = isDisabled => ({
  readOnly: isDisabled,
  value: 'Long-Term Growth Rate',
  gridType: 'percentage',
  dbType: 'string',
  dbDecimalPlaces: 2,
  format: dcfPercentFormat,
  minValue: 0,
  maxValue: 100,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: LONG_TERM_GROWTH_RATE,
});
export const DISCOUNT_FACTOR_CONFIG = mainTableReference => ({
  readOnly: true,
  value: 'x CFn Discount Factor',
  gridType: 'number',
  format: xStandardSuffixFormat,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: DISCOUNT_FACTOR,
  origin: DCF_FINANCIAL_PERIOD_TABLE_NAME,
  expr: `=${mainTableReference}.col_last_25`,
});
export const PV_OF_TERMINAL_VALUE_CONFIG = (row1, row2) => ({
  readOnly: true,
  value: 'PV of Terminal Value',
  gridType: 'number',
  format: largeCurrencyFormat,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  alias: PV_OF_TERMINAL_VALUE,
  expr: `=@${row1}*@${row2}`,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
  dbType: 'string',
  dbDecimalPlaces: 2,
});
export const YEARS_OF_HIGH_GROWTH_CONFIG = isDisabled => ({
  readOnly: isDisabled,
  value: 'Years of High Growth',
  gridType: 'number',
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  format: smallDecimalFormat,
  alias: YEARS_OF_HIGH_GROWTH,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
});
export const HIGH_GROWTH_RATE_CONFIG = isDisabled => ({
  readOnly: isDisabled,
  value: 'High Growth Rate',
  gridType: 'percentage',
  dbType: 'string',
  dbDecimalPlaces: 2,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  format: dcfPercentFormat,
  alias: HIGH_GROWTH_RATE,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
});
export const SELECT_MULTIPLE_CONFIG = (alias, dcfWacc, percentileSelections, isDisabled) => ({
  readOnly: isDisabled,
  value: 'Select Multiple',
  gridType: 'number',
  dataEditor: props => <MultipleTypeApproachSelect useHandleChange={false} usePortal dcfWacc={dcfWacc} {...props} />,
  valueViewer: props => <MultipleTypeApproachValueViewer dcfWacc={dcfWacc} {...props} />,
  forceComponent: true,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
  alias,
  percentileSelections,
});
export const SELECT_APPROACH_CONFIG = (alias, options, isDisabled) => ({
  readOnly: isDisabled,
  value: 'Select Approach',
  gridType: 'string',
  dataEditor: props => <GridSelect menuPosition="fixed" options={options} {...props} />,
  valueViewer: props => <SelectValueViewer options={options} {...props} />,
  forceComponent: true,
  alias,
  isRequired: true,
});
export const MULTIPLE_CONFIG = (alias, options, benchmarkType, isDisabled) => ({
  readOnly: isDisabled,
  value: 'Multiple',
  gridType: 'number',
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  format: xStandardSuffixFormat,
  expr: '',
  dbType: 'string',
  dbDecimalPlaces: 2,
  alias,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
  options,
  benchmarkType,
});
export const ESTIMATED_EXIT_VALUE_CONFIG = (terminalYearRowNumber, estimatedExitRowNumber) => ({
  readOnly: true,
  value: 'Estimated Exit Value',
  gridType: 'number',
  format: largeCurrencyFormat,
  alias: 'estimated_exit_value',
  expr: `=@${terminalYearRowNumber}*@${estimatedExitRowNumber}`,
});
export const DISCOUNT_PERIODS_CONFIG = mainTableReference => ({
  readOnly: true,
  value: 'Discount Periods',
  gridType: 'number',
  expr: `=${mainTableReference}.col_last_24+0.5`,
  format: smallDecimalFormat,
  alias: DISCOUNT_PERIODS,
});
export const PV_OF_TERMINAL_VALUE_CONFIG_2 = (waccRowNumber, discountPeriodsRowNumber, pvOfTerminalValueRowNumber) => ({
  readOnly: true,
  value: 'PV of Terminal Value',
  gridType: 'number',
  format: largeCurrencyFormat,
  alias: PV_OF_TERMINAL_VALUE,
  expr: `=@${waccRowNumber}/(1+@${discountPeriodsRowNumber})^@${pvOfTerminalValueRowNumber}`,
  origin: VALUATION_APPROACH_DCF_TABLE_NAME,
  maxNumberDigits: MAX_VALUATION_NUMBER_DIGITS,
  dbType: 'number',
  dbDecimalPlaces: 2,
});
export const TERMINAL_YEAR_REVENUE_CONFIG = mainTableReference => ({
  readOnly: true,
  value: 'Terminal Year Revenue',
  gridType: 'number',
  expr: `=${mainTableReference}.col_last_2`,
  format: largeCurrencyFormat,
  alias: REVENUE_TOTAL,
  origin: DCF_FINANCIAL_PERIOD_TABLE_NAME,
});
export const TERMINAL_YEAR_EBITDA_CONFIG = mainTableReference => ({
  readOnly: true,
  value: 'Terminal Year EBITDA',
  gridType: 'number',
  format: largeCurrencyFormat,
  expr: `=${mainTableReference}.col_last_4`,
  alias: EBITDA_TOTAL,
  origin: DCF_FINANCIAL_PERIOD_TABLE_NAME,
});
