import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import getRowConfig from 'pages/Valuations/approaches/GuidelineTransactions/config/getRowConfig';
import parser from 'pages/Valuations/approaches/GuidelineTransactions/config/parser';
import reverseParser from 'pages/Valuations/approaches/GuidelineTransactions/config/reverseParser';
import rowTransformer from 'pages/Valuations/approaches/GuidelineTransactions/config/rowTransformer';
import afterCellChanged from './afterCellChanged';
import validations from './validations';

const createPublicCompsData = ({
  approach,
  name,
  companyInfo,
  financials,
  format,
  compGroups,
  gptAttributes,
  isDisabled,
  gpcApproaches,
  gpcOptions,
}) => {
  const rowConfig = getRowConfig({
    companyName: companyInfo.name,
    approach: approach.valuations_approach_gpt,
    allCompGroups: compGroups,
    isDisabled,
    gpcOptions,
  });

  const getColumns = () => rowTransformer(approach.valuations_approach_gpt, financials);
  const columns = getColumns();

  const tableTerms = {
    tableSlug: 'valuation-table',
  };

  return new SpreadsheetConfig({
    columns,
    rowConfig,
    parser,
    reverseParser,
    name,
    tableData: { approach, companyName: companyInfo.name, isDisabled, gpcApproaches, gpcOptions, financials },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    afterCellChanged,
    allowConfirmAndDeleteColumn: false,
    tableTerms,
    format,
    fieldAttributes: gptAttributes,
    customValidations: validations,
    allowCopyColumn: false,
    allowReorderColumns: false,
    allowCopyRows: !isDisabled,
    allowAddMultipleRows: !isDisabled,
    allowDeleteRow: !isDisabled,
    getColumns,
  });
};

export default createPublicCompsData;
