import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { each, isEmpty, isNull, isUndefined, toString } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { captableAction } from 'common/actions';
import { SYSTEM_COLLAPSE, SYSTEM_EXPAND } from 'common/actions/row-groups/types';
import {
  ASSIGNED_TYPE_FOR_NOTES,
  CAP_TABLE_LOCKED_MESSAGE,
  CURRENT_OWNERSHIP_ROW_NUMBER,
  FULLY_DILUTED_OWNERSHIP_ROW_NUMBER,
  INITIAL_LIQUIDATION_PREFERENCE_ROW_NUMBER,
  PREFERRED_TERMS_ALIAS,
  SECURITY_TYPE_ROW_NUMBER,
  SHARES_FULLY_DILUTED_ROW_NUMBER,
  SHARES_OUTSTANDING_ROW_NUMBER,
  STRIKE_PRICE_ALIAS,
  TWO_DECIMAL_PLACES,
} from 'common/constants/cap-table';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { CAP_TABLE_REFERENCE_TYPE } from 'common/constants/documents';
import { CAPTABLE_VERSION_FILTER } from 'common/constants/pageFilters';
import { CONVERTIBLE_NOTES, OPTION, PREFERRED_STOCK, UNISSUED_OPTIONS, WARRANT } from 'common/constants/securityTypes';
import { useFormat } from 'common/hooks';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { useStore } from 'common/store';
import { Alert, AllocationFinalMessage, EmptyTableMessage } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import { ConfirmationCannotDelete, ConfirmationDeleteWithList, ConfirmationDialog } from 'components/Dialogs';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL } from 'components/FeaturedSpreadsheet/constants';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import CapTableContext from 'context/CapTableContext';
import DialogContext from 'context/DialogContext';
import LayoutContext from 'context/LayoutContext';
import UnsavedChanges from 'context/UnsavedChanges';
import { ConfirmationSaveFinal, ProformaCaptableDialog } from 'pages/CapTable/cap-table/components/index';
import { blankPreferred, securityTemplate } from 'pages/CapTable/cap-table/data';
import { linkedCellsUpdates, reverseParser } from 'pages/CapTable/cap-table/utilities';
import { useKeyEventForFabButton, useReadFieldAttributes } from 'services/hooks';
import { useUpdateCapTableAndSecuritiesInfo } from 'services/hooks/captable';
import {
  CAPTABLE_MODEL_NAME,
  CAPTABLE_PAGE,
  CONVERTIBLE_NOTE_MODEL_NAME,
  SECURITY_MODEL_NAME,
} from 'services/hooks/useReadFieldAttributes/constants';
import { getLastColumn } from 'utillities/alphabet-utilities';
import WorkbookContext from '../../../../components/ScalarSpreadsheet/utilities/WorkbookContext';
import useCaptableData from '../utilities/useCaptableData';

function calcCurrentOwnPercent(allColumns) {
  const columns = allColumns.filter(({ is_deleted }) => !is_deleted);
  const totalDiluted = columns.reduce((total, { shares_diluted, security_type }) => {
    if (![UNISSUED_OPTIONS, CONVERTIBLE_NOTES].includes(security_type)) {
      return total + parseFloat(shares_diluted);
    }
    return total;
  }, 0);

  function hasRelatedSecurity(security_group_ref) {
    return (
      allColumns.flatMap(c => c.convertible_notes).filter(c => c.security_group_ref === security_group_ref).length === 1
    );
  }

  return allColumns.map(column => {
    if (column.is_deleted) {
      return column;
    }
    const { security_type, shares_diluted, security_group_ref } = column;
    let current_own_percent;
    if (security_type === UNISSUED_OPTIONS) {
      current_own_percent = 0;
    } else {
      if (security_type === CONVERTIBLE_NOTES) {
        // sometimes loans turn up NaN's as the current ownership.
        if (security_group_ref && hasRelatedSecurity(security_group_ref)) {
          current_own_percent = 0;
        } else {
          // eslint-disable-next-line no-param-reassign
          column.convertible_notes = column.convertible_notes?.map(note => {
            const current_ownership = parseFloat(note.expected_shares) / totalDiluted;
            return {
              ...note,
              current_ownership: current_ownership.toFixed(10),
            };
          });
        }
      }
      if (isUndefined(current_own_percent)) {
        current_own_percent = parseFloat(shares_diluted) / totalDiluted;
      }
    }
    return {
      ...column,
      current_own_percent: current_own_percent.toFixed(10),
    };
  });
}

const initialState = {
  strike_price: SYSTEM_COLLAPSE,
  prefered_terms: SYSTEM_COLLAPSE,
};

const CapTableTable = ({
  measurementDate,
  selectedMeasurementDate,
  saveAsNewProforma,
  filesToSave,
  addReferenceForExistingDocuments,
  notes,
  saveNotes,
  notesHasChanged,
  setIsDisplayingRowNumbers,
  setDocumentDisabledStatus,
}) => {
  const [{ captableInfo, isShowLoadingProgress, companyInfo }] = useStore();
  const [format, formatDispatch] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    units: `${DEFAULT_CURRENCY} ${DEFAULT_CURRENCY_SYMBOL}`,
  });
  const [isDisabled, setIsDisabled] = useState();
  const [captableId, setCaptableId] = useState();
  const [finalAllocations, setFinalAllocations] = useState([]);
  const [isAllocationFinal, setIsAllocationFinal] = useState();
  const [openProformaDialog, setOpenProformaDialog] = useState(false);
  const [persistentDeletedColumns, setPersistentDeletedColumns] = useState([]);
  const { showDialog } = useContext(DialogContext);
  const [, updateCapTableData] = useUpdateCapTableAndSecuritiesInfo();
  const [rowGroups, setRowGroups] = useReducer(rowsGroupsReducer, initialState);
  const [isTableReady, setIsTableReady] = useState(false);
  const fieldAttributes = useReadFieldAttributes(CAPTABLE_PAGE);
  const securityFieldAttrs = fieldAttributes.get(SECURITY_MODEL_NAME);
  const capTableFieldAttrs = fieldAttributes.get(CAPTABLE_MODEL_NAME);
  const convertibleNotesFieldAttrs = fieldAttributes.get(CONVERTIBLE_NOTE_MODEL_NAME);

  const existingHiddenNoteColumnsMapRef = useRef(null);

  const { updatePageActions, pageFilters, viewOnlyUser } = useContext(LayoutContext);

  const savesAsProformaConfirmation = () => {
    setOpenProformaDialog(true);
  };

  const initialFormValues = useMemo(
    () => ({
      isValid: true,
      name: `${captableInfo?.name} Proforma`,
      values: {},
      errors: {},
    }),
    [captableInfo]
  );
  const [formState, setFormState] = useState(initialFormValues);

  const {
    spreadsheet: captableSheet,
    tableTerms,
    visibleColumns,
    setVisibleColumns,
    virtualColumns,
    setColumns,
    columns,
    isButtonDisabled,
    setIsButtonDisabled,
    ...restCaptableProps
  } = useCaptableData({ captableInfo, measurementDate, fieldAttributes: securityFieldAttrs, isDisabled });

  useEffect(() => {
    if (!isEmpty(captableInfo) && pageFilters?.length) {
      const capTableFilterId = CAPTABLE_VERSION_FILTER;
      const { selectedValue } = pageFilters.find(filter => toString(filter.id) === toString(capTableFilterId)) || {};

      setIsButtonDisabled(selectedValue === '');
    }
  }, [captableInfo, pageFilters, setIsButtonDisabled]);

  const spreadsheets = useMemo(() => [captableSheet], [captableSheet]);

  const {
    onChange: onCellsChanged,
    cells,
    data,
    workbook,
    areCellsValid,
    workbookContextValue,
  } = useWorkbook(spreadsheets);

  const { cleanAction } = useContext(UnsavedChanges);

  const [updatedColumns, setUpdatedColumns] = useState();

  const onChange = (cell, expr, customFn) => {
    const reversedColumns = onCellsChanged(cell, expr, customFn)[0];

    if (visibleColumns?.length && cells) {
      setUpdatedColumns([...reversedColumns]);
    }
  };

  // Visible columns based on the last spreadsheet changes
  useEffect(() => {
    // function that compares the order of each captableSheet.columns with the visibleColumns and returns false
    // if the order is different.
    const compareColumns = (columns, visibleColumns) => {
      if (columns?.length !== visibleColumns?.length) {
        return false;
      }

      for (let i = 0; i < columns?.length; i++) {
        if (columns[i].columnRef !== visibleColumns[i].columnRef) {
          return false;
        }
      }
      return true;
    };

    if (visibleColumns?.length && captableSheet?.cells && compareColumns(captableSheet?.columns, visibleColumns)) {
      const reversedColumns = reverseParser({
        cells: captableSheet.cells,
        columns: visibleColumns,
        fieldAttributes: securityFieldAttrs,
        allowEmptyValues: false,
      });
      setUpdatedColumns([...reversedColumns]);
    } else {
      setUpdatedColumns([]);
    }
  }, [captableSheet, cells, securityFieldAttrs, visibleColumns]);

  const deletedColumns = useMemo(() => {
    if (columns?.length && cells?.captable) {
      const auxColumns = columns.filter(column => column.is_deleted && column.id !== 0);
      return auxColumns;
    }
    return [];
  }, [columns, cells]);

  // here are visible columns with ledger.
  const columnsWithLedgers = useMemo(() => {
    if (updatedColumns?.length) {
      return updatedColumns.filter(uc => uc.options_ledger?.length > 1);
    }
    return [];
  }, [updatedColumns]);

  // columns with notes
  const columnsWithNotes = useMemo(() => {
    if (updatedColumns?.length) {
      return updatedColumns.filter(column => !isEmpty(column.convertible_notes));
    }
    return [];
  }, [updatedColumns]);

  const getTotalValue = useCallback(
    rowNumber => {
      const totalColumnLegend = getLastColumn(1);
      const totalCell = cells?.captable?.[`TOTALS_${totalColumnLegend + rowNumber}`];
      if (totalCell?.value) {
        const decimalPlaces = capTableFieldAttrs
          ? capTableFieldAttrs[totalCell.alias]?.decimal_places
          : TWO_DECIMAL_PLACES;
        return Number(totalCell.value).toFixed(decimalPlaces);
      }
      return '0';
    },
    [capTableFieldAttrs, cells]
  );

  const calculateDilutedOwnPercent = useCallback(
    rowShares => (rowShares / Number(getTotalValue(SHARES_FULLY_DILUTED_ROW_NUMBER))).toFixed(15),
    [getTotalValue]
  );

  // The current ownership depends on the diluted shares with the exception of unissued options
  const calculateCurrentOwnPercent = useCallback(
    rowShares =>
      (
        rowShares
        / Number(
          visibleColumns?.reduce((acc, column) => {
            if (column.security_type !== UNISSUED_OPTIONS) {
              return acc + Number(column.shares_diluted);
            }
            return acc;
          }, 0)
        )
      ).toFixed(15),
    [visibleColumns]
  );

  const getExistingHiddenNoteColumns = securities =>
    securities?.filter(s => s.is_hidden && ![WARRANT, OPTION].includes(s.security_type)) || [];

  const getExistingConvertibleNotes = convertibleNotesFromColumns =>
    convertibleNotesFromColumns
      .map(column => column.convertible_notes)
      .flat()
      .filter(note => note.id !== 0);

  const createHiddenSecurity = (column, sourceNote, assignedSecurityType, baseContent, baseName) => ({
    ...baseContent,
    name: `${baseName} - ${sourceNote.note_name}`,
    is_deleted: sourceNote.is_deleted,
    conversion_rate: '1.00',
    investment_date: sourceNote.issuance_date,
    issue_price: sourceNote.conversion_price_per_share || 0,
    security_type: assignedSecurityType,
    current_own_percent: sourceNote.current_ownership,
    security_group_ref: column.security_ref,
    shares_outstanding: sourceNote.expected_shares || 0,
    shares_diluted: sourceNote.expected_shares || 0,
    loan_value: sourceNote.loan_value,
  });

  const existingConvertibleNotes = useMemo(() => getExistingConvertibleNotes(columnsWithNotes), [columnsWithNotes]);

  const existingHiddenNoteColumns = useMemo(
    () => getExistingHiddenNoteColumns(captableInfo?.securities),
    [captableInfo]
  );

  const existingHiddenNoteColumnsMap = useMemo(() => {
    if (
      existingHiddenNoteColumnsMapRef.current === null
      && !isEmpty(existingConvertibleNotes)
      && !isEmpty(existingHiddenNoteColumns)
    ) {
      const hiddenNoteColumnsMap = existingHiddenNoteColumns.reduce((map, column) => {
        // 'Conv Note - Investor Name' -> 'Investor Name'
        const noteName = column.name.split(' - ')[1];
        const tmpMap = { ...map };
        tmpMap[existingConvertibleNotes.find(note => note.note_name === noteName)?.id] = column.id;
        return tmpMap;
      }, {});
      existingHiddenNoteColumnsMapRef.current = hiddenNoteColumnsMap;
    }
    return existingHiddenNoteColumnsMapRef.current;
  }, [existingConvertibleNotes, existingHiddenNoteColumns]);

  const updatedHiddenNoteColumns = useMemo(() => {
    const hiddenSecurities = [];
    if (visibleColumns?.length && !isNull(captableInfo) && !isEmpty(existingHiddenNoteColumnsMap)) {
      if (existingConvertibleNotes?.length) {
        existingHiddenNoteColumns.forEach(column => {
          const tmpSourceNote
            = existingConvertibleNotes.find(
              note =>
                note.security_group_ref === column.security_ref || existingHiddenNoteColumnsMap[note.id] === column.id
            ) || {};

          const sourceNote = tmpSourceNote ? { ...tmpSourceNote } : {};
          const sourceColumn = columnsWithNotes.find(col => col.id === sourceNote.convertible_note_security) || {};
          const assignedSecurityType = ASSIGNED_TYPE_FOR_NOTES[sourceNote.model_as_equity];
          const baseContent = assignedSecurityType === PREFERRED_STOCK ? column : { ...column, ...blankPreferred };
          const baseName = sourceColumn.name;

          hiddenSecurities.push(createHiddenSecurity(column, sourceNote, assignedSecurityType, baseContent, baseName));
        });
      }
    }
    return hiddenSecurities;
  }, [
    captableInfo,
    columnsWithNotes,
    visibleColumns,
    existingConvertibleNotes,
    existingHiddenNoteColumns,
    existingHiddenNoteColumnsMap,
  ]);

  const updatedHiddenColumns = useMemo(() => {
    const hiddenSecurities = [];
    if (visibleColumns?.length) {
      const existingHiddenColumns
        = captableInfo?.securities?.filter(s => s.is_hidden && [WARRANT, OPTION].includes(s.security_type)) || [];

      const virtualOptionLedgersRows
        = columnsWithLedgers
          .filter(col => isNull(col.id))
          .map(col => col.options_ledger)
          .flat() || [];

      existingHiddenColumns.forEach(col => {
        const ledgerRowData = virtualOptionLedgersRows.find(row => row.security_ref === col.security_ref) || {};
        const { shares, price } = ledgerRowData;
        const { security_group_ref, strike_price } = col;
        const parentColumn = columnsWithLedgers.find(c => c.security_ref === col.security_group_ref);
        hiddenSecurities.push({
          ...col,
          current_own_percent: Number(calculateCurrentOwnPercent(shares)),
          diluted_own_percent: Number(calculateDilutedOwnPercent(shares)),
          name: `${Number(price).toFixed(2)} ${parentColumn?.name}`,
          shares_outstanding: shares,
          strike_price,
          // A hidden column not in virtualOptionLedgerRows means it was deleted from the ledger
          is_deleted: isEmpty(ledgerRowData),
          order: visibleColumns.find(vc => vc.security_group_ref === security_group_ref)?.order,
        });
      });
    }
    return hiddenSecurities;
  }, [visibleColumns, captableInfo, columnsWithLedgers, calculateCurrentOwnPercent, calculateDilutedOwnPercent]);

  // memo to create new column from mutated
  const fromVirtualToReal = useMemo(() => {
    if (updatedColumns?.length) {
      const tmpCols = updatedColumns
        .filter(col => isNull(col.id) && ![WARRANT, OPTION].includes(col.security_type))
        .map(col => ({
          ...col,
          id: 0,
          security_group_ref: null,
          underlying_security_ref: null,
          options_ledger: [],
        }));
      return tmpCols;
    }
    return [];
  }, [updatedColumns]);

  useEffect(() => {
    if (updatedColumns) {
      updatedColumns.forEach((updatedCol, index, arr) => {
        const { id, options_ledger } = updatedCol;
        if (!id && options_ledger.length === 1) {
          // Persist the virtual column as an actual security...
          // eslint-disable-next-line no-param-reassign
          arr[index].id = 0;
          // eslint-disable-next-line no-param-reassign
          arr[index].security_group_ref = null;
          // ...and make the security_ref of the ledger row null.
          // This will generate a new hidden security based on that one row.
          // eslint-disable-next-line no-param-reassign
          arr[index].options_ledger = [
            {
              ...options_ledger[0],
              security_ref: null,
            },
          ];
        }
      });
    }
  }, [updatedColumns]);

  // see if I can catch the ones I want
  const deletedBySecurityTypeChange = useMemo(() => {
    if (fromVirtualToReal?.length) {
      const aux = [...fromVirtualToReal];
      let deleted = [];
      aux.forEach(virtual => {
        const toDelete = columns
          ?.filter(col => col.security_group_ref === virtual.security_ref)
          .map(col => ({ ...col, is_deleted: true }));
        deleted = [...deleted, ...toDelete];
      });
      return deleted;
    }
    return [];
  }, [columns, fromVirtualToReal]);

  const getColumnIndex = (cols, securityRef) => {
    const columnByRef = cols.find(column => column.security_ref === securityRef);
    return cols.indexOf(columnByRef);
  };

  const hiddenColumns = useMemo(() => {
    const securities = [];
    // columnsWithLedgers comes from a memo looking at every col with options_ledger.length > 1
    columnsWithLedgers.forEach(column => {
      const { options_ledger: optionsLedger } = column;
      if (optionsLedger.length > 1) {
        // Rows without security refs represent new hidden columns
        optionsLedger
          .filter(ledgerRow => !ledgerRow.security_ref)
          .forEach(row => {
            securities.push({
              ...column,
              // An options/warrant security with only one row in the ledger does not make up any virtual columns.
              // However, when more rows are added, all of them need to be converted into hidden securities.
              id: column.isVirtualColumn || optionsLedger.length >= 2 ? 0 : null,
              security_ref: uuid(),
              name: `${Number(row.price).toFixed(2)} ${column.name}`,
              is_hidden: true,
              security_group_ref: column.security_ref,
              shares_diluted: row.shares,
              diluted_own_percent: Number(calculateDilutedOwnPercent(row.shares)),
              shares_outstanding: row.shares,
              current_own_percent: Number(calculateCurrentOwnPercent(row.shares)),
              strike_price: row.price,
              order: getColumnIndex(updatedColumns, column.security_ref),
            });
          });
      }
    });

    return securities;
  }, [columnsWithLedgers, calculateDilutedOwnPercent, calculateCurrentOwnPercent, updatedColumns]);

  // hidden columns that come from notes in the CN ledger
  const fromNotesToSecurities = useMemo(() => {
    const securities = [];
    columnsWithNotes.forEach(column => {
      const { convertible_notes: convertibleNotes } = column;
      if (convertibleNotes.length >= 1) {
        column.convertible_notes
          .filter(ledgerNote => ledgerNote.id === 0 && !ledgerNote.is_deleted)
          .forEach(note => {
            const assignedSecurityType = ASSIGNED_TYPE_FOR_NOTES[note.model_as_equity];
            const baseContent = assignedSecurityType === PREFERRED_STOCK ? column : { ...column, ...blankPreferred };
            securities.push({
              ...baseContent,
              id: 0,
              security_ref: note.security_group_ref,
              name: `${column.name} - ${note.note_name}`,
              is_hidden: true,
              investment_date: note.issuance_date,
              issue_price: note.conversion_price_per_share || 0,
              security_type: assignedSecurityType,
              security_group_ref: column.security_ref,
              shares_outstanding: note.expected_shares || 0,
              shares_diluted: note.expected_shares || 0,
              cap_table: captableInfo.id,
              convertible_notes: [],
              diluted_own_percent: Number(calculateDilutedOwnPercent(note.expected_shares)),
              current_own_percent: Number(calculateCurrentOwnPercent(note.expected_shares)),
              total_note_value_at_md: note.value_at_md,
              total_note_preference: note.note_principle_amount,
            });
          });
      }
    });
    return securities;
  }, [columnsWithNotes, captableInfo, calculateDilutedOwnPercent, calculateCurrentOwnPercent]);

  const saveConfirmation = useCallback(
    callback => {
      if (isAllocationFinal) {
        showDialog({
          wrapper: ConfirmationDialog,
          wrapperProps: {
            title: 'Confirmation',
          },
          content: ConfirmationSaveFinal,
          actions: [
            {
              label: 'Cancel',
              variant: 'text',
              type: 'primary',
            },
            {
              label: 'Save',
              variant: 'contained',
              type: 'success',
              callback,
            },
          ],
        });
      }
    },
    [isAllocationFinal, showDialog]
  );

  const updatedTableData = useMemo(() => {
    if (isEmpty(cells)) {
      return { ...captableInfo };
    }

    const tmpSecurities = !isEmpty(captableInfo?.securities) ? captableInfo.securities : updatedColumns;

    return {
      ...captableInfo,
      securities: tmpSecurities.filter(s => !s.is_hidden),
      shares_outstanding: getTotalValue(SHARES_OUTSTANDING_ROW_NUMBER),
      shares_diluted: getTotalValue(SHARES_FULLY_DILUTED_ROW_NUMBER),
      current_own_percent: Number(getTotalValue(CURRENT_OWNERSHIP_ROW_NUMBER)).toFixed(15),
      diluted_own_percent: Number(getTotalValue(FULLY_DILUTED_OWNERSHIP_ROW_NUMBER)).toFixed(15),
      initial_liquidation_preference: getTotalValue(INITIAL_LIQUIDATION_PREFERENCE_ROW_NUMBER),
    };
  }, [cells, captableInfo, updatedColumns, getTotalValue]);

  const generateNewSecurities = count => {
    const securityArray = [];

    for (let i = 0; i < count; i++) {
      const newSecurity = {
        ...securityTemplate,
        security_ref: uuid(),
        cap_table: captableInfo.id,
      };
      securityArray.push(newSecurity);
    }

    return securityArray;
  };

  const addSecurity = (newColumns = 1) => {
    const securities = generateNewSecurities(newColumns);

    const tmpPersistedColumns = reverseParser({
      cells: cells.captable,
      columns: visibleColumns,
      fieldAttributes: securityFieldAttrs,
    });
    const allColumns = [...securities, ...tmpPersistedColumns, ...deletedColumns];
    setColumns(allColumns);
  };

  const deleteColumn = useCallback(
    (columnIndex, captableCells) => {
      const tmpPersistedColumns = [
        ...reverseParser({
          cells: captableCells,
          columns: visibleColumns,
          fieldAttributes: securityFieldAttrs,
        }),
      ];

      tmpPersistedColumns[columnIndex].is_deleted = true;
      const relatedDeleted = [];
      // check if has notes, if it has, delete the related hidden columns
      if (tmpPersistedColumns[columnIndex].security_type === 6) {
        const relatedHiddenSecuritiesRef = tmpPersistedColumns[columnIndex].convertible_notes.map(
          note => note.security_group_ref
        );

        captableInfo.securities.forEach(security => {
          if (relatedHiddenSecuritiesRef.includes(security.security_ref)) {
            relatedDeleted.push({ ...security, is_deleted: true });
          }
        });
      }

      setPersistentDeletedColumns([...persistentDeletedColumns, ...relatedDeleted, tmpPersistedColumns[columnIndex]]);
      setColumns([...tmpPersistedColumns, ...deletedColumns]);
    },
    [captableInfo, deletedColumns, persistentDeletedColumns, securityFieldAttrs, setColumns, visibleColumns]
  );

  const sharedProps = {
    workbook,
    onChange,
    page: 'captable',
    setIsDisplayingRowNumbers,
  };

  const cloneColumn = useCallback(
    columnIndex => {
      const tmpPersistedColumns = [
        ...reverseParser({
          cells: captableSheet?.cells,
          fieldAttributes: securityFieldAttrs,
          columns: visibleColumns,
        }),
      ];

      const column = tmpPersistedColumns[columnIndex];
      if (column) {
        const nameCount = tmpPersistedColumns.filter(s => s.name.toString().includes(`${column.name} copy`)).length + 1;

        const clonedOptions = column.options_ledger?.map(securityRow => ({
          ...securityRow,
          security_ref: undefined,
        }));

        const clonedNotes = column.convertible_notes?.map(note => ({
          ...note,
          id: 0,
          convertible_note_security: undefined,
          security_group_ref: uuid(),
        }));

        const newSecurity = {
          ...column,
          id: 0,
          is_used_in_backsolve: false,
          is_used_in_fod: false,
          security_ref: uuid(),
          options_ledger: clonedOptions,
          convertible_notes: clonedNotes,
          is_deleted: false,
          name: `${column.name} copy ${nameCount > 1 ? nameCount : ''}`,
        };

        const allColumns = [newSecurity, ...tmpPersistedColumns, ...deletedColumns];
        setColumns(allColumns);
      }
    },
    [captableSheet, deletedColumns, securityFieldAttrs, setColumns, visibleColumns]
  );

  const showDeleteConfirmation = useCallback(
    columnIndex => {
      const messages = [];

      const showDialogIfSecurityIsUsed = () => {
        showDialog({
          wrapper: ConfirmationDialog,
          wrapperProps: {
            title: 'Action not allowed',
          },
          content: ConfirmationCannotDelete,
          contentProps: { messages, itemName: 'security' },
          actions: [
            {
              label: 'Cancel',
              variant: 'contained',
              type: 'primary',
            },
          ],
        });
      };

      const showRegularDeleteDialog = securityToDelete => {
        const hasMultipleInvestments = securityToDelete.has_multiple_investments;
        const containMultipleInvestments = securityToDelete.multiple_investments?.length;

        if (hasMultipleInvestments && containMultipleInvestments) {
          messages.push('All tranches in Multiple Investments Dates.');
        }
        showDialog({
          wrapper: ConfirmationDialog,
          wrapperProps: {
            title: `Are you sure you want to permanently delete ${securityToDelete.name}?`,
          },
          content: ConfirmationDeleteWithList,
          contentProps: { messages },
          actions: [
            {
              label: 'Delete',
              variant: 'contained',
              type: 'danger',
              callback: () => deleteColumn(columnIndex, captableSheet?.cells),
            },
          ],
        });
      };

      if (visibleColumns) {
        const security = visibleColumns[columnIndex];
        const { is_used_in_fod: isUsedInFod, is_used_in_backsolve: isUsedInBacksolve } = security;

        if (isUsedInFod) messages.push('Fund Ownership');
        if (isUsedInBacksolve) messages.push('Backsolve');

        if (isUsedInFod || isUsedInBacksolve) {
          showDialogIfSecurityIsUsed();
        } else {
          showRegularDeleteDialog(security);
        }
      }
    },
    [visibleColumns, showDialog, deleteColumn, captableSheet]
  );

  const saveProformaColumns = useCallback(
    activeColumns => {
      if (isDisabled) {
        setIsDisabled(false);
      }

      saveAsNewProforma({
        ...updatedTableData,
        name: formState.values.name || `${captableInfo.name} Proforma`,
        securities: activeColumns,
      });
    },
    [captableInfo, formState, isDisabled, saveAsNewProforma, updatedTableData]
  );

  const saveCapTable = useCallback(
    (updateGlobalStore, saveAsProForma = false) => {
      // Save files in widget
      if (!isEmpty(filesToSave)) {
        addReferenceForExistingDocuments(
          selectedMeasurementDate.id,
          filesToSave,
          captableInfo.id,
          CAP_TABLE_REFERENCE_TYPE
        );
      }

      if (!isEmpty(notes) && notesHasChanged) {
        saveNotes();
      }

      // Save table
      if (areCellsValid()) {
        const deletedColumnsIds = deletedColumns.map(col => col.id);
        const originalLedgerColumns = columnsWithLedgers.map(column => ({
          ...column,
          is_deleted: true,
          // Undefined is used to force the column to be deleted in the database
          options_ledger_data_updated: isNull(column.id) || undefined,
        }));

        const updatedFilteredColumns
          = updatedColumns?.filter(
            updatedCol => !originalLedgerColumns.map(uc => uc.security_ref).includes(updatedCol.security_ref)
          ) || [];

        // This excludes columns that were added and deleted before saving them in the database
        const persistentDeletedFilteredColumns = persistentDeletedColumns.filter(
          col => col.id !== 0 && !deletedColumnsIds.includes(col.id)
        );

        const persistentDeletedIds = persistentDeletedFilteredColumns.map(({ id }) => id);

        const hiddenNotesColumns = updatedHiddenNoteColumns.map(col => {
          const is_deleted = persistentDeletedIds.includes(col.id) ? true : col.is_deleted;
          return {
            ...col,
            is_deleted,
          };
        });

        const deletedBySecurityChangeIds = deletedBySecurityTypeChange.map(({ id }) => id);

        let activeColumns = [
          ...updatedFilteredColumns,
          ...updatedHiddenColumns.filter(col => !deletedBySecurityChangeIds.includes(col.id)),
          ...hiddenNotesColumns,
          ...hiddenColumns, // these don't have a security_ref
          ...fromNotesToSecurities,
          ...fromVirtualToReal,
        ];

        activeColumns = calcCurrentOwnPercent(activeColumns);

        if (saveAsProForma) {
          saveProformaColumns(activeColumns);
          return;
        }

        const allColumns = [
          ...activeColumns,
          ...deletedColumns,
          ...deletedBySecurityTypeChange,
          ...persistentDeletedFilteredColumns,
          ...originalLedgerColumns,
        ];

        if (isAllocationFinal) {
          saveConfirmation(() => {
            updateCapTableData(captableInfo.id, allColumns, updatedTableData, updateGlobalStore);
          });
        } else {
          updateCapTableData(captableInfo.id, allColumns, updatedTableData, updateGlobalStore);
        }
        cleanAction();
      }

      let tmpValidatedCells = {};

      Object.values(cells.captable).forEach(cell => {
        // Update cells to show error feedback
        tmpValidatedCells = { ...tmpValidatedCells, [cell.key]: cell };

        if (!cell.isValid && cell.parent) {
          setRowGroups({ type: SYSTEM_EXPAND, row: cell.parent });
        }
      });
    },
    [
      addReferenceForExistingDocuments,
      saveNotes,
      notes,
      notesHasChanged,
      areCellsValid,
      captableInfo,
      cells,
      cleanAction,
      columnsWithLedgers,
      deletedBySecurityTypeChange,
      deletedColumns,
      filesToSave,
      fromNotesToSecurities,
      fromVirtualToReal,
      hiddenColumns,
      isAllocationFinal,
      persistentDeletedColumns,
      saveConfirmation,
      saveProformaColumns,
      selectedMeasurementDate,
      updateCapTableData,
      updatedColumns,
      updatedHiddenColumns,
      updatedHiddenNoteColumns,
      updatedTableData,
    ]
  );

  const saveProforma = useCallback(() => {
    saveCapTable(false, true);
  }, [saveCapTable]);

  // Set page actions
  useEffect(() => {
    if (!isEmpty(captableInfo)) {
      updatePageActions({
        mainAction: {
          id: 'captable',
          label: 'Save',
          isActive: isDisabled,
          callback: saveCapTable,
          isLock: isAllocationFinal,
        },
        secondaryActions: [
          {
            label: 'Save as New Proforma',
            isActive: captableInfo.is_primary,
            callback: savesAsProformaConfirmation,
          },
        ],
      });
    }

    return () => {
      updatePageActions(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableInfo, cells, isDisabled, filesToSave, companyInfo, updatedColumns, notes, updatePageActions]);

  const isGettingInitialData = useMemo(
    () => [captableId, isAllocationFinal, isDisabled].includes(undefined),
    [captableId, isAllocationFinal, isDisabled]
  );

  useKeyEventForFabButton(isGettingInitialData);

  useEffect(
    () => () => {
      setIsAllocationFinal(undefined);
      setIsDisabled(undefined);
    },
    [captableId]
  );

  // Set the table disabled if the allocation is marked as final
  useEffect(() => {
    if (captableInfo?.id) {
      setCaptableId(captableInfo.id);

      const verifyAllocationStatus = async () => {
        try {
          const response = await captableAction.verifyIfHasFinalAllocation(captableInfo.id);
          if (response) {
            setFinalAllocations(response);
          }
          setIsAllocationFinal(!isEmpty(response));
          setIsDisabled(!isEmpty(response) || viewOnlyUser);
          setDocumentDisabledStatus(viewOnlyUser);
        } catch (error) {
          setIsAllocationFinal(false);
          setIsDisabled(false);
          throw new Error(error);
        }
      };
      verifyAllocationStatus();
    }

    return () => {
      setCaptableId(undefined);
    };
  }, [captableInfo, companyInfo, viewOnlyUser, setDocumentDisabledStatus]);

  function getErrors(securityType, isInvalid, preferredWithErrors, cell, warrantWithErrors, optionWithErrors) {
    switch (securityType) {
      case PREFERRED_STOCK.toString():
        if (isInvalid) preferredWithErrors.push(cell);
        break;
      case WARRANT.toString():
        if (isInvalid) warrantWithErrors.push(cell);
        break;
      case OPTION.toString():
        if (isInvalid) optionWithErrors.push(cell);
        break;
      default:
        break;
    }
  }

  // Open row groups if find any cell with errors
  const toggleRows = useCallback(
    (isFirstRender = false) => {
      const capCells = cells.capTable;
      const preferredWithErrors = [];
      const warrantWithErrors = [];
      const optionWithErrors = [];

      each(capCells, cell => {
        if (!isFirstRender) {
          const securityTypeKey = cell.columnLegend + SECURITY_TYPE_ROW_NUMBER;
          const securityType = toString(cells[securityTypeKey] ? cells[securityTypeKey].value : '0');
          const isInvalid = cell.isValid === false;

          getErrors(securityType, isInvalid, preferredWithErrors, cell, warrantWithErrors, optionWithErrors);
        }
      });

      if (!isFirstRender) {
        // Expand if has errors
        if (preferredWithErrors.length || warrantWithErrors.length) {
          setRowGroups({ type: SYSTEM_EXPAND, row: PREFERRED_TERMS_ALIAS });
        }
        if (optionWithErrors.length || warrantWithErrors.length) {
          setRowGroups({ type: SYSTEM_EXPAND, row: STRIKE_PRICE_ALIAS });
        }
      }
    },
    [cells]
  );

  useEffect(() => {
    if (!isEmpty(cells) && !isTableReady) {
      toggleRows(true);
      setIsTableReady(true);
    }
  }, [cells, isTableReady, toggleRows]);

  const getLinkedCellUpdates = useCallback(
    newCells => {
      // Get updates for linked cells
      const cellUpdates = linkedCellsUpdates({
        prevCells: cells,
        cells: newCells,
      });

      return cellUpdates;
    },
    [cells]
  );

  const capTableContextValue = useMemo(
    () => ({
      measurementDate,
      selectedMeasurementDate,
      rowGroups,
      setRowGroups,
      isDisabled: false,
      cells,
      getLinkedCellUpdates,
      convertibleNotesFieldAttrs,
    }),
    [cells, convertibleNotesFieldAttrs, getLinkedCellUpdates, measurementDate, rowGroups, selectedMeasurementDate]
  );

  const renderProformaCaptable = useMemo(
    () => (
      <ProformaCaptableDialog
        editMode={false}
        initialValues={initialFormValues}
        isValid={formState?.isValid || false}
        onClose={() => setOpenProformaDialog(false)}
        onFormChange={updatedFormState => setFormState(updatedFormState)}
        onSave={saveProforma}
        open={openProformaDialog || false}
        tableTerms={tableTerms}
        title={`New Proforma ${tableTerms.tableName}`}
      />
    ),
    [formState, initialFormValues, openProformaDialog, saveProforma, tableTerms]
  );

  if (!captableSheet) {
    return <GridSkeleton />;
  }

  return data && cells && captableSheet?.data ? (
    <CapTableContext.Provider value={capTableContextValue}>
      {/* Proforma Cap Table */}
      {renderProformaCaptable}

      {isDisabled && isAllocationFinal && (
        <AllocationFinalMessage
          tableTerms={tableTerms}
          openProformaDialog={openProformaDialog}
          finalAllocations={finalAllocations}
          setIsDisabled={setIsDisabled}
          DocumentsDisabled={setDocumentDisabledStatus}
          savesAsProformaConfirmation={savesAsProformaConfirmation}
          variant
          actions={[
            {
              label: 'Duplicate',
              buttonProps: {
                variant: 'contained',
                size: 'small',
              },
              callback: savesAsProformaConfirmation,
            },
          ]}
        />
      )}

      {isAllocationFinal && !isDisabled && <Alert isAlertVisible>{CAP_TABLE_LOCKED_MESSAGE}</Alert>}

      <WorkbookContext.Provider value={workbookContextValue}>
        <ScalarSpreadsheet
          {...captableSheet}
          {...restCaptableProps}
          {...sharedProps}
          cloneColumn={cloneColumn}
          allowCloneColumn={!isDisabled}
          setColumns={setVisibleColumns}
          rowGroups={rowGroups}
          setRowGroups={setRowGroups}
          toggleRows={toggleRows}
          deleteColumn={showDeleteConfirmation}
          tableTerms={tableTerms}
          sheet={captableSheet}
          format={format}
          formatDispatch={formatDispatch}
          addMultipleColumns={!isDisabled && addSecurity}
          allowDeleteColumn={!isDisabled}>
          {isEmpty(visibleColumns) && <EmptyTableMessage tableTerms={tableTerms} />}
        </ScalarSpreadsheet>
      </WorkbookContext.Provider>
      <ExtendedFabButton
        id="captable-add-security-btn"
        disabled={isShowLoadingProgress || isDisabled || isButtonDisabled}
        label="Add Security"
        onClick={() => addSecurity(1)}
      />
    </CapTableContext.Provider>
  ) : (
    <></>
  );
};

CapTableTable.propTypes = {
  measurementDate: PropTypes.string,
  selectedMeasurementDate: PropTypes.shape({
    cmd_id: PropTypes.number,
    date: PropTypes.string,
    id: PropTypes.number,
    is_open: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  saveAsNewProforma: PropTypes.func,
  notes: PropTypes.array,
  saveNotes: PropTypes.func,
  notesHasChanged: PropTypes.bool,
  filesToSave: PropTypes.array,
  addReferenceForExistingDocuments: PropTypes.func,
};

export default CapTableTable;
