import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  infoCards: {
    display: 'flex',
    padding: '1rem 2.5rem',
    backgroundColor: '#f1f4f6',

    '& .MuiPaper-root': {
      flexGrow: 1,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  spreadsheet: {
    '& td': {
      maxWidth: '140px',
    },
  },
}));

export default useStyles;
