/* eslint-disable import/no-unresolved */
import { sortBy } from 'lodash';
import { parseDatabaseValue, roundToN } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { ALLOCATION_BACKSOLVE_WEIGHTING_ALIAS } from './constants';
import { VALUATION_APPROACH_GPC_ALIAS } from '../../constants';
import { ADJUSTMENT_TYPE_ALIAS } from '../../MarketAdjustmentTable/util/constants';

function extractApproachGpc(calcValue, tableData, row, tmpColumn) {
  if (calcValue) {
    const colAlias = tableData.approaches.find(
      ({ valuations_approach_gpc }) => valuations_approach_gpc?.id?.toString() === calcValue
    )
      ? 'gpc_approach'
      : 'reference_for_backsolve';
    // eslint-disable-next-line no-param-reassign
    tmpColumn[colAlias] = calcValue;
  } else {
    // eslint-disable-next-line no-param-reassign
    tmpColumn.gpc_approach = undefined;
  }
}

const reverseParseMethodologyPresentShareValues = (column, valueDecimalPlaces, rowConfig) => {
  if (column.present_share_values?.length > 0) {
    const relevantRowSecurityMap = rowConfig
      .filter(row => row.securityName)
      .reduce(
        (sofar, next) => ({
          ...sofar,
          [next.customKey]: next,
        }),
        {}
      );

    let matchedPresentShareValues = {};
    const unmatchedPresentShareValues = [...(column.deleted_present_share_values ?? [])];
    column.present_share_values.forEach(psv => {
      if (relevantRowSecurityMap[psv.security]) {
        matchedPresentShareValues = {
          ...matchedPresentShareValues,
          [psv.security]: psv,
        };
      } else if (psv.id) {
        unmatchedPresentShareValues.push(psv.id);
      }
    });
    const mappedPSV = Object.values(relevantRowSecurityMap).map(row => ({
      security: row.customKey,
      methodology: column.id ?? null,
      id: matchedPresentShareValues[row.customKey]?.id ?? 0,
      value: roundToN(column[row.customKey], valueDecimalPlaces),
    }));
    // eslint-disable-next-line no-param-reassign
    column.present_share_values = mappedPSV;
    // eslint-disable-next-line no-param-reassign
    column.deleted_present_share_values = Array.from(new Set(unmatchedPresentShareValues));
  } else if (column.present_share_values?.length === 0) {
    // eslint-disable-next-line no-param-reassign
    column.present_share_values = Object.entries(column)
      .filter(pair => Number(pair[0]))
      .map(pair => ({
        security: pair[0],
        value: roundToN(pair[1], valueDecimalPlaces),
        id: 0,
        methodology: column.id ?? null,
      }));
  }
};

const reverseParser = ({ cells, columns, allowEmptyValues, rowConfig, fieldAttributes, tableData }) => {
  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // copy original values
    const tmpColumn = column;

    // replace updated values
    rowConfig.forEach((row, cellIndex) => {
      const key = columnLegend + (cellIndex + 1);
      const defaultValue = row.defaultValue || null;
      const type = row.dbType || null;
      const format = row.format || null;
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = row;
      let decimalPlaces = fieldAttributes[row.alias]?.decimal_places;

      if (row.alias === ALLOCATION_BACKSOLVE_WEIGHTING_ALIAS) {
        decimalPlaces = 3;
      }

      if (row.alias && cells[key]) {
        const calcValue = parseDatabaseValue({
          type,
          value,
          defaultValue,
          format,
          allowEmptyValues,
          gridType,
          decimalPlaces,
        });

        if (row.alias === VALUATION_APPROACH_GPC_ALIAS) {
          extractApproachGpc(calcValue, tableData, row, tmpColumn);
        }

        if (row.alias === ADJUSTMENT_TYPE_ALIAS && tableData?.approach?.valuations_approach_backsolve) {
          // eslint-disable-next-line no-param-reassign
          tableData.approach.valuations_approach_backsolve.adjustment_type_selection = calcValue;
        }

        tmpColumn[row.alias] = calcValue;
      }
    });

    reverseParseMethodologyPresentShareValues(tmpColumn, fieldAttributes.value?.decimal_places, rowConfig);
  });
};

export default reverseParser;
