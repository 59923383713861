import React from 'react';
import { NOT_APPLICABLE } from 'common/constants/general';
import { largeCurrencyFormat, oneDecimalPercentFormat, xStandardSuffixFormat } from 'common/formats/formats';
import { Cell, Cells, GridType } from 'common/types/scalarSpreadsheet';
import { PerformanceMetricsColumn } from 'pages/Valuations/approaches/guidelineCalibration/performance_metrics/config/createColumns/types';
import { SHEET_TITLES_CONSTANTS } from 'pages/Valuations/approaches/guidelineCalibration/performance_metrics/config/index';
import { generateNTMRevenueGrowthExpression } from 'pages/Valuations/approaches/guidelineCalibration/utilities';
import { SHEET_ALIASES_CONSTANTS } from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS } from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics/sheetAliases/sheetAliases';
import {
  CellParserCalibrationParams,
  CustomParserParamCalibration,
  EvaluateNTMRevenueGrowth,
  EvaluateNTMRevenueGrowthReturn,
  GetRankExpressionCalibrationParams,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/config/customParser/types';
import { countDecimalPlaces, generateAllRowsExpression, getExpression, getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';

const {
  PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_SUBTITLE,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE,
} = SHEET_ALIASES_CONSTANTS;
const {
  CURRENCY_COLUMNS,
  EXCLUDE_TITLE_AND_SUBTITLE,
  PERFORMANCE_METRICS_SPREADSHEET_COLUMNS,
  PERFORMANCE_METRICS_SPREADSHEET_HEADERS,
} = SHEET_TITLES_CONSTANTS;

const currencyColumns = new Set(CURRENCY_COLUMNS);

const evaluateNTMRevenueGrowth: EvaluateNTMRevenueGrowth = params => {
  const { alias, alphabet, rowNumber } = params;
  let { value, expr } = params;

  if (value === NOT_APPLICABLE && alias !== PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
    return {
      expr: '',
      value,
    };
  }

  // If the NTM Revenue Growth has 2 or less decimal places, we need to use the Expression to display the accurate value
  // Because that means that the value is not using the new decimal places coming from the CAPIQ API
  // We also need to use the Expression for the Company
  if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY || countDecimalPlaces(Number(value)) <= 2) {
    value = 0;
    expr = generateNTMRevenueGrowthExpression({ alphabet, rowNumber });
  }

  // The value is in percentage, we only need to apply the format
  if (alias !== PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
    value = Number(value ?? 0);
  }

  return { expr, value };
};

function getRankExpression({ allComparisonsExpression }: GetRankExpressionCalibrationParams) {
  let expr = null;
  let hidden = true;
  expr = `=PERCENTRANK([${allComparisonsExpression}], ${allComparisonsExpression.at(-1)})`;
  hidden = false;
  return { expr, hidden };
}

function CommonAliasMultiple(
  aliasId: string[],
  aliasMultiple: string,
  column: PerformanceMetricsColumn
): {
  customKey: string;
  gridType: GridType;
  format: {
    validateFloat: boolean;
    maximumFractionDigits: number;
    minimumFractionDigits: number;
    style: string;
    suffix: string;
  };
  alias: string;
  value: string | number | boolean | React.JSX.Element;
} {
  const newAliasMultiple: string = aliasMultiple + aliasId[1];
  const value = column[newAliasMultiple]?.value ?? '';
  const format = xStandardSuffixFormat;
  const gridType = 'number';
  const alias = newAliasMultiple;
  const customKey = newAliasMultiple;

  return { value, format, gridType, alias, customKey };
}

const cellParser = (params: CellParserCalibrationParams) => {
  const { calibration, alphabet, colIndex, column, row, rowIndex, allComparisonsExpression } = params;
  const { panelId } = calibration;
  const panelIdNew = panelId.slice(0, 5);
  const rowNumber = rowIndex + 1;
  const colNumber = colIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  let { alias, expr = '', format = null, gridType = 'string', hidden } = getObjectValue(row);
  const { className = '', colSpan = 1, readOnly, options } = getObjectValue(row);

  let value = column[alias]?.value ?? '';
  value = value === 'N/A' ? 0 : value;

  let NTMRevenueGrowthEvaluation: EvaluateNTMRevenueGrowthReturn = {};
  const aliasId = alias.split('performance_metrics_');
  let aliasMultiple = '';
  let customKey = '';
  // Parse cell based on Row alias
  switch (alias) {
    // Header titles
    case PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE:
      value = PERFORMANCE_METRICS_SPREADSHEET_HEADERS[colNumber].value;
      break;

    // Header subtitles
    case PERFORMANCE_METRICS_SPREADSHEET_HEADER_SUBTITLE:
      value = PERFORMANCE_METRICS_SPREADSHEET_HEADERS[colNumber].subtitle;
      break;

    case PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS:
      {
        const rankValues = getRankExpression({ allComparisonsExpression });
        expr = rankValues.expr;
        hidden = rankValues.hidden;
        gridType = 'percentage';
      }
      break;

    // Display (for the moment) values on Company Volatility columns
    // Rest of the rows (excluding Header titles and subtitles)
    default:
      if (currencyColumns.has(colNumber as (typeof CURRENCY_COLUMNS)[number])) {
        format = largeCurrencyFormat;
        gridType = 'number';
      }
      // Parse cell based on Column number
      switch (colNumber) {
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE_MULTIPLE:
          aliasMultiple = 'performance_metrics_ltm_revenue_multiple_';
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            aliasId[1] = 'current_company';
            expr = `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ltm_revenue_inputs`;
          }
          ({ value, format, gridType, alias, customKey } = CommonAliasMultiple(aliasId, aliasMultiple, column));
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE_MULTIPLE:
          aliasMultiple = 'performance_metrics_ntm_revenue_multiple_';
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            aliasId[1] = 'current_company';
            expr = `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ntm_revenue_inputs`;
          }
          ({ value, format, gridType, alias, customKey } = CommonAliasMultiple(aliasId, aliasMultiple, column));
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_EBITDA_MULTIPLE:
          aliasMultiple = 'performance_metrics_ltm_ebitda_multiple_';
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            aliasId[1] = 'current_company';
            expr = `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ltm_ebitda_inputs`;
          }
          ({ value, format, gridType, alias, customKey } = CommonAliasMultiple(aliasId, aliasMultiple, column));
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_EBITDA_MULTIPLE:
          aliasMultiple = 'performance_metrics_ntm_ebitda_multiple_';
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            aliasId[1] = 'current_company';
            expr = `= calibration_inputs_${panelIdNew}.enterprise_value_inputs / calibration_inputs_${panelIdNew}.ntm_ebitda_inputs`;
          }
          ({ value, format, gridType, alias, customKey } = CommonAliasMultiple(aliasId, aliasMultiple, column));
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE_GROWTH:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.GROSS_MARGIN:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.EBITDA_MARGIN:
          format = oneDecimalPercentFormat;
          gridType = 'percentage';
          break;
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE_GROWTH:
          format = oneDecimalPercentFormat;
          gridType = 'percentage';
          NTMRevenueGrowthEvaluation = evaluateNTMRevenueGrowth({ alphabet, alias, expr, rowNumber, value });
          expr = NTMRevenueGrowthEvaluation?.expr ?? expr;
          value = NTMRevenueGrowthEvaluation?.value ?? value;
          break;
        default:
          break;
      }
      break;
  }

  return {
    [key]: {
      ...row,
      alias,
      className,
      colSpan,
      columnLegend,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      key,
      customKey,
      hidden,
      value,
      readOnly,
      options,
    } as Cell,
  };
};

const customParser = (params: CustomParserParamCalibration) => {
  const { columns, rowConfig, tableData } = params;
  const { isDisabled, calibration } = tableData;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  const allComparisonsExpression = generateAllRowsExpression({
    rowConfig: rowConfig.filter(row => !row.forceComponent),
    excludedRows: EXCLUDE_TITLE_AND_SUBTITLE,
    excludeLastRow: true,
  });

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          calibration,
          allComparisonsExpression,
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
          isDisabled,
          initialObject: params.initialObject,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
