/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import DCFApproachContext from 'pages/Valuations/approaches/discountCashFlow/context/DCFApproachContext';
import { APPROACH_SELECTIONS_ALIASES, TERMINAL_VALUE, TERMINAL_VALUE_OPTIONS } from 'pages/Valuations/util/constants';
import {
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util';
import { getApproachesByType } from 'pages/ValuationsAllocation/util/getApproachesByType';
import { getObjectValue } from 'utillities';
import DCFTVTContext from './context/DCFTVTContext';

const tvtSpreadSheetKey = {
  [TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH]: 'dcfTVTPG',
  [TERMINAL_VALUE_OPTIONS.H_MODEL]: 'dcfTVTHM',
  [TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE]: 'dcfTVTRM',
  [TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE]: 'dcfTVTEM',
  [TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE]: 'dcfTVTREM',
};

const getApproachSelected = (approaches, selectedApproachId) => {
  const filteredApproaches = getApproachesByType({
    approaches,
    approachTypes: [VALUATIONS_PUBLIC_COMPANIES_APPROACH, VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH],
  });
  const idToMatch = selectedApproachId?.toString();
  return filteredApproaches.find(
    ({ panelId, valuations_approach_gpc, valuations_approach_gpt }) =>
      panelId === idToMatch
      || valuations_approach_gpc?.id?.toString() === idToMatch
      || valuations_approach_gpt?.id?.toString() === idToMatch
  );
};

const DCFTerminalValueTable = ({
  format,
  formatDispatch,
  spreadsheets,
  onChange: onWorkbookChange,
  workbook,
  terminalValue,
  approach,
  approaches,
}) => {
  const initialDcfTvtProps = tvtSpreadSheetKey[terminalValue || TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH];
  const [dcfTVTProps, setDcfTVTProps] = useState(spreadsheets[initialDcfTvtProps]);

  const { valuations_approach_dcf } = getObjectValue(approach);
  const {
    revenue_gpc_approach_selection,
    revenue_gpt_approach_selection,
    ebitda_gpc_approach_selection,
    ebitda_gpt_approach_selection,
  } = getObjectValue(valuations_approach_dcf);

  const revenueApproachSelected = revenue_gpc_approach_selection ?? revenue_gpt_approach_selection;
  const ebitdaApproachSelected = ebitda_gpc_approach_selection ?? ebitda_gpt_approach_selection;

  const [revenueSelectedApproach, setRevenueSelectedApproach] = useState(
    getApproachSelected(approaches, revenueApproachSelected)
  );
  const [ebitdaSelectedApproach, setEbitdaSelectedApproach] = useState(
    getApproachSelected(approaches, ebitdaApproachSelected)
  );
  const { setCurrentTerminalValue, currentTerminalValue } = useContext(DCFApproachContext);

  const changeTvtType = useCallback(
    ({ tvtType }) => {
      const newSpreadsheetConfigs = spreadsheets[tvtSpreadSheetKey[tvtType]];
      Object.values(tvtSpreadSheetKey).forEach(key => {
        spreadsheets[key].columns[0].terminal_value = tvtType;
      });
      setCurrentTerminalValue(newSpreadsheetConfigs);
    },
    [setCurrentTerminalValue, spreadsheets]
  );

  const getMultipleOptions = useCallback(selectedApproach => {
    const approach = selectedApproach.valuations_approach_gpc ?? selectedApproach.valuations_approach_gpt;
    return [...getSelectionCellOptions({ specificApproach: approach }), VALUATIONS_OTHER_LABEL];
  }, []);

  const revenueMultipleOptions = useMemo(() => {
    if (revenueSelectedApproach) {
      return getMultipleOptions(revenueSelectedApproach);
    }
    return [VALUATIONS_OTHER_LABEL];
  }, [revenueSelectedApproach, getMultipleOptions]);

  const ebitdaMultipleOptions = useMemo(() => {
    if (ebitdaSelectedApproach) {
      return getMultipleOptions(ebitdaSelectedApproach);
    }
    return [VALUATIONS_OTHER_LABEL];
  }, [ebitdaSelectedApproach, getMultipleOptions]);

  useEffect(() => {
    if (currentTerminalValue) {
      setDcfTVTProps(currentTerminalValue);
    }
  }, [currentTerminalValue, revenueMultipleOptions, ebitdaMultipleOptions]);

  const handleApproachSelection = useCallback(
    cell => {
      const foundApproach = getApproachSelected(approaches, cell.value);
      const terminalValue = valuations_approach_dcf.terminal_value;
      if (terminalValue === TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE) {
        setRevenueSelectedApproach(foundApproach);
      } else if (terminalValue === TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE) {
        setEbitdaSelectedApproach(foundApproach);
      } else if (terminalValue === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE) {
        if (cell.columnLegend === 'A') {
          setRevenueSelectedApproach(foundApproach);
        } else {
          setEbitdaSelectedApproach(foundApproach);
        }
      }
    },
    [approaches, valuations_approach_dcf]
  );

  const onChange = useCallback(
    (cell, expr) => {
      onWorkbookChange(cell, expr);

      if (cell.alias === TERMINAL_VALUE) {
        changeTvtType({ tvtType: expr });
      }
      if (APPROACH_SELECTIONS_ALIASES.includes(cell.alias)) {
        handleApproachSelection(cell);
      }
    },
    [changeTvtType, onWorkbookChange, handleApproachSelection]
  );

  const contextValue = useMemo(
    () => ({
      revenueMultipleOptions,
      ebitdaMultipleOptions,
    }),
    [revenueMultipleOptions, ebitdaMultipleOptions]
  );

  return (
    <div>
      <DCFTVTContext.Provider value={contextValue}>
        <ScalarSpreadsheet
          {...dcfTVTProps}
          key={dcfTVTProps.name}
          onChange={onChange}
          sheet={dcfTVTProps}
          workbook={workbook}
          format={format}
          formatDispatch={formatDispatch}
        />
      </DCFTVTContext.Provider>
    </div>
  );
};

DCFTerminalValueTable.propTypes = {
  name: PropTypes.string,
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
  setEnterpriseValueMap: PropTypes.func,
  enterpriseValueMap: PropTypes.object,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
  terminalValue: PropTypes.string,
};

export default DCFTerminalValueTable;
