import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';

// Styles for the wrapped component
const styles = {
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
};

// Styles for the tooltip label
const useStyles = makeStyles({
  tooltip: {
    textAlign: 'left',
    maxWidth: '200px',
  },
});

// Memoized function to determine whether the tooltip should be displayed
const shouldShowTooltip = (key, tooltipMessages, dialogs, alias) =>
  !isEmpty(tooltipMessages) && !dialogs?.some(({ cellId, alias: cellAlias }) => cellId === key && cellAlias === alias);

const withTooltip = WrappedComponent => {
  const WithTooltip = props => {
    const { cell } = props;
    const { alias, key, tooltipMessages = [] } = cell;

    const classes = useStyles();

    const { dialogs } = useDataSheetDialogActions();

    const shouldRenderTooltip = shouldShowTooltip(key, tooltipMessages, dialogs, alias);
    const title = shouldRenderTooltip ? tooltipMessages.join(' ') : null;

    // Memoize the tooltip placement to prevent unnecessary recalculations
    const tooltipPlacement = useMemo(() => cell?.tooltipPlacement ?? 'top', [cell.tooltipPlacement]);

    const popperOptions = useMemo(() => (cell.positionFixed ? { positionFixed: true } : {}), [cell.positionFixed]);

    const disableHoverEvent = useMemo(
      () => dialogs?.some(({ cellId, alias: cellAlias }) => cellId === key && cellAlias === alias),
      [alias, dialogs, key]
    );

    if (shouldRenderTooltip) {
      return (
        <Tooltip
          arrow
          classes={{ tooltip: classes.tooltip }}
          disableHoverListener={disableHoverEvent}
          disableTouchListener={disableHoverEvent}
          placement={tooltipPlacement}
          PopperProps={{
            disablePortal: !!cell.insideLedger,
            popperOptions,
          }}
          title={title}>
          <div style={styles}>
            <div className="tooltip-indicator" />
            <WrappedComponent {...props} />
          </div>
        </Tooltip>
      );
    }

    return <WrappedComponent {...props} />;
  };

  WithTooltip.propTypes = {
    cell: PropTypes.shape({
      alias: PropTypes.string,
      key: PropTypes.string,
      tooltipMessages: PropTypes.arrayOf(PropTypes.string),
      tooltipPlacement: PropTypes.string,
      positionFixed: PropTypes.bool,
      insideLedger: PropTypes.bool,
    }),
  };
  return WithTooltip;
};

export default withTooltip;
