import {
  DOCUMENT_REQUEST_CATEGORY,
  QUESTIONNAIRE_REQUEST_CATEGORY,
} from '../../../../common/constants/process-management';
import {
  CurrentInformationRequest,
  DocumentProgress,
  QuestionProgress,
} from '../CurrentRequestedDocumentsAndQuestions/types';

export const filterDocumentInformationRequests = (currentInformationRequests: CurrentInformationRequest[]) => {
  const documentRequests = currentInformationRequests.find(request => request.category === DOCUMENT_REQUEST_CATEGORY);
  return (documentRequests?.progress as DocumentProgress[]) || [];
};

export const filterQuestionnaireInformationRequests = (currentInformationRequests: CurrentInformationRequest[]) => {
  const questionRequests = currentInformationRequests.find(
    request => request.category !== QUESTIONNAIRE_REQUEST_CATEGORY
  );
  return (questionRequests?.progress as QuestionProgress[]) || [];
};

export const getDocumentProgressValue = (progress: DocumentProgress[]) => {
  const completed = progress?.filter(item => item.files.length > 0)?.length || 0;
  const total = progress?.length || 0;
  const pending = total - completed;
  const display = `${completed}/${total}`;
  const value = (completed / total) * 100;
  return { display, value, completed, total, pending };
};

export const getQuestionnaireProgressValue = (progress: QuestionProgress[]) => {
  const completed = progress?.filter(item => item.answers?.length > 0)?.length || 0;
  const total = progress?.length || 0;
  const pending = total - completed;
  const display = `${completed}/${total}`;
  const value = (completed / total) * 100;
  return { display, value, completed, total, pending };
};
