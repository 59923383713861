import { FinancialsTransposed } from 'pages/ValuationsAllocation/util';

const rowTransformerCalibrationInputs = (financials: FinancialsTransposed | undefined) => {
  const firstColumn = {
    ltm_revenue_inputs: {
      value: financials?.ltm_revenue,
      id: 'ltm_revenue_inputs',
    },
    ntm_revenue_inputs: {
      value: financials?.ntm_revenue,
      id: 'ntm_revenue_inputs',
    },
    ltm_ebitda_inputs: {
      value: financials?.ltm_ebitda,
      id: 'ltm_ebitda_inputs',
    },
    ntm_ebitda_inputs: {
      value: financials?.ntm_ebitda,
      id: 'ntm_ebitda_inputs',
    },
    VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT: {
      value: financials?.total_debt,
      id: 'VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT',
    },
    VALUATION_SUMMARY_SPREADSHEET_LESS_CASH: {
      value: financials?.total_cash_equivalents,
      id: 'VALUATION_SUMMARY_SPREADSHEET_LESS_CASH',
    },
  };

  return [firstColumn];
};

export default rowTransformerCalibrationInputs;
