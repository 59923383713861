import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { EditFolderDialogProps, FolderMap } from './types';
import { FolderView } from '../../../../api';

const EditFolderDialog = (props: EditFolderDialogProps) => {
  const { open, onClose, folder, onSave, folders } = props;
  const folderMap
    = folders?.reduce((acc, toMapFolder) => {
      if (folder.id === toMapFolder.id || toMapFolder.parent_folder?.id === folder.id) {
        return acc;
      }
      acc[toMapFolder.id as number] = toMapFolder;
      return acc;
    }, {} as FolderMap) || {};

  const [parentFolder, setParentFolder] = React.useState<FolderView | undefined>(
    folderMap[folder.parent_folder?.id as number] || undefined
  );
  const [folderName, setFolderName] = React.useState(folder.name);
  const handleFolderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  };
  const handleParentFolderChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.value === null) {
      setParentFolder(undefined);
      return;
    }
    setParentFolder(folderMap[event.target.value as number]);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Folder</DialogTitle>
      <DialogContent>
        <TextField label="Folder Name" value={folderName} onChange={handleFolderNameChange} fullWidth />
        <FormControl fullWidth>
          <InputLabel id="parent-folder-label">Parent Folder</InputLabel>

          <Select labelId="parent-folder-label" value={parentFolder?.id} onChange={handleParentFolderChange}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {Object.values(folderMap).map(folder => (
              <MenuItem key={folder.id} value={folder.id}>
                {folder.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(folderName, parentFolder)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFolderDialog;
