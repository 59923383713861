/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { ISO_DATE_FORMAT } from 'utillities';
import { isDateInPeriods } from 'utillities/dateUtils';
import { createColumns, customParser, reverseParser, rowConfig } from './config';
import { MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE } from '../common/constants';

const createPerformanceMetricsAsOfDate = ({
  companyName,
  approach,
  measurementDate,
  financials,
  financialsPeriods,
  fiscalYearEnd,
  comparisons,
  multiplePremiumDiscountFromDB,
  companyAsOfDatePerformanceMetrics,
  performanceMetricsSheetColumns,
}) => {
  const name = getApproachTableName({ approach, tableSuffix: 'performance_metrics_as_of_date' });
  const mpdCustomDate = !isEmpty(multiplePremiumDiscountFromDB)
    ? multiplePremiumDiscountFromDB.find(item => item.name === MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE)
    : {};
  const asOfDate = !isEmpty(multiplePremiumDiscountFromDB) ? mpdCustomDate.as_of_date : measurementDate.date;
  const isReadOnlyCompanyPerfMetricsAsOfDateRow = isDateInPeriods(
    asOfDate,
    financialsPeriods,
    'statement_date',
    ISO_DATE_FORMAT
  );

  const getColumns = () =>
    createColumns({
      asOfDate: !isEmpty(multiplePremiumDiscountFromDB) ? mpdCustomDate.as_of_date : measurementDate.date,
      comparisons,
      financials,
      fiscalYearEnd,
      companyName,
      isReadOnlyCompanyPerfMetricsAsOfDateRow,
      financialsPeriods,
      companyAsOfDatePerformanceMetrics,
      measurementDate: measurementDate.date,
      performanceMetricsSheetColumns,
    });

  const columns = getColumns();

  const config = rowConfig({
    asOfDate,
    companyName,
    isReadOnlyCompanyPerfMetricsAsOfDateRow,
    comparisons,
  });

  const spreadsheet = new SpreadsheetConfig({
    columns,
    currencyFormatter: true,
    format: undefined,
    name,
    parser: customParser,
    reverseParser,
    rowConfig: config,
    showToolbar: true,
    page: 'Performance Metrics As Of Date',
    tableTerms: {
      columnName: 'Column',
      pluralColumnName: 'Columns',
      tableName: 'Performance Metrics As Of Date',
      tableSlug: 'performance-metrics-as-of-date',
    },
    tableData: {
      asOfDate: mpdCustomDate.as_of_date,
      approach,
      companyName,
      companyAsOfDatePerformanceMetrics,
    },
    totalParser: undefined,
    unitsFormatter: true,
    reverseParseAfterLoad: true,
    getColumns,
  });

  return { spreadsheet };
};

export default createPerformanceMetricsAsOfDate;
