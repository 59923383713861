import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import {
  PUBLIC_COMPANIES,
  SPECIFIED_VOLATILITY,
} from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/constants';
import customValidations from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/customValidations';
import parser from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/parser';
import reverseParser from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/reverseParser';
import { extractGPCApproaches } from 'pages/Valuations/util/util';
import afterCellChanged from './afterCellChanged';
import conditions from './conditions';

const createOpmInputData = ({ attrs, tableName, data, approaches, measurementDate, isDisabled }) => {
  const name = tableName;
  const column = data;
  const getColumns = () => [{}, column];
  const columns = getColumns();
  const rowTemplate = {
    ignoreRowCopy: true,
  };
  const volatilitySourceOptions = [{ value: null, label: SPECIFIED_VOLATILITY }, ...extractGPCApproaches(approaches)];
  const gpcApproaches = approaches.filter(({ approach_type }) => approach_type === PUBLIC_COMPANIES);
  return new SpreadsheetConfig({
    name,
    parser,
    reverseParser,
    afterCellChanged,
    conditions,
    rowConfig: [
      { ...rowTemplate },
      { ...rowTemplate },
      { ...rowTemplate },
      { ...rowTemplate },
      { ...rowTemplate },
      { ...rowTemplate },
    ],
    tableData: { data, approaches, measurementDate, isDisabled, volatilitySourceOptions, gpcApproaches },
    customValidations,
    columns,
    showTitlesColumn: false,
    fieldAttributes: attrs,
    hasColTitle: false,
    getColumns,
  });
};

export default createOpmInputData;
