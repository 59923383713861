import { isEmpty } from 'lodash';
import { getEnterpriseValueFormula, getEquityValueFormula } from 'common/formulas/math.js/ENTERPRISE_AND_EQUITY_VALUE';
import { EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetAliases/sheetAliases';
import { VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD } from 'pages/ValuationsAllocation/common/constants/valuationSummary/sheetAliases/sheetAliases';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import {
  ADJUSTMENT_TYPE_ALIAS,
  ENTERPRISE_VALUE_OPTION,
  EQUITY_VALUE_OPTION,
  MARKET_ADJUSTMENT_ALIAS,
  MARKET_ADJUSTMENT_CUSTOM_KEY,
  VALUATION_ALLOCATION_METHOD_COLUMN,
} from './constants';
import { BACKSOLVE_TABLE_NAMES } from '../../constants';

const afterCellsChanged = ([change], cells, rowConfig, tableData, workbookCells) => {
  const changes = [change];
  if (change.cell.alias === MARKET_ADJUSTMENT_ALIAS && change.value > 0) {
    const approachId = tableData?.approach?.id ? tableData.approach.id.toString() : null;
    if (!approachId) {
      return;
    }
    const equitySummaryCellsArray = Object.values(workbookCells.equityAllocation);
    const allocationSummaryCellsArray = Object.values(workbookCells.summary);
    const allocationMethodCells = equitySummaryCellsArray.filter(
      cell =>
        cell.alias === EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD && cell.expr?.includes(approachId.toString())
    );
    const titleMethodCells = allocationSummaryCellsArray.filter(
      cell =>
        cell.alias === VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD && cell.expr?.includes(approachId.toString())
    );
    const weightingMethodCells = allocationSummaryCellsArray.filter(
      cell => cell.alias === approachId && cell.columnLegend === VALUATION_ALLOCATION_METHOD_COLUMN
    );
    if (!isEmpty(allocationMethodCells) && allocationMethodCells[0].value === `allocation-method__${approachId}`) {
      changes.push({ cell: allocationMethodCells[0], value: null });
      changes.push({ cell: titleMethodCells[0], value: null });
      changes.push({ cell: weightingMethodCells[0], value: null });
    }
  }
  if (change.cell.alias === ADJUSTMENT_TYPE_ALIAS) {
    const cash = 'summary.less_cash';
    const debt = 'summary.plus_debt';
    const adjustedEquityValueCell = cells.equity_value;
    const adjustedEnterpriseValueCell = cells.enterprise_value;
    const summaryTableName = getApproachTableName({
      approach: tableData.approach,
      tableSuffix: BACKSOLVE_TABLE_NAMES.BACKSOLVE_SUMMARY,
    });
    const backsolveSummaryCells = workbookCells[summaryTableName];
    const enterpriseValueCell = backsolveSummaryCells.summary_enterprise_value;
    const equityValueCell = backsolveSummaryCells.implied_equity_value;
    let adjustedEquityExpr;
    let adjustedEnterpriseExpr;
    if (change.value === EQUITY_VALUE_OPTION) {
      adjustedEquityExpr = `=${summaryTableName}.${equityValueCell.key} + ${summaryTableName}.${equityValueCell.key} * ${MARKET_ADJUSTMENT_CUSTOM_KEY}`;
      adjustedEnterpriseExpr = `=${getEnterpriseValueFormula(adjustedEquityValueCell.key, cash, debt)}`;
      // because these cells interplay with one another we need to delete dependency to avoid a stack overflow
      adjustedEnterpriseValueCell.dependencies.delete(adjustedEquityValueCell);
      changes.push({ cell: adjustedEquityValueCell, value: adjustedEquityExpr });
      changes.push({ cell: adjustedEnterpriseValueCell, value: adjustedEnterpriseExpr });
    } else if (change.value === ENTERPRISE_VALUE_OPTION) {
      adjustedEnterpriseExpr = `=${summaryTableName}.${enterpriseValueCell.key} + ${summaryTableName}.${enterpriseValueCell.key} * ${MARKET_ADJUSTMENT_CUSTOM_KEY}`;
      adjustedEquityExpr = `=${getEquityValueFormula(adjustedEnterpriseValueCell.key, cash, debt)}`;
      adjustedEquityValueCell.dependencies.delete(adjustedEnterpriseValueCell);
      changes.push({ cell: adjustedEnterpriseValueCell, value: adjustedEnterpriseExpr });
      changes.push({ cell: adjustedEquityValueCell, value: adjustedEquityExpr });
    }
  }
  return changes;
};

export default afterCellsChanged;
