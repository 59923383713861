import { uniqBy } from 'lodash';
import { AllocationScenarioValuationModel } from 'api';
import { filterSecurities, getArrayValue, getObjectValue } from 'utillities';
import { AllocationScenarioDetailSharesSecurityModel } from './types';

const getCapTableSecurities = (allocationScenarios: AllocationScenarioValuationModel[]) => {
  const allocationScenariosSecurities = getArrayValue(
    allocationScenarios?.reduce((accumulator, current) => {
      const { cap_table: capTable } = current;
      const { securities: capTableSecurities = [] } = getObjectValue(capTable);

      return [...accumulator, ...filterSecurities(capTableSecurities)];
    }, [] as AllocationScenarioDetailSharesSecurityModel[])
  );

  return getArrayValue(uniqBy(allocationScenariosSecurities, 'name'));
};

export default getCapTableSecurities;
