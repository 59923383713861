import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  CurrentInformationRequest,
  DocumentProgress,
  QuestionProgress,
} from 'dashboard409a/pages/Information/CurrentRequestedDocumentsAndQuestions/types';
import { getCurrentInformationRequests } from 'dashboard409a/services/dashboard';

export interface UseInformation409AProps {
  currentCompanyMeasurementDateId?: number | null;
  setCurrentInformationRequests?: (currentRequests: CurrentInformationRequest[]) => void;
}

const useInformation409A = ({
  currentCompanyMeasurementDateId,
  setCurrentInformationRequests,
}: UseInformation409AProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleGetCurrentInformationRequests = useCallback(async () => {
    try {
      if (currentCompanyMeasurementDateId && setCurrentInformationRequests) {
        setIsLoading(true);
        const currentRequests = await getCurrentInformationRequests(currentCompanyMeasurementDateId);
        setCurrentInformationRequests(currentRequests);
      }
    } catch (error) {
      enqueueSnackbar('Error getting current information requests', { variant: 'error' });
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  }, [currentCompanyMeasurementDateId, enqueueSnackbar, setCurrentInformationRequests]);

  const isDocumentUploaded = (progress: DocumentProgress | QuestionProgress): boolean =>
    'is_uploaded' in progress ? progress.is_uploaded : true;

  const isQuestionAnswered = (progress: DocumentProgress | QuestionProgress): boolean =>
    'answers' in progress ? !isEmpty(progress.answers) : true;

  const isRequestComplete = (request: CurrentInformationRequest): boolean =>
    'progress' in request && request.progress.every(isDocumentUploaded) && request.progress.every(isQuestionAnswered);

  const checkIfAllQuestionsAnsweredAndDocumentsAreUploaded = (
    currentInformationRequests: CurrentInformationRequest[]
  ): boolean => currentInformationRequests.every(isRequestComplete);

  return {
    handleGetCurrentInformationRequests,
    checkIfAllQuestionsAnsweredAndDocumentsAreUploaded,
    isLoading,
    hasLoaded,
  };
};

export default useInformation409A;
