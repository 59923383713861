import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import afterCellChanged from './config/afterCellChanged';
import getRowConfig from './config/getRowConfig';
import parser from './config/parser';
import reverseParser from './config/reverseParser';
import rowTransformer from './config/rowTransformer';
import validations from './config/validations';

const createGpcData = ({
  companyName,
  approach,
  financials,
  financialsPeriods,
  name,
  compGroups,
  gpcAttributes,
  isDisabled,
}) => {
  const filteredFinancialPeriods = financialsPeriods.filter(
    ({ financial_statement }) => financial_statement === approach.financial_statement
  );
  const rowConfig = getRowConfig({
    companyName,
    approach: approach.valuations_approach_gpc,
    allCompGroups: compGroups,
    isDisabled,
  });

  const getColumns = () =>
    rowTransformer(
      approach.valuations_approach_gpc,
      financials,
      filteredFinancialPeriods,
      approach.financial_statement
    );

  const columns = getColumns();

  return new SpreadsheetConfig({
    columns,
    rowConfig,
    parser,
    name,
    tableData: {
      companyName,
      approach,
      financials,
      financialsPeriods: filteredFinancialPeriods,
      isDisabled,
    },
    showToolbar: true,
    reverseParser,
    customValidations: validations,
    afterCellChanged,
    currencyFormatter: true,
    unitsFormatter: true,
    allowConfirmAndDeleteColumn: false,
    fieldAttributes: gpcAttributes,
    allowCopyColumn: false,
    allowReorderColumns: false,
    allowCopyRows: !isDisabled,
    allowAddSingleRow: !isDisabled,
    allowDeleteRow: !isDisabled,
    getColumns,
  });
};

export default createGpcData;
